import { FC } from 'react';

import { Skeleton, Stack } from '@mui/material';

import { StartupHeaderStyles as Styled } from './StartupHeader.styles';

export const StartupHeaderSkeleton: FC = () => {
  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: { xs: 'center', sm: 'space-between' },
          justifyContent: { xs: 'center', sm: 'space-between' },

          width: '100%',
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '30%',
          }}
        >
          <Skeleton sx={{ ...Styled.ImageSX, height: 60 }} />
          <Skeleton variant="rectangular" sx={{ width: '60%', height: 30 }} />
          <Skeleton variant="circular" sx={{ height: 30, width: 30 }} />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Skeleton variant="rectangular" sx={{ width: '40%', height: 30 }} />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            width: '30%',
          }}
        >
          <Skeleton variant="rectangular" sx={{ width: '40%', height: 30 }} />
        </Stack>
      </Stack>
    </>
  );
};
