//here we keep all regex expressions

export const UUID_REGEXP =
  /\/?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/gi;

export const NUMBERS_WITH_DOLLAR_SIGN_REGEXP = /\d+\s?\$/gi;

export const ALL_DIGITS_REGEXP = /\d+/gi;

export const NAME_VALIDATION_REGEX = /^[A-Za-zÀ-ÖØ-öø-ÿ -]*$/;
export const EMAIL_VALIDATION_REGEX =
  /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,})+$/;
