import { FC } from 'react';

import { List, Stack } from '@mui/material';

import { useStartupId } from '@hooks/useStartupId';

import { NavList } from './NavList';
import { StyledSubheader } from './VerticalNavSection.styles';
import { INavSectionProps } from './verticalNavSection.types';

import { LOCAL_STORAGE_LAST_SEARCHED_STARTUP_ID_KEY } from '@constants/global';
import { UUID_REGEXP } from '@constants/regex';

export const VerticalNavSection: FC<INavSectionProps> = (props) => {
  const { data, sx, ...other } = props;

  const { id } = useStartupId();
  const startupId = id?.match(UUID_REGEXP) ? id : localStorage.getItem(LOCAL_STORAGE_LAST_SEARCHED_STARTUP_ID_KEY);

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;
        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {group.subheader && <StyledSubheader disableSticky>{group.subheader}</StyledSubheader>}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                startupId={startupId}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
};
