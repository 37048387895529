import { useGet_Last_Searched_Startups_By_UserQuery } from '@generated/graphql';

import { useNotistackError } from './useNotistackError';
import { useSearchBar } from './useSearchBar';

import { LOCAL_STORAGE_LAST_SEARCHED_STARTUP_ID_KEY } from '@constants/global';

export const useGetLastSearchedStartupsByUser = () => {
  const { setIsEmptyScreen, isEmptyScreen } = useSearchBar();

  const {
    data: lastSearchedStartups,
    loading: isLastSearchedLoading,
    error,
  } = useGet_Last_Searched_Startups_By_UserQuery({
    onCompleted(data) {
      //set isEmptyValue if we do not have startups
      const startups = data?.users[0]?.last_search_relation_users_startups;
      const isEmpty = !startups?.length || !!error;
      isEmptyScreen !== isEmpty && setIsEmptyScreen(isEmpty);

      startups &&
        localStorage.setItem(
          LOCAL_STORAGE_LAST_SEARCHED_STARTUP_ID_KEY,
          startups[0]?.startup.uuid
        );
    },
  });

  useNotistackError(error);

  return {
    lastSearchedStartups,
    isLastSearchedLoading,
    isEmptyScreen,
  };
};
