import { FC, useMemo } from 'react';

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useAuth0 } from '@auth0/auth0-react';
import { useSnackbar } from 'notistack';

import { BASE_API_URL } from '@services/apiService';

import { IProvider } from './types';

export const AppApolloProvider: FC<IProvider> = (props) => {
  const { children } = props;
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const client = useMemo(() => {
    const httpLink = new HttpLink({
      uri: BASE_API_URL,
    });

    const authLink = setContext(async (_, { headers, ...rest }) => {
      try {
        const token = await getAccessTokenSilently();
        if (!token) return { headers, ...rest };

        return {
          headers: {
            ...headers,
            Authorization: `Bearer ${token}`,
          },
        };
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Failed to get Auth0 token', {
          variant: 'error',
        });
      }
    });

    return new ApolloClient({
      link: authLink.concat(authLink.concat(httpLink)),
      cache: new InMemoryCache(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
