import { FC } from 'react';

import { SxProps, Theme } from '@mui/material';

import { Icon, TIconType } from './Icon';
import { IconStyles as Styled } from './Icon.styles';

interface ICenteredIconProps {
  type: TIconType;
  sx?: SxProps<Theme>;
}
export const CenteredIcon: FC<ICenteredIconProps> = (props) => (
  <Styled.IconWrapper sx={props.sx}>
    <Icon type={props.type} />
  </Styled.IconWrapper>
);
