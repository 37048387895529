import { FC, useEffect, useRef, useState } from 'react';

import { Alert, Button, Collapse, Container, Grid, Icon, IconButton, TextField, Typography } from '@mui/material';

import { AlertSweetSpot } from '@components/AlertSweetSpot';
import { StartupHeader } from '@components/Cards/StartupHeader/StartupHeader';
import { ChatMessage } from '@components/Chat/Message';
import { Iconify } from '@components/Iconify';
import { NoSearchedStartupsScreen } from '@components/NoSearchedStartupsScreen';
import { useSearchBar } from '@hooks/useSearchBar';
import { useStartupId } from '@hooks/useStartupId';

const Chat: FC = () => {
  const { isEmptyScreen } = useSearchBar();
  const { id, isNotUUID } = useStartupId();

  const [messages, setMessages] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [disabled, setDisabled] = useState(false);

  // Scroll to bottom on initial load
  const [data, setData] = useState<string>('');
  const [openAImessages, setOpenAImessages] = useState<any[]>([]);
  const [messageSources, setMessageSources] = useState<any[]>([]);

  const [alertOpen, setAlertOpen] = useState(true);

  function splitMarkdown(md: any) {
    // Split by two or more newlines
    let units = md.split(/\n{2,}/);

    // Process each unit for finer granularity (like list items)
    units = units.reduce((acc: any, unit: any) => {
      // Check if the unit is a list (ordered or unordered)
      if (unit.match(/^\s*([-*+]\s+|\d+\.\s+)/)) {
        let items = unit.split(/\n(?=\s*[-*+]\s+|\s*\d+\.\s+)/);
        return acc.concat(items);
      }
      return acc.concat(unit);
    }, []);

    return units;
  }

  const url =
    'https://z6iyarfbk6bhhzafql6b7n6wyy0odiue.lambda-url.eu-west-3.on.aws?x-api-key=aZ917gbvlmaU5pbwX1V6y6fDwldyXq0U44Ew51d1';

  const fetchData = async () => {
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        content: inputValue,
        startupId: id,
        messages: openAImessages,
      }),
    });

    if (response.body) {
      const reader = response.body.getReader();

      const stream = new ReadableStream({
        start(controller) {
          function push() {
            reader.read().then(({ done, value }) => {
              // When no more data needs to be consumed, close the stream
              if (done) {
                controller.close();
                setDisabled(false);

                // Scroll to bottom
                const element = document.getElementById('chat');
                if (element) {
                  element.scrollTop = element.scrollHeight;
                }

                // re-focus  id="outlined-multiline-static"
                const input = document.getElementById('outlined-multiline-static');
                if (input) {
                  input.focus();
                }

                return;
              }

              // Enqueue the next data chunk into our target stream
              controller.enqueue(value);

              const response = new TextDecoder().decode(value);
              const buffers = response.split('\n');

              const data = buffers.map((buffer: any) => {
                buffer = buffer.replace('data: ', '');

                if (buffer.length > 5 && !buffer.includes('[DONE]')) {
                  console.log(buffer);
                  return JSON.parse(buffer);
                } else {
                  return null;
                }
              });

              for (let chunk of data) {
                if (
                  chunk &&
                  chunk?.type != 'sources_and_messages' &&
                  chunk.choices.length &&
                  chunk.choices[0].delta.content
                ) {
                  setMessages((prevMessage) => {
                    return prevMessage.length && prevMessage[prevMessage.length - 1].role === 'assistant'
                      ? [
                          ...prevMessage.slice(0, prevMessage.length - 1),
                          {
                            role: 'assistant',
                            content: prevMessage[prevMessage.length - 1].content + chunk.choices[0].delta.content,
                            sourceId: prevMessage[prevMessage.length - 1].sourceId,
                          },
                        ]
                      : [
                          ...prevMessage,
                          {
                            role: 'assistant',
                            content: chunk.choices[0].delta.content,
                            sourceId: messageSources.length,
                          },
                        ];
                  });
                } else if (chunk?.type == 'sources_and_messages') {
                  setOpenAImessages(chunk.messages);

                  let content = chunk.messages[chunk.messages.length - 1].content;
                  const correctedSources: any = [];
                  const URLs: any = [];

                  chunk.sources.forEach((source: any, index: any) => {
                    if (content.includes(`[${index + 1}]`) && URLs.indexOf(source.source_url) === -1) {
                      correctedSources.push(source);
                      URLs.push(source.source_url);
                      content = content.replace(new RegExp(`\\[${index + 1}\\]`, 'g'), `[${correctedSources.length}]`);
                    } else if (content.includes(`[${index + 1}]`)) {
                      const sourceIndex = URLs.indexOf(source.source_url);
                      content = content.replace(new RegExp(`\\[${index + 1}\\]`, 'g'), `[${sourceIndex + 1}]`);
                    }
                  });

                  for (let i = 0; i < correctedSources.length; i++) {
                    // replace all repeated [N] by a single [N]
                    // e.g: [1][1][1] -> [1] and [1][1] -> [1]
                    let regex = new RegExp(`\\[${i}\\](?=\\[${i}\\])`, 'g');
                    content = content.replace(regex, '');
                  }

                  setMessages((prevMessage) => [
                    ...prevMessage.slice(0, prevMessage.length - 1),
                    {
                      role: 'assistant',
                      content: content,
                      sourceId: messageSources.length,
                    },
                  ]);

                  console.log(content);
                  console.log(correctedSources);

                  setMessageSources((prevSources: any) => [...prevSources, correctedSources]);
                }
              }

              // Recursively read the next chunk
              push();
            });
          }

          push();
        },
      });
    }
  };

  return (
    <Container
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: 'calc(100vh - 100px)',
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: '-16px',
        marginRight: '-16px',
        width: 'calc(100% + 32px)',
        minWidth: 'calc(100% + 32px)',
      }}
    >
      <Collapse
        in={alertOpen}
        style={{
          padding: '0px 64px 16px 64px',
        }}
      >
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <Iconify icon="mdi:close" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          This is a beta version of the chat module. Context only includes startup website text content & Crunchbase
          description. Dropbox, Slack and other data sources will be added in future updates.
        </Alert>
      </Collapse>
      {isEmptyScreen || isNotUUID ? (
        <NoSearchedStartupsScreen />
      ) : (
        <>
          <AlertSweetSpot startupId={id} />

          <div
            style={{
              padding: '0px 64px 16px 64px',
            }}
          >
            <StartupHeader startupId={id} />
          </div>
          <div
            style={{
              flex: '1',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column', // Reverse order
            }}
            id="chat"
          >
            {messages.map((message, index) => (
              <ChatMessage
                key={index}
                role={message.role}
                content={message.content}
                id={index}
                sources={message.role === 'assistant' ? messageSources[message.sourceId] : []}
              />
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px 0px',
              zIndex: 10,
              position: 'sticky',
              bottom: 0,
              background: 'white',
              backgroundColor: 'rgba(255,255,255, 0.75)',
            }}
          >
            <TextField
              onChange={(e) => setInputValue(e.target.value)} // Update state on change
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  setMessages([
                    ...messages,
                    {
                      role: 'user',
                      content: inputValue,
                    },
                  ]);
                  setInputValue('');
                  e.preventDefault();
                  setDisabled(true);
                  fetchData(); // Call fetchData when a message is sent
                }
              }}
              id="outlined-multiline-static"
              multiline
              value={inputValue}
              placeholder="Type your message..."
              maxRows={8}
              disabled={disabled}
              style={{
                backgroundColor: 'white',
                maxWidth: '50%',
                width: '50%',
                padding: 0,
                margin: 'auto',
              }}
              InputProps={{
                endAdornment: (
                  <Icon>
                    <Iconify icon="mdi:send" />
                  </Icon>
                ),
              }}
            />
          </div>
        </>
      )}
    </Container>
  );
};

export default Chat;
