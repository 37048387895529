import { FC } from 'react';

import { LinearProgress } from '@mui/material';

import { StyledBox } from './CallBackPage.styles';

export const CallBackPage: FC = () => {
  return (
    <StyledBox>
      <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
    </StyledBox>
  );
};
