import { FC } from 'react';

import { Link, LinkProps } from '@mui/material';

interface ICustomLinkProps extends LinkProps {
  children: React.ReactNode;
}
export const CustomLink: FC<ICustomLinkProps> = (props) => {
  const { children, href, sx, ...other } = props;

  const onLinkClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => !href && event.preventDefault();

  return (
    <Link
      sx={{ textDecoration: 'none', color: 'inherit', ...sx }}
      onClick={onLinkClick}
      href={href}
      {...other}
    >
      {children}
    </Link>
  );
};
