import { FC } from 'react';

import { MenuItem, Skeleton, Stack, Typography } from '@mui/material';

import { useGet_Sourcing_Criteria_For_CountryQuery } from '@generated/graphql';
import { useNotistackError } from '@hooks/useNotistackError';

import { Iconify } from '../../Iconify';
import { CustomLink } from '../../Link';
import { Scrollbar } from '../../ScrollBar';

import { getScopeInfo } from './helpers';
import { ScopeInfo } from './ScopeInfo';
import { getPopoverLinkItems } from './StartupLogoPopover.constants';
import { IStartupLogoPopoverProps } from './StartupLogoPopover.types';

export const StartupLogoPopover: FC<IStartupLogoPopoverProps> = (props) => {
  const { startup } = props;
  const { data, error, loading } = useGet_Sourcing_Criteria_For_CountryQuery({
    variables: {
      _eq: startup?.headquartersCountry,
    },
  });

  useNotistackError(error);

  const dataSourcesLastUpdate = startup?.data_sources_last_update;

  const popoverLinks = getPopoverLinkItems({
    crunchbase: {
      url: startup?.crunchbase_link,
      date: dataSourcesLastUpdate?.crunchbase,
    },
    g2: {
      url: startup?.g2_link,
      date: dataSourcesLastUpdate?.g2,
    },
    glassdoor: {
      url: startup?.glassdoor,
      date: dataSourcesLastUpdate?.glassdoor,
    },
    linkedIn: {
      url: startup?.linkedin,
      date: dataSourcesLastUpdate?.linkedIn,
    },
    twitter: {
      url: startup?.twitter,
      date: dataSourcesLastUpdate?.twitter,
    },
    similarWeb: {
      url: '',
      date: dataSourcesLastUpdate?.similarweb,
    },
  });

  const scopeInfo = getScopeInfo({
    startup,
    minimumAmountRaised:
      data?.country_of_startups_for_sourcing[0]?.minimumAmountRaised,
  });

  return (
    <Stack spacing={1.5} sx={{ py: 1.2, px: 1.5 }}>
      <Stack direction="row" sx={{ px: 1.5, pt: 0.5 }}>
        <Typography variant="h6">Sources</Typography>
      </Stack>
      <Scrollbar
        sx={{
          maxHeight: 320,
          height: '100%',
          '& .simplebar-track.simplebar-vertical': {
            right: 0,
          },
        }}
      >
        {popoverLinks.map((item) => (
          <CustomLink key={item.title} target="_blank" href={item.url || ''}>
            <MenuItem>
              <Iconify
                icon={item.iconName}
                color={item.color}
                sx={{ mr: '8px !important' }}
              />
              <Stack>
                <Stack direction="row" spacing={0.3}>
                  <Typography variant="subtitle2">{item.title}</Typography>
                  {item.date && (
                    <Typography variant="subtitle2">{`- ${item.date}`}</Typography>
                  )}
                </Stack>
              </Stack>
            </MenuItem>
          </CustomLink>
        ))}

        <Stack sx={{ px: 1 }}>
          {loading ? (
            <Skeleton variant="rounded" height={80} />
          ) : (
            <ScopeInfo scopeInfo={scopeInfo} />
          )}
        </Stack>
      </Scrollbar>
    </Stack>
  );
};
