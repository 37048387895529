import { Theme } from '@mui/material/styles';

import { Alert } from './Alert';
import { Button } from './Button';
import { Progress } from './Progress';
import { Table } from './Table';
import { Tabs } from './Tabs';

export const ComponentsOverrides = (theme: Theme) =>
  Object.assign(
    Tabs(theme),
    Button(theme),
    Table(theme),
    Alert(theme),
    Progress(theme)
  );
