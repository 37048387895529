import { FC } from 'react';

import { Popover, PopoverOrigin } from '@mui/material';

import { getPosition } from './getPosition';
import { MenuPopoverStyles as Styled } from './styles';
import { IMenuPopoverProps } from './types';

export const PopoverWrapper: FC<IMenuPopoverProps> = (props) => {
  const {
    open,
    children,
    arrow = 'top-right',
    disabledArrow,
    sx,
    PaperProps,
    popoverSX,
    anchorEl,
    ...other
  } = props;

  const { style, anchorOrigin, transformOrigin } = getPosition(arrow);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin as PopoverOrigin}
      transformOrigin={transformOrigin as PopoverOrigin}
      PaperProps={{
        sx: {
          p: 1,
          width: 'auto',
          overflow: 'inherit',
          ...style,
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20, flexShrink: 0 },
          },
          ...sx,
        },
        ...PaperProps,
      }}
      sx={popoverSX}
      {...other}
    >
      {!disabledArrow && <Styled.StyledArrow arrow={arrow} />}

      {children}
    </Popover>
  );
};
