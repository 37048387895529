import { FC } from 'react';

import { AppState, Auth0Provider as Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { IProvider } from './types';

import { ROUTES } from '@constants/routes';

const domain = process.env.REACT_APP_AUTH_DOMAIN;
const clientId = process.env.REACT_APP_CLIENT_ID;
const redirect_uri = `${window.location.origin}${ROUTES.callback}`;

export const Auth0Provider: FC<IProvider> = (props) => {
  const { children } = props;

  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) =>
    navigate(appState?.returnTo || ROUTES.home, { replace: true });

  if (!(domain && clientId)) {
    return null;
  }

  return (
    <Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri,
        scope: 'openid profile',
        connection: 'Username-Password-Authentication',
        audience: 'hasura',
      }}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Provider>
  );
};
