import { useState } from 'react';

//hook for toggle MUI popovers
export const useTogglePopover = () => {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) =>
    setOpenPopover(event.currentTarget);

  const handleClosePopover = () => setOpenPopover(null);

  return { openPopover, handleOpenPopover, handleClosePopover };
};
