import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';

import { IconButtonAnimate } from '../../../../animate';
import { CustomAvatar } from '../../../../CustomAvatar';
import { PopoverWrapper } from '../../../../popovers/PopoverWrapper';

import { MENU_LIST } from './AccountPopover.constants';
import { AccountPopoverStyles as Styled } from './AccountPopover.styles';
import { useAccountPopoverState } from './useAccountPopover.state';

export const AccountPopover = () => {
  const {
    handleClosePopover,
    handleOpenPopover,
    onMenuItemClickHandler,
    openPopover,
    user,
  } = useAccountPopoverState();
  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && Styled.IconButtonAnimateSX),
        }}
      >
        <CustomAvatar
          src={user?.picture}
          alt="user name"
          name={user?.nickname}
        />
      </IconButtonAnimate>

      <PopoverWrapper
        open={!!openPopover}
        anchorEl={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0 }}
        key={openPopover ? 'open' : 'closed'}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.nickname}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.name}
          </Typography>
        </Box>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_LIST.map((item) => (
            <MenuItem
              key={item.title}
              id={item.to}
              onClick={onMenuItemClickHandler}
            >
              {item.title}
            </MenuItem>
          ))}
        </Stack>
      </PopoverWrapper>
    </>
  );
};
