import { FC, Suspense, useEffect } from 'react';

import posthog from 'posthog-js';
import { Route, Routes, useLocation } from 'react-router-dom';

import { MainLayout } from '@components/Layout/MainLayout';
import { LoadingScreen } from '@components/LoadingScreen';
import { CallBackPage } from '@screens/CallBack';
import { Home } from '@screens/Main/Home';
import { Profile } from '@screens/Main/Profile';
import { Settings } from '@screens/Main/Settings';
import Chat from '@screens/Main/Startups/Chat/Chat';
import { Competition } from '@screens/Main/Startups/Competition';
import { ESG } from '@screens/Main/Startups/ESG';
import { Growth } from '@screens/Main/Startups/Growth';
import { International } from '@screens/Main/Startups/International';
import { Overview } from '@screens/Main/Startups/Overview';
import { Recruitment } from '@screens/Main/Startups/Recruitment';
import { Team } from '@screens/Main/Startups/Team';
import { WebsiteApps } from '@screens/Main/Startups/WebsiteApps';
import { Page404 } from '@screens/Page404';

import { PrivateRouter } from './PrivateRouter';

import { ROUTES } from '@constants/routes';

export const AppRouter: FC = () => {
  let location = useLocation(); // new

  useEffect(() => {
    // new
    posthog.capture('$pageview');
  }, [location]);

  return (
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path={ROUTES.home} element={<MainLayout />}>
          <Route element={<PrivateRouter />}>
            <Route index element={<Home />} />
            <Route path={ROUTES.startups.root}>
              <Route path={ROUTES.startups.overview} element={<Overview />} />
              <Route path={ROUTES.startups.growth} element={<Growth />} />
              <Route path={ROUTES.startups.team} element={<Team />} />
              <Route path={ROUTES.startups.international} element={<International />} />
              <Route path={ROUTES.startups.competition} element={<Competition />} />

              <Route path={ROUTES.startups.esg} element={<ESG />} />

              <Route path={ROUTES.startups.websiteApp} element={<WebsiteApps />} />
              <Route path={ROUTES.startups.chat} element={<Chat />} />
              <Route path={ROUTES.startups.recruitment} element={<Recruitment />} />
            </Route>
            <Route path={ROUTES.settings} element={<Settings />} />
            <Route path={ROUTES.profile} element={<Profile />} />
          </Route>
        </Route>

        <Route path={ROUTES.notFound} element={<Page404 />} />
        <Route path={ROUTES.callback} element={<CallBackPage />} />
      </Routes>
    </Suspense>
  );
};
