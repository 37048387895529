import { FC } from 'react';

import { Modal, Stack, Typography } from '@mui/material';

import { StyledCard } from '@styles/overrides/Card';

import { AddStartupStyles as Styled } from '../AddStartup/AddStartup.styles';

interface ISuccessfullyRefreshedModalWindowProps {
  isDataRefreshedModalWindowOpen: boolean;
  onToggleIsDataRefreshedModalWindowOpen: () => void;
}

export const SuccessfullyRefreshedModalWindow: FC<
  ISuccessfullyRefreshedModalWindowProps
> = (props) => {
  const {
    isDataRefreshedModalWindowOpen,
    onToggleIsDataRefreshedModalWindowOpen,
  } = props;
  return (
    <Modal
      open={isDataRefreshedModalWindowOpen}
      onClose={onToggleIsDataRefreshedModalWindowOpen}
      sx={{ m: 1 }}
    >
      <StyledCard sx={Styled.CardSX}>
        <Stack sx={{ maxWidth: 320, width: 1 }} spacing={3}>
          <Typography variant="h4" textAlign="center">
            Data are being refreshed...
          </Typography>

          <Typography variant="body1" textAlign="center">
            It should take 5 to 15 min{' '}
          </Typography>
        </Stack>
      </StyledCard>
    </Modal>
  );
};
