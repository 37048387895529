import { FC } from 'react';

import { Box, SxProps, Theme } from '@mui/material';

import { Iconify } from '../Iconify';

interface IInfoIcon2Props {
  sx?: SxProps<Theme>;
  handleOpenPopover: (event: React.MouseEvent<HTMLElement>) => void;
}

export const InfoIcon2: FC<IInfoIcon2Props> = (props) => {
  const { handleOpenPopover, sx } = props;
  return (
    <Box
      component="span"
      sx={{
        display: 'flex',

        cursor: 'pointer',
        ...sx,
      }}
      onClick={handleOpenPopover}
    >
      <Iconify
        icon="ic:baseline-info"
        sx={{
          color: (theme) => theme.palette.grey[700],
          transition: '0.25s',
          ':hover': {
            color: (theme) => theme.palette.grey[900],
          },
          width: 24,
        }}
      />
    </Box>
  );
};
