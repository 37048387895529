import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

import { useTogglePopover } from '@hooks/useTogglePopover';

export const useAccountPopoverState = () => {
  const { user } = useAuth0();
  const navigate = useNavigate();

  const { handleClosePopover, handleOpenPopover, openPopover } =
    useTogglePopover();

  const onMenuItemClickHandler = (event: React.MouseEvent<HTMLLIElement>) => {
    handleClosePopover();
    navigate(event.currentTarget.id);
  };

  return {
    handleClosePopover,
    handleOpenPopover,
    onMenuItemClickHandler,
    openPopover,
    user,
  };
};
