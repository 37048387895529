import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  bpchar: any;
  date: any;
  float8: any;
  jsonb: any;
  numeric: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "bcorp_startups" */
export type Bcorp_Startups = {
  __typename?: 'bcorp_startups';
  bcorp_score?: Maybe<Scalars['float8']>;
  community_score?: Maybe<Scalars['float8']>;
  customer_score?: Maybe<Scalars['float8']>;
  date: Scalars['date'];
  environment_score?: Maybe<Scalars['float8']>;
  governance_score?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  /** An object relationship */
  startup: Startups;
  startup_in_bcorp: Scalars['Boolean'];
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  worker_score?: Maybe<Scalars['float8']>;
};

/** order by aggregate values of table "bcorp_startups" */
export type Bcorp_Startups_Aggregate_Order_By = {
  avg?: InputMaybe<Bcorp_Startups_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Bcorp_Startups_Max_Order_By>;
  min?: InputMaybe<Bcorp_Startups_Min_Order_By>;
  stddev?: InputMaybe<Bcorp_Startups_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Bcorp_Startups_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Bcorp_Startups_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Bcorp_Startups_Sum_Order_By>;
  var_pop?: InputMaybe<Bcorp_Startups_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Bcorp_Startups_Var_Samp_Order_By>;
  variance?: InputMaybe<Bcorp_Startups_Variance_Order_By>;
};

/** order by avg() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Avg_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "bcorp_startups". All fields are combined with a logical 'AND'. */
export type Bcorp_Startups_Bool_Exp = {
  _and?: InputMaybe<Array<Bcorp_Startups_Bool_Exp>>;
  _not?: InputMaybe<Bcorp_Startups_Bool_Exp>;
  _or?: InputMaybe<Array<Bcorp_Startups_Bool_Exp>>;
  bcorp_score?: InputMaybe<Float8_Comparison_Exp>;
  community_score?: InputMaybe<Float8_Comparison_Exp>;
  customer_score?: InputMaybe<Float8_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  environment_score?: InputMaybe<Float8_Comparison_Exp>;
  governance_score?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_in_bcorp?: InputMaybe<Boolean_Comparison_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  worker_score?: InputMaybe<Float8_Comparison_Exp>;
};

/** order by max() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Max_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Min_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "bcorp_startups". */
export type Bcorp_Startups_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_in_bcorp?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** select columns of table "bcorp_startups" */
export enum Bcorp_Startups_Select_Column {
  /** column name */
  BcorpScore = 'bcorp_score',
  /** column name */
  CommunityScore = 'community_score',
  /** column name */
  CustomerScore = 'customer_score',
  /** column name */
  Date = 'date',
  /** column name */
  EnvironmentScore = 'environment_score',
  /** column name */
  GovernanceScore = 'governance_score',
  /** column name */
  Id = 'id',
  /** column name */
  StartupInBcorp = 'startup_in_bcorp',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  WorkerScore = 'worker_score',
}

/** order by stddev() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Stddev_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Stddev_Pop_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Stddev_Samp_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "bcorp_startups" */
export type Bcorp_Startups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Bcorp_Startups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Bcorp_Startups_Stream_Cursor_Value_Input = {
  bcorp_score?: InputMaybe<Scalars['float8']>;
  community_score?: InputMaybe<Scalars['float8']>;
  customer_score?: InputMaybe<Scalars['float8']>;
  date?: InputMaybe<Scalars['date']>;
  environment_score?: InputMaybe<Scalars['float8']>;
  governance_score?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  startup_in_bcorp?: InputMaybe<Scalars['Boolean']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  worker_score?: InputMaybe<Scalars['float8']>;
};

/** order by sum() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Sum_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Var_Pop_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Var_Samp_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "bcorp_startups" */
export type Bcorp_Startups_Variance_Order_By = {
  bcorp_score?: InputMaybe<Order_By>;
  community_score?: InputMaybe<Order_By>;
  customer_score?: InputMaybe<Order_By>;
  environment_score?: InputMaybe<Order_By>;
  governance_score?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  worker_score?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']>;
  _gt?: InputMaybe<Scalars['bpchar']>;
  _gte?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']>;
  _in?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']>;
  _lt?: InputMaybe<Scalars['bpchar']>;
  _lte?: InputMaybe<Scalars['bpchar']>;
  _neq?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']>;
};

/** columns and relationships of "cofounders" */
export type Cofounders = {
  __typename?: 'cofounders';
  averageArticles?: Maybe<Scalars['float8']>;
  averageYearsOfExperience?: Maybe<Scalars['float8']>;
  calculatedDate?: Maybe<Scalars['String']>;
  cofounderScore?: Maybe<Scalars['float8']>;
  cofounderScoreComplementary?: Maybe<Scalars['float8']>;
  cofounderScoreTeamLevel?: Maybe<Scalars['float8']>;
  confidenceScore?: Maybe<Scalars['Int']>;
  founderCoverage?: Maybe<Scalars['String']>;
  haveAtLeastFoundedOneOrganization?: Maybe<Scalars['Int']>;
  haveAtLeastOneExit?: Maybe<Scalars['Int']>;
  haveAtLeastOneIndustryExperienceOfTheirStartup?: Maybe<Scalars['Boolean']>;
  haveAtLeastOneTechnicalAndOneBusinessProfile?: Maybe<Scalars['Boolean']>;
  haveDifferentProfiles?: Maybe<Scalars['Boolean']>;
  haveRaisedDuringAFounderExperience?: Maybe<Scalars['float8']>;
  haveWorkedInDifferentCountries?: Maybe<Scalars['Boolean']>;
  haveWorkedOrAttendedUniversityTogether?: Maybe<Scalars['Boolean']>;
  havingTopExperience?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  percentile_cofounder_score?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  startup: Startups;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
  whoDidTopTierSchool?: Maybe<Scalars['float8']>;
};

/** Boolean expression to filter rows from the table "cofounders". All fields are combined with a logical 'AND'. */
export type Cofounders_Bool_Exp = {
  _and?: InputMaybe<Array<Cofounders_Bool_Exp>>;
  _not?: InputMaybe<Cofounders_Bool_Exp>;
  _or?: InputMaybe<Array<Cofounders_Bool_Exp>>;
  averageArticles?: InputMaybe<Float8_Comparison_Exp>;
  averageYearsOfExperience?: InputMaybe<Float8_Comparison_Exp>;
  calculatedDate?: InputMaybe<String_Comparison_Exp>;
  cofounderScore?: InputMaybe<Float8_Comparison_Exp>;
  cofounderScoreComplementary?: InputMaybe<Float8_Comparison_Exp>;
  cofounderScoreTeamLevel?: InputMaybe<Float8_Comparison_Exp>;
  confidenceScore?: InputMaybe<Int_Comparison_Exp>;
  founderCoverage?: InputMaybe<String_Comparison_Exp>;
  haveAtLeastFoundedOneOrganization?: InputMaybe<Int_Comparison_Exp>;
  haveAtLeastOneExit?: InputMaybe<Int_Comparison_Exp>;
  haveAtLeastOneIndustryExperienceOfTheirStartup?: InputMaybe<Boolean_Comparison_Exp>;
  haveAtLeastOneTechnicalAndOneBusinessProfile?: InputMaybe<Boolean_Comparison_Exp>;
  haveDifferentProfiles?: InputMaybe<Boolean_Comparison_Exp>;
  haveRaisedDuringAFounderExperience?: InputMaybe<Float8_Comparison_Exp>;
  haveWorkedInDifferentCountries?: InputMaybe<Boolean_Comparison_Exp>;
  haveWorkedOrAttendedUniversityTogether?: InputMaybe<Boolean_Comparison_Exp>;
  havingTopExperience?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  percentile_cofounder_score?: InputMaybe<Numeric_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  whoDidTopTierSchool?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "cofounders". */
export type Cofounders_Order_By = {
  averageArticles?: InputMaybe<Order_By>;
  averageYearsOfExperience?: InputMaybe<Order_By>;
  calculatedDate?: InputMaybe<Order_By>;
  cofounderScore?: InputMaybe<Order_By>;
  cofounderScoreComplementary?: InputMaybe<Order_By>;
  cofounderScoreTeamLevel?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  founderCoverage?: InputMaybe<Order_By>;
  haveAtLeastFoundedOneOrganization?: InputMaybe<Order_By>;
  haveAtLeastOneExit?: InputMaybe<Order_By>;
  haveAtLeastOneIndustryExperienceOfTheirStartup?: InputMaybe<Order_By>;
  haveAtLeastOneTechnicalAndOneBusinessProfile?: InputMaybe<Order_By>;
  haveDifferentProfiles?: InputMaybe<Order_By>;
  haveRaisedDuringAFounderExperience?: InputMaybe<Order_By>;
  haveWorkedInDifferentCountries?: InputMaybe<Order_By>;
  haveWorkedOrAttendedUniversityTogether?: InputMaybe<Order_By>;
  havingTopExperience?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percentile_cofounder_score?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  whoDidTopTierSchool?: InputMaybe<Order_By>;
};

/** select columns of table "cofounders" */
export enum Cofounders_Select_Column {
  /** column name */
  AverageArticles = 'averageArticles',
  /** column name */
  AverageYearsOfExperience = 'averageYearsOfExperience',
  /** column name */
  CalculatedDate = 'calculatedDate',
  /** column name */
  CofounderScore = 'cofounderScore',
  /** column name */
  CofounderScoreComplementary = 'cofounderScoreComplementary',
  /** column name */
  CofounderScoreTeamLevel = 'cofounderScoreTeamLevel',
  /** column name */
  ConfidenceScore = 'confidenceScore',
  /** column name */
  FounderCoverage = 'founderCoverage',
  /** column name */
  HaveAtLeastFoundedOneOrganization = 'haveAtLeastFoundedOneOrganization',
  /** column name */
  HaveAtLeastOneExit = 'haveAtLeastOneExit',
  /** column name */
  HaveAtLeastOneIndustryExperienceOfTheirStartup = 'haveAtLeastOneIndustryExperienceOfTheirStartup',
  /** column name */
  HaveAtLeastOneTechnicalAndOneBusinessProfile = 'haveAtLeastOneTechnicalAndOneBusinessProfile',
  /** column name */
  HaveDifferentProfiles = 'haveDifferentProfiles',
  /** column name */
  HaveRaisedDuringAFounderExperience = 'haveRaisedDuringAFounderExperience',
  /** column name */
  HaveWorkedInDifferentCountries = 'haveWorkedInDifferentCountries',
  /** column name */
  HaveWorkedOrAttendedUniversityTogether = 'haveWorkedOrAttendedUniversityTogether',
  /** column name */
  HavingTopExperience = 'havingTopExperience',
  /** column name */
  Id = 'id',
  /** column name */
  PercentileCofounderScore = 'percentile_cofounder_score',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  WhoDidTopTierSchool = 'whoDidTopTierSchool',
}

/** Streaming cursor of the table "cofounders" */
export type Cofounders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cofounders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cofounders_Stream_Cursor_Value_Input = {
  averageArticles?: InputMaybe<Scalars['float8']>;
  averageYearsOfExperience?: InputMaybe<Scalars['float8']>;
  calculatedDate?: InputMaybe<Scalars['String']>;
  cofounderScore?: InputMaybe<Scalars['float8']>;
  cofounderScoreComplementary?: InputMaybe<Scalars['float8']>;
  cofounderScoreTeamLevel?: InputMaybe<Scalars['float8']>;
  confidenceScore?: InputMaybe<Scalars['Int']>;
  founderCoverage?: InputMaybe<Scalars['String']>;
  haveAtLeastFoundedOneOrganization?: InputMaybe<Scalars['Int']>;
  haveAtLeastOneExit?: InputMaybe<Scalars['Int']>;
  haveAtLeastOneIndustryExperienceOfTheirStartup?: InputMaybe<Scalars['Boolean']>;
  haveAtLeastOneTechnicalAndOneBusinessProfile?: InputMaybe<Scalars['Boolean']>;
  haveDifferentProfiles?: InputMaybe<Scalars['Boolean']>;
  haveRaisedDuringAFounderExperience?: InputMaybe<Scalars['float8']>;
  haveWorkedInDifferentCountries?: InputMaybe<Scalars['Boolean']>;
  haveWorkedOrAttendedUniversityTogether?: InputMaybe<Scalars['Boolean']>;
  havingTopExperience?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  percentile_cofounder_score?: InputMaybe<Scalars['numeric']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  whoDidTopTierSchool?: InputMaybe<Scalars['float8']>;
};

/** columns and relationships of "competition_classification" */
export type Competition_Classification = {
  __typename?: 'competition_classification';
  confidence?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  feedback?: Maybe<Scalars['String']>;
  prediction: Scalars['Boolean'];
  probability?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  startup: Startups;
  /** An object relationship */
  startupByStartupUuid2: Startups;
  startup_name_1: Scalars['String'];
  startup_name_2: Scalars['String'];
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
  unique_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by aggregate values of table "competition_classification" */
export type Competition_Classification_Aggregate_Order_By = {
  avg?: InputMaybe<Competition_Classification_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Competition_Classification_Max_Order_By>;
  min?: InputMaybe<Competition_Classification_Min_Order_By>;
  stddev?: InputMaybe<Competition_Classification_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Competition_Classification_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Competition_Classification_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Competition_Classification_Sum_Order_By>;
  var_pop?: InputMaybe<Competition_Classification_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Competition_Classification_Var_Samp_Order_By>;
  variance?: InputMaybe<Competition_Classification_Variance_Order_By>;
};

/** order by avg() on columns of table "competition_classification" */
export type Competition_Classification_Avg_Order_By = {
  confidence?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "competition_classification". All fields are combined with a logical 'AND'. */
export type Competition_Classification_Bool_Exp = {
  _and?: InputMaybe<Array<Competition_Classification_Bool_Exp>>;
  _not?: InputMaybe<Competition_Classification_Bool_Exp>;
  _or?: InputMaybe<Array<Competition_Classification_Bool_Exp>>;
  confidence?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feedback?: InputMaybe<String_Comparison_Exp>;
  prediction?: InputMaybe<Boolean_Comparison_Exp>;
  probability?: InputMaybe<Numeric_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startupByStartupUuid2?: InputMaybe<Startups_Bool_Exp>;
  startup_name_1?: InputMaybe<String_Comparison_Exp>;
  startup_name_2?: InputMaybe<String_Comparison_Exp>;
  startup_uuid_1?: InputMaybe<Uuid_Comparison_Exp>;
  startup_uuid_2?: InputMaybe<Uuid_Comparison_Exp>;
  unique_key?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "competition_classification" */
export type Competition_Classification_Max_Order_By = {
  confidence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
  unique_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "competition_classification" */
export type Competition_Classification_Min_Order_By = {
  confidence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
  unique_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "competition_classification" */
export type Competition_Classification_Mutation_Response = {
  __typename?: 'competition_classification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Competition_Classification>;
};

/** Ordering options when selecting data from "competition_classification". */
export type Competition_Classification_Order_By = {
  confidence?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  prediction?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startupByStartupUuid2?: InputMaybe<Startups_Order_By>;
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
  unique_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: competition_classification */
export type Competition_Classification_Pk_Columns_Input = {
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
};

/** select columns of table "competition_classification" */
export enum Competition_Classification_Select_Column {
  /** column name */
  Confidence = 'confidence',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Prediction = 'prediction',
  /** column name */
  Probability = 'probability',
  /** column name */
  StartupName_1 = 'startup_name_1',
  /** column name */
  StartupName_2 = 'startup_name_2',
  /** column name */
  StartupUuid_1 = 'startup_uuid_1',
  /** column name */
  StartupUuid_2 = 'startup_uuid_2',
  /** column name */
  UniqueKey = 'unique_key',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "competition_classification" */
export type Competition_Classification_Set_Input = {
  feedback?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "competition_classification" */
export type Competition_Classification_Stddev_Order_By = {
  confidence?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "competition_classification" */
export type Competition_Classification_Stddev_Pop_Order_By = {
  confidence?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "competition_classification" */
export type Competition_Classification_Stddev_Samp_Order_By = {
  confidence?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "competition_classification" */
export type Competition_Classification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competition_Classification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competition_Classification_Stream_Cursor_Value_Input = {
  confidence?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feedback?: InputMaybe<Scalars['String']>;
  prediction?: InputMaybe<Scalars['Boolean']>;
  probability?: InputMaybe<Scalars['numeric']>;
  startup_name_1?: InputMaybe<Scalars['String']>;
  startup_name_2?: InputMaybe<Scalars['String']>;
  startup_uuid_1?: InputMaybe<Scalars['uuid']>;
  startup_uuid_2?: InputMaybe<Scalars['uuid']>;
  unique_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "competition_classification" */
export type Competition_Classification_Sum_Order_By = {
  confidence?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
};

export type Competition_Classification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Competition_Classification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Competition_Classification_Bool_Exp;
};

/** order by var_pop() on columns of table "competition_classification" */
export type Competition_Classification_Var_Pop_Order_By = {
  confidence?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "competition_classification" */
export type Competition_Classification_Var_Samp_Order_By = {
  confidence?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "competition_classification" */
export type Competition_Classification_Variance_Order_By = {
  confidence?: InputMaybe<Order_By>;
  probability?: InputMaybe<Order_By>;
};

/** columns and relationships of "competitors" */
export type Competitors = {
  __typename?: 'competitors';
  competitor: Scalars['String'];
  competitor_uuid?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  potential?: Maybe<Scalars['Boolean']>;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "competitors". All fields are combined with a logical 'AND'. */
export type Competitors_Bool_Exp = {
  _and?: InputMaybe<Array<Competitors_Bool_Exp>>;
  _not?: InputMaybe<Competitors_Bool_Exp>;
  _or?: InputMaybe<Array<Competitors_Bool_Exp>>;
  competitor?: InputMaybe<String_Comparison_Exp>;
  competitor_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  potential?: InputMaybe<Boolean_Comparison_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "competitors". */
export type Competitors_Order_By = {
  competitor?: InputMaybe<Order_By>;
  competitor_uuid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  potential?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "competitors" */
export enum Competitors_Select_Column {
  /** column name */
  Competitor = 'competitor',
  /** column name */
  CompetitorUuid = 'competitor_uuid',
  /** column name */
  Id = 'id',
  /** column name */
  Potential = 'potential',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** Streaming cursor of the table "competitors" */
export type Competitors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Competitors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Competitors_Stream_Cursor_Value_Input = {
  competitor?: InputMaybe<Scalars['String']>;
  competitor_uuid?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  potential?: InputMaybe<Scalars['Boolean']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "country_names" */
export type Country_Names = {
  __typename?: 'country_names';
  country_id: Scalars['Int'];
  country_name: Scalars['String'];
  /** An array relationship */
  employee_country_repartitions: Array<Employee_Country_Repartition>;
};

/** columns and relationships of "country_names" */
export type Country_NamesEmployee_Country_RepartitionsArgs = {
  distinct_on?: InputMaybe<Array<Employee_Country_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Country_Repartition_Order_By>>;
  where?: InputMaybe<Employee_Country_Repartition_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "country_names". All fields are combined with a logical 'AND'. */
export type Country_Names_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Names_Bool_Exp>>;
  _not?: InputMaybe<Country_Names_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Names_Bool_Exp>>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  country_name?: InputMaybe<String_Comparison_Exp>;
  employee_country_repartitions?: InputMaybe<Employee_Country_Repartition_Bool_Exp>;
};

/** Ordering options when selecting data from "country_names". */
export type Country_Names_Order_By = {
  country_id?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Order_By>;
  employee_country_repartitions_aggregate?: InputMaybe<Employee_Country_Repartition_Aggregate_Order_By>;
};

/** select columns of table "country_names" */
export enum Country_Names_Select_Column {
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CountryName = 'country_name',
}

/** Streaming cursor of the table "country_names" */
export type Country_Names_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Names_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Names_Stream_Cursor_Value_Input = {
  country_id?: InputMaybe<Scalars['Int']>;
  country_name?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "country_of_startups_for_sourcing" */
export type Country_Of_Startups_For_Sourcing = {
  __typename?: 'country_of_startups_for_sourcing';
  countryID?: Maybe<Scalars['String']>;
  countryName: Scalars['String'];
  doWeWantItForSourcing: Scalars['Boolean'];
  includeStartupsThatHaveBetween1And10MRevenues?: Maybe<Scalars['Boolean']>;
  maximumAmountRaised: Scalars['Int'];
  minimumAmountRaised: Scalars['Int'];
  minimumDateOfCreation?: Maybe<Scalars['date']>;
};

/** Boolean expression to filter rows from the table "country_of_startups_for_sourcing". All fields are combined with a logical 'AND'. */
export type Country_Of_Startups_For_Sourcing_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Of_Startups_For_Sourcing_Bool_Exp>>;
  _not?: InputMaybe<Country_Of_Startups_For_Sourcing_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Of_Startups_For_Sourcing_Bool_Exp>>;
  countryID?: InputMaybe<String_Comparison_Exp>;
  countryName?: InputMaybe<String_Comparison_Exp>;
  doWeWantItForSourcing?: InputMaybe<Boolean_Comparison_Exp>;
  includeStartupsThatHaveBetween1And10MRevenues?: InputMaybe<Boolean_Comparison_Exp>;
  maximumAmountRaised?: InputMaybe<Int_Comparison_Exp>;
  minimumAmountRaised?: InputMaybe<Int_Comparison_Exp>;
  minimumDateOfCreation?: InputMaybe<Date_Comparison_Exp>;
};

/** Ordering options when selecting data from "country_of_startups_for_sourcing". */
export type Country_Of_Startups_For_Sourcing_Order_By = {
  countryID?: InputMaybe<Order_By>;
  countryName?: InputMaybe<Order_By>;
  doWeWantItForSourcing?: InputMaybe<Order_By>;
  includeStartupsThatHaveBetween1And10MRevenues?: InputMaybe<Order_By>;
  maximumAmountRaised?: InputMaybe<Order_By>;
  minimumAmountRaised?: InputMaybe<Order_By>;
  minimumDateOfCreation?: InputMaybe<Order_By>;
};

/** select columns of table "country_of_startups_for_sourcing" */
export enum Country_Of_Startups_For_Sourcing_Select_Column {
  /** column name */
  CountryId = 'countryID',
  /** column name */
  CountryName = 'countryName',
  /** column name */
  DoWeWantItForSourcing = 'doWeWantItForSourcing',
  /** column name */
  IncludeStartupsThatHaveBetween1And10MRevenues = 'includeStartupsThatHaveBetween1And10MRevenues',
  /** column name */
  MaximumAmountRaised = 'maximumAmountRaised',
  /** column name */
  MinimumAmountRaised = 'minimumAmountRaised',
  /** column name */
  MinimumDateOfCreation = 'minimumDateOfCreation',
}

/** Streaming cursor of the table "country_of_startups_for_sourcing" */
export type Country_Of_Startups_For_Sourcing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Of_Startups_For_Sourcing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Of_Startups_For_Sourcing_Stream_Cursor_Value_Input = {
  countryID?: InputMaybe<Scalars['String']>;
  countryName?: InputMaybe<Scalars['String']>;
  doWeWantItForSourcing?: InputMaybe<Scalars['Boolean']>;
  includeStartupsThatHaveBetween1And10MRevenues?: InputMaybe<Scalars['Boolean']>;
  maximumAmountRaised?: InputMaybe<Scalars['Int']>;
  minimumAmountRaised?: InputMaybe<Scalars['Int']>;
  minimumDateOfCreation?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "country_of_startups_for_specter" */
export type Country_Of_Startups_For_Specter = {
  __typename?: 'country_of_startups_for_specter';
  countryID?: Maybe<Scalars['String']>;
  countryName: Scalars['String'];
  doWeWantItForSpecter: Scalars['Boolean'];
  includeStartupsThatHaveBetween1And10MRevenues?: Maybe<Scalars['Boolean']>;
  maximumAmountRaised: Scalars['Int'];
  minimumAmountRaised: Scalars['Int'];
  minimumDateOfCreation?: Maybe<Scalars['date']>;
};

/** Boolean expression to filter rows from the table "country_of_startups_for_specter". All fields are combined with a logical 'AND'. */
export type Country_Of_Startups_For_Specter_Bool_Exp = {
  _and?: InputMaybe<Array<Country_Of_Startups_For_Specter_Bool_Exp>>;
  _not?: InputMaybe<Country_Of_Startups_For_Specter_Bool_Exp>;
  _or?: InputMaybe<Array<Country_Of_Startups_For_Specter_Bool_Exp>>;
  countryID?: InputMaybe<String_Comparison_Exp>;
  countryName?: InputMaybe<String_Comparison_Exp>;
  doWeWantItForSpecter?: InputMaybe<Boolean_Comparison_Exp>;
  includeStartupsThatHaveBetween1And10MRevenues?: InputMaybe<Boolean_Comparison_Exp>;
  maximumAmountRaised?: InputMaybe<Int_Comparison_Exp>;
  minimumAmountRaised?: InputMaybe<Int_Comparison_Exp>;
  minimumDateOfCreation?: InputMaybe<Date_Comparison_Exp>;
};

/** Ordering options when selecting data from "country_of_startups_for_specter". */
export type Country_Of_Startups_For_Specter_Order_By = {
  countryID?: InputMaybe<Order_By>;
  countryName?: InputMaybe<Order_By>;
  doWeWantItForSpecter?: InputMaybe<Order_By>;
  includeStartupsThatHaveBetween1And10MRevenues?: InputMaybe<Order_By>;
  maximumAmountRaised?: InputMaybe<Order_By>;
  minimumAmountRaised?: InputMaybe<Order_By>;
  minimumDateOfCreation?: InputMaybe<Order_By>;
};

/** select columns of table "country_of_startups_for_specter" */
export enum Country_Of_Startups_For_Specter_Select_Column {
  /** column name */
  CountryId = 'countryID',
  /** column name */
  CountryName = 'countryName',
  /** column name */
  DoWeWantItForSpecter = 'doWeWantItForSpecter',
  /** column name */
  IncludeStartupsThatHaveBetween1And10MRevenues = 'includeStartupsThatHaveBetween1And10MRevenues',
  /** column name */
  MaximumAmountRaised = 'maximumAmountRaised',
  /** column name */
  MinimumAmountRaised = 'minimumAmountRaised',
  /** column name */
  MinimumDateOfCreation = 'minimumDateOfCreation',
}

/** Streaming cursor of the table "country_of_startups_for_specter" */
export type Country_Of_Startups_For_Specter_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Of_Startups_For_Specter_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Of_Startups_For_Specter_Stream_Cursor_Value_Input = {
  countryID?: InputMaybe<Scalars['String']>;
  countryName?: InputMaybe<Scalars['String']>;
  doWeWantItForSpecter?: InputMaybe<Scalars['Boolean']>;
  includeStartupsThatHaveBetween1And10MRevenues?: InputMaybe<Scalars['Boolean']>;
  maximumAmountRaised?: InputMaybe<Scalars['Int']>;
  minimumAmountRaised?: InputMaybe<Scalars['Int']>;
  minimumDateOfCreation?: InputMaybe<Scalars['date']>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "data_sources_last_update" */
export type Data_Sources_Last_Update = {
  __typename?: 'data_sources_last_update';
  bcorp?: Maybe<Scalars['date']>;
  birthInDB?: Maybe<Scalars['date']>;
  client?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  crunchbase?: Maybe<Scalars['date']>;
  g2?: Maybe<Scalars['date']>;
  github?: Maybe<Scalars['date']>;
  glassdoor?: Maybe<Scalars['date']>;
  googleNews?: Maybe<Scalars['date']>;
  googleStars?: Maybe<Scalars['date']>;
  googleTrends?: Maybe<Scalars['date']>;
  industry?: Maybe<Scalars['date']>;
  linkedIn?: Maybe<Scalars['date']>;
  linkedin_founders?: Maybe<Scalars['date']>;
  product?: Maybe<Scalars['date']>;
  scores?: Maybe<Scalars['date']>;
  similarweb?: Maybe<Scalars['date']>;
  specter?: Maybe<Scalars['date']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  twitter?: Maybe<Scalars['date']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Boolean expression to filter rows from the table "data_sources_last_update". All fields are combined with a logical 'AND'. */
export type Data_Sources_Last_Update_Bool_Exp = {
  _and?: InputMaybe<Array<Data_Sources_Last_Update_Bool_Exp>>;
  _not?: InputMaybe<Data_Sources_Last_Update_Bool_Exp>;
  _or?: InputMaybe<Array<Data_Sources_Last_Update_Bool_Exp>>;
  bcorp?: InputMaybe<Date_Comparison_Exp>;
  birthInDB?: InputMaybe<Date_Comparison_Exp>;
  client?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crunchbase?: InputMaybe<Date_Comparison_Exp>;
  g2?: InputMaybe<Date_Comparison_Exp>;
  github?: InputMaybe<Date_Comparison_Exp>;
  glassdoor?: InputMaybe<Date_Comparison_Exp>;
  googleNews?: InputMaybe<Date_Comparison_Exp>;
  googleStars?: InputMaybe<Date_Comparison_Exp>;
  googleTrends?: InputMaybe<Date_Comparison_Exp>;
  industry?: InputMaybe<Date_Comparison_Exp>;
  linkedIn?: InputMaybe<Date_Comparison_Exp>;
  linkedin_founders?: InputMaybe<Date_Comparison_Exp>;
  product?: InputMaybe<Date_Comparison_Exp>;
  scores?: InputMaybe<Date_Comparison_Exp>;
  similarweb?: InputMaybe<Date_Comparison_Exp>;
  specter?: InputMaybe<Date_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  twitter?: InputMaybe<Date_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "data_sources_last_update". */
export type Data_Sources_Last_Update_Order_By = {
  bcorp?: InputMaybe<Order_By>;
  birthInDB?: InputMaybe<Order_By>;
  client?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crunchbase?: InputMaybe<Order_By>;
  g2?: InputMaybe<Order_By>;
  github?: InputMaybe<Order_By>;
  glassdoor?: InputMaybe<Order_By>;
  googleNews?: InputMaybe<Order_By>;
  googleStars?: InputMaybe<Order_By>;
  googleTrends?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  linkedIn?: InputMaybe<Order_By>;
  linkedin_founders?: InputMaybe<Order_By>;
  product?: InputMaybe<Order_By>;
  scores?: InputMaybe<Order_By>;
  similarweb?: InputMaybe<Order_By>;
  specter?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "data_sources_last_update" */
export enum Data_Sources_Last_Update_Select_Column {
  /** column name */
  Bcorp = 'bcorp',
  /** column name */
  BirthInDb = 'birthInDB',
  /** column name */
  Client = 'client',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Crunchbase = 'crunchbase',
  /** column name */
  G2 = 'g2',
  /** column name */
  Github = 'github',
  /** column name */
  Glassdoor = 'glassdoor',
  /** column name */
  GoogleNews = 'googleNews',
  /** column name */
  GoogleStars = 'googleStars',
  /** column name */
  GoogleTrends = 'googleTrends',
  /** column name */
  Industry = 'industry',
  /** column name */
  LinkedIn = 'linkedIn',
  /** column name */
  LinkedinFounders = 'linkedin_founders',
  /** column name */
  Product = 'product',
  /** column name */
  Scores = 'scores',
  /** column name */
  Similarweb = 'similarweb',
  /** column name */
  Specter = 'specter',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "data_sources_last_update" */
export type Data_Sources_Last_Update_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Data_Sources_Last_Update_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Data_Sources_Last_Update_Stream_Cursor_Value_Input = {
  bcorp?: InputMaybe<Scalars['date']>;
  birthInDB?: InputMaybe<Scalars['date']>;
  client?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  crunchbase?: InputMaybe<Scalars['date']>;
  g2?: InputMaybe<Scalars['date']>;
  github?: InputMaybe<Scalars['date']>;
  glassdoor?: InputMaybe<Scalars['date']>;
  googleNews?: InputMaybe<Scalars['date']>;
  googleStars?: InputMaybe<Scalars['date']>;
  googleTrends?: InputMaybe<Scalars['date']>;
  industry?: InputMaybe<Scalars['date']>;
  linkedIn?: InputMaybe<Scalars['date']>;
  linkedin_founders?: InputMaybe<Scalars['date']>;
  product?: InputMaybe<Scalars['date']>;
  scores?: InputMaybe<Scalars['date']>;
  similarweb?: InputMaybe<Scalars['date']>;
  specter?: InputMaybe<Scalars['date']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  twitter?: InputMaybe<Scalars['date']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "dealflow" */
export type Dealflow = {
  __typename?: 'dealflow';
  airtableID?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['float8']>;
  dateReceived?: Maybe<Scalars['date']>;
  notes?: Maybe<Scalars['String']>;
  opportunity: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  process?: Maybe<Scalars['String']>;
  revenueInMDollars?: Maybe<Scalars['float8']>;
  sourceType?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startupNameID?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  transfered_by?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "dealflow" */
export type Dealflow_Aggregate_Order_By = {
  avg?: InputMaybe<Dealflow_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dealflow_Max_Order_By>;
  min?: InputMaybe<Dealflow_Min_Order_By>;
  stddev?: InputMaybe<Dealflow_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dealflow_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dealflow_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dealflow_Sum_Order_By>;
  var_pop?: InputMaybe<Dealflow_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dealflow_Var_Samp_Order_By>;
  variance?: InputMaybe<Dealflow_Variance_Order_By>;
};

/** order by avg() on columns of table "dealflow" */
export type Dealflow_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dealflow". All fields are combined with a logical 'AND'. */
export type Dealflow_Bool_Exp = {
  _and?: InputMaybe<Array<Dealflow_Bool_Exp>>;
  _not?: InputMaybe<Dealflow_Bool_Exp>;
  _or?: InputMaybe<Array<Dealflow_Bool_Exp>>;
  airtableID?: InputMaybe<String_Comparison_Exp>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  dateReceived?: InputMaybe<Date_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  opportunity?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<String_Comparison_Exp>;
  process?: InputMaybe<String_Comparison_Exp>;
  revenueInMDollars?: InputMaybe<Float8_Comparison_Exp>;
  sourceType?: InputMaybe<String_Comparison_Exp>;
  stage?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startupNameID?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  transfered_by?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "dealflow" */
export type Dealflow_Max_Order_By = {
  airtableID?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  dateReceived?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  opportunity?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  process?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
  sourceType?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  startupNameID?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfered_by?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "dealflow" */
export type Dealflow_Min_Order_By = {
  airtableID?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  dateReceived?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  opportunity?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  process?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
  sourceType?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  startupNameID?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfered_by?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "dealflow". */
export type Dealflow_Order_By = {
  airtableID?: InputMaybe<Order_By>;
  amount?: InputMaybe<Order_By>;
  dateReceived?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  opportunity?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  process?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
  sourceType?: InputMaybe<Order_By>;
  stage?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startupNameID?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfered_by?: InputMaybe<Order_By>;
};

/** select columns of table "dealflow" */
export enum Dealflow_Select_Column {
  /** column name */
  AirtableId = 'airtableID',
  /** column name */
  Amount = 'amount',
  /** column name */
  DateReceived = 'dateReceived',
  /** column name */
  Notes = 'notes',
  /** column name */
  Opportunity = 'opportunity',
  /** column name */
  Owner = 'owner',
  /** column name */
  Process = 'process',
  /** column name */
  RevenueInMDollars = 'revenueInMDollars',
  /** column name */
  SourceType = 'sourceType',
  /** column name */
  Stage = 'stage',
  /** column name */
  StartupNameId = 'startupNameID',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  Status = 'status',
  /** column name */
  TransferedBy = 'transfered_by',
}

/** order by stddev() on columns of table "dealflow" */
export type Dealflow_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "dealflow" */
export type Dealflow_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "dealflow" */
export type Dealflow_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dealflow" */
export type Dealflow_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dealflow_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dealflow_Stream_Cursor_Value_Input = {
  airtableID?: InputMaybe<Scalars['String']>;
  amount?: InputMaybe<Scalars['float8']>;
  dateReceived?: InputMaybe<Scalars['date']>;
  notes?: InputMaybe<Scalars['String']>;
  opportunity?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  process?: InputMaybe<Scalars['String']>;
  revenueInMDollars?: InputMaybe<Scalars['float8']>;
  sourceType?: InputMaybe<Scalars['String']>;
  stage?: InputMaybe<Scalars['String']>;
  startupNameID?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  transfered_by?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "dealflow" */
export type Dealflow_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "dealflow" */
export type Dealflow_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "dealflow" */
export type Dealflow_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "dealflow" */
export type Dealflow_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  revenueInMDollars?: InputMaybe<Order_By>;
};

/** columns and relationships of "deals" */
export type Deals = {
  __typename?: 'deals';
  allInvestors?: Maybe<Scalars['String']>;
  announcedDate?: Maybe<Scalars['date']>;
  countryOfStartup?: Maybe<Scalars['String']>;
  crunchbaseLink?: Maybe<Scalars['String']>;
  fundingType: Scalars['String'];
  inBubble?: Maybe<Scalars['Boolean']>;
  monneyRaisedInDollars: Scalars['Int'];
  organizationName: Scalars['String'];
  round_id?: Maybe<Scalars['String']>;
  startup_id?: Maybe<Scalars['String']>;
  startup_uuid?: Maybe<Scalars['String']>;
  transactionName: Scalars['String'];
  uuid: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "deals". All fields are combined with a logical 'AND'. */
export type Deals_Bool_Exp = {
  _and?: InputMaybe<Array<Deals_Bool_Exp>>;
  _not?: InputMaybe<Deals_Bool_Exp>;
  _or?: InputMaybe<Array<Deals_Bool_Exp>>;
  allInvestors?: InputMaybe<String_Comparison_Exp>;
  announcedDate?: InputMaybe<Date_Comparison_Exp>;
  countryOfStartup?: InputMaybe<String_Comparison_Exp>;
  crunchbaseLink?: InputMaybe<String_Comparison_Exp>;
  fundingType?: InputMaybe<String_Comparison_Exp>;
  inBubble?: InputMaybe<Boolean_Comparison_Exp>;
  monneyRaisedInDollars?: InputMaybe<Int_Comparison_Exp>;
  organizationName?: InputMaybe<String_Comparison_Exp>;
  round_id?: InputMaybe<String_Comparison_Exp>;
  startup_id?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<String_Comparison_Exp>;
  transactionName?: InputMaybe<String_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "deals". */
export type Deals_Order_By = {
  allInvestors?: InputMaybe<Order_By>;
  announcedDate?: InputMaybe<Order_By>;
  countryOfStartup?: InputMaybe<Order_By>;
  crunchbaseLink?: InputMaybe<Order_By>;
  fundingType?: InputMaybe<Order_By>;
  inBubble?: InputMaybe<Order_By>;
  monneyRaisedInDollars?: InputMaybe<Order_By>;
  organizationName?: InputMaybe<Order_By>;
  round_id?: InputMaybe<Order_By>;
  startup_id?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  transactionName?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** select columns of table "deals" */
export enum Deals_Select_Column {
  /** column name */
  AllInvestors = 'allInvestors',
  /** column name */
  AnnouncedDate = 'announcedDate',
  /** column name */
  CountryOfStartup = 'countryOfStartup',
  /** column name */
  CrunchbaseLink = 'crunchbaseLink',
  /** column name */
  FundingType = 'fundingType',
  /** column name */
  InBubble = 'inBubble',
  /** column name */
  MonneyRaisedInDollars = 'monneyRaisedInDollars',
  /** column name */
  OrganizationName = 'organizationName',
  /** column name */
  RoundId = 'round_id',
  /** column name */
  StartupId = 'startup_id',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  TransactionName = 'transactionName',
  /** column name */
  Uuid = 'uuid',
}

/** Streaming cursor of the table "deals" */
export type Deals_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Deals_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Deals_Stream_Cursor_Value_Input = {
  allInvestors?: InputMaybe<Scalars['String']>;
  announcedDate?: InputMaybe<Scalars['date']>;
  countryOfStartup?: InputMaybe<Scalars['String']>;
  crunchbaseLink?: InputMaybe<Scalars['String']>;
  fundingType?: InputMaybe<Scalars['String']>;
  inBubble?: InputMaybe<Scalars['Boolean']>;
  monneyRaisedInDollars?: InputMaybe<Scalars['Int']>;
  organizationName?: InputMaybe<Scalars['String']>;
  round_id?: InputMaybe<Scalars['String']>;
  startup_id?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['String']>;
  transactionName?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "education" */
export type Education = {
  __typename?: 'education';
  degree?: Maybe<Scalars['String']>;
  educationUrl?: Maybe<Scalars['String']>;
  endYear?: Maybe<Scalars['Int']>;
  field?: Maybe<Scalars['String']>;
  /** An object relationship */
  founder: Founders;
  founderID: Scalars['Int'];
  id: Scalars['Int'];
  meta?: Maybe<Scalars['String']>;
  schoolID: Scalars['Int'];
  startYear?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "education" */
export type Education_Aggregate_Order_By = {
  avg?: InputMaybe<Education_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Education_Max_Order_By>;
  min?: InputMaybe<Education_Min_Order_By>;
  stddev?: InputMaybe<Education_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Education_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Education_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Education_Sum_Order_By>;
  var_pop?: InputMaybe<Education_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Education_Var_Samp_Order_By>;
  variance?: InputMaybe<Education_Variance_Order_By>;
};

/** order by avg() on columns of table "education" */
export type Education_Avg_Order_By = {
  endYear?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "education". All fields are combined with a logical 'AND'. */
export type Education_Bool_Exp = {
  _and?: InputMaybe<Array<Education_Bool_Exp>>;
  _not?: InputMaybe<Education_Bool_Exp>;
  _or?: InputMaybe<Array<Education_Bool_Exp>>;
  degree?: InputMaybe<String_Comparison_Exp>;
  educationUrl?: InputMaybe<String_Comparison_Exp>;
  endYear?: InputMaybe<Int_Comparison_Exp>;
  field?: InputMaybe<String_Comparison_Exp>;
  founder?: InputMaybe<Founders_Bool_Exp>;
  founderID?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  meta?: InputMaybe<String_Comparison_Exp>;
  schoolID?: InputMaybe<Int_Comparison_Exp>;
  startYear?: InputMaybe<Int_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "education" */
export type Education_Max_Order_By = {
  degree?: InputMaybe<Order_By>;
  educationUrl?: InputMaybe<Order_By>;
  endYear?: InputMaybe<Order_By>;
  field?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "education" */
export type Education_Min_Order_By = {
  degree?: InputMaybe<Order_By>;
  educationUrl?: InputMaybe<Order_By>;
  endYear?: InputMaybe<Order_By>;
  field?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "education". */
export type Education_Order_By = {
  degree?: InputMaybe<Order_By>;
  educationUrl?: InputMaybe<Order_By>;
  endYear?: InputMaybe<Order_By>;
  field?: InputMaybe<Order_By>;
  founder?: InputMaybe<Founders_Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** select columns of table "education" */
export enum Education_Select_Column {
  /** column name */
  Degree = 'degree',
  /** column name */
  EducationUrl = 'educationUrl',
  /** column name */
  EndYear = 'endYear',
  /** column name */
  Field = 'field',
  /** column name */
  FounderId = 'founderID',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  SchoolId = 'schoolID',
  /** column name */
  StartYear = 'startYear',
  /** column name */
  Title = 'title',
}

/** order by stddev() on columns of table "education" */
export type Education_Stddev_Order_By = {
  endYear?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "education" */
export type Education_Stddev_Pop_Order_By = {
  endYear?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "education" */
export type Education_Stddev_Samp_Order_By = {
  endYear?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "education" */
export type Education_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Education_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Education_Stream_Cursor_Value_Input = {
  degree?: InputMaybe<Scalars['String']>;
  educationUrl?: InputMaybe<Scalars['String']>;
  endYear?: InputMaybe<Scalars['Int']>;
  field?: InputMaybe<Scalars['String']>;
  founderID?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  meta?: InputMaybe<Scalars['String']>;
  schoolID?: InputMaybe<Scalars['Int']>;
  startYear?: InputMaybe<Scalars['Int']>;
  title?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "education" */
export type Education_Sum_Order_By = {
  endYear?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "education" */
export type Education_Var_Pop_Order_By = {
  endYear?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "education" */
export type Education_Var_Samp_Order_By = {
  endYear?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "education" */
export type Education_Variance_Order_By = {
  endYear?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  schoolID?: InputMaybe<Order_By>;
  startYear?: InputMaybe<Order_By>;
};

/** columns and relationships of "employee_country_repartition" */
export type Employee_Country_Repartition = {
  __typename?: 'employee_country_repartition';
  country?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  country_name?: Maybe<Country_Names>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date: Scalars['date'];
  id: Scalars['Int'];
  obs?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by aggregate values of table "employee_country_repartition" */
export type Employee_Country_Repartition_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Country_Repartition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Country_Repartition_Max_Order_By>;
  min?: InputMaybe<Employee_Country_Repartition_Min_Order_By>;
  stddev?: InputMaybe<Employee_Country_Repartition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Country_Repartition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Country_Repartition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Country_Repartition_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Country_Repartition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Country_Repartition_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Country_Repartition_Variance_Order_By>;
};

/** order by avg() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employee_country_repartition". All fields are combined with a logical 'AND'. */
export type Employee_Country_Repartition_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Country_Repartition_Bool_Exp>>;
  _not?: InputMaybe<Employee_Country_Repartition_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Country_Repartition_Bool_Exp>>;
  country?: InputMaybe<String_Comparison_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  country_name?: InputMaybe<Country_Names_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  obs?: InputMaybe<Numeric_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Max_Order_By = {
  country?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Min_Order_By = {
  country?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "employee_country_repartition". */
export type Employee_Country_Repartition_Order_By = {
  country?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Country_Names_Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "employee_country_repartition" */
export enum Employee_Country_Repartition_Select_Column {
  /** column name */
  Country = 'country',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Obs = 'obs',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** order by stddev() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employee_country_repartition" */
export type Employee_Country_Repartition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Country_Repartition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Country_Repartition_Stream_Cursor_Value_Input = {
  country?: InputMaybe<Scalars['String']>;
  country_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  obs?: InputMaybe<Scalars['numeric']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by sum() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "employee_country_repartition" */
export type Employee_Country_Repartition_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** columns and relationships of "employee_post_repartition" */
export type Employee_Post_Repartition = {
  __typename?: 'employee_post_repartition';
  date: Scalars['date'];
  id: Scalars['Int'];
  obs?: Maybe<Scalars['float8']>;
  post?: Maybe<Scalars['bpchar']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "employee_post_repartition" */
export type Employee_Post_Repartition_Aggregate_Order_By = {
  avg?: InputMaybe<Employee_Post_Repartition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employee_Post_Repartition_Max_Order_By>;
  min?: InputMaybe<Employee_Post_Repartition_Min_Order_By>;
  stddev?: InputMaybe<Employee_Post_Repartition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employee_Post_Repartition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employee_Post_Repartition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employee_Post_Repartition_Sum_Order_By>;
  var_pop?: InputMaybe<Employee_Post_Repartition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employee_Post_Repartition_Var_Samp_Order_By>;
  variance?: InputMaybe<Employee_Post_Repartition_Variance_Order_By>;
};

/** order by avg() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employee_post_repartition". All fields are combined with a logical 'AND'. */
export type Employee_Post_Repartition_Bool_Exp = {
  _and?: InputMaybe<Array<Employee_Post_Repartition_Bool_Exp>>;
  _not?: InputMaybe<Employee_Post_Repartition_Bool_Exp>;
  _or?: InputMaybe<Array<Employee_Post_Repartition_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  obs?: InputMaybe<Float8_Comparison_Exp>;
  post?: InputMaybe<Bpchar_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  post?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  post?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "employee_post_repartition". */
export type Employee_Post_Repartition_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  post?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "employee_post_repartition" */
export enum Employee_Post_Repartition_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Obs = 'obs',
  /** column name */
  Post = 'post',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employee_post_repartition" */
export type Employee_Post_Repartition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employee_Post_Repartition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employee_Post_Repartition_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  obs?: InputMaybe<Scalars['float8']>;
  post?: InputMaybe<Scalars['bpchar']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "employee_post_repartition" */
export type Employee_Post_Repartition_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** columns and relationships of "employees" */
export type Employees = {
  __typename?: 'employees';
  data_source?: Maybe<Scalars['String']>;
  date: Scalars['date'];
  id: Scalars['Int'];
  obs?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "employees" */
export type Employees_Aggregate_Order_By = {
  avg?: InputMaybe<Employees_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Employees_Max_Order_By>;
  min?: InputMaybe<Employees_Min_Order_By>;
  stddev?: InputMaybe<Employees_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Employees_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Employees_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Employees_Sum_Order_By>;
  var_pop?: InputMaybe<Employees_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Employees_Var_Samp_Order_By>;
  variance?: InputMaybe<Employees_Variance_Order_By>;
};

/** order by avg() on columns of table "employees" */
export type Employees_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "employees". All fields are combined with a logical 'AND'. */
export type Employees_Bool_Exp = {
  _and?: InputMaybe<Array<Employees_Bool_Exp>>;
  _not?: InputMaybe<Employees_Bool_Exp>;
  _or?: InputMaybe<Array<Employees_Bool_Exp>>;
  data_source?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  obs?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** columns and relationships of "employees_from_us" */
export type Employees_From_Us = {
  __typename?: 'employees_from_us';
  EmployeesFromTheUS?: Maybe<Scalars['float8']>;
  date: Scalars['date'];
  id: Scalars['Int'];
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "employees_from_us". All fields are combined with a logical 'AND'. */
export type Employees_From_Us_Bool_Exp = {
  EmployeesFromTheUS?: InputMaybe<Float8_Comparison_Exp>;
  _and?: InputMaybe<Array<Employees_From_Us_Bool_Exp>>;
  _not?: InputMaybe<Employees_From_Us_Bool_Exp>;
  _or?: InputMaybe<Array<Employees_From_Us_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "employees_from_us". */
export type Employees_From_Us_Order_By = {
  EmployeesFromTheUS?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "employees_from_us" */
export enum Employees_From_Us_Select_Column {
  /** column name */
  EmployeesFromTheUs = 'EmployeesFromTheUS',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** Streaming cursor of the table "employees_from_us" */
export type Employees_From_Us_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employees_From_Us_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employees_From_Us_Stream_Cursor_Value_Input = {
  EmployeesFromTheUS?: InputMaybe<Scalars['float8']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "employees" */
export type Employees_Max_Order_By = {
  data_source?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "employees" */
export type Employees_Min_Order_By = {
  data_source?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "employees". */
export type Employees_Order_By = {
  data_source?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "employees" */
export enum Employees_Select_Column {
  /** column name */
  DataSource = 'data_source',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Obs = 'obs',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "employees" */
export type Employees_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "employees" */
export type Employees_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "employees" */
export type Employees_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "employees" */
export type Employees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Employees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Employees_Stream_Cursor_Value_Input = {
  data_source?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  obs?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "employees" */
export type Employees_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "employees" */
export type Employees_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "employees" */
export type Employees_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "employees" */
export type Employees_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** columns and relationships of "events_news" */
export type Events_News = {
  __typename?: 'events_news';
  date: Scalars['date'];
  event_description?: Maybe<Scalars['String']>;
  icon_url?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  publisher?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  type_of_event?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "events_news" */
export type Events_News_Aggregate_Order_By = {
  avg?: InputMaybe<Events_News_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Events_News_Max_Order_By>;
  min?: InputMaybe<Events_News_Min_Order_By>;
  stddev?: InputMaybe<Events_News_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Events_News_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Events_News_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Events_News_Sum_Order_By>;
  var_pop?: InputMaybe<Events_News_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Events_News_Var_Samp_Order_By>;
  variance?: InputMaybe<Events_News_Variance_Order_By>;
};

/** order by avg() on columns of table "events_news" */
export type Events_News_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "events_news". All fields are combined with a logical 'AND'. */
export type Events_News_Bool_Exp = {
  _and?: InputMaybe<Array<Events_News_Bool_Exp>>;
  _not?: InputMaybe<Events_News_Bool_Exp>;
  _or?: InputMaybe<Array<Events_News_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  event_description?: InputMaybe<String_Comparison_Exp>;
  icon_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  publisher?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  type_of_event?: InputMaybe<String_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "events_news" */
export type Events_News_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  event_description?: InputMaybe<Order_By>;
  icon_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  publisher?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  type_of_event?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "events_news" */
export type Events_News_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  event_description?: InputMaybe<Order_By>;
  icon_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  publisher?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  type_of_event?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "events_news". */
export type Events_News_Order_By = {
  date?: InputMaybe<Order_By>;
  event_description?: InputMaybe<Order_By>;
  icon_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  publisher?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  type_of_event?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "events_news" */
export enum Events_News_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  EventDescription = 'event_description',
  /** column name */
  IconUrl = 'icon_url',
  /** column name */
  Id = 'id',
  /** column name */
  Publisher = 'publisher',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  TypeOfEvent = 'type_of_event',
  /** column name */
  Url = 'url',
}

/** order by stddev() on columns of table "events_news" */
export type Events_News_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "events_news" */
export type Events_News_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "events_news" */
export type Events_News_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "events_news" */
export type Events_News_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Events_News_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Events_News_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  event_description?: InputMaybe<Scalars['String']>;
  icon_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  publisher?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  type_of_event?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "events_news" */
export type Events_News_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "events_news" */
export type Events_News_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "events_news" */
export type Events_News_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "events_news" */
export type Events_News_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups = {
  __typename?: 'follow_relation_users_startups';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  startup: Startups;
  startup_uuid: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** order by aggregate values of table "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Follow_Relation_Users_Startups_Max_Order_By>;
  min?: InputMaybe<Follow_Relation_Users_Startups_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "follow_relation_users_startups". All fields are combined with a logical 'AND'. */
export type Follow_Relation_Users_Startups_Bool_Exp = {
  _and?: InputMaybe<Array<Follow_Relation_Users_Startups_Bool_Exp>>;
  _not?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
  _or?: InputMaybe<Array<Follow_Relation_Users_Startups_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "follow_relation_users_startups" */
export enum Follow_Relation_Users_Startups_Constraint {
  /** unique or primary key constraint on columns "id" */
  FollowRelationUsersStartupsPkey = 'follow_relation_users_startups_pkey',
  /** unique or primary key constraint on columns "startup_uuid", "user_id" */
  FollowRelationUsersStartupsUserIdStartupUuidKey = 'follow_relation_users_startups_user_id_startup_uuid_key',
}

/** input type for inserting data into table "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups_Insert_Input = {
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups_Mutation_Response = {
  __typename?: 'follow_relation_users_startups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Follow_Relation_Users_Startups>;
};

/** on_conflict condition type for table "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups_On_Conflict = {
  constraint: Follow_Relation_Users_Startups_Constraint;
  update_columns?: Array<Follow_Relation_Users_Startups_Update_Column>;
  where?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
};

/** Ordering options when selecting data from "follow_relation_users_startups". */
export type Follow_Relation_Users_Startups_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: follow_relation_users_startups */
export type Follow_Relation_Users_Startups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "follow_relation_users_startups" */
export enum Follow_Relation_Users_Startups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups_Set_Input = {
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "follow_relation_users_startups" */
export type Follow_Relation_Users_Startups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Follow_Relation_Users_Startups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Follow_Relation_Users_Startups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "follow_relation_users_startups" */
export enum Follow_Relation_Users_Startups_Update_Column {
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Follow_Relation_Users_Startups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Follow_Relation_Users_Startups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Follow_Relation_Users_Startups_Bool_Exp;
};

/** columns and relationships of "founder_jobs" */
export type Founder_Jobs = {
  __typename?: 'founder_jobs';
  companyID: Scalars['uuid'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  /** An object relationship */
  founder: Founders;
  founderID: Scalars['Int'];
  id: Scalars['Int'];
  startDate?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  wasCompanyFounder?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "founder_jobs" */
export type Founder_Jobs_Aggregate_Order_By = {
  avg?: InputMaybe<Founder_Jobs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Founder_Jobs_Max_Order_By>;
  min?: InputMaybe<Founder_Jobs_Min_Order_By>;
  stddev?: InputMaybe<Founder_Jobs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Founder_Jobs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Founder_Jobs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Founder_Jobs_Sum_Order_By>;
  var_pop?: InputMaybe<Founder_Jobs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Founder_Jobs_Var_Samp_Order_By>;
  variance?: InputMaybe<Founder_Jobs_Variance_Order_By>;
};

/** order by avg() on columns of table "founder_jobs" */
export type Founder_Jobs_Avg_Order_By = {
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "founder_jobs". All fields are combined with a logical 'AND'. */
export type Founder_Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Founder_Jobs_Bool_Exp>>;
  _not?: InputMaybe<Founder_Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Founder_Jobs_Bool_Exp>>;
  companyID?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  duration?: InputMaybe<String_Comparison_Exp>;
  endDate?: InputMaybe<String_Comparison_Exp>;
  founder?: InputMaybe<Founders_Bool_Exp>;
  founderID?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  startDate?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  wasCompanyFounder?: InputMaybe<Boolean_Comparison_Exp>;
};

/** order by max() on columns of table "founder_jobs" */
export type Founder_Jobs_Max_Order_By = {
  companyID?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "founder_jobs" */
export type Founder_Jobs_Min_Order_By = {
  companyID?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "founder_jobs". */
export type Founder_Jobs_Order_By = {
  companyID?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  endDate?: InputMaybe<Order_By>;
  founder?: InputMaybe<Founders_Order_By>;
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startDate?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  wasCompanyFounder?: InputMaybe<Order_By>;
};

/** select columns of table "founder_jobs" */
export enum Founder_Jobs_Select_Column {
  /** column name */
  CompanyId = 'companyID',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndDate = 'endDate',
  /** column name */
  FounderId = 'founderID',
  /** column name */
  Id = 'id',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Title = 'title',
  /** column name */
  WasCompanyFounder = 'wasCompanyFounder',
}

/** order by stddev() on columns of table "founder_jobs" */
export type Founder_Jobs_Stddev_Order_By = {
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "founder_jobs" */
export type Founder_Jobs_Stddev_Pop_Order_By = {
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "founder_jobs" */
export type Founder_Jobs_Stddev_Samp_Order_By = {
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "founder_jobs" */
export type Founder_Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Founder_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Founder_Jobs_Stream_Cursor_Value_Input = {
  companyID?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  founderID?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  wasCompanyFounder?: InputMaybe<Scalars['Boolean']>;
};

/** order by sum() on columns of table "founder_jobs" */
export type Founder_Jobs_Sum_Order_By = {
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "founder_jobs" */
export type Founder_Jobs_Var_Pop_Order_By = {
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "founder_jobs" */
export type Founder_Jobs_Var_Samp_Order_By = {
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "founder_jobs" */
export type Founder_Jobs_Variance_Order_By = {
  founderID?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "founder_score_weights" */
export type Founder_Score_Weights = {
  __typename?: 'founder_score_weights';
  articlesCategory: Scalars['float8'];
  companyExperienceRaisedBeforeAndDuringMoreThan5M?: Maybe<Scalars['float8']>;
  companyExperienceRaisedBeforeAndDuringPrevStartup?: Maybe<Scalars['float8']>;
  dateTime: Scalars['String'];
  didTopTierSchool: Scalars['float8'];
  exitsCategory: Scalars['float8'];
  foundedOrganzationsCategory: Scalars['float8'];
  hasCLvlGeneratingMoreThan10MOrhasCLvlMinimum10MFundedBeforeAndD?: Maybe<Scalars['float8']>;
  hasCLvlGeneratingMoreThan100MOrhasCLvlMinimum200MFundedBeforeAn?: Maybe<Scalars['float8']>;
  hasManagerLvlFundedBeforeAndDuringOrhasCLvlFundedBeforeAndDurin?: Maybe<Scalars['float8']>;
  hasManagerLvlGeneratingMoreThan10M?: Maybe<Scalars['float8']>;
  hasOneIndustryStartupMatch: Scalars['float8'];
  hasStartupIndustryExperience: Scalars['float8'];
  hasTopExperienceWeight: Scalars['float8'];
  hasTwoIndustryStartupMatch: Scalars['float8'];
  id: Scalars['Int'];
  jobsCategory: Scalars['float8'];
  raisedDuringAFounderExperienceCategory: Scalars['float8'];
  topInvestors: Scalars['float8'];
  workedInNrCountries: Scalars['float8'];
  workedInUS: Scalars['float8'];
  yearsOfExperienceWeight: Scalars['float8'];
};

/** Boolean expression to filter rows from the table "founder_score_weights". All fields are combined with a logical 'AND'. */
export type Founder_Score_Weights_Bool_Exp = {
  _and?: InputMaybe<Array<Founder_Score_Weights_Bool_Exp>>;
  _not?: InputMaybe<Founder_Score_Weights_Bool_Exp>;
  _or?: InputMaybe<Array<Founder_Score_Weights_Bool_Exp>>;
  articlesCategory?: InputMaybe<Float8_Comparison_Exp>;
  companyExperienceRaisedBeforeAndDuringMoreThan5M?: InputMaybe<Float8_Comparison_Exp>;
  companyExperienceRaisedBeforeAndDuringPrevStartup?: InputMaybe<Float8_Comparison_Exp>;
  dateTime?: InputMaybe<String_Comparison_Exp>;
  didTopTierSchool?: InputMaybe<Float8_Comparison_Exp>;
  exitsCategory?: InputMaybe<Float8_Comparison_Exp>;
  foundedOrganzationsCategory?: InputMaybe<Float8_Comparison_Exp>;
  hasCLvlGeneratingMoreThan10MOrhasCLvlMinimum10MFundedBeforeAndD?: InputMaybe<Float8_Comparison_Exp>;
  hasCLvlGeneratingMoreThan100MOrhasCLvlMinimum200MFundedBeforeAn?: InputMaybe<Float8_Comparison_Exp>;
  hasManagerLvlFundedBeforeAndDuringOrhasCLvlFundedBeforeAndDurin?: InputMaybe<Float8_Comparison_Exp>;
  hasManagerLvlGeneratingMoreThan10M?: InputMaybe<Float8_Comparison_Exp>;
  hasOneIndustryStartupMatch?: InputMaybe<Float8_Comparison_Exp>;
  hasStartupIndustryExperience?: InputMaybe<Float8_Comparison_Exp>;
  hasTopExperienceWeight?: InputMaybe<Float8_Comparison_Exp>;
  hasTwoIndustryStartupMatch?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  jobsCategory?: InputMaybe<Float8_Comparison_Exp>;
  raisedDuringAFounderExperienceCategory?: InputMaybe<Float8_Comparison_Exp>;
  topInvestors?: InputMaybe<Float8_Comparison_Exp>;
  workedInNrCountries?: InputMaybe<Float8_Comparison_Exp>;
  workedInUS?: InputMaybe<Float8_Comparison_Exp>;
  yearsOfExperienceWeight?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "founder_score_weights". */
export type Founder_Score_Weights_Order_By = {
  articlesCategory?: InputMaybe<Order_By>;
  companyExperienceRaisedBeforeAndDuringMoreThan5M?: InputMaybe<Order_By>;
  companyExperienceRaisedBeforeAndDuringPrevStartup?: InputMaybe<Order_By>;
  dateTime?: InputMaybe<Order_By>;
  didTopTierSchool?: InputMaybe<Order_By>;
  exitsCategory?: InputMaybe<Order_By>;
  foundedOrganzationsCategory?: InputMaybe<Order_By>;
  hasCLvlGeneratingMoreThan10MOrhasCLvlMinimum10MFundedBeforeAndD?: InputMaybe<Order_By>;
  hasCLvlGeneratingMoreThan100MOrhasCLvlMinimum200MFundedBeforeAn?: InputMaybe<Order_By>;
  hasManagerLvlFundedBeforeAndDuringOrhasCLvlFundedBeforeAndDurin?: InputMaybe<Order_By>;
  hasManagerLvlGeneratingMoreThan10M?: InputMaybe<Order_By>;
  hasOneIndustryStartupMatch?: InputMaybe<Order_By>;
  hasStartupIndustryExperience?: InputMaybe<Order_By>;
  hasTopExperienceWeight?: InputMaybe<Order_By>;
  hasTwoIndustryStartupMatch?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobsCategory?: InputMaybe<Order_By>;
  raisedDuringAFounderExperienceCategory?: InputMaybe<Order_By>;
  topInvestors?: InputMaybe<Order_By>;
  workedInNrCountries?: InputMaybe<Order_By>;
  workedInUS?: InputMaybe<Order_By>;
  yearsOfExperienceWeight?: InputMaybe<Order_By>;
};

/** select columns of table "founder_score_weights" */
export enum Founder_Score_Weights_Select_Column {
  /** column name */
  ArticlesCategory = 'articlesCategory',
  /** column name */
  CompanyExperienceRaisedBeforeAndDuringMoreThan5M = 'companyExperienceRaisedBeforeAndDuringMoreThan5M',
  /** column name */
  CompanyExperienceRaisedBeforeAndDuringPrevStartup = 'companyExperienceRaisedBeforeAndDuringPrevStartup',
  /** column name */
  DateTime = 'dateTime',
  /** column name */
  DidTopTierSchool = 'didTopTierSchool',
  /** column name */
  ExitsCategory = 'exitsCategory',
  /** column name */
  FoundedOrganzationsCategory = 'foundedOrganzationsCategory',
  /** column name */
  HasCLvlGeneratingMoreThan10MOrhasCLvlMinimum10MFundedBeforeAndD = 'hasCLvlGeneratingMoreThan10MOrhasCLvlMinimum10MFundedBeforeAndD',
  /** column name */
  HasCLvlGeneratingMoreThan100MOrhasCLvlMinimum200MFundedBeforeAn = 'hasCLvlGeneratingMoreThan100MOrhasCLvlMinimum200MFundedBeforeAn',
  /** column name */
  HasManagerLvlFundedBeforeAndDuringOrhasCLvlFundedBeforeAndDurin = 'hasManagerLvlFundedBeforeAndDuringOrhasCLvlFundedBeforeAndDurin',
  /** column name */
  HasManagerLvlGeneratingMoreThan10M = 'hasManagerLvlGeneratingMoreThan10M',
  /** column name */
  HasOneIndustryStartupMatch = 'hasOneIndustryStartupMatch',
  /** column name */
  HasStartupIndustryExperience = 'hasStartupIndustryExperience',
  /** column name */
  HasTopExperienceWeight = 'hasTopExperienceWeight',
  /** column name */
  HasTwoIndustryStartupMatch = 'hasTwoIndustryStartupMatch',
  /** column name */
  Id = 'id',
  /** column name */
  JobsCategory = 'jobsCategory',
  /** column name */
  RaisedDuringAFounderExperienceCategory = 'raisedDuringAFounderExperienceCategory',
  /** column name */
  TopInvestors = 'topInvestors',
  /** column name */
  WorkedInNrCountries = 'workedInNrCountries',
  /** column name */
  WorkedInUs = 'workedInUS',
  /** column name */
  YearsOfExperienceWeight = 'yearsOfExperienceWeight',
}

/** Streaming cursor of the table "founder_score_weights" */
export type Founder_Score_Weights_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Founder_Score_Weights_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Founder_Score_Weights_Stream_Cursor_Value_Input = {
  articlesCategory?: InputMaybe<Scalars['float8']>;
  companyExperienceRaisedBeforeAndDuringMoreThan5M?: InputMaybe<Scalars['float8']>;
  companyExperienceRaisedBeforeAndDuringPrevStartup?: InputMaybe<Scalars['float8']>;
  dateTime?: InputMaybe<Scalars['String']>;
  didTopTierSchool?: InputMaybe<Scalars['float8']>;
  exitsCategory?: InputMaybe<Scalars['float8']>;
  foundedOrganzationsCategory?: InputMaybe<Scalars['float8']>;
  hasCLvlGeneratingMoreThan10MOrhasCLvlMinimum10MFundedBeforeAndD?: InputMaybe<Scalars['float8']>;
  hasCLvlGeneratingMoreThan100MOrhasCLvlMinimum200MFundedBeforeAn?: InputMaybe<Scalars['float8']>;
  hasManagerLvlFundedBeforeAndDuringOrhasCLvlFundedBeforeAndDurin?: InputMaybe<Scalars['float8']>;
  hasManagerLvlGeneratingMoreThan10M?: InputMaybe<Scalars['float8']>;
  hasOneIndustryStartupMatch?: InputMaybe<Scalars['float8']>;
  hasStartupIndustryExperience?: InputMaybe<Scalars['float8']>;
  hasTopExperienceWeight?: InputMaybe<Scalars['float8']>;
  hasTwoIndustryStartupMatch?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  jobsCategory?: InputMaybe<Scalars['float8']>;
  raisedDuringAFounderExperienceCategory?: InputMaybe<Scalars['float8']>;
  topInvestors?: InputMaybe<Scalars['float8']>;
  workedInNrCountries?: InputMaybe<Scalars['float8']>;
  workedInUS?: InputMaybe<Scalars['float8']>;
  yearsOfExperienceWeight?: InputMaybe<Scalars['float8']>;
};

/** columns and relationships of "founders" */
export type Founders = {
  __typename?: 'founders';
  age?: Maybe<Scalars['Int']>;
  confidenceScore?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  crunchbaseRank?: Maybe<Scalars['Int']>;
  dateOfBirth?: Maybe<Scalars['date']>;
  dateOfLastUpdate?: Maybe<Scalars['String']>;
  degreesTypes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  educations: Array<Education>;
  featureArticlesCategory?: Maybe<Scalars['Int']>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: Maybe<Scalars['Int']>;
  featureBonusHasCLvlFundedBeforeAndDuring?: Maybe<Scalars['Boolean']>;
  featureBonusHasCLvlGeneratingMoreThan10M?: Maybe<Scalars['Boolean']>;
  featureBonusHasCLvlGeneratingMoreThan100M?: Maybe<Scalars['Boolean']>;
  featureBonusHasCLvlMinimum10MFundedBeforeAndDuring?: Maybe<Scalars['Boolean']>;
  featureBonusHasCLvlMinimum200MFundedBeforeAndDuring?: Maybe<Scalars['Boolean']>;
  featureBonusHasManagerLvlFundedBeforeAndDuring?: Maybe<Scalars['Boolean']>;
  featureBonusHasManagerLvlGeneratingMoreThan10M?: Maybe<Scalars['Boolean']>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: Maybe<Scalars['Int']>;
  featureBonusTopInvestors?: Maybe<Scalars['Int']>;
  featureBonusWorkedInNrCountries?: Maybe<Scalars['Int']>;
  featureBonusWorkedInUS?: Maybe<Scalars['Boolean']>;
  featureDidTopTierSchool?: Maybe<Scalars['Boolean']>;
  featureExitsCategory?: Maybe<Scalars['Int']>;
  featureFoundedOrganzationsCategory?: Maybe<Scalars['Int']>;
  featureHasStartupIndustryExperience?: Maybe<Scalars['Boolean']>;
  featureHasTopExperience?: Maybe<Scalars['Boolean']>;
  featureJobsCategory?: Maybe<Scalars['Int']>;
  featureRaisedDuringAFounderExperienceCategory?: Maybe<Scalars['Boolean']>;
  featureYearsOfExperience?: Maybe<Scalars['Int']>;
  founderDataSaved: Scalars['Boolean'];
  founderID: Scalars['String'];
  founderScore?: Maybe<Scalars['float8']>;
  founderScoreWithBonus?: Maybe<Scalars['float8']>;
  founderTag?: Maybe<Scalars['String']>;
  /** An array relationship */
  founder_jobs: Array<Founder_Jobs>;
  founder_score?: Maybe<Scalars['float8']>;
  gender?: Maybe<Scalars['String']>;
  hasMoreThan30?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  inBubble?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  livesInTheUS?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  numberOfArticles?: Maybe<Scalars['Int']>;
  numberOfArticlesBeforeStartup?: Maybe<Scalars['Int']>;
  numberOfCurrentJobs?: Maybe<Scalars['Int']>;
  numberOfCurrentJobsBeforeStartup?: Maybe<Scalars['Int']>;
  numberOfExits?: Maybe<Scalars['Int']>;
  numberOfExitsBeforeStartup?: Maybe<Scalars['Int']>;
  numberOfFoundedOrganizations?: Maybe<Scalars['Int']>;
  numberOfFoundedOrganizationsBeforeStartup?: Maybe<Scalars['Int']>;
  numberOfInvestments?: Maybe<Scalars['Int']>;
  numberOfInvestmentsBeforeStartup?: Maybe<Scalars['Int']>;
  numberOfJobs?: Maybe<Scalars['Int']>;
  numberOfTimesFetch?: Maybe<Scalars['Int']>;
  schoolScore?: Maybe<Scalars['Int']>;
  schools?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  trendScore30days?: Maybe<Scalars['float8']>;
  twitter?: Maybe<Scalars['String']>;
  updatedProfileDate?: Maybe<Scalars['date']>;
};

/** columns and relationships of "founders" */
export type FoundersEducationsArgs = {
  distinct_on?: InputMaybe<Array<Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Education_Order_By>>;
  where?: InputMaybe<Education_Bool_Exp>;
};

/** columns and relationships of "founders" */
export type FoundersFounder_JobsArgs = {
  distinct_on?: InputMaybe<Array<Founder_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Founder_Jobs_Order_By>>;
  where?: InputMaybe<Founder_Jobs_Bool_Exp>;
};

/** order by aggregate values of table "founders" */
export type Founders_Aggregate_Order_By = {
  avg?: InputMaybe<Founders_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Founders_Max_Order_By>;
  min?: InputMaybe<Founders_Min_Order_By>;
  stddev?: InputMaybe<Founders_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Founders_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Founders_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Founders_Sum_Order_By>;
  var_pop?: InputMaybe<Founders_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Founders_Var_Samp_Order_By>;
  variance?: InputMaybe<Founders_Variance_Order_By>;
};

/** order by avg() on columns of table "founders" */
export type Founders_Avg_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "founders". All fields are combined with a logical 'AND'. */
export type Founders_Bool_Exp = {
  _and?: InputMaybe<Array<Founders_Bool_Exp>>;
  _not?: InputMaybe<Founders_Bool_Exp>;
  _or?: InputMaybe<Array<Founders_Bool_Exp>>;
  age?: InputMaybe<Int_Comparison_Exp>;
  confidenceScore?: InputMaybe<Int_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  crunchbaseRank?: InputMaybe<Int_Comparison_Exp>;
  dateOfBirth?: InputMaybe<Date_Comparison_Exp>;
  dateOfLastUpdate?: InputMaybe<String_Comparison_Exp>;
  degreesTypes?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  educations?: InputMaybe<Education_Bool_Exp>;
  featureArticlesCategory?: InputMaybe<Int_Comparison_Exp>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Int_Comparison_Exp>;
  featureBonusHasCLvlFundedBeforeAndDuring?: InputMaybe<Boolean_Comparison_Exp>;
  featureBonusHasCLvlGeneratingMoreThan10M?: InputMaybe<Boolean_Comparison_Exp>;
  featureBonusHasCLvlGeneratingMoreThan100M?: InputMaybe<Boolean_Comparison_Exp>;
  featureBonusHasCLvlMinimum10MFundedBeforeAndDuring?: InputMaybe<Boolean_Comparison_Exp>;
  featureBonusHasCLvlMinimum200MFundedBeforeAndDuring?: InputMaybe<Boolean_Comparison_Exp>;
  featureBonusHasManagerLvlFundedBeforeAndDuring?: InputMaybe<Boolean_Comparison_Exp>;
  featureBonusHasManagerLvlGeneratingMoreThan10M?: InputMaybe<Boolean_Comparison_Exp>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Int_Comparison_Exp>;
  featureBonusTopInvestors?: InputMaybe<Int_Comparison_Exp>;
  featureBonusWorkedInNrCountries?: InputMaybe<Int_Comparison_Exp>;
  featureBonusWorkedInUS?: InputMaybe<Boolean_Comparison_Exp>;
  featureDidTopTierSchool?: InputMaybe<Boolean_Comparison_Exp>;
  featureExitsCategory?: InputMaybe<Int_Comparison_Exp>;
  featureFoundedOrganzationsCategory?: InputMaybe<Int_Comparison_Exp>;
  featureHasStartupIndustryExperience?: InputMaybe<Boolean_Comparison_Exp>;
  featureHasTopExperience?: InputMaybe<Boolean_Comparison_Exp>;
  featureJobsCategory?: InputMaybe<Int_Comparison_Exp>;
  featureRaisedDuringAFounderExperienceCategory?: InputMaybe<Boolean_Comparison_Exp>;
  featureYearsOfExperience?: InputMaybe<Int_Comparison_Exp>;
  founderDataSaved?: InputMaybe<Boolean_Comparison_Exp>;
  founderID?: InputMaybe<String_Comparison_Exp>;
  founderScore?: InputMaybe<Float8_Comparison_Exp>;
  founderScoreWithBonus?: InputMaybe<Float8_Comparison_Exp>;
  founderTag?: InputMaybe<String_Comparison_Exp>;
  founder_jobs?: InputMaybe<Founder_Jobs_Bool_Exp>;
  founder_score?: InputMaybe<Float8_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  hasMoreThan30?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inBubble?: InputMaybe<Boolean_Comparison_Exp>;
  jobTitle?: InputMaybe<String_Comparison_Exp>;
  linkedin?: InputMaybe<String_Comparison_Exp>;
  livesInTheUS?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  numberOfArticles?: InputMaybe<Int_Comparison_Exp>;
  numberOfArticlesBeforeStartup?: InputMaybe<Int_Comparison_Exp>;
  numberOfCurrentJobs?: InputMaybe<Int_Comparison_Exp>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Int_Comparison_Exp>;
  numberOfExits?: InputMaybe<Int_Comparison_Exp>;
  numberOfExitsBeforeStartup?: InputMaybe<Int_Comparison_Exp>;
  numberOfFoundedOrganizations?: InputMaybe<Int_Comparison_Exp>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Int_Comparison_Exp>;
  numberOfInvestments?: InputMaybe<Int_Comparison_Exp>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Int_Comparison_Exp>;
  numberOfJobs?: InputMaybe<Int_Comparison_Exp>;
  numberOfTimesFetch?: InputMaybe<Int_Comparison_Exp>;
  schoolScore?: InputMaybe<Int_Comparison_Exp>;
  schools?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  trendScore30days?: InputMaybe<Float8_Comparison_Exp>;
  twitter?: InputMaybe<String_Comparison_Exp>;
  updatedProfileDate?: InputMaybe<Date_Comparison_Exp>;
};

/** order by max() on columns of table "founders" */
export type Founders_Max_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  dateOfLastUpdate?: InputMaybe<Order_By>;
  degreesTypes?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founderTag?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobTitle?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  schools?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedProfileDate?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "founders" */
export type Founders_Min_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  dateOfLastUpdate?: InputMaybe<Order_By>;
  degreesTypes?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founderTag?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  jobTitle?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  schools?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedProfileDate?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "founders". */
export type Founders_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  dateOfBirth?: InputMaybe<Order_By>;
  dateOfLastUpdate?: InputMaybe<Order_By>;
  degreesTypes?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  educations_aggregate?: InputMaybe<Education_Aggregate_Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusHasCLvlFundedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusHasCLvlGeneratingMoreThan10M?: InputMaybe<Order_By>;
  featureBonusHasCLvlGeneratingMoreThan100M?: InputMaybe<Order_By>;
  featureBonusHasCLvlMinimum10MFundedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusHasCLvlMinimum200MFundedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusHasManagerLvlFundedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusHasManagerLvlGeneratingMoreThan10M?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureBonusWorkedInUS?: InputMaybe<Order_By>;
  featureDidTopTierSchool?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureHasStartupIndustryExperience?: InputMaybe<Order_By>;
  featureHasTopExperience?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureRaisedDuringAFounderExperienceCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderDataSaved?: InputMaybe<Order_By>;
  founderID?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founderTag?: InputMaybe<Order_By>;
  founder_jobs_aggregate?: InputMaybe<Founder_Jobs_Aggregate_Order_By>;
  founder_score?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBubble?: InputMaybe<Order_By>;
  jobTitle?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  schools?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  updatedProfileDate?: InputMaybe<Order_By>;
};

/** select columns of table "founders" */
export enum Founders_Select_Column {
  /** column name */
  Age = 'age',
  /** column name */
  ConfidenceScore = 'confidenceScore',
  /** column name */
  Country = 'country',
  /** column name */
  CrunchbaseRank = 'crunchbaseRank',
  /** column name */
  DateOfBirth = 'dateOfBirth',
  /** column name */
  DateOfLastUpdate = 'dateOfLastUpdate',
  /** column name */
  DegreesTypes = 'degreesTypes',
  /** column name */
  Description = 'description',
  /** column name */
  FeatureArticlesCategory = 'featureArticlesCategory',
  /** column name */
  FeatureBonusCompanyExperienceRaisedBeforeAndDuring = 'featureBonusCompanyExperienceRaisedBeforeAndDuring',
  /** column name */
  FeatureBonusHasCLvlFundedBeforeAndDuring = 'featureBonusHasCLvlFundedBeforeAndDuring',
  /** column name */
  FeatureBonusHasCLvlGeneratingMoreThan10M = 'featureBonusHasCLvlGeneratingMoreThan10M',
  /** column name */
  FeatureBonusHasCLvlGeneratingMoreThan100M = 'featureBonusHasCLvlGeneratingMoreThan100M',
  /** column name */
  FeatureBonusHasCLvlMinimum10MFundedBeforeAndDuring = 'featureBonusHasCLvlMinimum10MFundedBeforeAndDuring',
  /** column name */
  FeatureBonusHasCLvlMinimum200MFundedBeforeAndDuring = 'featureBonusHasCLvlMinimum200MFundedBeforeAndDuring',
  /** column name */
  FeatureBonusHasManagerLvlFundedBeforeAndDuring = 'featureBonusHasManagerLvlFundedBeforeAndDuring',
  /** column name */
  FeatureBonusHasManagerLvlGeneratingMoreThan10M = 'featureBonusHasManagerLvlGeneratingMoreThan10M',
  /** column name */
  FeatureBonusNbIndustryExperiencesCrunchTop2Bonus = 'featureBonusNbIndustryExperiencesCrunchTop2Bonus',
  /** column name */
  FeatureBonusTopInvestors = 'featureBonusTopInvestors',
  /** column name */
  FeatureBonusWorkedInNrCountries = 'featureBonusWorkedInNrCountries',
  /** column name */
  FeatureBonusWorkedInUs = 'featureBonusWorkedInUS',
  /** column name */
  FeatureDidTopTierSchool = 'featureDidTopTierSchool',
  /** column name */
  FeatureExitsCategory = 'featureExitsCategory',
  /** column name */
  FeatureFoundedOrganzationsCategory = 'featureFoundedOrganzationsCategory',
  /** column name */
  FeatureHasStartupIndustryExperience = 'featureHasStartupIndustryExperience',
  /** column name */
  FeatureHasTopExperience = 'featureHasTopExperience',
  /** column name */
  FeatureJobsCategory = 'featureJobsCategory',
  /** column name */
  FeatureRaisedDuringAFounderExperienceCategory = 'featureRaisedDuringAFounderExperienceCategory',
  /** column name */
  FeatureYearsOfExperience = 'featureYearsOfExperience',
  /** column name */
  FounderDataSaved = 'founderDataSaved',
  /** column name */
  FounderId = 'founderID',
  /** column name */
  FounderScoreWithBonus = 'founderScoreWithBonus',
  /** column name */
  FounderTag = 'founderTag',
  /** column name */
  FounderScore = 'founder_score',
  /** column name */
  Gender = 'gender',
  /** column name */
  HasMoreThan30 = 'hasMoreThan30',
  /** column name */
  Id = 'id',
  /** column name */
  InBubble = 'inBubble',
  /** column name */
  JobTitle = 'jobTitle',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  LivesInTheUs = 'livesInTheUS',
  /** column name */
  Name = 'name',
  /** column name */
  NumberOfArticles = 'numberOfArticles',
  /** column name */
  NumberOfArticlesBeforeStartup = 'numberOfArticlesBeforeStartup',
  /** column name */
  NumberOfCurrentJobs = 'numberOfCurrentJobs',
  /** column name */
  NumberOfCurrentJobsBeforeStartup = 'numberOfCurrentJobsBeforeStartup',
  /** column name */
  NumberOfExits = 'numberOfExits',
  /** column name */
  NumberOfExitsBeforeStartup = 'numberOfExitsBeforeStartup',
  /** column name */
  NumberOfFoundedOrganizations = 'numberOfFoundedOrganizations',
  /** column name */
  NumberOfFoundedOrganizationsBeforeStartup = 'numberOfFoundedOrganizationsBeforeStartup',
  /** column name */
  NumberOfInvestments = 'numberOfInvestments',
  /** column name */
  NumberOfInvestmentsBeforeStartup = 'numberOfInvestmentsBeforeStartup',
  /** column name */
  NumberOfJobs = 'numberOfJobs',
  /** column name */
  NumberOfTimesFetch = 'numberOfTimesFetch',
  /** column name */
  SchoolScore = 'schoolScore',
  /** column name */
  Schools = 'schools',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  TrendScore30days = 'trendScore30days',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  UpdatedProfileDate = 'updatedProfileDate',
}

/** order by stddev() on columns of table "founders" */
export type Founders_Stddev_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "founders" */
export type Founders_Stddev_Pop_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "founders" */
export type Founders_Stddev_Samp_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "founders" */
export type Founders_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Founders_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Founders_Stream_Cursor_Value_Input = {
  age?: InputMaybe<Scalars['Int']>;
  confidenceScore?: InputMaybe<Scalars['Int']>;
  country?: InputMaybe<Scalars['String']>;
  crunchbaseRank?: InputMaybe<Scalars['Int']>;
  dateOfBirth?: InputMaybe<Scalars['date']>;
  dateOfLastUpdate?: InputMaybe<Scalars['String']>;
  degreesTypes?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  featureArticlesCategory?: InputMaybe<Scalars['Int']>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Scalars['Int']>;
  featureBonusHasCLvlFundedBeforeAndDuring?: InputMaybe<Scalars['Boolean']>;
  featureBonusHasCLvlGeneratingMoreThan10M?: InputMaybe<Scalars['Boolean']>;
  featureBonusHasCLvlGeneratingMoreThan100M?: InputMaybe<Scalars['Boolean']>;
  featureBonusHasCLvlMinimum10MFundedBeforeAndDuring?: InputMaybe<Scalars['Boolean']>;
  featureBonusHasCLvlMinimum200MFundedBeforeAndDuring?: InputMaybe<Scalars['Boolean']>;
  featureBonusHasManagerLvlFundedBeforeAndDuring?: InputMaybe<Scalars['Boolean']>;
  featureBonusHasManagerLvlGeneratingMoreThan10M?: InputMaybe<Scalars['Boolean']>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Scalars['Int']>;
  featureBonusTopInvestors?: InputMaybe<Scalars['Int']>;
  featureBonusWorkedInNrCountries?: InputMaybe<Scalars['Int']>;
  featureBonusWorkedInUS?: InputMaybe<Scalars['Boolean']>;
  featureDidTopTierSchool?: InputMaybe<Scalars['Boolean']>;
  featureExitsCategory?: InputMaybe<Scalars['Int']>;
  featureFoundedOrganzationsCategory?: InputMaybe<Scalars['Int']>;
  featureHasStartupIndustryExperience?: InputMaybe<Scalars['Boolean']>;
  featureHasTopExperience?: InputMaybe<Scalars['Boolean']>;
  featureJobsCategory?: InputMaybe<Scalars['Int']>;
  featureRaisedDuringAFounderExperienceCategory?: InputMaybe<Scalars['Boolean']>;
  featureYearsOfExperience?: InputMaybe<Scalars['Int']>;
  founderDataSaved?: InputMaybe<Scalars['Boolean']>;
  founderID?: InputMaybe<Scalars['String']>;
  founderScore?: InputMaybe<Scalars['float8']>;
  founderScoreWithBonus?: InputMaybe<Scalars['float8']>;
  founderTag?: InputMaybe<Scalars['String']>;
  founder_score?: InputMaybe<Scalars['float8']>;
  gender?: InputMaybe<Scalars['String']>;
  hasMoreThan30?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  inBubble?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  livesInTheUS?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  numberOfArticles?: InputMaybe<Scalars['Int']>;
  numberOfArticlesBeforeStartup?: InputMaybe<Scalars['Int']>;
  numberOfCurrentJobs?: InputMaybe<Scalars['Int']>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Scalars['Int']>;
  numberOfExits?: InputMaybe<Scalars['Int']>;
  numberOfExitsBeforeStartup?: InputMaybe<Scalars['Int']>;
  numberOfFoundedOrganizations?: InputMaybe<Scalars['Int']>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Scalars['Int']>;
  numberOfInvestments?: InputMaybe<Scalars['Int']>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Scalars['Int']>;
  numberOfJobs?: InputMaybe<Scalars['Int']>;
  numberOfTimesFetch?: InputMaybe<Scalars['Int']>;
  schoolScore?: InputMaybe<Scalars['Int']>;
  schools?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  trendScore30days?: InputMaybe<Scalars['float8']>;
  twitter?: InputMaybe<Scalars['String']>;
  updatedProfileDate?: InputMaybe<Scalars['date']>;
};

/** order by sum() on columns of table "founders" */
export type Founders_Sum_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "founders" */
export type Founders_Var_Pop_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "founders" */
export type Founders_Var_Samp_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "founders" */
export type Founders_Variance_Order_By = {
  age?: InputMaybe<Order_By>;
  confidenceScore?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  featureArticlesCategory?: InputMaybe<Order_By>;
  featureBonusCompanyExperienceRaisedBeforeAndDuring?: InputMaybe<Order_By>;
  featureBonusNbIndustryExperiencesCrunchTop2Bonus?: InputMaybe<Order_By>;
  featureBonusTopInvestors?: InputMaybe<Order_By>;
  featureBonusWorkedInNrCountries?: InputMaybe<Order_By>;
  featureExitsCategory?: InputMaybe<Order_By>;
  featureFoundedOrganzationsCategory?: InputMaybe<Order_By>;
  featureJobsCategory?: InputMaybe<Order_By>;
  featureYearsOfExperience?: InputMaybe<Order_By>;
  founderScore?: InputMaybe<Order_By>;
  founderScoreWithBonus?: InputMaybe<Order_By>;
  founder_score?: InputMaybe<Order_By>;
  hasMoreThan30?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  livesInTheUS?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfArticlesBeforeStartup?: InputMaybe<Order_By>;
  numberOfCurrentJobs?: InputMaybe<Order_By>;
  numberOfCurrentJobsBeforeStartup?: InputMaybe<Order_By>;
  numberOfExits?: InputMaybe<Order_By>;
  numberOfExitsBeforeStartup?: InputMaybe<Order_By>;
  numberOfFoundedOrganizations?: InputMaybe<Order_By>;
  numberOfFoundedOrganizationsBeforeStartup?: InputMaybe<Order_By>;
  numberOfInvestments?: InputMaybe<Order_By>;
  numberOfInvestmentsBeforeStartup?: InputMaybe<Order_By>;
  numberOfJobs?: InputMaybe<Order_By>;
  numberOfTimesFetch?: InputMaybe<Order_By>;
  schoolScore?: InputMaybe<Order_By>;
  trendScore30days?: InputMaybe<Order_By>;
};

/** columns and relationships of "g2_historical_data" */
export type G2_Historical_Data = {
  __typename?: 'g2_historical_data';
  date: Scalars['date'];
  id: Scalars['Int'];
  number_of_reviews?: Maybe<Scalars['Int']>;
  number_of_stars?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "g2_historical_data" */
export type G2_Historical_Data_Aggregate_Order_By = {
  avg?: InputMaybe<G2_Historical_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<G2_Historical_Data_Max_Order_By>;
  min?: InputMaybe<G2_Historical_Data_Min_Order_By>;
  stddev?: InputMaybe<G2_Historical_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<G2_Historical_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<G2_Historical_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<G2_Historical_Data_Sum_Order_By>;
  var_pop?: InputMaybe<G2_Historical_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<G2_Historical_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<G2_Historical_Data_Variance_Order_By>;
};

/** order by avg() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "g2_historical_data". All fields are combined with a logical 'AND'. */
export type G2_Historical_Data_Bool_Exp = {
  _and?: InputMaybe<Array<G2_Historical_Data_Bool_Exp>>;
  _not?: InputMaybe<G2_Historical_Data_Bool_Exp>;
  _or?: InputMaybe<Array<G2_Historical_Data_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  number_of_reviews?: InputMaybe<Int_Comparison_Exp>;
  number_of_stars?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "g2_historical_data". */
export type G2_Historical_Data_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "g2_historical_data" */
export enum G2_Historical_Data_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfReviews = 'number_of_reviews',
  /** column name */
  NumberOfStars = 'number_of_stars',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "g2_historical_data" */
export type G2_Historical_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: G2_Historical_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type G2_Historical_Data_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  number_of_reviews?: InputMaybe<Scalars['Int']>;
  number_of_stars?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "g2_historical_data" */
export type G2_Historical_Data_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  number_of_stars?: InputMaybe<Order_By>;
};

/** columns and relationships of "g2_industry" */
export type G2_Industry = {
  __typename?: 'g2_industry';
  id: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  number_of_reviews?: Maybe<Scalars['Int']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "g2_industry" */
export type G2_Industry_Aggregate_Order_By = {
  avg?: InputMaybe<G2_Industry_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<G2_Industry_Max_Order_By>;
  min?: InputMaybe<G2_Industry_Min_Order_By>;
  stddev?: InputMaybe<G2_Industry_Stddev_Order_By>;
  stddev_pop?: InputMaybe<G2_Industry_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<G2_Industry_Stddev_Samp_Order_By>;
  sum?: InputMaybe<G2_Industry_Sum_Order_By>;
  var_pop?: InputMaybe<G2_Industry_Var_Pop_Order_By>;
  var_samp?: InputMaybe<G2_Industry_Var_Samp_Order_By>;
  variance?: InputMaybe<G2_Industry_Variance_Order_By>;
};

/** order by avg() on columns of table "g2_industry" */
export type G2_Industry_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "g2_industry". All fields are combined with a logical 'AND'. */
export type G2_Industry_Bool_Exp = {
  _and?: InputMaybe<Array<G2_Industry_Bool_Exp>>;
  _not?: InputMaybe<G2_Industry_Bool_Exp>;
  _or?: InputMaybe<Array<G2_Industry_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  number_of_reviews?: InputMaybe<Int_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "g2_industry" */
export type G2_Industry_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "g2_industry" */
export type G2_Industry_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "g2_industry". */
export type G2_Industry_Order_By = {
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "g2_industry" */
export enum G2_Industry_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  NumberOfReviews = 'number_of_reviews',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "g2_industry" */
export type G2_Industry_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "g2_industry" */
export type G2_Industry_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "g2_industry" */
export type G2_Industry_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "g2_industry" */
export type G2_Industry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: G2_Industry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type G2_Industry_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  number_of_reviews?: InputMaybe<Scalars['Int']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "g2_industry" */
export type G2_Industry_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "g2_industry" */
export type G2_Industry_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "g2_industry" */
export type G2_Industry_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "g2_industry" */
export type G2_Industry_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_reviews?: InputMaybe<Order_By>;
};

/** columns and relationships of "g2_product_category" */
export type G2_Product_Category = {
  __typename?: 'g2_product_category';
  id: Scalars['Int'];
  product_category?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "g2_product_category" */
export type G2_Product_Category_Aggregate_Order_By = {
  avg?: InputMaybe<G2_Product_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<G2_Product_Category_Max_Order_By>;
  min?: InputMaybe<G2_Product_Category_Min_Order_By>;
  stddev?: InputMaybe<G2_Product_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<G2_Product_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<G2_Product_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<G2_Product_Category_Sum_Order_By>;
  var_pop?: InputMaybe<G2_Product_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<G2_Product_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<G2_Product_Category_Variance_Order_By>;
};

/** order by avg() on columns of table "g2_product_category" */
export type G2_Product_Category_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "g2_product_category". All fields are combined with a logical 'AND'. */
export type G2_Product_Category_Bool_Exp = {
  _and?: InputMaybe<Array<G2_Product_Category_Bool_Exp>>;
  _not?: InputMaybe<G2_Product_Category_Bool_Exp>;
  _or?: InputMaybe<Array<G2_Product_Category_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  product_category?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "g2_product_category" */
export type G2_Product_Category_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "g2_product_category" */
export type G2_Product_Category_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "g2_product_category". */
export type G2_Product_Category_Order_By = {
  id?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "g2_product_category" */
export enum G2_Product_Category_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  ProductCategory = 'product_category',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "g2_product_category" */
export type G2_Product_Category_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "g2_product_category" */
export type G2_Product_Category_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "g2_product_category" */
export type G2_Product_Category_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "g2_product_category" */
export type G2_Product_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: G2_Product_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type G2_Product_Category_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  product_category?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "g2_product_category" */
export type G2_Product_Category_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "g2_product_category" */
export type G2_Product_Category_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "g2_product_category" */
export type G2_Product_Category_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "g2_product_category" */
export type G2_Product_Category_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "g2_product_competitors" */
export type G2_Product_Competitors = {
  __typename?: 'g2_product_competitors';
  competitorName?: Maybe<Scalars['String']>;
  competitor_uuid?: Maybe<Scalars['uuid']>;
  id: Scalars['Int'];
  product_competitor: Scalars['String'];
  /** An object relationship */
  startup: Startups;
  /** An object relationship */
  startup_competitor?: Maybe<Startups>;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "g2_product_competitors" */
export type G2_Product_Competitors_Aggregate_Order_By = {
  avg?: InputMaybe<G2_Product_Competitors_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<G2_Product_Competitors_Max_Order_By>;
  min?: InputMaybe<G2_Product_Competitors_Min_Order_By>;
  stddev?: InputMaybe<G2_Product_Competitors_Stddev_Order_By>;
  stddev_pop?: InputMaybe<G2_Product_Competitors_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<G2_Product_Competitors_Stddev_Samp_Order_By>;
  sum?: InputMaybe<G2_Product_Competitors_Sum_Order_By>;
  var_pop?: InputMaybe<G2_Product_Competitors_Var_Pop_Order_By>;
  var_samp?: InputMaybe<G2_Product_Competitors_Var_Samp_Order_By>;
  variance?: InputMaybe<G2_Product_Competitors_Variance_Order_By>;
};

/** order by avg() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "g2_product_competitors". All fields are combined with a logical 'AND'. */
export type G2_Product_Competitors_Bool_Exp = {
  _and?: InputMaybe<Array<G2_Product_Competitors_Bool_Exp>>;
  _not?: InputMaybe<G2_Product_Competitors_Bool_Exp>;
  _or?: InputMaybe<Array<G2_Product_Competitors_Bool_Exp>>;
  competitorName?: InputMaybe<String_Comparison_Exp>;
  competitor_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  product_competitor?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_competitor?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Max_Order_By = {
  competitorName?: InputMaybe<Order_By>;
  competitor_uuid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_competitor?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Min_Order_By = {
  competitorName?: InputMaybe<Order_By>;
  competitor_uuid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_competitor?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "g2_product_competitors". */
export type G2_Product_Competitors_Order_By = {
  competitorName?: InputMaybe<Order_By>;
  competitor_uuid?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_competitor?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_competitor?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "g2_product_competitors" */
export enum G2_Product_Competitors_Select_Column {
  /** column name */
  CompetitorName = 'competitorName',
  /** column name */
  CompetitorUuid = 'competitor_uuid',
  /** column name */
  Id = 'id',
  /** column name */
  ProductCompetitor = 'product_competitor',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "g2_product_competitors" */
export type G2_Product_Competitors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: G2_Product_Competitors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type G2_Product_Competitors_Stream_Cursor_Value_Input = {
  competitorName?: InputMaybe<Scalars['String']>;
  competitor_uuid?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['Int']>;
  product_competitor?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "g2_product_competitors" */
export type G2_Product_Competitors_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "g2_product_reviews" */
export type G2_Product_Reviews = {
  __typename?: 'g2_product_reviews';
  category_review?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  product_competitor: Scalars['String'];
  review_content?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "g2_product_reviews" */
export type G2_Product_Reviews_Aggregate_Order_By = {
  avg?: InputMaybe<G2_Product_Reviews_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<G2_Product_Reviews_Max_Order_By>;
  min?: InputMaybe<G2_Product_Reviews_Min_Order_By>;
  stddev?: InputMaybe<G2_Product_Reviews_Stddev_Order_By>;
  stddev_pop?: InputMaybe<G2_Product_Reviews_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<G2_Product_Reviews_Stddev_Samp_Order_By>;
  sum?: InputMaybe<G2_Product_Reviews_Sum_Order_By>;
  var_pop?: InputMaybe<G2_Product_Reviews_Var_Pop_Order_By>;
  var_samp?: InputMaybe<G2_Product_Reviews_Var_Samp_Order_By>;
  variance?: InputMaybe<G2_Product_Reviews_Variance_Order_By>;
};

/** order by avg() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "g2_product_reviews". All fields are combined with a logical 'AND'. */
export type G2_Product_Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<G2_Product_Reviews_Bool_Exp>>;
  _not?: InputMaybe<G2_Product_Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<G2_Product_Reviews_Bool_Exp>>;
  category_review?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  product_competitor?: InputMaybe<String_Comparison_Exp>;
  review_content?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Max_Order_By = {
  category_review?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_competitor?: InputMaybe<Order_By>;
  review_content?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Min_Order_By = {
  category_review?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_competitor?: InputMaybe<Order_By>;
  review_content?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "g2_product_reviews". */
export type G2_Product_Reviews_Order_By = {
  category_review?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_competitor?: InputMaybe<Order_By>;
  review_content?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "g2_product_reviews" */
export enum G2_Product_Reviews_Select_Column {
  /** column name */
  CategoryReview = 'category_review',
  /** column name */
  Id = 'id',
  /** column name */
  ProductCompetitor = 'product_competitor',
  /** column name */
  ReviewContent = 'review_content',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "g2_product_reviews" */
export type G2_Product_Reviews_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: G2_Product_Reviews_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type G2_Product_Reviews_Stream_Cursor_Value_Input = {
  category_review?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  product_competitor?: InputMaybe<Scalars['String']>;
  review_content?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "g2_product_reviews" */
export type G2_Product_Reviews_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "glassdoor_data" */
export type Glassdoor_Data = {
  __typename?: 'glassdoor_data';
  date: Scalars['date'];
  details_revenue?: Maybe<Scalars['String']>;
  diversity_inclusion_score?: Maybe<Scalars['float8']>;
  experienceNegative?: Maybe<Scalars['float8']>;
  experienceNeutral?: Maybe<Scalars['float8']>;
  experiencePositive?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  ratings_all_review_count?: Maybe<Scalars['float8']>;
  ratings_business_outlook?: Maybe<Scalars['float8']>;
  ratings_career_opportunities?: Maybe<Scalars['float8']>;
  ratings_ceo_approval?: Maybe<Scalars['float8']>;
  ratings_compensation_benefits?: Maybe<Scalars['float8']>;
  ratings_culture_values?: Maybe<Scalars['float8']>;
  ratings_overall?: Maybe<Scalars['float8']>;
  ratings_recommend_to_friend?: Maybe<Scalars['float8']>;
  ratings_work_life_balance?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  url_oveview?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "glassdoor_data" */
export type Glassdoor_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Glassdoor_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Glassdoor_Data_Max_Order_By>;
  min?: InputMaybe<Glassdoor_Data_Min_Order_By>;
  stddev?: InputMaybe<Glassdoor_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Glassdoor_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Glassdoor_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Glassdoor_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Glassdoor_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Glassdoor_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Glassdoor_Data_Variance_Order_By>;
};

/** order by avg() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Avg_Order_By = {
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "glassdoor_data". All fields are combined with a logical 'AND'. */
export type Glassdoor_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Glassdoor_Data_Bool_Exp>>;
  _not?: InputMaybe<Glassdoor_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Glassdoor_Data_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  details_revenue?: InputMaybe<String_Comparison_Exp>;
  diversity_inclusion_score?: InputMaybe<Float8_Comparison_Exp>;
  experienceNegative?: InputMaybe<Float8_Comparison_Exp>;
  experienceNeutral?: InputMaybe<Float8_Comparison_Exp>;
  experiencePositive?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  ratings_all_review_count?: InputMaybe<Float8_Comparison_Exp>;
  ratings_business_outlook?: InputMaybe<Float8_Comparison_Exp>;
  ratings_career_opportunities?: InputMaybe<Float8_Comparison_Exp>;
  ratings_ceo_approval?: InputMaybe<Float8_Comparison_Exp>;
  ratings_compensation_benefits?: InputMaybe<Float8_Comparison_Exp>;
  ratings_culture_values?: InputMaybe<Float8_Comparison_Exp>;
  ratings_overall?: InputMaybe<Float8_Comparison_Exp>;
  ratings_recommend_to_friend?: InputMaybe<Float8_Comparison_Exp>;
  ratings_work_life_balance?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  url_oveview?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  details_revenue?: InputMaybe<Order_By>;
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  url_oveview?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  details_revenue?: InputMaybe<Order_By>;
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  url_oveview?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "glassdoor_data". */
export type Glassdoor_Data_Order_By = {
  date?: InputMaybe<Order_By>;
  details_revenue?: InputMaybe<Order_By>;
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  url_oveview?: InputMaybe<Order_By>;
};

/** select columns of table "glassdoor_data" */
export enum Glassdoor_Data_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  DetailsRevenue = 'details_revenue',
  /** column name */
  DiversityInclusionScore = 'diversity_inclusion_score',
  /** column name */
  ExperienceNegative = 'experienceNegative',
  /** column name */
  ExperienceNeutral = 'experienceNeutral',
  /** column name */
  ExperiencePositive = 'experiencePositive',
  /** column name */
  Id = 'id',
  /** column name */
  RatingsAllReviewCount = 'ratings_all_review_count',
  /** column name */
  RatingsBusinessOutlook = 'ratings_business_outlook',
  /** column name */
  RatingsCareerOpportunities = 'ratings_career_opportunities',
  /** column name */
  RatingsCeoApproval = 'ratings_ceo_approval',
  /** column name */
  RatingsCompensationBenefits = 'ratings_compensation_benefits',
  /** column name */
  RatingsCultureValues = 'ratings_culture_values',
  /** column name */
  RatingsOverall = 'ratings_overall',
  /** column name */
  RatingsRecommendToFriend = 'ratings_recommend_to_friend',
  /** column name */
  RatingsWorkLifeBalance = 'ratings_work_life_balance',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  UrlOveview = 'url_oveview',
}

/** order by stddev() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Stddev_Order_By = {
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Stddev_Pop_Order_By = {
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Stddev_Samp_Order_By = {
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "glassdoor_data" */
export type Glassdoor_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Glassdoor_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Glassdoor_Data_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  details_revenue?: InputMaybe<Scalars['String']>;
  diversity_inclusion_score?: InputMaybe<Scalars['float8']>;
  experienceNegative?: InputMaybe<Scalars['float8']>;
  experienceNeutral?: InputMaybe<Scalars['float8']>;
  experiencePositive?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  ratings_all_review_count?: InputMaybe<Scalars['float8']>;
  ratings_business_outlook?: InputMaybe<Scalars['float8']>;
  ratings_career_opportunities?: InputMaybe<Scalars['float8']>;
  ratings_ceo_approval?: InputMaybe<Scalars['float8']>;
  ratings_compensation_benefits?: InputMaybe<Scalars['float8']>;
  ratings_culture_values?: InputMaybe<Scalars['float8']>;
  ratings_overall?: InputMaybe<Scalars['float8']>;
  ratings_recommend_to_friend?: InputMaybe<Scalars['float8']>;
  ratings_work_life_balance?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  url_oveview?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Sum_Order_By = {
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Var_Pop_Order_By = {
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Var_Samp_Order_By = {
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "glassdoor_data" */
export type Glassdoor_Data_Variance_Order_By = {
  diversity_inclusion_score?: InputMaybe<Order_By>;
  experienceNegative?: InputMaybe<Order_By>;
  experienceNeutral?: InputMaybe<Order_By>;
  experiencePositive?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ratings_all_review_count?: InputMaybe<Order_By>;
  ratings_business_outlook?: InputMaybe<Order_By>;
  ratings_career_opportunities?: InputMaybe<Order_By>;
  ratings_ceo_approval?: InputMaybe<Order_By>;
  ratings_compensation_benefits?: InputMaybe<Order_By>;
  ratings_culture_values?: InputMaybe<Order_By>;
  ratings_overall?: InputMaybe<Order_By>;
  ratings_recommend_to_friend?: InputMaybe<Order_By>;
  ratings_work_life_balance?: InputMaybe<Order_By>;
};

/** columns and relationships of "glassdoor_trend" */
export type Glassdoor_Trend = {
  __typename?: 'glassdoor_trend';
  date: Scalars['date'];
  id: Scalars['Int'];
  inBubble?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  trend?: Maybe<Scalars['float8']>;
};

/** order by aggregate values of table "glassdoor_trend" */
export type Glassdoor_Trend_Aggregate_Order_By = {
  avg?: InputMaybe<Glassdoor_Trend_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Glassdoor_Trend_Max_Order_By>;
  min?: InputMaybe<Glassdoor_Trend_Min_Order_By>;
  stddev?: InputMaybe<Glassdoor_Trend_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Glassdoor_Trend_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Glassdoor_Trend_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Glassdoor_Trend_Sum_Order_By>;
  var_pop?: InputMaybe<Glassdoor_Trend_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Glassdoor_Trend_Var_Samp_Order_By>;
  variance?: InputMaybe<Glassdoor_Trend_Variance_Order_By>;
};

/** order by avg() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "glassdoor_trend". All fields are combined with a logical 'AND'. */
export type Glassdoor_Trend_Bool_Exp = {
  _and?: InputMaybe<Array<Glassdoor_Trend_Bool_Exp>>;
  _not?: InputMaybe<Glassdoor_Trend_Bool_Exp>;
  _or?: InputMaybe<Array<Glassdoor_Trend_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inBubble?: InputMaybe<Boolean_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  trend?: InputMaybe<Float8_Comparison_Exp>;
};

/** order by max() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "glassdoor_trend". */
export type Glassdoor_Trend_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBubble?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** select columns of table "glassdoor_trend" */
export enum Glassdoor_Trend_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InBubble = 'inBubble',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  Trend = 'trend',
}

/** order by stddev() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "glassdoor_trend" */
export type Glassdoor_Trend_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Glassdoor_Trend_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Glassdoor_Trend_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  inBubble?: InputMaybe<Scalars['Boolean']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  trend?: InputMaybe<Scalars['float8']>;
};

/** order by sum() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "glassdoor_trend" */
export type Glassdoor_Trend_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  trend?: InputMaybe<Order_By>;
};

/** columns and relationships of "google_serp" */
export type Google_Serp = {
  __typename?: 'google_serp';
  google_competitor?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  startup: Startups;
  /** An object relationship */
  startupByStartupUuid2: Startups;
  startup_name_1?: Maybe<Scalars['String']>;
  startup_name_2?: Maybe<Scalars['String']>;
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
  suggestion?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "google_serp" */
export type Google_Serp_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Google_Serp_Max_Order_By>;
  min?: InputMaybe<Google_Serp_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "google_serp". All fields are combined with a logical 'AND'. */
export type Google_Serp_Bool_Exp = {
  _and?: InputMaybe<Array<Google_Serp_Bool_Exp>>;
  _not?: InputMaybe<Google_Serp_Bool_Exp>;
  _or?: InputMaybe<Array<Google_Serp_Bool_Exp>>;
  google_competitor?: InputMaybe<Boolean_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startupByStartupUuid2?: InputMaybe<Startups_Bool_Exp>;
  startup_name_1?: InputMaybe<String_Comparison_Exp>;
  startup_name_2?: InputMaybe<String_Comparison_Exp>;
  startup_uuid_1?: InputMaybe<Uuid_Comparison_Exp>;
  startup_uuid_2?: InputMaybe<Uuid_Comparison_Exp>;
  suggestion?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "google_serp" */
export type Google_Serp_Max_Order_By = {
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "google_serp" */
export type Google_Serp_Min_Order_By = {
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "google_serp". */
export type Google_Serp_Order_By = {
  google_competitor?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startupByStartupUuid2?: InputMaybe<Startups_Order_By>;
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
  suggestion?: InputMaybe<Order_By>;
};

/** select columns of table "google_serp" */
export enum Google_Serp_Select_Column {
  /** column name */
  GoogleCompetitor = 'google_competitor',
  /** column name */
  StartupName_1 = 'startup_name_1',
  /** column name */
  StartupName_2 = 'startup_name_2',
  /** column name */
  StartupUuid_1 = 'startup_uuid_1',
  /** column name */
  StartupUuid_2 = 'startup_uuid_2',
  /** column name */
  Suggestion = 'suggestion',
}

/** Streaming cursor of the table "google_serp" */
export type Google_Serp_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Google_Serp_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Google_Serp_Stream_Cursor_Value_Input = {
  google_competitor?: InputMaybe<Scalars['Boolean']>;
  startup_name_1?: InputMaybe<Scalars['String']>;
  startup_name_2?: InputMaybe<Scalars['String']>;
  startup_uuid_1?: InputMaybe<Scalars['uuid']>;
  startup_uuid_2?: InputMaybe<Scalars['uuid']>;
  suggestion?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "growth" */
export type Growth = {
  __typename?: 'growth';
  annualized_growth?: Maybe<Scalars['numeric']>;
  annualized_growth_dau?: Maybe<Scalars['float8']>;
  annualized_growth_downloads?: Maybe<Scalars['float8']>;
  annualized_growth_employee?: Maybe<Scalars['float8']>;
  annualized_growth_g2?: Maybe<Scalars['numeric']>;
  annualized_growth_github?: Maybe<Scalars['numeric']>;
  annualized_growth_linkedin?: Maybe<Scalars['float8']>;
  annualized_growth_percentile?: Maybe<Scalars['numeric']>;
  annualized_growth_producthunt?: Maybe<Scalars['numeric']>;
  annualized_growth_trustpilot?: Maybe<Scalars['numeric']>;
  annualized_growth_twitter?: Maybe<Scalars['float8']>;
  annualized_growth_web_visit?: Maybe<Scalars['float8']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date: Scalars['date'];
  id: Scalars['bigint'];
  monthly_growth_us_web_visits?: Maybe<Scalars['numeric']>;
  quarterly_growth_us_web_visits?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  three_month_growth?: Maybe<Scalars['float8']>;
  three_month_growth_dau?: Maybe<Scalars['float8']>;
  three_month_growth_downloads?: Maybe<Scalars['float8']>;
  three_month_growth_employee?: Maybe<Scalars['float8']>;
  three_month_growth_g2?: Maybe<Scalars['numeric']>;
  three_month_growth_github?: Maybe<Scalars['numeric']>;
  three_month_growth_linkedin?: Maybe<Scalars['float8']>;
  three_month_growth_percentile?: Maybe<Scalars['float8']>;
  three_month_growth_producthunt?: Maybe<Scalars['numeric']>;
  three_month_growth_trustpilot?: Maybe<Scalars['numeric']>;
  three_month_growth_twitter?: Maybe<Scalars['float8']>;
  three_month_growth_web_visit?: Maybe<Scalars['float8']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  weight_dau?: Maybe<Scalars['float8']>;
  weight_downloads?: Maybe<Scalars['float8']>;
  weight_employee?: Maybe<Scalars['float8']>;
  weight_g2?: Maybe<Scalars['numeric']>;
  weight_github?: Maybe<Scalars['numeric']>;
  weight_linkedin?: Maybe<Scalars['float8']>;
  weight_producthunt?: Maybe<Scalars['numeric']>;
  weight_trustpilot?: Maybe<Scalars['numeric']>;
  weight_twitter?: Maybe<Scalars['float8']>;
  weight_web_visit?: Maybe<Scalars['float8']>;
  weights?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "growth" */
export type GrowthWeightsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "growth" */
export type Growth_Aggregate_Order_By = {
  avg?: InputMaybe<Growth_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Growth_Max_Order_By>;
  min?: InputMaybe<Growth_Min_Order_By>;
  stddev?: InputMaybe<Growth_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Growth_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Growth_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Growth_Sum_Order_By>;
  var_pop?: InputMaybe<Growth_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Growth_Var_Samp_Order_By>;
  variance?: InputMaybe<Growth_Variance_Order_By>;
};

/** order by avg() on columns of table "growth" */
export type Growth_Avg_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "growth". All fields are combined with a logical 'AND'. */
export type Growth_Bool_Exp = {
  _and?: InputMaybe<Array<Growth_Bool_Exp>>;
  _not?: InputMaybe<Growth_Bool_Exp>;
  _or?: InputMaybe<Array<Growth_Bool_Exp>>;
  annualized_growth?: InputMaybe<Numeric_Comparison_Exp>;
  annualized_growth_dau?: InputMaybe<Float8_Comparison_Exp>;
  annualized_growth_downloads?: InputMaybe<Float8_Comparison_Exp>;
  annualized_growth_employee?: InputMaybe<Float8_Comparison_Exp>;
  annualized_growth_g2?: InputMaybe<Numeric_Comparison_Exp>;
  annualized_growth_github?: InputMaybe<Numeric_Comparison_Exp>;
  annualized_growth_linkedin?: InputMaybe<Float8_Comparison_Exp>;
  annualized_growth_percentile?: InputMaybe<Numeric_Comparison_Exp>;
  annualized_growth_producthunt?: InputMaybe<Numeric_Comparison_Exp>;
  annualized_growth_trustpilot?: InputMaybe<Numeric_Comparison_Exp>;
  annualized_growth_twitter?: InputMaybe<Float8_Comparison_Exp>;
  annualized_growth_web_visit?: InputMaybe<Float8_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  monthly_growth_us_web_visits?: InputMaybe<Numeric_Comparison_Exp>;
  quarterly_growth_us_web_visits?: InputMaybe<Numeric_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  three_month_growth?: InputMaybe<Float8_Comparison_Exp>;
  three_month_growth_dau?: InputMaybe<Float8_Comparison_Exp>;
  three_month_growth_downloads?: InputMaybe<Float8_Comparison_Exp>;
  three_month_growth_employee?: InputMaybe<Float8_Comparison_Exp>;
  three_month_growth_g2?: InputMaybe<Numeric_Comparison_Exp>;
  three_month_growth_github?: InputMaybe<Numeric_Comparison_Exp>;
  three_month_growth_linkedin?: InputMaybe<Float8_Comparison_Exp>;
  three_month_growth_percentile?: InputMaybe<Float8_Comparison_Exp>;
  three_month_growth_producthunt?: InputMaybe<Numeric_Comparison_Exp>;
  three_month_growth_trustpilot?: InputMaybe<Numeric_Comparison_Exp>;
  three_month_growth_twitter?: InputMaybe<Float8_Comparison_Exp>;
  three_month_growth_web_visit?: InputMaybe<Float8_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  weight_dau?: InputMaybe<Float8_Comparison_Exp>;
  weight_downloads?: InputMaybe<Float8_Comparison_Exp>;
  weight_employee?: InputMaybe<Float8_Comparison_Exp>;
  weight_g2?: InputMaybe<Numeric_Comparison_Exp>;
  weight_github?: InputMaybe<Numeric_Comparison_Exp>;
  weight_linkedin?: InputMaybe<Float8_Comparison_Exp>;
  weight_producthunt?: InputMaybe<Numeric_Comparison_Exp>;
  weight_trustpilot?: InputMaybe<Numeric_Comparison_Exp>;
  weight_twitter?: InputMaybe<Float8_Comparison_Exp>;
  weight_web_visit?: InputMaybe<Float8_Comparison_Exp>;
  weights?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** order by max() on columns of table "growth" */
export type Growth_Max_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "growth" */
export type Growth_Min_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "growth". */
export type Growth_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
  weights?: InputMaybe<Order_By>;
};

/** select columns of table "growth" */
export enum Growth_Select_Column {
  /** column name */
  AnnualizedGrowth = 'annualized_growth',
  /** column name */
  AnnualizedGrowthDau = 'annualized_growth_dau',
  /** column name */
  AnnualizedGrowthDownloads = 'annualized_growth_downloads',
  /** column name */
  AnnualizedGrowthEmployee = 'annualized_growth_employee',
  /** column name */
  AnnualizedGrowthG2 = 'annualized_growth_g2',
  /** column name */
  AnnualizedGrowthGithub = 'annualized_growth_github',
  /** column name */
  AnnualizedGrowthLinkedin = 'annualized_growth_linkedin',
  /** column name */
  AnnualizedGrowthPercentile = 'annualized_growth_percentile',
  /** column name */
  AnnualizedGrowthProducthunt = 'annualized_growth_producthunt',
  /** column name */
  AnnualizedGrowthTrustpilot = 'annualized_growth_trustpilot',
  /** column name */
  AnnualizedGrowthTwitter = 'annualized_growth_twitter',
  /** column name */
  AnnualizedGrowthWebVisit = 'annualized_growth_web_visit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  MonthlyGrowthUsWebVisits = 'monthly_growth_us_web_visits',
  /** column name */
  QuarterlyGrowthUsWebVisits = 'quarterly_growth_us_web_visits',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  ThreeMonthGrowth = 'three_month_growth',
  /** column name */
  ThreeMonthGrowthDau = 'three_month_growth_dau',
  /** column name */
  ThreeMonthGrowthDownloads = 'three_month_growth_downloads',
  /** column name */
  ThreeMonthGrowthEmployee = 'three_month_growth_employee',
  /** column name */
  ThreeMonthGrowthG2 = 'three_month_growth_g2',
  /** column name */
  ThreeMonthGrowthGithub = 'three_month_growth_github',
  /** column name */
  ThreeMonthGrowthLinkedin = 'three_month_growth_linkedin',
  /** column name */
  ThreeMonthGrowthPercentile = 'three_month_growth_percentile',
  /** column name */
  ThreeMonthGrowthProducthunt = 'three_month_growth_producthunt',
  /** column name */
  ThreeMonthGrowthTrustpilot = 'three_month_growth_trustpilot',
  /** column name */
  ThreeMonthGrowthTwitter = 'three_month_growth_twitter',
  /** column name */
  ThreeMonthGrowthWebVisit = 'three_month_growth_web_visit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WeightDau = 'weight_dau',
  /** column name */
  WeightDownloads = 'weight_downloads',
  /** column name */
  WeightEmployee = 'weight_employee',
  /** column name */
  WeightG2 = 'weight_g2',
  /** column name */
  WeightGithub = 'weight_github',
  /** column name */
  WeightLinkedin = 'weight_linkedin',
  /** column name */
  WeightProducthunt = 'weight_producthunt',
  /** column name */
  WeightTrustpilot = 'weight_trustpilot',
  /** column name */
  WeightTwitter = 'weight_twitter',
  /** column name */
  WeightWebVisit = 'weight_web_visit',
  /** column name */
  Weights = 'weights',
}

/** order by stddev() on columns of table "growth" */
export type Growth_Stddev_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "growth" */
export type Growth_Stddev_Pop_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "growth" */
export type Growth_Stddev_Samp_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "growth" */
export type Growth_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Growth_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Growth_Stream_Cursor_Value_Input = {
  annualized_growth?: InputMaybe<Scalars['numeric']>;
  annualized_growth_dau?: InputMaybe<Scalars['float8']>;
  annualized_growth_downloads?: InputMaybe<Scalars['float8']>;
  annualized_growth_employee?: InputMaybe<Scalars['float8']>;
  annualized_growth_g2?: InputMaybe<Scalars['numeric']>;
  annualized_growth_github?: InputMaybe<Scalars['numeric']>;
  annualized_growth_linkedin?: InputMaybe<Scalars['float8']>;
  annualized_growth_percentile?: InputMaybe<Scalars['numeric']>;
  annualized_growth_producthunt?: InputMaybe<Scalars['numeric']>;
  annualized_growth_trustpilot?: InputMaybe<Scalars['numeric']>;
  annualized_growth_twitter?: InputMaybe<Scalars['float8']>;
  annualized_growth_web_visit?: InputMaybe<Scalars['float8']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['bigint']>;
  monthly_growth_us_web_visits?: InputMaybe<Scalars['numeric']>;
  quarterly_growth_us_web_visits?: InputMaybe<Scalars['numeric']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  three_month_growth?: InputMaybe<Scalars['float8']>;
  three_month_growth_dau?: InputMaybe<Scalars['float8']>;
  three_month_growth_downloads?: InputMaybe<Scalars['float8']>;
  three_month_growth_employee?: InputMaybe<Scalars['float8']>;
  three_month_growth_g2?: InputMaybe<Scalars['numeric']>;
  three_month_growth_github?: InputMaybe<Scalars['numeric']>;
  three_month_growth_linkedin?: InputMaybe<Scalars['float8']>;
  three_month_growth_percentile?: InputMaybe<Scalars['float8']>;
  three_month_growth_producthunt?: InputMaybe<Scalars['numeric']>;
  three_month_growth_trustpilot?: InputMaybe<Scalars['numeric']>;
  three_month_growth_twitter?: InputMaybe<Scalars['float8']>;
  three_month_growth_web_visit?: InputMaybe<Scalars['float8']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  weight_dau?: InputMaybe<Scalars['float8']>;
  weight_downloads?: InputMaybe<Scalars['float8']>;
  weight_employee?: InputMaybe<Scalars['float8']>;
  weight_g2?: InputMaybe<Scalars['numeric']>;
  weight_github?: InputMaybe<Scalars['numeric']>;
  weight_linkedin?: InputMaybe<Scalars['float8']>;
  weight_producthunt?: InputMaybe<Scalars['numeric']>;
  weight_trustpilot?: InputMaybe<Scalars['numeric']>;
  weight_twitter?: InputMaybe<Scalars['float8']>;
  weight_web_visit?: InputMaybe<Scalars['float8']>;
  weights?: InputMaybe<Scalars['jsonb']>;
};

/** order by sum() on columns of table "growth" */
export type Growth_Sum_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "growth" */
export type Growth_Var_Pop_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "growth" */
export type Growth_Var_Samp_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "growth" */
export type Growth_Variance_Order_By = {
  annualized_growth?: InputMaybe<Order_By>;
  annualized_growth_dau?: InputMaybe<Order_By>;
  annualized_growth_downloads?: InputMaybe<Order_By>;
  annualized_growth_employee?: InputMaybe<Order_By>;
  annualized_growth_g2?: InputMaybe<Order_By>;
  annualized_growth_github?: InputMaybe<Order_By>;
  annualized_growth_linkedin?: InputMaybe<Order_By>;
  annualized_growth_percentile?: InputMaybe<Order_By>;
  annualized_growth_producthunt?: InputMaybe<Order_By>;
  annualized_growth_trustpilot?: InputMaybe<Order_By>;
  annualized_growth_twitter?: InputMaybe<Order_By>;
  annualized_growth_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_growth_us_web_visits?: InputMaybe<Order_By>;
  quarterly_growth_us_web_visits?: InputMaybe<Order_By>;
  three_month_growth?: InputMaybe<Order_By>;
  three_month_growth_dau?: InputMaybe<Order_By>;
  three_month_growth_downloads?: InputMaybe<Order_By>;
  three_month_growth_employee?: InputMaybe<Order_By>;
  three_month_growth_g2?: InputMaybe<Order_By>;
  three_month_growth_github?: InputMaybe<Order_By>;
  three_month_growth_linkedin?: InputMaybe<Order_By>;
  three_month_growth_percentile?: InputMaybe<Order_By>;
  three_month_growth_producthunt?: InputMaybe<Order_By>;
  three_month_growth_trustpilot?: InputMaybe<Order_By>;
  three_month_growth_twitter?: InputMaybe<Order_By>;
  three_month_growth_web_visit?: InputMaybe<Order_By>;
  weight_dau?: InputMaybe<Order_By>;
  weight_downloads?: InputMaybe<Order_By>;
  weight_employee?: InputMaybe<Order_By>;
  weight_g2?: InputMaybe<Order_By>;
  weight_github?: InputMaybe<Order_By>;
  weight_linkedin?: InputMaybe<Order_By>;
  weight_producthunt?: InputMaybe<Order_By>;
  weight_trustpilot?: InputMaybe<Order_By>;
  weight_twitter?: InputMaybe<Order_By>;
  weight_web_visit?: InputMaybe<Order_By>;
};

/** columns and relationships of "identity_cards" */
export type Identity_Cards = {
  __typename?: 'identity_cards';
  business_model?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  industries?: Maybe<Scalars['String']>;
  industries_reduced?: Maybe<Scalars['String']>;
  industries_taxonomy?: Maybe<Array<Scalars['String']>>;
  product_features?: Maybe<Scalars['String']>;
  sources?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  target_clients?: Maybe<Scalars['String']>;
  technologies?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "identity_cards" */
export type Identity_CardsSourcesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "identity_cards". All fields are combined with a logical 'AND'. */
export type Identity_Cards_Bool_Exp = {
  _and?: InputMaybe<Array<Identity_Cards_Bool_Exp>>;
  _not?: InputMaybe<Identity_Cards_Bool_Exp>;
  _or?: InputMaybe<Array<Identity_Cards_Bool_Exp>>;
  business_model?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  industries?: InputMaybe<String_Comparison_Exp>;
  industries_reduced?: InputMaybe<String_Comparison_Exp>;
  industries_taxonomy?: InputMaybe<String_Array_Comparison_Exp>;
  product_features?: InputMaybe<String_Comparison_Exp>;
  sources?: InputMaybe<Jsonb_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  target_clients?: InputMaybe<String_Comparison_Exp>;
  technologies?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "identity_cards". */
export type Identity_Cards_Order_By = {
  business_model?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  industries?: InputMaybe<Order_By>;
  industries_reduced?: InputMaybe<Order_By>;
  industries_taxonomy?: InputMaybe<Order_By>;
  product_features?: InputMaybe<Order_By>;
  sources?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  target_clients?: InputMaybe<Order_By>;
  technologies?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "identity_cards" */
export enum Identity_Cards_Select_Column {
  /** column name */
  BusinessModel = 'business_model',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Industries = 'industries',
  /** column name */
  IndustriesReduced = 'industries_reduced',
  /** column name */
  IndustriesTaxonomy = 'industries_taxonomy',
  /** column name */
  ProductFeatures = 'product_features',
  /** column name */
  Sources = 'sources',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  TargetClients = 'target_clients',
  /** column name */
  Technologies = 'technologies',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "identity_cards" */
export type Identity_Cards_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Identity_Cards_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Identity_Cards_Stream_Cursor_Value_Input = {
  business_model?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  industries?: InputMaybe<Scalars['String']>;
  industries_reduced?: InputMaybe<Scalars['String']>;
  industries_taxonomy?: InputMaybe<Array<Scalars['String']>>;
  product_features?: InputMaybe<Scalars['String']>;
  sources?: InputMaybe<Scalars['jsonb']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  target_clients?: InputMaybe<Scalars['String']>;
  technologies?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "investments" */
export type Investments = {
  __typename?: 'investments';
  announcedOn?: Maybe<Scalars['String']>;
  crunchbaseUuid: Scalars['String'];
  founder_id: Scalars['Int'];
  fundingRoundInvestmentType?: Maybe<Scalars['String']>;
  fundingRoundMoneyRaisedValueUsd?: Maybe<Scalars['float8']>;
  id: Scalars['Int'];
  investorStage?: Maybe<Scalars['String']>;
  investorType?: Maybe<Scalars['String']>;
  startup_name: Scalars['String'];
  startup_uuid?: Maybe<Scalars['uuid']>;
};

/** Boolean expression to filter rows from the table "investments". All fields are combined with a logical 'AND'. */
export type Investments_Bool_Exp = {
  _and?: InputMaybe<Array<Investments_Bool_Exp>>;
  _not?: InputMaybe<Investments_Bool_Exp>;
  _or?: InputMaybe<Array<Investments_Bool_Exp>>;
  announcedOn?: InputMaybe<String_Comparison_Exp>;
  crunchbaseUuid?: InputMaybe<String_Comparison_Exp>;
  founder_id?: InputMaybe<Int_Comparison_Exp>;
  fundingRoundInvestmentType?: InputMaybe<String_Comparison_Exp>;
  fundingRoundMoneyRaisedValueUsd?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  investorStage?: InputMaybe<String_Comparison_Exp>;
  investorType?: InputMaybe<String_Comparison_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "investments". */
export type Investments_Order_By = {
  announcedOn?: InputMaybe<Order_By>;
  crunchbaseUuid?: InputMaybe<Order_By>;
  founder_id?: InputMaybe<Order_By>;
  fundingRoundInvestmentType?: InputMaybe<Order_By>;
  fundingRoundMoneyRaisedValueUsd?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  investorStage?: InputMaybe<Order_By>;
  investorType?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "investments" */
export enum Investments_Select_Column {
  /** column name */
  AnnouncedOn = 'announcedOn',
  /** column name */
  CrunchbaseUuid = 'crunchbaseUuid',
  /** column name */
  FounderId = 'founder_id',
  /** column name */
  FundingRoundInvestmentType = 'fundingRoundInvestmentType',
  /** column name */
  FundingRoundMoneyRaisedValueUsd = 'fundingRoundMoneyRaisedValueUsd',
  /** column name */
  Id = 'id',
  /** column name */
  InvestorStage = 'investorStage',
  /** column name */
  InvestorType = 'investorType',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** Streaming cursor of the table "investments" */
export type Investments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investments_Stream_Cursor_Value_Input = {
  announcedOn?: InputMaybe<Scalars['String']>;
  crunchbaseUuid?: InputMaybe<Scalars['String']>;
  founder_id?: InputMaybe<Scalars['Int']>;
  fundingRoundInvestmentType?: InputMaybe<Scalars['String']>;
  fundingRoundMoneyRaisedValueUsd?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['Int']>;
  investorStage?: InputMaybe<Scalars['String']>;
  investorType?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "investor_startup_relation" */
export type Investor_Startup_Relation = {
  __typename?: 'investor_startup_relation';
  /** An object relationship */
  investor: Investors;
  investor_name: Scalars['String'];
  investor_uuid: Scalars['uuid'];
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "investor_startup_relation" */
export type Investor_Startup_Relation_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Investor_Startup_Relation_Max_Order_By>;
  min?: InputMaybe<Investor_Startup_Relation_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "investor_startup_relation". All fields are combined with a logical 'AND'. */
export type Investor_Startup_Relation_Bool_Exp = {
  _and?: InputMaybe<Array<Investor_Startup_Relation_Bool_Exp>>;
  _not?: InputMaybe<Investor_Startup_Relation_Bool_Exp>;
  _or?: InputMaybe<Array<Investor_Startup_Relation_Bool_Exp>>;
  investor?: InputMaybe<Investors_Bool_Exp>;
  investor_name?: InputMaybe<String_Comparison_Exp>;
  investor_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "investor_startup_relation" */
export type Investor_Startup_Relation_Max_Order_By = {
  investor_name?: InputMaybe<Order_By>;
  investor_uuid?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "investor_startup_relation" */
export type Investor_Startup_Relation_Min_Order_By = {
  investor_name?: InputMaybe<Order_By>;
  investor_uuid?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "investor_startup_relation". */
export type Investor_Startup_Relation_Order_By = {
  investor?: InputMaybe<Investors_Order_By>;
  investor_name?: InputMaybe<Order_By>;
  investor_uuid?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "investor_startup_relation" */
export enum Investor_Startup_Relation_Select_Column {
  /** column name */
  InvestorName = 'investor_name',
  /** column name */
  InvestorUuid = 'investor_uuid',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** Streaming cursor of the table "investor_startup_relation" */
export type Investor_Startup_Relation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investor_Startup_Relation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investor_Startup_Relation_Stream_Cursor_Value_Input = {
  investor_name?: InputMaybe<Scalars['String']>;
  investor_uuid?: InputMaybe<Scalars['uuid']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "investors" */
export type Investors = {
  __typename?: 'investors';
  average_time_btw_funding_rounds?: Maybe<Scalars['Int']>;
  crm_link?: Maybe<Scalars['String']>;
  crunchbase_link?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  founder_identifiers?: Maybe<Scalars['String']>;
  fundOrAngel?: Maybe<Scalars['String']>;
  inBubble?: Maybe<Scalars['Boolean']>;
  investor_city?: Maybe<Scalars['String']>;
  investor_continent?: Maybe<Scalars['String']>;
  investor_country?: Maybe<Scalars['String']>;
  investor_from_whom_we_want_participations?: Maybe<Scalars['Boolean']>;
  investor_id: Scalars['String'];
  investor_name: Scalars['String'];
  investor_region?: Maybe<Scalars['String']>;
  investor_stage?: Maybe<Scalars['String']>;
  /** An array relationship */
  investor_startup_relation: Array<Investor_Startup_Relation>;
  investor_type?: Maybe<Scalars['String']>;
  is_american_investor?: Maybe<Scalars['Boolean']>;
  is_impact_fund?: Maybe<Scalars['Boolean']>;
  is_top_investor?: Maybe<Scalars['Boolean']>;
  is_us_related_investor?: Maybe<Scalars['Boolean']>;
  linkedin_link?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  num_exits?: Maybe<Scalars['Int']>;
  num_investments?: Maybe<Scalars['Int']>;
  performance_score?: Maybe<Scalars['Int']>;
  us_employee_rate?: Maybe<Scalars['float8']>;
  uuid: Scalars['uuid'];
  website_url?: Maybe<Scalars['String']>;
};

/** columns and relationships of "investors" */
export type InvestorsInvestor_Startup_RelationArgs = {
  distinct_on?: InputMaybe<Array<Investor_Startup_Relation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Startup_Relation_Order_By>>;
  where?: InputMaybe<Investor_Startup_Relation_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "investors". All fields are combined with a logical 'AND'. */
export type Investors_Bool_Exp = {
  _and?: InputMaybe<Array<Investors_Bool_Exp>>;
  _not?: InputMaybe<Investors_Bool_Exp>;
  _or?: InputMaybe<Array<Investors_Bool_Exp>>;
  average_time_btw_funding_rounds?: InputMaybe<Int_Comparison_Exp>;
  crm_link?: InputMaybe<String_Comparison_Exp>;
  crunchbase_link?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  founder_identifiers?: InputMaybe<String_Comparison_Exp>;
  fundOrAngel?: InputMaybe<String_Comparison_Exp>;
  inBubble?: InputMaybe<Boolean_Comparison_Exp>;
  investor_city?: InputMaybe<String_Comparison_Exp>;
  investor_continent?: InputMaybe<String_Comparison_Exp>;
  investor_country?: InputMaybe<String_Comparison_Exp>;
  investor_from_whom_we_want_participations?: InputMaybe<Boolean_Comparison_Exp>;
  investor_id?: InputMaybe<String_Comparison_Exp>;
  investor_name?: InputMaybe<String_Comparison_Exp>;
  investor_region?: InputMaybe<String_Comparison_Exp>;
  investor_stage?: InputMaybe<String_Comparison_Exp>;
  investor_startup_relation?: InputMaybe<Investor_Startup_Relation_Bool_Exp>;
  investor_type?: InputMaybe<String_Comparison_Exp>;
  is_american_investor?: InputMaybe<Boolean_Comparison_Exp>;
  is_impact_fund?: InputMaybe<Boolean_Comparison_Exp>;
  is_top_investor?: InputMaybe<Boolean_Comparison_Exp>;
  is_us_related_investor?: InputMaybe<Boolean_Comparison_Exp>;
  linkedin_link?: InputMaybe<String_Comparison_Exp>;
  logo_url?: InputMaybe<String_Comparison_Exp>;
  num_exits?: InputMaybe<Int_Comparison_Exp>;
  num_investments?: InputMaybe<Int_Comparison_Exp>;
  performance_score?: InputMaybe<Int_Comparison_Exp>;
  us_employee_rate?: InputMaybe<Float8_Comparison_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
  website_url?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "investors". */
export type Investors_Order_By = {
  average_time_btw_funding_rounds?: InputMaybe<Order_By>;
  crm_link?: InputMaybe<Order_By>;
  crunchbase_link?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  founder_identifiers?: InputMaybe<Order_By>;
  fundOrAngel?: InputMaybe<Order_By>;
  inBubble?: InputMaybe<Order_By>;
  investor_city?: InputMaybe<Order_By>;
  investor_continent?: InputMaybe<Order_By>;
  investor_country?: InputMaybe<Order_By>;
  investor_from_whom_we_want_participations?: InputMaybe<Order_By>;
  investor_id?: InputMaybe<Order_By>;
  investor_name?: InputMaybe<Order_By>;
  investor_region?: InputMaybe<Order_By>;
  investor_stage?: InputMaybe<Order_By>;
  investor_startup_relation_aggregate?: InputMaybe<Investor_Startup_Relation_Aggregate_Order_By>;
  investor_type?: InputMaybe<Order_By>;
  is_american_investor?: InputMaybe<Order_By>;
  is_impact_fund?: InputMaybe<Order_By>;
  is_top_investor?: InputMaybe<Order_By>;
  is_us_related_investor?: InputMaybe<Order_By>;
  linkedin_link?: InputMaybe<Order_By>;
  logo_url?: InputMaybe<Order_By>;
  num_exits?: InputMaybe<Order_By>;
  num_investments?: InputMaybe<Order_By>;
  performance_score?: InputMaybe<Order_By>;
  us_employee_rate?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
  website_url?: InputMaybe<Order_By>;
};

/** select columns of table "investors" */
export enum Investors_Select_Column {
  /** column name */
  AverageTimeBtwFundingRounds = 'average_time_btw_funding_rounds',
  /** column name */
  CrmLink = 'crm_link',
  /** column name */
  CrunchbaseLink = 'crunchbase_link',
  /** column name */
  Description = 'description',
  /** column name */
  FounderIdentifiers = 'founder_identifiers',
  /** column name */
  FundOrAngel = 'fundOrAngel',
  /** column name */
  InBubble = 'inBubble',
  /** column name */
  InvestorCity = 'investor_city',
  /** column name */
  InvestorContinent = 'investor_continent',
  /** column name */
  InvestorCountry = 'investor_country',
  /** column name */
  InvestorFromWhomWeWantParticipations = 'investor_from_whom_we_want_participations',
  /** column name */
  InvestorId = 'investor_id',
  /** column name */
  InvestorName = 'investor_name',
  /** column name */
  InvestorRegion = 'investor_region',
  /** column name */
  InvestorStage = 'investor_stage',
  /** column name */
  InvestorType = 'investor_type',
  /** column name */
  IsAmericanInvestor = 'is_american_investor',
  /** column name */
  IsImpactFund = 'is_impact_fund',
  /** column name */
  IsTopInvestor = 'is_top_investor',
  /** column name */
  IsUsRelatedInvestor = 'is_us_related_investor',
  /** column name */
  LinkedinLink = 'linkedin_link',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  NumExits = 'num_exits',
  /** column name */
  NumInvestments = 'num_investments',
  /** column name */
  PerformanceScore = 'performance_score',
  /** column name */
  UsEmployeeRate = 'us_employee_rate',
  /** column name */
  Uuid = 'uuid',
  /** column name */
  WebsiteUrl = 'website_url',
}

/** Streaming cursor of the table "investors" */
export type Investors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Investors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Investors_Stream_Cursor_Value_Input = {
  average_time_btw_funding_rounds?: InputMaybe<Scalars['Int']>;
  crm_link?: InputMaybe<Scalars['String']>;
  crunchbase_link?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  founder_identifiers?: InputMaybe<Scalars['String']>;
  fundOrAngel?: InputMaybe<Scalars['String']>;
  inBubble?: InputMaybe<Scalars['Boolean']>;
  investor_city?: InputMaybe<Scalars['String']>;
  investor_continent?: InputMaybe<Scalars['String']>;
  investor_country?: InputMaybe<Scalars['String']>;
  investor_from_whom_we_want_participations?: InputMaybe<Scalars['Boolean']>;
  investor_id?: InputMaybe<Scalars['String']>;
  investor_name?: InputMaybe<Scalars['String']>;
  investor_region?: InputMaybe<Scalars['String']>;
  investor_stage?: InputMaybe<Scalars['String']>;
  investor_type?: InputMaybe<Scalars['String']>;
  is_american_investor?: InputMaybe<Scalars['Boolean']>;
  is_impact_fund?: InputMaybe<Scalars['Boolean']>;
  is_top_investor?: InputMaybe<Scalars['Boolean']>;
  is_us_related_investor?: InputMaybe<Scalars['Boolean']>;
  linkedin_link?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  num_exits?: InputMaybe<Scalars['Int']>;
  num_investments?: InputMaybe<Scalars['Int']>;
  performance_score?: InputMaybe<Scalars['Int']>;
  us_employee_rate?: InputMaybe<Scalars['float8']>;
  uuid?: InputMaybe<Scalars['uuid']>;
  website_url?: InputMaybe<Scalars['String']>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups = {
  __typename?: 'last_search_relation_users_startups';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  startup: Startups;
  startup_uuid: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['String'];
};

/** order by aggregate values of table "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Last_Search_Relation_Users_Startups_Max_Order_By>;
  min?: InputMaybe<Last_Search_Relation_Users_Startups_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "last_search_relation_users_startups". All fields are combined with a logical 'AND'. */
export type Last_Search_Relation_Users_Startups_Bool_Exp = {
  _and?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Bool_Exp>>;
  _not?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
  _or?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "last_search_relation_users_startups" */
export enum Last_Search_Relation_Users_Startups_Constraint {
  /** unique or primary key constraint on columns "id" */
  LastSearchRelationUsersStartupsPkey = 'last_search_relation_users_startups_pkey',
  /** unique or primary key constraint on columns "startup_uuid", "user_id" */
  LastSearchRelationUsersStartupsUserIdStartupUuidKey = 'last_search_relation_users_startups_user_id_startup_uuid_key',
}

/** input type for inserting data into table "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups_Insert_Input = {
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups_Mutation_Response = {
  __typename?: 'last_search_relation_users_startups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Last_Search_Relation_Users_Startups>;
};

/** on_conflict condition type for table "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups_On_Conflict = {
  constraint: Last_Search_Relation_Users_Startups_Constraint;
  update_columns?: Array<Last_Search_Relation_Users_Startups_Update_Column>;
  where?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
};

/** Ordering options when selecting data from "last_search_relation_users_startups". */
export type Last_Search_Relation_Users_Startups_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: last_search_relation_users_startups */
export type Last_Search_Relation_Users_Startups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "last_search_relation_users_startups" */
export enum Last_Search_Relation_Users_Startups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups_Set_Input = {
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "last_search_relation_users_startups" */
export type Last_Search_Relation_Users_Startups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Last_Search_Relation_Users_Startups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Last_Search_Relation_Users_Startups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "last_search_relation_users_startups" */
export enum Last_Search_Relation_Users_Startups_Update_Column {
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Last_Search_Relation_Users_Startups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Last_Search_Relation_Users_Startups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Last_Search_Relation_Users_Startups_Bool_Exp;
};

/** columns and relationships of "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure = {
  __typename?: 'linkedin_employee_avg_tenure';
  average_tenure?: Maybe<Scalars['float8']>;
  date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  invyo_id?: Maybe<Scalars['Int']>;
  linkedin_url?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Aggregate_Order_By = {
  avg?: InputMaybe<Linkedin_Employee_Avg_Tenure_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Linkedin_Employee_Avg_Tenure_Max_Order_By>;
  min?: InputMaybe<Linkedin_Employee_Avg_Tenure_Min_Order_By>;
  stddev?: InputMaybe<Linkedin_Employee_Avg_Tenure_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Linkedin_Employee_Avg_Tenure_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Linkedin_Employee_Avg_Tenure_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Linkedin_Employee_Avg_Tenure_Sum_Order_By>;
  var_pop?: InputMaybe<Linkedin_Employee_Avg_Tenure_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Linkedin_Employee_Avg_Tenure_Var_Samp_Order_By>;
  variance?: InputMaybe<Linkedin_Employee_Avg_Tenure_Variance_Order_By>;
};

/** order by avg() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Avg_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "linkedin_employee_avg_tenure". All fields are combined with a logical 'AND'. */
export type Linkedin_Employee_Avg_Tenure_Bool_Exp = {
  _and?: InputMaybe<Array<Linkedin_Employee_Avg_Tenure_Bool_Exp>>;
  _not?: InputMaybe<Linkedin_Employee_Avg_Tenure_Bool_Exp>;
  _or?: InputMaybe<Array<Linkedin_Employee_Avg_Tenure_Bool_Exp>>;
  average_tenure?: InputMaybe<Float8_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invyo_id?: InputMaybe<Int_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Max_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Min_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "linkedin_employee_avg_tenure". */
export type Linkedin_Employee_Avg_Tenure_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "linkedin_employee_avg_tenure" */
export enum Linkedin_Employee_Avg_Tenure_Select_Column {
  /** column name */
  AverageTenure = 'average_tenure',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InvyoId = 'invyo_id',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Stddev_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Stddev_Pop_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Stddev_Samp_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linkedin_Employee_Avg_Tenure_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linkedin_Employee_Avg_Tenure_Stream_Cursor_Value_Input = {
  average_tenure?: InputMaybe<Scalars['float8']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  invyo_id?: InputMaybe<Scalars['Int']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Sum_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Var_Pop_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Var_Samp_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "linkedin_employee_avg_tenure" */
export type Linkedin_Employee_Avg_Tenure_Variance_Order_By = {
  average_tenure?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "linkedin_employees" */
export type Linkedin_Employees = {
  __typename?: 'linkedin_employees';
  date?: Maybe<Scalars['date']>;
  employee_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  industry?: Maybe<Scalars['String']>;
  invyo_id?: Maybe<Scalars['bigint']>;
  is_influencer?: Maybe<Scalars['Int']>;
  is_job_seaker?: Maybe<Scalars['Int']>;
  link?: Maybe<Scalars['String']>;
  linkedin_universal_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  occupation?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "linkedin_employees" */
export type Linkedin_Employees_Aggregate_Order_By = {
  avg?: InputMaybe<Linkedin_Employees_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Linkedin_Employees_Max_Order_By>;
  min?: InputMaybe<Linkedin_Employees_Min_Order_By>;
  stddev?: InputMaybe<Linkedin_Employees_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Linkedin_Employees_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Linkedin_Employees_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Linkedin_Employees_Sum_Order_By>;
  var_pop?: InputMaybe<Linkedin_Employees_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Linkedin_Employees_Var_Samp_Order_By>;
  variance?: InputMaybe<Linkedin_Employees_Variance_Order_By>;
};

/** order by avg() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Avg_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "linkedin_employees". All fields are combined with a logical 'AND'. */
export type Linkedin_Employees_Bool_Exp = {
  _and?: InputMaybe<Array<Linkedin_Employees_Bool_Exp>>;
  _not?: InputMaybe<Linkedin_Employees_Bool_Exp>;
  _or?: InputMaybe<Array<Linkedin_Employees_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  employee_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  industry?: InputMaybe<String_Comparison_Exp>;
  invyo_id?: InputMaybe<Bigint_Comparison_Exp>;
  is_influencer?: InputMaybe<Int_Comparison_Exp>;
  is_job_seaker?: InputMaybe<Int_Comparison_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  linkedin_universal_name?: InputMaybe<String_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  occupation?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  occupation?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  occupation?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "linkedin_employees". */
export type Linkedin_Employees_Order_By = {
  date?: InputMaybe<Order_By>;
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  industry?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
  link?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  occupation?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "linkedin_employees" */
export enum Linkedin_Employees_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  EmployeeId = 'employee_id',
  /** column name */
  Id = 'id',
  /** column name */
  Industry = 'industry',
  /** column name */
  InvyoId = 'invyo_id',
  /** column name */
  IsInfluencer = 'is_influencer',
  /** column name */
  IsJobSeaker = 'is_job_seaker',
  /** column name */
  Link = 'link',
  /** column name */
  LinkedinUniversalName = 'linkedin_universal_name',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Occupation = 'occupation',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Stddev_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Stddev_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Stddev_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "linkedin_employees" */
export type Linkedin_Employees_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linkedin_Employees_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linkedin_Employees_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  employee_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  industry?: InputMaybe<Scalars['String']>;
  invyo_id?: InputMaybe<Scalars['bigint']>;
  is_influencer?: InputMaybe<Scalars['Int']>;
  is_job_seaker?: InputMaybe<Scalars['Int']>;
  link?: InputMaybe<Scalars['String']>;
  linkedin_universal_name?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Sum_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Var_Pop_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Var_Samp_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "linkedin_employees" */
export type Linkedin_Employees_Variance_Order_By = {
  employee_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_influencer?: InputMaybe<Order_By>;
  is_job_seaker?: InputMaybe<Order_By>;
};

/** columns and relationships of "linkedin_followers" */
export type Linkedin_Followers = {
  __typename?: 'linkedin_followers';
  date: Scalars['date'];
  id: Scalars['Int'];
  obs?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "linkedin_followers" */
export type Linkedin_Followers_Aggregate_Order_By = {
  avg?: InputMaybe<Linkedin_Followers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Linkedin_Followers_Max_Order_By>;
  min?: InputMaybe<Linkedin_Followers_Min_Order_By>;
  stddev?: InputMaybe<Linkedin_Followers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Linkedin_Followers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Linkedin_Followers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Linkedin_Followers_Sum_Order_By>;
  var_pop?: InputMaybe<Linkedin_Followers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Linkedin_Followers_Var_Samp_Order_By>;
  variance?: InputMaybe<Linkedin_Followers_Variance_Order_By>;
};

/** order by avg() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "linkedin_followers". All fields are combined with a logical 'AND'. */
export type Linkedin_Followers_Bool_Exp = {
  _and?: InputMaybe<Array<Linkedin_Followers_Bool_Exp>>;
  _not?: InputMaybe<Linkedin_Followers_Bool_Exp>;
  _or?: InputMaybe<Array<Linkedin_Followers_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  obs?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "linkedin_followers". */
export type Linkedin_Followers_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "linkedin_followers" */
export enum Linkedin_Followers_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Obs = 'obs',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "linkedin_followers" */
export type Linkedin_Followers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linkedin_Followers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linkedin_Followers_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  obs?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "linkedin_followers" */
export type Linkedin_Followers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** columns and relationships of "linkedin_headquarters" */
export type Linkedin_Headquarters = {
  __typename?: 'linkedin_headquarters';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  headquarter_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  invyo_id?: Maybe<Scalars['Int']>;
  latitude?: Maybe<Scalars['float8']>;
  linkedin_universal_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Aggregate_Order_By = {
  avg?: InputMaybe<Linkedin_Headquarters_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Linkedin_Headquarters_Max_Order_By>;
  min?: InputMaybe<Linkedin_Headquarters_Min_Order_By>;
  stddev?: InputMaybe<Linkedin_Headquarters_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Linkedin_Headquarters_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Linkedin_Headquarters_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Linkedin_Headquarters_Sum_Order_By>;
  var_pop?: InputMaybe<Linkedin_Headquarters_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Linkedin_Headquarters_Var_Samp_Order_By>;
  variance?: InputMaybe<Linkedin_Headquarters_Variance_Order_By>;
};

/** order by avg() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Avg_Order_By = {
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "linkedin_headquarters". All fields are combined with a logical 'AND'. */
export type Linkedin_Headquarters_Bool_Exp = {
  _and?: InputMaybe<Array<Linkedin_Headquarters_Bool_Exp>>;
  _not?: InputMaybe<Linkedin_Headquarters_Bool_Exp>;
  _or?: InputMaybe<Array<Linkedin_Headquarters_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  headquarter_id?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invyo_id?: InputMaybe<Int_Comparison_Exp>;
  latitude?: InputMaybe<Float8_Comparison_Exp>;
  linkedin_universal_name?: InputMaybe<String_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  longitude?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "linkedin_headquarters". */
export type Linkedin_Headquarters_Order_By = {
  address?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "linkedin_headquarters" */
export enum Linkedin_Headquarters_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  Date = 'date',
  /** column name */
  HeadquarterId = 'headquarter_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvyoId = 'invyo_id',
  /** column name */
  Latitude = 'latitude',
  /** column name */
  LinkedinUniversalName = 'linkedin_universal_name',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Longitude = 'longitude',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Stddev_Order_By = {
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Stddev_Pop_Order_By = {
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Stddev_Samp_Order_By = {
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "linkedin_headquarters" */
export type Linkedin_Headquarters_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linkedin_Headquarters_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linkedin_Headquarters_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  headquarter_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invyo_id?: InputMaybe<Scalars['Int']>;
  latitude?: InputMaybe<Scalars['float8']>;
  linkedin_universal_name?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Sum_Order_By = {
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Var_Pop_Order_By = {
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Var_Samp_Order_By = {
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "linkedin_headquarters" */
export type Linkedin_Headquarters_Variance_Order_By = {
  headquarter_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  latitude?: InputMaybe<Order_By>;
  longitude?: InputMaybe<Order_By>;
};

/** columns and relationships of "linkedin_publications" */
export type Linkedin_Publications = {
  __typename?: 'linkedin_publications';
  comment_total?: Maybe<Scalars['Int']>;
  content?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  date_of_publication?: Maybe<Scalars['date']>;
  empathy_count?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  invyo_id?: Maybe<Scalars['bigint']>;
  is_reshared?: Maybe<Scalars['Int']>;
  is_sponsored?: Maybe<Scalars['Int']>;
  language?: Maybe<Scalars['String']>;
  like_count?: Maybe<Scalars['Int']>;
  linkedin_universal_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  praise_count?: Maybe<Scalars['Int']>;
  publication_id?: Maybe<Scalars['Int']>;
  reaction_total?: Maybe<Scalars['Int']>;
  share_total?: Maybe<Scalars['Int']>;
  /** An object relationship */
  startup: Startups;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
  url?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "linkedin_publications" */
export type Linkedin_Publications_Aggregate_Order_By = {
  avg?: InputMaybe<Linkedin_Publications_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Linkedin_Publications_Max_Order_By>;
  min?: InputMaybe<Linkedin_Publications_Min_Order_By>;
  stddev?: InputMaybe<Linkedin_Publications_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Linkedin_Publications_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Linkedin_Publications_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Linkedin_Publications_Sum_Order_By>;
  var_pop?: InputMaybe<Linkedin_Publications_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Linkedin_Publications_Var_Samp_Order_By>;
  variance?: InputMaybe<Linkedin_Publications_Variance_Order_By>;
};

/** order by avg() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Avg_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "linkedin_publications". All fields are combined with a logical 'AND'. */
export type Linkedin_Publications_Bool_Exp = {
  _and?: InputMaybe<Array<Linkedin_Publications_Bool_Exp>>;
  _not?: InputMaybe<Linkedin_Publications_Bool_Exp>;
  _or?: InputMaybe<Array<Linkedin_Publications_Bool_Exp>>;
  comment_total?: InputMaybe<Int_Comparison_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  date_of_publication?: InputMaybe<Date_Comparison_Exp>;
  empathy_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invyo_id?: InputMaybe<Bigint_Comparison_Exp>;
  is_reshared?: InputMaybe<Int_Comparison_Exp>;
  is_sponsored?: InputMaybe<Int_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  like_count?: InputMaybe<Int_Comparison_Exp>;
  linkedin_universal_name?: InputMaybe<String_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  praise_count?: InputMaybe<Int_Comparison_Exp>;
  publication_id?: InputMaybe<Int_Comparison_Exp>;
  reaction_total?: InputMaybe<Int_Comparison_Exp>;
  share_total?: InputMaybe<Int_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Max_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  date_of_publication?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Min_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  date_of_publication?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "linkedin_publications". */
export type Linkedin_Publications_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  date_of_publication?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "linkedin_publications" */
export enum Linkedin_Publications_Select_Column {
  /** column name */
  CommentTotal = 'comment_total',
  /** column name */
  Content = 'content',
  /** column name */
  Date = 'date',
  /** column name */
  DateOfPublication = 'date_of_publication',
  /** column name */
  EmpathyCount = 'empathy_count',
  /** column name */
  Id = 'id',
  /** column name */
  InvyoId = 'invyo_id',
  /** column name */
  IsReshared = 'is_reshared',
  /** column name */
  IsSponsored = 'is_sponsored',
  /** column name */
  Language = 'language',
  /** column name */
  LikeCount = 'like_count',
  /** column name */
  LinkedinUniversalName = 'linkedin_universal_name',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  PraiseCount = 'praise_count',
  /** column name */
  PublicationId = 'publication_id',
  /** column name */
  ReactionTotal = 'reaction_total',
  /** column name */
  ShareTotal = 'share_total',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  Url = 'url',
}

/** order by stddev() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Stddev_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Stddev_Pop_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Stddev_Samp_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "linkedin_publications" */
export type Linkedin_Publications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linkedin_Publications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linkedin_Publications_Stream_Cursor_Value_Input = {
  comment_total?: InputMaybe<Scalars['Int']>;
  content?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  date_of_publication?: InputMaybe<Scalars['date']>;
  empathy_count?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invyo_id?: InputMaybe<Scalars['bigint']>;
  is_reshared?: InputMaybe<Scalars['Int']>;
  is_sponsored?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  like_count?: InputMaybe<Scalars['Int']>;
  linkedin_universal_name?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  praise_count?: InputMaybe<Scalars['Int']>;
  publication_id?: InputMaybe<Scalars['Int']>;
  reaction_total?: InputMaybe<Scalars['Int']>;
  share_total?: InputMaybe<Scalars['Int']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  url?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Sum_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Var_Pop_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Var_Samp_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "linkedin_publications" */
export type Linkedin_Publications_Variance_Order_By = {
  comment_total?: InputMaybe<Order_By>;
  empathy_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  is_reshared?: InputMaybe<Order_By>;
  is_sponsored?: InputMaybe<Order_By>;
  like_count?: InputMaybe<Order_By>;
  praise_count?: InputMaybe<Order_By>;
  publication_id?: InputMaybe<Order_By>;
  reaction_total?: InputMaybe<Order_By>;
  share_total?: InputMaybe<Order_By>;
};

/** columns and relationships of "linkedin_school_repartition" */
export type Linkedin_School_Repartition = {
  __typename?: 'linkedin_school_repartition';
  date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  invyo_id?: Maybe<Scalars['Int']>;
  linkedin_universal_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  obs?: Maybe<Scalars['Int']>;
  school_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Aggregate_Order_By = {
  avg?: InputMaybe<Linkedin_School_Repartition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Linkedin_School_Repartition_Max_Order_By>;
  min?: InputMaybe<Linkedin_School_Repartition_Min_Order_By>;
  stddev?: InputMaybe<Linkedin_School_Repartition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Linkedin_School_Repartition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Linkedin_School_Repartition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Linkedin_School_Repartition_Sum_Order_By>;
  var_pop?: InputMaybe<Linkedin_School_Repartition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Linkedin_School_Repartition_Var_Samp_Order_By>;
  variance?: InputMaybe<Linkedin_School_Repartition_Variance_Order_By>;
};

/** order by avg() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "linkedin_school_repartition". All fields are combined with a logical 'AND'. */
export type Linkedin_School_Repartition_Bool_Exp = {
  _and?: InputMaybe<Array<Linkedin_School_Repartition_Bool_Exp>>;
  _not?: InputMaybe<Linkedin_School_Repartition_Bool_Exp>;
  _or?: InputMaybe<Array<Linkedin_School_Repartition_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invyo_id?: InputMaybe<Int_Comparison_Exp>;
  linkedin_universal_name?: InputMaybe<String_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  obs?: InputMaybe<Int_Comparison_Exp>;
  school_name?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  school_name?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  school_name?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "linkedin_school_repartition". */
export type Linkedin_School_Repartition_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  school_name?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "linkedin_school_repartition" */
export enum Linkedin_School_Repartition_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InvyoId = 'invyo_id',
  /** column name */
  LinkedinUniversalName = 'linkedin_universal_name',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Obs = 'obs',
  /** column name */
  SchoolName = 'school_name',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linkedin_School_Repartition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linkedin_School_Repartition_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  invyo_id?: InputMaybe<Scalars['Int']>;
  linkedin_universal_name?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  obs?: InputMaybe<Scalars['Int']>;
  school_name?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "linkedin_school_repartition" */
export type Linkedin_School_Repartition_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** columns and relationships of "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition = {
  __typename?: 'linkedin_skill_repartition';
  date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  invyo_id?: Maybe<Scalars['Int']>;
  linkedin_universal_name?: Maybe<Scalars['String']>;
  linkedin_url?: Maybe<Scalars['String']>;
  obs?: Maybe<Scalars['Int']>;
  skill_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  startup: Startups;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Aggregate_Order_By = {
  avg?: InputMaybe<Linkedin_Skill_Repartition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Linkedin_Skill_Repartition_Max_Order_By>;
  min?: InputMaybe<Linkedin_Skill_Repartition_Min_Order_By>;
  stddev?: InputMaybe<Linkedin_Skill_Repartition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Linkedin_Skill_Repartition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Linkedin_Skill_Repartition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Linkedin_Skill_Repartition_Sum_Order_By>;
  var_pop?: InputMaybe<Linkedin_Skill_Repartition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Linkedin_Skill_Repartition_Var_Samp_Order_By>;
  variance?: InputMaybe<Linkedin_Skill_Repartition_Variance_Order_By>;
};

/** order by avg() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "linkedin_skill_repartition". All fields are combined with a logical 'AND'. */
export type Linkedin_Skill_Repartition_Bool_Exp = {
  _and?: InputMaybe<Array<Linkedin_Skill_Repartition_Bool_Exp>>;
  _not?: InputMaybe<Linkedin_Skill_Repartition_Bool_Exp>;
  _or?: InputMaybe<Array<Linkedin_Skill_Repartition_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  invyo_id?: InputMaybe<Int_Comparison_Exp>;
  linkedin_universal_name?: InputMaybe<String_Comparison_Exp>;
  linkedin_url?: InputMaybe<String_Comparison_Exp>;
  obs?: InputMaybe<Int_Comparison_Exp>;
  skill_name?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  skill_name?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  skill_name?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "linkedin_skill_repartition". */
export type Linkedin_Skill_Repartition_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  linkedin_universal_name?: InputMaybe<Order_By>;
  linkedin_url?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  skill_name?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "linkedin_skill_repartition" */
export enum Linkedin_Skill_Repartition_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InvyoId = 'invyo_id',
  /** column name */
  LinkedinUniversalName = 'linkedin_universal_name',
  /** column name */
  LinkedinUrl = 'linkedin_url',
  /** column name */
  Obs = 'obs',
  /** column name */
  SkillName = 'skill_name',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Linkedin_Skill_Repartition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Linkedin_Skill_Repartition_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  invyo_id?: InputMaybe<Scalars['Int']>;
  linkedin_universal_name?: InputMaybe<Scalars['String']>;
  linkedin_url?: InputMaybe<Scalars['String']>;
  obs?: InputMaybe<Scalars['Int']>;
  skill_name?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "linkedin_skill_repartition" */
export type Linkedin_Skill_Repartition_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** columns and relationships of "mobile_app" */
export type Mobile_App = {
  __typename?: 'mobile_app';
  app_id: Scalars['String'];
  app_name?: Maybe<Scalars['String']>;
  date: Scalars['date'];
  id: Scalars['Int'];
  inBubble?: Maybe<Scalars['Boolean']>;
  nb_dau?: Maybe<Scalars['Int']>;
  nb_downloads?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** aggregated selection of "mobile_app" */
export type Mobile_App_Aggregate = {
  __typename?: 'mobile_app_aggregate';
  aggregate?: Maybe<Mobile_App_Aggregate_Fields>;
  nodes: Array<Mobile_App>;
};

export type Mobile_App_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Mobile_App_Aggregate_Bool_Exp_Var_Samp>;
};

export type Mobile_App_Aggregate_Bool_Exp_Avg = {
  arguments: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Bool_And = {
  arguments: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Corr = {
  arguments: Mobile_App_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Mobile_App_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Mobile_App_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Mobile_App_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Mobile_App_Aggregate_Bool_Exp_Max = {
  arguments: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Min = {
  arguments: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Sum = {
  arguments: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Mobile_App_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Mobile_App_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "mobile_app" */
export type Mobile_App_Aggregate_Fields = {
  __typename?: 'mobile_app_aggregate_fields';
  avg?: Maybe<Mobile_App_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Mobile_App_Max_Fields>;
  min?: Maybe<Mobile_App_Min_Fields>;
  stddev?: Maybe<Mobile_App_Stddev_Fields>;
  stddev_pop?: Maybe<Mobile_App_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Mobile_App_Stddev_Samp_Fields>;
  sum?: Maybe<Mobile_App_Sum_Fields>;
  var_pop?: Maybe<Mobile_App_Var_Pop_Fields>;
  var_samp?: Maybe<Mobile_App_Var_Samp_Fields>;
  variance?: Maybe<Mobile_App_Variance_Fields>;
};

/** aggregate fields of "mobile_app" */
export type Mobile_App_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Mobile_App_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "mobile_app" */
export type Mobile_App_Aggregate_Order_By = {
  avg?: InputMaybe<Mobile_App_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Mobile_App_Max_Order_By>;
  min?: InputMaybe<Mobile_App_Min_Order_By>;
  stddev?: InputMaybe<Mobile_App_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Mobile_App_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Mobile_App_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Mobile_App_Sum_Order_By>;
  var_pop?: InputMaybe<Mobile_App_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Mobile_App_Var_Samp_Order_By>;
  variance?: InputMaybe<Mobile_App_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Mobile_App_Avg_Fields = {
  __typename?: 'mobile_app_avg_fields';
  id?: Maybe<Scalars['Float']>;
  nb_dau?: Maybe<Scalars['Float']>;
  nb_downloads?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "mobile_app" */
export type Mobile_App_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "mobile_app". All fields are combined with a logical 'AND'. */
export type Mobile_App_Bool_Exp = {
  _and?: InputMaybe<Array<Mobile_App_Bool_Exp>>;
  _not?: InputMaybe<Mobile_App_Bool_Exp>;
  _or?: InputMaybe<Array<Mobile_App_Bool_Exp>>;
  app_id?: InputMaybe<String_Comparison_Exp>;
  app_name?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inBubble?: InputMaybe<Boolean_Comparison_Exp>;
  nb_dau?: InputMaybe<Int_Comparison_Exp>;
  nb_downloads?: InputMaybe<Int_Comparison_Exp>;
  rating?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Mobile_App_Max_Fields = {
  __typename?: 'mobile_app_max_fields';
  app_id?: Maybe<Scalars['String']>;
  app_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  nb_dau?: Maybe<Scalars['Int']>;
  nb_downloads?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['float8']>;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "mobile_app" */
export type Mobile_App_Max_Order_By = {
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Mobile_App_Min_Fields = {
  __typename?: 'mobile_app_min_fields';
  app_id?: Maybe<Scalars['String']>;
  app_name?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  nb_dau?: Maybe<Scalars['Int']>;
  nb_downloads?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['float8']>;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "mobile_app" */
export type Mobile_App_Min_Order_By = {
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "mobile_app". */
export type Mobile_App_Order_By = {
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBubble?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "mobile_app" */
export enum Mobile_App_Select_Column {
  /** column name */
  AppId = 'app_id',
  /** column name */
  AppName = 'app_name',
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InBubble = 'inBubble',
  /** column name */
  NbDau = 'nb_dau',
  /** column name */
  NbDownloads = 'nb_downloads',
  /** column name */
  Rating = 'rating',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** select "mobile_app_aggregate_bool_exp_avg_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Rating = 'rating',
}

/** select "mobile_app_aggregate_bool_exp_bool_and_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  InBubble = 'inBubble',
}

/** select "mobile_app_aggregate_bool_exp_bool_or_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  InBubble = 'inBubble',
}

/** select "mobile_app_aggregate_bool_exp_corr_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Rating = 'rating',
}

/** select "mobile_app_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Rating = 'rating',
}

/** select "mobile_app_aggregate_bool_exp_max_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Rating = 'rating',
}

/** select "mobile_app_aggregate_bool_exp_min_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Rating = 'rating',
}

/** select "mobile_app_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Rating = 'rating',
}

/** select "mobile_app_aggregate_bool_exp_sum_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Rating = 'rating',
}

/** select "mobile_app_aggregate_bool_exp_var_samp_arguments_columns" columns of table "mobile_app" */
export enum Mobile_App_Select_Column_Mobile_App_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Rating = 'rating',
}

/** aggregate stddev on columns */
export type Mobile_App_Stddev_Fields = {
  __typename?: 'mobile_app_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  nb_dau?: Maybe<Scalars['Float']>;
  nb_downloads?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "mobile_app" */
export type Mobile_App_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Mobile_App_Stddev_Pop_Fields = {
  __typename?: 'mobile_app_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nb_dau?: Maybe<Scalars['Float']>;
  nb_downloads?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "mobile_app" */
export type Mobile_App_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Mobile_App_Stddev_Samp_Fields = {
  __typename?: 'mobile_app_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nb_dau?: Maybe<Scalars['Float']>;
  nb_downloads?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "mobile_app" */
export type Mobile_App_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "mobile_app" */
export type Mobile_App_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Mobile_App_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Mobile_App_Stream_Cursor_Value_Input = {
  app_id?: InputMaybe<Scalars['String']>;
  app_name?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  inBubble?: InputMaybe<Scalars['Boolean']>;
  nb_dau?: InputMaybe<Scalars['Int']>;
  nb_downloads?: InputMaybe<Scalars['Int']>;
  rating?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Mobile_App_Sum_Fields = {
  __typename?: 'mobile_app_sum_fields';
  id?: Maybe<Scalars['Int']>;
  nb_dau?: Maybe<Scalars['Int']>;
  nb_downloads?: Maybe<Scalars['Int']>;
  rating?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "mobile_app" */
export type Mobile_App_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Mobile_App_Var_Pop_Fields = {
  __typename?: 'mobile_app_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  nb_dau?: Maybe<Scalars['Float']>;
  nb_downloads?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "mobile_app" */
export type Mobile_App_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Mobile_App_Var_Samp_Fields = {
  __typename?: 'mobile_app_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  nb_dau?: Maybe<Scalars['Float']>;
  nb_downloads?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "mobile_app" */
export type Mobile_App_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Mobile_App_Variance_Fields = {
  __typename?: 'mobile_app_variance_fields';
  id?: Maybe<Scalars['Float']>;
  nb_dau?: Maybe<Scalars['Float']>;
  nb_downloads?: Maybe<Scalars['Float']>;
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "mobile_app" */
export type Mobile_App_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  nb_dau?: InputMaybe<Order_By>;
  nb_downloads?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "follow_relation_users_startups" */
  delete_follow_relation_users_startups?: Maybe<Follow_Relation_Users_Startups_Mutation_Response>;
  /** delete single row from the table: "follow_relation_users_startups" */
  delete_follow_relation_users_startups_by_pk?: Maybe<Follow_Relation_Users_Startups>;
  /** insert data into the table: "follow_relation_users_startups" */
  insert_follow_relation_users_startups?: Maybe<Follow_Relation_Users_Startups_Mutation_Response>;
  /** insert a single row into the table: "follow_relation_users_startups" */
  insert_follow_relation_users_startups_one?: Maybe<Follow_Relation_Users_Startups>;
  /** insert data into the table: "last_search_relation_users_startups" */
  insert_last_search_relation_users_startups?: Maybe<Last_Search_Relation_Users_Startups_Mutation_Response>;
  /** insert a single row into the table: "last_search_relation_users_startups" */
  insert_last_search_relation_users_startups_one?: Maybe<Last_Search_Relation_Users_Startups>;
  /** update data of the table: "competition_classification" */
  update_competition_classification?: Maybe<Competition_Classification_Mutation_Response>;
  /** update single row of the table: "competition_classification" */
  update_competition_classification_by_pk?: Maybe<Competition_Classification>;
  /** update multiples rows of table: "competition_classification" */
  update_competition_classification_many?: Maybe<Array<Maybe<Competition_Classification_Mutation_Response>>>;
  /** update data of the table: "follow_relation_users_startups" */
  update_follow_relation_users_startups?: Maybe<Follow_Relation_Users_Startups_Mutation_Response>;
  /** update single row of the table: "follow_relation_users_startups" */
  update_follow_relation_users_startups_by_pk?: Maybe<Follow_Relation_Users_Startups>;
  /** update multiples rows of table: "follow_relation_users_startups" */
  update_follow_relation_users_startups_many?: Maybe<Array<Maybe<Follow_Relation_Users_Startups_Mutation_Response>>>;
  /** update data of the table: "last_search_relation_users_startups" */
  update_last_search_relation_users_startups?: Maybe<Last_Search_Relation_Users_Startups_Mutation_Response>;
  /** update single row of the table: "last_search_relation_users_startups" */
  update_last_search_relation_users_startups_by_pk?: Maybe<Last_Search_Relation_Users_Startups>;
  /** update multiples rows of table: "last_search_relation_users_startups" */
  update_last_search_relation_users_startups_many?: Maybe<
    Array<Maybe<Last_Search_Relation_Users_Startups_Mutation_Response>>
  >;
};

/** mutation root */
export type Mutation_RootDelete_Follow_Relation_Users_StartupsArgs = {
  where: Follow_Relation_Users_Startups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Follow_Relation_Users_Startups_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootInsert_Follow_Relation_Users_StartupsArgs = {
  objects: Array<Follow_Relation_Users_Startups_Insert_Input>;
  on_conflict?: InputMaybe<Follow_Relation_Users_Startups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Follow_Relation_Users_Startups_OneArgs = {
  object: Follow_Relation_Users_Startups_Insert_Input;
  on_conflict?: InputMaybe<Follow_Relation_Users_Startups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Last_Search_Relation_Users_StartupsArgs = {
  objects: Array<Last_Search_Relation_Users_Startups_Insert_Input>;
  on_conflict?: InputMaybe<Last_Search_Relation_Users_Startups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Last_Search_Relation_Users_Startups_OneArgs = {
  object: Last_Search_Relation_Users_Startups_Insert_Input;
  on_conflict?: InputMaybe<Last_Search_Relation_Users_Startups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Competition_ClassificationArgs = {
  _set?: InputMaybe<Competition_Classification_Set_Input>;
  where: Competition_Classification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Competition_Classification_By_PkArgs = {
  _set?: InputMaybe<Competition_Classification_Set_Input>;
  pk_columns: Competition_Classification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Competition_Classification_ManyArgs = {
  updates: Array<Competition_Classification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Follow_Relation_Users_StartupsArgs = {
  _set?: InputMaybe<Follow_Relation_Users_Startups_Set_Input>;
  where: Follow_Relation_Users_Startups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Follow_Relation_Users_Startups_By_PkArgs = {
  _set?: InputMaybe<Follow_Relation_Users_Startups_Set_Input>;
  pk_columns: Follow_Relation_Users_Startups_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Follow_Relation_Users_Startups_ManyArgs = {
  updates: Array<Follow_Relation_Users_Startups_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Last_Search_Relation_Users_StartupsArgs = {
  _set?: InputMaybe<Last_Search_Relation_Users_Startups_Set_Input>;
  where: Last_Search_Relation_Users_Startups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Last_Search_Relation_Users_Startups_By_PkArgs = {
  _set?: InputMaybe<Last_Search_Relation_Users_Startups_Set_Input>;
  pk_columns: Last_Search_Relation_Users_Startups_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Last_Search_Relation_Users_Startups_ManyArgs = {
  updates: Array<Last_Search_Relation_Users_Startups_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "percentiles" */
export type Percentiles = {
  __typename?: 'percentiles';
  category: Scalars['String'];
  percentile: Scalars['Int'];
  value?: Maybe<Scalars['float8']>;
};

/** Boolean expression to filter rows from the table "percentiles". All fields are combined with a logical 'AND'. */
export type Percentiles_Bool_Exp = {
  _and?: InputMaybe<Array<Percentiles_Bool_Exp>>;
  _not?: InputMaybe<Percentiles_Bool_Exp>;
  _or?: InputMaybe<Array<Percentiles_Bool_Exp>>;
  category?: InputMaybe<String_Comparison_Exp>;
  percentile?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "percentiles". */
export type Percentiles_Order_By = {
  category?: InputMaybe<Order_By>;
  percentile?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "percentiles" */
export enum Percentiles_Select_Column {
  /** column name */
  Category = 'category',
  /** column name */
  Percentile = 'percentile',
  /** column name */
  Value = 'value',
}

/** Streaming cursor of the table "percentiles" */
export type Percentiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Percentiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Percentiles_Stream_Cursor_Value_Input = {
  category?: InputMaybe<Scalars['String']>;
  percentile?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['float8']>;
};

/** Tracking predict leads job openings */
export type Predictleads_Job_Openings = {
  __typename?: 'predictleads_job_openings';
  categories?: Maybe<Array<Scalars['String']>>;
  closed: Scalars['Boolean'];
  contract_types?: Maybe<Array<Scalars['String']>>;
  country_id?: Maybe<Scalars['Int']>;
  country_name?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  first_seen_at: Scalars['timestamptz'];
  full_location?: Maybe<Scalars['String']>;
  full_salary?: Maybe<Scalars['String']>;
  full_seniority?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_seen_at: Scalars['timestamptz'];
  salary_currency?: Maybe<Scalars['String']>;
  salary_high?: Maybe<Scalars['numeric']>;
  salary_high_usd?: Maybe<Scalars['numeric']>;
  salary_low?: Maybe<Scalars['numeric']>;
  salary_low_usd?: Maybe<Scalars['numeric']>;
  salary_time_unit?: Maybe<Scalars['String']>;
  seniority_types?: Maybe<Array<Scalars['String']>>;
  /** An object relationship */
  startup: Startups;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid: Scalars['uuid'];
  tags?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  url: Scalars['String'];
};

/** order by aggregate values of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Aggregate_Order_By = {
  avg?: InputMaybe<Predictleads_Job_Openings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Predictleads_Job_Openings_Max_Order_By>;
  min?: InputMaybe<Predictleads_Job_Openings_Min_Order_By>;
  stddev?: InputMaybe<Predictleads_Job_Openings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Predictleads_Job_Openings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Predictleads_Job_Openings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Predictleads_Job_Openings_Sum_Order_By>;
  var_pop?: InputMaybe<Predictleads_Job_Openings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Predictleads_Job_Openings_Var_Samp_Order_By>;
  variance?: InputMaybe<Predictleads_Job_Openings_Variance_Order_By>;
};

/** order by avg() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Avg_Order_By = {
  country_id?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "predictleads_job_openings". All fields are combined with a logical 'AND'. */
export type Predictleads_Job_Openings_Bool_Exp = {
  _and?: InputMaybe<Array<Predictleads_Job_Openings_Bool_Exp>>;
  _not?: InputMaybe<Predictleads_Job_Openings_Bool_Exp>;
  _or?: InputMaybe<Array<Predictleads_Job_Openings_Bool_Exp>>;
  categories?: InputMaybe<String_Array_Comparison_Exp>;
  closed?: InputMaybe<Boolean_Comparison_Exp>;
  contract_types?: InputMaybe<String_Array_Comparison_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  country_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  first_seen_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  full_location?: InputMaybe<String_Comparison_Exp>;
  full_salary?: InputMaybe<String_Comparison_Exp>;
  full_seniority?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_seen_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  salary_currency?: InputMaybe<String_Comparison_Exp>;
  salary_high?: InputMaybe<Numeric_Comparison_Exp>;
  salary_high_usd?: InputMaybe<Numeric_Comparison_Exp>;
  salary_low?: InputMaybe<Numeric_Comparison_Exp>;
  salary_low_usd?: InputMaybe<Numeric_Comparison_Exp>;
  salary_time_unit?: InputMaybe<String_Comparison_Exp>;
  seniority_types?: InputMaybe<String_Array_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  tags?: InputMaybe<String_Array_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Max_Order_By = {
  categories?: InputMaybe<Order_By>;
  contract_types?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  first_seen_at?: InputMaybe<Order_By>;
  full_location?: InputMaybe<Order_By>;
  full_salary?: InputMaybe<Order_By>;
  full_seniority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen_at?: InputMaybe<Order_By>;
  salary_currency?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
  salary_time_unit?: InputMaybe<Order_By>;
  seniority_types?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Min_Order_By = {
  categories?: InputMaybe<Order_By>;
  contract_types?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  first_seen_at?: InputMaybe<Order_By>;
  full_location?: InputMaybe<Order_By>;
  full_salary?: InputMaybe<Order_By>;
  full_seniority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen_at?: InputMaybe<Order_By>;
  salary_currency?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
  salary_time_unit?: InputMaybe<Order_By>;
  seniority_types?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "predictleads_job_openings". */
export type Predictleads_Job_Openings_Order_By = {
  categories?: InputMaybe<Order_By>;
  closed?: InputMaybe<Order_By>;
  contract_types?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  first_seen_at?: InputMaybe<Order_By>;
  full_location?: InputMaybe<Order_By>;
  full_salary?: InputMaybe<Order_By>;
  full_seniority?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_seen_at?: InputMaybe<Order_By>;
  salary_currency?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
  salary_time_unit?: InputMaybe<Order_By>;
  seniority_types?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** select columns of table "predictleads_job_openings" */
export enum Predictleads_Job_Openings_Select_Column {
  /** column name */
  Categories = 'categories',
  /** column name */
  Closed = 'closed',
  /** column name */
  ContractTypes = 'contract_types',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CountryName = 'country_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FirstSeenAt = 'first_seen_at',
  /** column name */
  FullLocation = 'full_location',
  /** column name */
  FullSalary = 'full_salary',
  /** column name */
  FullSeniority = 'full_seniority',
  /** column name */
  Id = 'id',
  /** column name */
  LastSeenAt = 'last_seen_at',
  /** column name */
  SalaryCurrency = 'salary_currency',
  /** column name */
  SalaryHigh = 'salary_high',
  /** column name */
  SalaryHighUsd = 'salary_high_usd',
  /** column name */
  SalaryLow = 'salary_low',
  /** column name */
  SalaryLowUsd = 'salary_low_usd',
  /** column name */
  SalaryTimeUnit = 'salary_time_unit',
  /** column name */
  SeniorityTypes = 'seniority_types',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  Tags = 'tags',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url',
}

/** order by stddev() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Stddev_Order_By = {
  country_id?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Stddev_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Stddev_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Predictleads_Job_Openings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Predictleads_Job_Openings_Stream_Cursor_Value_Input = {
  categories?: InputMaybe<Array<Scalars['String']>>;
  closed?: InputMaybe<Scalars['Boolean']>;
  contract_types?: InputMaybe<Array<Scalars['String']>>;
  country_id?: InputMaybe<Scalars['Int']>;
  country_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  first_seen_at?: InputMaybe<Scalars['timestamptz']>;
  full_location?: InputMaybe<Scalars['String']>;
  full_salary?: InputMaybe<Scalars['String']>;
  full_seniority?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_seen_at?: InputMaybe<Scalars['timestamptz']>;
  salary_currency?: InputMaybe<Scalars['String']>;
  salary_high?: InputMaybe<Scalars['numeric']>;
  salary_high_usd?: InputMaybe<Scalars['numeric']>;
  salary_low?: InputMaybe<Scalars['numeric']>;
  salary_low_usd?: InputMaybe<Scalars['numeric']>;
  salary_time_unit?: InputMaybe<Scalars['String']>;
  seniority_types?: InputMaybe<Array<Scalars['String']>>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  url?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Sum_Order_By = {
  country_id?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Var_Pop_Order_By = {
  country_id?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Var_Samp_Order_By = {
  country_id?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "predictleads_job_openings" */
export type Predictleads_Job_Openings_Variance_Order_By = {
  country_id?: InputMaybe<Order_By>;
  salary_high?: InputMaybe<Order_By>;
  salary_high_usd?: InputMaybe<Order_By>;
  salary_low?: InputMaybe<Order_By>;
  salary_low_usd?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** An array relationship */
  bcorp_startups: Array<Bcorp_Startups>;
  /** fetch data from the table: "bcorp_startups" using primary key columns */
  bcorp_startups_by_pk?: Maybe<Bcorp_Startups>;
  /** fetch data from the table: "cofounders" */
  cofounders: Array<Cofounders>;
  /** fetch data from the table: "cofounders" using primary key columns */
  cofounders_by_pk?: Maybe<Cofounders>;
  /** fetch data from the table: "competition_classification" */
  competition_classification: Array<Competition_Classification>;
  /** fetch data from the table: "competition_classification" using primary key columns */
  competition_classification_by_pk?: Maybe<Competition_Classification>;
  /** fetch data from the table: "competitors" */
  competitors: Array<Competitors>;
  /** fetch data from the table: "competitors" using primary key columns */
  competitors_by_pk?: Maybe<Competitors>;
  /** fetch data from the table: "country_names" */
  country_names: Array<Country_Names>;
  /** fetch data from the table: "country_names" using primary key columns */
  country_names_by_pk?: Maybe<Country_Names>;
  /** fetch data from the table: "country_of_startups_for_sourcing" */
  country_of_startups_for_sourcing: Array<Country_Of_Startups_For_Sourcing>;
  /** fetch data from the table: "country_of_startups_for_sourcing" using primary key columns */
  country_of_startups_for_sourcing_by_pk?: Maybe<Country_Of_Startups_For_Sourcing>;
  /** fetch data from the table: "country_of_startups_for_specter" */
  country_of_startups_for_specter: Array<Country_Of_Startups_For_Specter>;
  /** fetch data from the table: "country_of_startups_for_specter" using primary key columns */
  country_of_startups_for_specter_by_pk?: Maybe<Country_Of_Startups_For_Specter>;
  /** fetch data from the table: "data_sources_last_update" */
  data_sources_last_update: Array<Data_Sources_Last_Update>;
  /** fetch data from the table: "data_sources_last_update" using primary key columns */
  data_sources_last_update_by_pk?: Maybe<Data_Sources_Last_Update>;
  /** fetch data from the table: "dealflow" */
  dealflow: Array<Dealflow>;
  /** fetch data from the table: "dealflow" using primary key columns */
  dealflow_by_pk?: Maybe<Dealflow>;
  /** fetch data from the table: "deals" */
  deals: Array<Deals>;
  /** fetch data from the table: "deals" using primary key columns */
  deals_by_pk?: Maybe<Deals>;
  /** fetch data from the table: "education" */
  education: Array<Education>;
  /** fetch data from the table: "education" using primary key columns */
  education_by_pk?: Maybe<Education>;
  /** fetch data from the table: "employee_country_repartition" */
  employee_country_repartition: Array<Employee_Country_Repartition>;
  /** fetch data from the table: "employee_country_repartition" using primary key columns */
  employee_country_repartition_by_pk?: Maybe<Employee_Country_Repartition>;
  /** fetch data from the table: "employee_post_repartition" */
  employee_post_repartition: Array<Employee_Post_Repartition>;
  /** fetch data from the table: "employee_post_repartition" using primary key columns */
  employee_post_repartition_by_pk?: Maybe<Employee_Post_Repartition>;
  /** An array relationship */
  employees: Array<Employees>;
  /** fetch data from the table: "employees" using primary key columns */
  employees_by_pk?: Maybe<Employees>;
  /** fetch data from the table: "employees_from_us" */
  employees_from_us: Array<Employees_From_Us>;
  /** fetch data from the table: "employees_from_us" using primary key columns */
  employees_from_us_by_pk?: Maybe<Employees_From_Us>;
  /** An array relationship */
  events_news: Array<Events_News>;
  /** fetch data from the table: "events_news" using primary key columns */
  events_news_by_pk?: Maybe<Events_News>;
  /** An array relationship */
  follow_relation_users_startups: Array<Follow_Relation_Users_Startups>;
  /** fetch data from the table: "follow_relation_users_startups" using primary key columns */
  follow_relation_users_startups_by_pk?: Maybe<Follow_Relation_Users_Startups>;
  /** An array relationship */
  founder_jobs: Array<Founder_Jobs>;
  /** fetch data from the table: "founder_jobs" using primary key columns */
  founder_jobs_by_pk?: Maybe<Founder_Jobs>;
  /** fetch data from the table: "founder_score_weights" */
  founder_score_weights: Array<Founder_Score_Weights>;
  /** fetch data from the table: "founder_score_weights" using primary key columns */
  founder_score_weights_by_pk?: Maybe<Founder_Score_Weights>;
  /** An array relationship */
  founders: Array<Founders>;
  /** fetch data from the table: "founders" using primary key columns */
  founders_by_pk?: Maybe<Founders>;
  /** An array relationship */
  g2_historical_data: Array<G2_Historical_Data>;
  /** fetch data from the table: "g2_historical_data" using primary key columns */
  g2_historical_data_by_pk?: Maybe<G2_Historical_Data>;
  /** fetch data from the table: "g2_industry" */
  g2_industry: Array<G2_Industry>;
  /** fetch data from the table: "g2_industry" using primary key columns */
  g2_industry_by_pk?: Maybe<G2_Industry>;
  /** fetch data from the table: "g2_product_category" */
  g2_product_category: Array<G2_Product_Category>;
  /** fetch data from the table: "g2_product_category" using primary key columns */
  g2_product_category_by_pk?: Maybe<G2_Product_Category>;
  /** An array relationship */
  g2_product_competitors: Array<G2_Product_Competitors>;
  /** fetch data from the table: "g2_product_competitors" using primary key columns */
  g2_product_competitors_by_pk?: Maybe<G2_Product_Competitors>;
  /** An array relationship */
  g2_product_reviews: Array<G2_Product_Reviews>;
  /** fetch data from the table: "g2_product_reviews" using primary key columns */
  g2_product_reviews_by_pk?: Maybe<G2_Product_Reviews>;
  /** An array relationship */
  glassdoor_data: Array<Glassdoor_Data>;
  /** fetch data from the table: "glassdoor_data" using primary key columns */
  glassdoor_data_by_pk?: Maybe<Glassdoor_Data>;
  /** fetch data from the table: "glassdoor_trend" */
  glassdoor_trend: Array<Glassdoor_Trend>;
  /** fetch data from the table: "glassdoor_trend" using primary key columns */
  glassdoor_trend_by_pk?: Maybe<Glassdoor_Trend>;
  /** fetch data from the table: "google_serp" */
  google_serp: Array<Google_Serp>;
  /** fetch data from the table: "google_serp" using primary key columns */
  google_serp_by_pk?: Maybe<Google_Serp>;
  /** fetch data from the table: "growth" */
  growth: Array<Growth>;
  /** fetch data from the table: "growth" using primary key columns */
  growth_by_pk?: Maybe<Growth>;
  /** fetch data from the table: "identity_cards" */
  identity_cards: Array<Identity_Cards>;
  /** fetch data from the table: "identity_cards" using primary key columns */
  identity_cards_by_pk?: Maybe<Identity_Cards>;
  /** fetch data from the table: "investments" */
  investments: Array<Investments>;
  /** fetch data from the table: "investments" using primary key columns */
  investments_by_pk?: Maybe<Investments>;
  /** An array relationship */
  investor_startup_relation: Array<Investor_Startup_Relation>;
  /** fetch data from the table: "investor_startup_relation" using primary key columns */
  investor_startup_relation_by_pk?: Maybe<Investor_Startup_Relation>;
  /** fetch data from the table: "investors" */
  investors: Array<Investors>;
  /** fetch data from the table: "investors" using primary key columns */
  investors_by_pk?: Maybe<Investors>;
  /** An array relationship */
  last_search_relation_users_startups: Array<Last_Search_Relation_Users_Startups>;
  /** fetch data from the table: "last_search_relation_users_startups" using primary key columns */
  last_search_relation_users_startups_by_pk?: Maybe<Last_Search_Relation_Users_Startups>;
  /** fetch data from the table: "linkedin_employee_avg_tenure" */
  linkedin_employee_avg_tenure: Array<Linkedin_Employee_Avg_Tenure>;
  /** fetch data from the table: "linkedin_employee_avg_tenure" using primary key columns */
  linkedin_employee_avg_tenure_by_pk?: Maybe<Linkedin_Employee_Avg_Tenure>;
  /** fetch data from the table: "linkedin_employees" */
  linkedin_employees: Array<Linkedin_Employees>;
  /** fetch data from the table: "linkedin_employees" using primary key columns */
  linkedin_employees_by_pk?: Maybe<Linkedin_Employees>;
  /** fetch data from the table: "linkedin_followers" */
  linkedin_followers: Array<Linkedin_Followers>;
  /** fetch data from the table: "linkedin_followers" using primary key columns */
  linkedin_followers_by_pk?: Maybe<Linkedin_Followers>;
  /** An array relationship */
  linkedin_headquarters: Array<Linkedin_Headquarters>;
  /** fetch data from the table: "linkedin_headquarters" using primary key columns */
  linkedin_headquarters_by_pk?: Maybe<Linkedin_Headquarters>;
  /** An array relationship */
  linkedin_publications: Array<Linkedin_Publications>;
  /** fetch data from the table: "linkedin_publications" using primary key columns */
  linkedin_publications_by_pk?: Maybe<Linkedin_Publications>;
  /** fetch data from the table: "linkedin_school_repartition" */
  linkedin_school_repartition: Array<Linkedin_School_Repartition>;
  /** fetch data from the table: "linkedin_school_repartition" using primary key columns */
  linkedin_school_repartition_by_pk?: Maybe<Linkedin_School_Repartition>;
  /** fetch data from the table: "linkedin_skill_repartition" */
  linkedin_skill_repartition: Array<Linkedin_Skill_Repartition>;
  /** fetch data from the table: "linkedin_skill_repartition" using primary key columns */
  linkedin_skill_repartition_by_pk?: Maybe<Linkedin_Skill_Repartition>;
  /** fetch data from the table: "mobile_app" */
  mobile_app: Array<Mobile_App>;
  /** fetch aggregated fields from the table: "mobile_app" */
  mobile_app_aggregate: Mobile_App_Aggregate;
  /** fetch data from the table: "mobile_app" using primary key columns */
  mobile_app_by_pk?: Maybe<Mobile_App>;
  /** fetch data from the table: "percentiles" */
  percentiles: Array<Percentiles>;
  /** fetch data from the table: "percentiles" using primary key columns */
  percentiles_by_pk?: Maybe<Percentiles>;
  /** An array relationship */
  predictleads_job_openings: Array<Predictleads_Job_Openings>;
  /** fetch data from the table: "predictleads_job_openings" using primary key columns */
  predictleads_job_openings_by_pk?: Maybe<Predictleads_Job_Openings>;
  /** fetch data from the table: "schools" */
  schools: Array<Schools>;
  /** fetch data from the table: "schools" using primary key columns */
  schools_by_pk?: Maybe<Schools>;
  /** An array relationship */
  scores: Array<Scores>;
  /** An aggregate relationship */
  scores_aggregate: Scores_Aggregate;
  /** fetch data from the table: "scores" using primary key columns */
  scores_by_pk?: Maybe<Scores>;
  /** execute function "search_startup_name" which returns "startups" */
  search_startup_name: Array<Startups>;
  /** execute function "search_startup_name" and query aggregates on result of table type "startups" */
  search_startup_name_aggregate: Startups_Aggregate;
  /** execute function "search_startup_name_in_identity_cards" which returns "identity_cards" */
  search_startup_name_in_identity_cards: Array<Identity_Cards>;
  /** An array relationship */
  similar_sites: Array<Similar_Sites>;
  /** fetch data from the table: "similar_sites" using primary key columns */
  similar_sites_by_pk?: Maybe<Similar_Sites>;
  /** fetch data from the table: "sourcing" */
  sourcing: Array<Sourcing>;
  /** fetch data from the table: "sourcing" using primary key columns */
  sourcing_by_pk?: Maybe<Sourcing>;
  /** fetch data from the table: "sourcing_status" */
  sourcing_status: Array<Sourcing_Status>;
  /** fetch data from the table: "sourcing_status" using primary key columns */
  sourcing_status_by_pk?: Maybe<Sourcing_Status>;
  /** fetch data from the table: "startups" */
  startups: Array<Startups>;
  /** fetch aggregated fields from the table: "startups" */
  startups_aggregate: Startups_Aggregate;
  /** fetch data from the table: "startups" using primary key columns */
  startups_by_pk?: Maybe<Startups>;
  /** An array relationship */
  twitter_followers: Array<Twitter_Followers>;
  /** fetch data from the table: "twitter_followers" using primary key columns */
  twitter_followers_by_pk?: Maybe<Twitter_Followers>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "web_visits_country_repartition" */
  web_visits_country_repartition: Array<Web_Visits_Country_Repartition>;
  /** fetch data from the table: "web_visits_country_repartition" using primary key columns */
  web_visits_country_repartition_by_pk?: Maybe<Web_Visits_Country_Repartition>;
  /** An array relationship */
  web_visits_general_data: Array<Web_Visits_General_Data>;
  /** fetch data from the table: "web_visits_general_data" using primary key columns */
  web_visits_general_data_by_pk?: Maybe<Web_Visits_General_Data>;
  /** An array relationship */
  web_visits_historical_data: Array<Web_Visits_Historical_Data>;
  /** An aggregate relationship */
  web_visits_historical_data_aggregate: Web_Visits_Historical_Data_Aggregate;
  /** fetch data from the table: "web_visits_historical_data" using primary key columns */
  web_visits_historical_data_by_pk?: Maybe<Web_Visits_Historical_Data>;
};

export type Query_RootBcorp_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Bcorp_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bcorp_Startups_Order_By>>;
  where?: InputMaybe<Bcorp_Startups_Bool_Exp>;
};

export type Query_RootBcorp_Startups_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCofoundersArgs = {
  distinct_on?: InputMaybe<Array<Cofounders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cofounders_Order_By>>;
  where?: InputMaybe<Cofounders_Bool_Exp>;
};

export type Query_RootCofounders_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCompetition_ClassificationArgs = {
  distinct_on?: InputMaybe<Array<Competition_Classification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Competition_Classification_Order_By>>;
  where?: InputMaybe<Competition_Classification_Bool_Exp>;
};

export type Query_RootCompetition_Classification_By_PkArgs = {
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
};

export type Query_RootCompetitorsArgs = {
  distinct_on?: InputMaybe<Array<Competitors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Competitors_Order_By>>;
  where?: InputMaybe<Competitors_Bool_Exp>;
};

export type Query_RootCompetitors_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootCountry_NamesArgs = {
  distinct_on?: InputMaybe<Array<Country_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Names_Order_By>>;
  where?: InputMaybe<Country_Names_Bool_Exp>;
};

export type Query_RootCountry_Names_By_PkArgs = {
  country_id: Scalars['Int'];
};

export type Query_RootCountry_Of_Startups_For_SourcingArgs = {
  distinct_on?: InputMaybe<Array<Country_Of_Startups_For_Sourcing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Of_Startups_For_Sourcing_Order_By>>;
  where?: InputMaybe<Country_Of_Startups_For_Sourcing_Bool_Exp>;
};

export type Query_RootCountry_Of_Startups_For_Sourcing_By_PkArgs = {
  countryName: Scalars['String'];
};

export type Query_RootCountry_Of_Startups_For_SpecterArgs = {
  distinct_on?: InputMaybe<Array<Country_Of_Startups_For_Specter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Of_Startups_For_Specter_Order_By>>;
  where?: InputMaybe<Country_Of_Startups_For_Specter_Bool_Exp>;
};

export type Query_RootCountry_Of_Startups_For_Specter_By_PkArgs = {
  countryName: Scalars['String'];
};

export type Query_RootData_Sources_Last_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Data_Sources_Last_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Data_Sources_Last_Update_Order_By>>;
  where?: InputMaybe<Data_Sources_Last_Update_Bool_Exp>;
};

export type Query_RootData_Sources_Last_Update_By_PkArgs = {
  startup_uuid: Scalars['uuid'];
};

export type Query_RootDealflowArgs = {
  distinct_on?: InputMaybe<Array<Dealflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dealflow_Order_By>>;
  where?: InputMaybe<Dealflow_Bool_Exp>;
};

export type Query_RootDealflow_By_PkArgs = {
  opportunity: Scalars['String'];
};

export type Query_RootDealsArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

export type Query_RootDeals_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootEducationArgs = {
  distinct_on?: InputMaybe<Array<Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Education_Order_By>>;
  where?: InputMaybe<Education_Bool_Exp>;
};

export type Query_RootEducation_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEmployee_Country_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Employee_Country_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Country_Repartition_Order_By>>;
  where?: InputMaybe<Employee_Country_Repartition_Bool_Exp>;
};

export type Query_RootEmployee_Country_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEmployee_Post_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Employee_Post_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Post_Repartition_Order_By>>;
  where?: InputMaybe<Employee_Post_Repartition_Bool_Exp>;
};

export type Query_RootEmployee_Post_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEmployeesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};

export type Query_RootEmployees_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEmployees_From_UsArgs = {
  distinct_on?: InputMaybe<Array<Employees_From_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_From_Us_Order_By>>;
  where?: InputMaybe<Employees_From_Us_Bool_Exp>;
};

export type Query_RootEmployees_From_Us_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootEvents_NewsArgs = {
  distinct_on?: InputMaybe<Array<Events_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_News_Order_By>>;
  where?: InputMaybe<Events_News_Bool_Exp>;
};

export type Query_RootEvents_News_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootFollow_Relation_Users_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Follow_Relation_Users_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Follow_Relation_Users_Startups_Order_By>>;
  where?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
};

export type Query_RootFollow_Relation_Users_Startups_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootFounder_JobsArgs = {
  distinct_on?: InputMaybe<Array<Founder_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Founder_Jobs_Order_By>>;
  where?: InputMaybe<Founder_Jobs_Bool_Exp>;
};

export type Query_RootFounder_Jobs_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootFounder_Score_WeightsArgs = {
  distinct_on?: InputMaybe<Array<Founder_Score_Weights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Founder_Score_Weights_Order_By>>;
  where?: InputMaybe<Founder_Score_Weights_Bool_Exp>;
};

export type Query_RootFounder_Score_Weights_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootFoundersArgs = {
  distinct_on?: InputMaybe<Array<Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Founders_Order_By>>;
  where?: InputMaybe<Founders_Bool_Exp>;
};

export type Query_RootFounders_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootG2_Historical_DataArgs = {
  distinct_on?: InputMaybe<Array<G2_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Historical_Data_Order_By>>;
  where?: InputMaybe<G2_Historical_Data_Bool_Exp>;
};

export type Query_RootG2_Historical_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootG2_IndustryArgs = {
  distinct_on?: InputMaybe<Array<G2_Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Industry_Order_By>>;
  where?: InputMaybe<G2_Industry_Bool_Exp>;
};

export type Query_RootG2_Industry_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootG2_Product_CategoryArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Category_Order_By>>;
  where?: InputMaybe<G2_Product_Category_Bool_Exp>;
};

export type Query_RootG2_Product_Category_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootG2_Product_CompetitorsArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Competitors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Competitors_Order_By>>;
  where?: InputMaybe<G2_Product_Competitors_Bool_Exp>;
};

export type Query_RootG2_Product_Competitors_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootG2_Product_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Reviews_Order_By>>;
  where?: InputMaybe<G2_Product_Reviews_Bool_Exp>;
};

export type Query_RootG2_Product_Reviews_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGlassdoor_DataArgs = {
  distinct_on?: InputMaybe<Array<Glassdoor_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Glassdoor_Data_Order_By>>;
  where?: InputMaybe<Glassdoor_Data_Bool_Exp>;
};

export type Query_RootGlassdoor_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGlassdoor_TrendArgs = {
  distinct_on?: InputMaybe<Array<Glassdoor_Trend_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Glassdoor_Trend_Order_By>>;
  where?: InputMaybe<Glassdoor_Trend_Bool_Exp>;
};

export type Query_RootGlassdoor_Trend_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootGoogle_SerpArgs = {
  distinct_on?: InputMaybe<Array<Google_Serp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Serp_Order_By>>;
  where?: InputMaybe<Google_Serp_Bool_Exp>;
};

export type Query_RootGoogle_Serp_By_PkArgs = {
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
};

export type Query_RootGrowthArgs = {
  distinct_on?: InputMaybe<Array<Growth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Growth_Order_By>>;
  where?: InputMaybe<Growth_Bool_Exp>;
};

export type Query_RootGrowth_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootIdentity_CardsArgs = {
  distinct_on?: InputMaybe<Array<Identity_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Cards_Order_By>>;
  where?: InputMaybe<Identity_Cards_Bool_Exp>;
};

export type Query_RootIdentity_Cards_By_PkArgs = {
  startup_uuid: Scalars['uuid'];
};

export type Query_RootInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};

export type Query_RootInvestments_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootInvestor_Startup_RelationArgs = {
  distinct_on?: InputMaybe<Array<Investor_Startup_Relation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Startup_Relation_Order_By>>;
  where?: InputMaybe<Investor_Startup_Relation_Bool_Exp>;
};

export type Query_RootInvestor_Startup_Relation_By_PkArgs = {
  investor_uuid: Scalars['uuid'];
  startup_uuid: Scalars['uuid'];
};

export type Query_RootInvestorsArgs = {
  distinct_on?: InputMaybe<Array<Investors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investors_Order_By>>;
  where?: InputMaybe<Investors_Bool_Exp>;
};

export type Query_RootInvestors_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootLast_Search_Relation_Users_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Order_By>>;
  where?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
};

export type Query_RootLast_Search_Relation_Users_Startups_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLinkedin_Employee_Avg_TenureArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Employee_Avg_Tenure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Employee_Avg_Tenure_Order_By>>;
  where?: InputMaybe<Linkedin_Employee_Avg_Tenure_Bool_Exp>;
};

export type Query_RootLinkedin_Employee_Avg_Tenure_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLinkedin_EmployeesArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Employees_Order_By>>;
  where?: InputMaybe<Linkedin_Employees_Bool_Exp>;
};

export type Query_RootLinkedin_Employees_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLinkedin_FollowersArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Followers_Order_By>>;
  where?: InputMaybe<Linkedin_Followers_Bool_Exp>;
};

export type Query_RootLinkedin_Followers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLinkedin_HeadquartersArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Headquarters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Headquarters_Order_By>>;
  where?: InputMaybe<Linkedin_Headquarters_Bool_Exp>;
};

export type Query_RootLinkedin_Headquarters_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLinkedin_PublicationsArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Publications_Order_By>>;
  where?: InputMaybe<Linkedin_Publications_Bool_Exp>;
};

export type Query_RootLinkedin_Publications_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLinkedin_School_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_School_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_School_Repartition_Order_By>>;
  where?: InputMaybe<Linkedin_School_Repartition_Bool_Exp>;
};

export type Query_RootLinkedin_School_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootLinkedin_Skill_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Skill_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Skill_Repartition_Order_By>>;
  where?: InputMaybe<Linkedin_Skill_Repartition_Bool_Exp>;
};

export type Query_RootLinkedin_Skill_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootMobile_AppArgs = {
  distinct_on?: InputMaybe<Array<Mobile_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobile_App_Order_By>>;
  where?: InputMaybe<Mobile_App_Bool_Exp>;
};

export type Query_RootMobile_App_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobile_App_Order_By>>;
  where?: InputMaybe<Mobile_App_Bool_Exp>;
};

export type Query_RootMobile_App_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootPercentilesArgs = {
  distinct_on?: InputMaybe<Array<Percentiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Percentiles_Order_By>>;
  where?: InputMaybe<Percentiles_Bool_Exp>;
};

export type Query_RootPercentiles_By_PkArgs = {
  category: Scalars['String'];
  percentile: Scalars['Int'];
};

export type Query_RootPredictleads_Job_OpeningsArgs = {
  distinct_on?: InputMaybe<Array<Predictleads_Job_Openings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Predictleads_Job_Openings_Order_By>>;
  where?: InputMaybe<Predictleads_Job_Openings_Bool_Exp>;
};

export type Query_RootPredictleads_Job_Openings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSchoolsArgs = {
  distinct_on?: InputMaybe<Array<Schools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Schools_Order_By>>;
  where?: InputMaybe<Schools_Bool_Exp>;
};

export type Query_RootSchools_By_PkArgs = {
  schoolName: Scalars['String'];
};

export type Query_RootScoresArgs = {
  distinct_on?: InputMaybe<Array<Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scores_Order_By>>;
  where?: InputMaybe<Scores_Bool_Exp>;
};

export type Query_RootScores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scores_Order_By>>;
  where?: InputMaybe<Scores_Bool_Exp>;
};

export type Query_RootScores_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootSearch_Startup_NameArgs = {
  args: Search_Startup_Name_Args;
  distinct_on?: InputMaybe<Array<Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Startups_Order_By>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Query_RootSearch_Startup_Name_AggregateArgs = {
  args: Search_Startup_Name_Args;
  distinct_on?: InputMaybe<Array<Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Startups_Order_By>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Query_RootSearch_Startup_Name_In_Identity_CardsArgs = {
  args: Search_Startup_Name_In_Identity_Cards_Args;
  distinct_on?: InputMaybe<Array<Identity_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Cards_Order_By>>;
  where?: InputMaybe<Identity_Cards_Bool_Exp>;
};

export type Query_RootSimilar_SitesArgs = {
  distinct_on?: InputMaybe<Array<Similar_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Similar_Sites_Order_By>>;
  where?: InputMaybe<Similar_Sites_Bool_Exp>;
};

export type Query_RootSimilar_Sites_By_PkArgs = {
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
};

export type Query_RootSourcingArgs = {
  distinct_on?: InputMaybe<Array<Sourcing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sourcing_Order_By>>;
  where?: InputMaybe<Sourcing_Bool_Exp>;
};

export type Query_RootSourcing_By_PkArgs = {
  sourcingOpportunityName: Scalars['String'];
};

export type Query_RootSourcing_StatusArgs = {
  distinct_on?: InputMaybe<Array<Sourcing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sourcing_Status_Order_By>>;
  where?: InputMaybe<Sourcing_Status_Bool_Exp>;
};

export type Query_RootSourcing_Status_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootStartupsArgs = {
  distinct_on?: InputMaybe<Array<Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Startups_Order_By>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Query_RootStartups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Startups_Order_By>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Query_RootStartups_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Query_RootTwitter_FollowersArgs = {
  distinct_on?: InputMaybe<Array<Twitter_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Twitter_Followers_Order_By>>;
  where?: InputMaybe<Twitter_Followers_Bool_Exp>;
};

export type Query_RootTwitter_Followers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootWeb_Visits_Country_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Country_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Country_Repartition_Order_By>>;
  where?: InputMaybe<Web_Visits_Country_Repartition_Bool_Exp>;
};

export type Query_RootWeb_Visits_Country_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootWeb_Visits_General_DataArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_General_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_General_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_General_Data_Bool_Exp>;
};

export type Query_RootWeb_Visits_General_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootWeb_Visits_Historical_DataArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Historical_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
};

export type Query_RootWeb_Visits_Historical_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Historical_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
};

export type Query_RootWeb_Visits_Historical_Data_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "schools" */
export type Schools = {
  __typename?: 'schools';
  linkedin?: Maybe<Scalars['String']>;
  schoolCountry?: Maybe<Scalars['String']>;
  schoolId: Scalars['Int'];
  schoolName: Scalars['String'];
  topSchool?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "schools". All fields are combined with a logical 'AND'. */
export type Schools_Bool_Exp = {
  _and?: InputMaybe<Array<Schools_Bool_Exp>>;
  _not?: InputMaybe<Schools_Bool_Exp>;
  _or?: InputMaybe<Array<Schools_Bool_Exp>>;
  linkedin?: InputMaybe<String_Comparison_Exp>;
  schoolCountry?: InputMaybe<String_Comparison_Exp>;
  schoolId?: InputMaybe<Int_Comparison_Exp>;
  schoolName?: InputMaybe<String_Comparison_Exp>;
  topSchool?: InputMaybe<Boolean_Comparison_Exp>;
};

/** Ordering options when selecting data from "schools". */
export type Schools_Order_By = {
  linkedin?: InputMaybe<Order_By>;
  schoolCountry?: InputMaybe<Order_By>;
  schoolId?: InputMaybe<Order_By>;
  schoolName?: InputMaybe<Order_By>;
  topSchool?: InputMaybe<Order_By>;
};

/** select columns of table "schools" */
export enum Schools_Select_Column {
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  SchoolCountry = 'schoolCountry',
  /** column name */
  SchoolId = 'schoolId',
  /** column name */
  SchoolName = 'schoolName',
  /** column name */
  TopSchool = 'topSchool',
}

/** Streaming cursor of the table "schools" */
export type Schools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Schools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Schools_Stream_Cursor_Value_Input = {
  linkedin?: InputMaybe<Scalars['String']>;
  schoolCountry?: InputMaybe<Scalars['String']>;
  schoolId?: InputMaybe<Scalars['Int']>;
  schoolName?: InputMaybe<Scalars['String']>;
  topSchool?: InputMaybe<Scalars['Boolean']>;
};

/** columns and relationships of "scores" */
export type Scores = {
  __typename?: 'scores';
  USGroundPresenceScore?: Maybe<Scalars['float8']>;
  USpresenceScore?: Maybe<Scalars['float8']>;
  annual_growth_score_dau?: Maybe<Scalars['numeric']>;
  annual_growth_score_downloads?: Maybe<Scalars['numeric']>;
  annual_growth_score_employee?: Maybe<Scalars['numeric']>;
  annual_growth_score_g2?: Maybe<Scalars['numeric']>;
  annual_growth_score_github?: Maybe<Scalars['numeric']>;
  annual_growth_score_linkedin?: Maybe<Scalars['numeric']>;
  annual_growth_score_producthunt?: Maybe<Scalars['numeric']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  annual_growth_score_twitter?: Maybe<Scalars['numeric']>;
  annual_growth_score_web_visit?: Maybe<Scalars['numeric']>;
  confidenceIndex?: Maybe<Scalars['float8']>;
  confidence_impact_score?: Maybe<Scalars['numeric']>;
  confidence_priority_score?: Maybe<Scalars['numeric']>;
  confidence_us_potential_score?: Maybe<Scalars['numeric']>;
  confidence_us_presence_score?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date: Scalars['date'];
  growthEstimation?: Maybe<Scalars['String']>;
  growthPercentile?: Maybe<Scalars['float8']>;
  growthPrediction?: Maybe<Scalars['float8']>;
  growthPriority?: Maybe<Scalars['float8']>;
  growth_monthly?: Maybe<Scalars['float8']>;
  growth_score_adaptative?: Maybe<Scalars['float8']>;
  growth_score_dau?: Maybe<Scalars['numeric']>;
  growth_score_downloads?: Maybe<Scalars['numeric']>;
  growth_score_employee?: Maybe<Scalars['numeric']>;
  growth_score_g2?: Maybe<Scalars['numeric']>;
  growth_score_github?: Maybe<Scalars['numeric']>;
  growth_score_linkedin?: Maybe<Scalars['numeric']>;
  growth_score_producthunt?: Maybe<Scalars['numeric']>;
  growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  growth_score_twitter?: Maybe<Scalars['numeric']>;
  growth_score_web_visit?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  impact_score?: Maybe<Scalars['float8']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['numeric']>;
  percentile_impact_score?: Maybe<Scalars['numeric']>;
  percentile_us_presence_score?: Maybe<Scalars['numeric']>;
  priorityPercentile?: Maybe<Scalars['float8']>;
  priorityScore?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  three_months_growth_score_dau?: Maybe<Scalars['numeric']>;
  three_months_growth_score_downloads?: Maybe<Scalars['numeric']>;
  three_months_growth_score_employee?: Maybe<Scalars['numeric']>;
  three_months_growth_score_g2?: Maybe<Scalars['numeric']>;
  three_months_growth_score_github?: Maybe<Scalars['numeric']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['numeric']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['numeric']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  three_months_growth_score_twitter?: Maybe<Scalars['numeric']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  us_potential_score?: Maybe<Scalars['numeric']>;
  us_potential_score_percentile?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "scores" */
export type Scores_Aggregate = {
  __typename?: 'scores_aggregate';
  aggregate?: Maybe<Scores_Aggregate_Fields>;
  nodes: Array<Scores>;
};

export type Scores_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Scores_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Scores_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Scores_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Scores_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Scores_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Scores_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Scores_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Scores_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Scores_Aggregate_Bool_Exp_Var_Samp>;
};

export type Scores_Aggregate_Bool_Exp_Avg = {
  arguments: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scores_Aggregate_Bool_Exp_Corr = {
  arguments: Scores_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scores_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Scores_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Scores_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Scores_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Scores_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scores_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Scores_Aggregate_Bool_Exp_Max = {
  arguments: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scores_Aggregate_Bool_Exp_Min = {
  arguments: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scores_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scores_Aggregate_Bool_Exp_Sum = {
  arguments: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Scores_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Scores_Select_Column_Scores_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Scores_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "scores" */
export type Scores_Aggregate_Fields = {
  __typename?: 'scores_aggregate_fields';
  avg?: Maybe<Scores_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Scores_Max_Fields>;
  min?: Maybe<Scores_Min_Fields>;
  stddev?: Maybe<Scores_Stddev_Fields>;
  stddev_pop?: Maybe<Scores_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scores_Stddev_Samp_Fields>;
  sum?: Maybe<Scores_Sum_Fields>;
  var_pop?: Maybe<Scores_Var_Pop_Fields>;
  var_samp?: Maybe<Scores_Var_Samp_Fields>;
  variance?: Maybe<Scores_Variance_Fields>;
};

/** aggregate fields of "scores" */
export type Scores_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Scores_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "scores" */
export type Scores_Aggregate_Order_By = {
  avg?: InputMaybe<Scores_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Scores_Max_Order_By>;
  min?: InputMaybe<Scores_Min_Order_By>;
  stddev?: InputMaybe<Scores_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Scores_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Scores_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Scores_Sum_Order_By>;
  var_pop?: InputMaybe<Scores_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Scores_Var_Samp_Order_By>;
  variance?: InputMaybe<Scores_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Scores_Avg_Fields = {
  __typename?: 'scores_avg_fields';
  USGroundPresenceScore?: Maybe<Scalars['Float']>;
  USpresenceScore?: Maybe<Scalars['Float']>;
  annual_growth_score_dau?: Maybe<Scalars['Float']>;
  annual_growth_score_downloads?: Maybe<Scalars['Float']>;
  annual_growth_score_employee?: Maybe<Scalars['Float']>;
  annual_growth_score_g2?: Maybe<Scalars['Float']>;
  annual_growth_score_github?: Maybe<Scalars['Float']>;
  annual_growth_score_linkedin?: Maybe<Scalars['Float']>;
  annual_growth_score_producthunt?: Maybe<Scalars['Float']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  annual_growth_score_twitter?: Maybe<Scalars['Float']>;
  annual_growth_score_web_visit?: Maybe<Scalars['Float']>;
  confidenceIndex?: Maybe<Scalars['Float']>;
  confidence_impact_score?: Maybe<Scalars['Float']>;
  confidence_priority_score?: Maybe<Scalars['Float']>;
  confidence_us_potential_score?: Maybe<Scalars['Float']>;
  confidence_us_presence_score?: Maybe<Scalars['Float']>;
  growthPercentile?: Maybe<Scalars['Float']>;
  growthPrediction?: Maybe<Scalars['Float']>;
  growthPriority?: Maybe<Scalars['Float']>;
  growth_monthly?: Maybe<Scalars['Float']>;
  growth_score_adaptative?: Maybe<Scalars['Float']>;
  growth_score_dau?: Maybe<Scalars['Float']>;
  growth_score_downloads?: Maybe<Scalars['Float']>;
  growth_score_employee?: Maybe<Scalars['Float']>;
  growth_score_g2?: Maybe<Scalars['Float']>;
  growth_score_github?: Maybe<Scalars['Float']>;
  growth_score_linkedin?: Maybe<Scalars['Float']>;
  growth_score_producthunt?: Maybe<Scalars['Float']>;
  growth_score_trustpilot?: Maybe<Scalars['Float']>;
  growth_score_twitter?: Maybe<Scalars['Float']>;
  growth_score_web_visit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact_score?: Maybe<Scalars['Float']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['Float']>;
  percentile_impact_score?: Maybe<Scalars['Float']>;
  percentile_us_presence_score?: Maybe<Scalars['Float']>;
  priorityPercentile?: Maybe<Scalars['Float']>;
  priorityScore?: Maybe<Scalars['Float']>;
  three_months_growth_score_dau?: Maybe<Scalars['Float']>;
  three_months_growth_score_downloads?: Maybe<Scalars['Float']>;
  three_months_growth_score_employee?: Maybe<Scalars['Float']>;
  three_months_growth_score_g2?: Maybe<Scalars['Float']>;
  three_months_growth_score_github?: Maybe<Scalars['Float']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['Float']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['Float']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  three_months_growth_score_twitter?: Maybe<Scalars['Float']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['Float']>;
  us_potential_score?: Maybe<Scalars['Float']>;
  us_potential_score_percentile?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "scores" */
export type Scores_Avg_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scores". All fields are combined with a logical 'AND'. */
export type Scores_Bool_Exp = {
  USGroundPresenceScore?: InputMaybe<Float8_Comparison_Exp>;
  USpresenceScore?: InputMaybe<Float8_Comparison_Exp>;
  _and?: InputMaybe<Array<Scores_Bool_Exp>>;
  _not?: InputMaybe<Scores_Bool_Exp>;
  _or?: InputMaybe<Array<Scores_Bool_Exp>>;
  annual_growth_score_dau?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_downloads?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_employee?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_g2?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_github?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_linkedin?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_producthunt?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_trustpilot?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_twitter?: InputMaybe<Numeric_Comparison_Exp>;
  annual_growth_score_web_visit?: InputMaybe<Numeric_Comparison_Exp>;
  confidenceIndex?: InputMaybe<Float8_Comparison_Exp>;
  confidence_impact_score?: InputMaybe<Numeric_Comparison_Exp>;
  confidence_priority_score?: InputMaybe<Numeric_Comparison_Exp>;
  confidence_us_potential_score?: InputMaybe<Numeric_Comparison_Exp>;
  confidence_us_presence_score?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  growthEstimation?: InputMaybe<String_Comparison_Exp>;
  growthPercentile?: InputMaybe<Float8_Comparison_Exp>;
  growthPrediction?: InputMaybe<Float8_Comparison_Exp>;
  growthPriority?: InputMaybe<Float8_Comparison_Exp>;
  growth_monthly?: InputMaybe<Float8_Comparison_Exp>;
  growth_score_adaptative?: InputMaybe<Float8_Comparison_Exp>;
  growth_score_dau?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_downloads?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_employee?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_g2?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_github?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_linkedin?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_producthunt?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_trustpilot?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_twitter?: InputMaybe<Numeric_Comparison_Exp>;
  growth_score_web_visit?: InputMaybe<Numeric_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  impact_score?: InputMaybe<Float8_Comparison_Exp>;
  percentile_growth_score_adaptative?: InputMaybe<Numeric_Comparison_Exp>;
  percentile_impact_score?: InputMaybe<Numeric_Comparison_Exp>;
  percentile_us_presence_score?: InputMaybe<Numeric_Comparison_Exp>;
  priorityPercentile?: InputMaybe<Float8_Comparison_Exp>;
  priorityScore?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  three_months_growth_score_dau?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_downloads?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_employee?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_g2?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_github?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_linkedin?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_producthunt?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_trustpilot?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_twitter?: InputMaybe<Numeric_Comparison_Exp>;
  three_months_growth_score_web_visit?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  us_potential_score?: InputMaybe<Numeric_Comparison_Exp>;
  us_potential_score_percentile?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Scores_Max_Fields = {
  __typename?: 'scores_max_fields';
  USGroundPresenceScore?: Maybe<Scalars['float8']>;
  USpresenceScore?: Maybe<Scalars['float8']>;
  annual_growth_score_dau?: Maybe<Scalars['numeric']>;
  annual_growth_score_downloads?: Maybe<Scalars['numeric']>;
  annual_growth_score_employee?: Maybe<Scalars['numeric']>;
  annual_growth_score_g2?: Maybe<Scalars['numeric']>;
  annual_growth_score_github?: Maybe<Scalars['numeric']>;
  annual_growth_score_linkedin?: Maybe<Scalars['numeric']>;
  annual_growth_score_producthunt?: Maybe<Scalars['numeric']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  annual_growth_score_twitter?: Maybe<Scalars['numeric']>;
  annual_growth_score_web_visit?: Maybe<Scalars['numeric']>;
  confidenceIndex?: Maybe<Scalars['float8']>;
  confidence_impact_score?: Maybe<Scalars['numeric']>;
  confidence_priority_score?: Maybe<Scalars['numeric']>;
  confidence_us_potential_score?: Maybe<Scalars['numeric']>;
  confidence_us_presence_score?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  growthEstimation?: Maybe<Scalars['String']>;
  growthPercentile?: Maybe<Scalars['float8']>;
  growthPrediction?: Maybe<Scalars['float8']>;
  growthPriority?: Maybe<Scalars['float8']>;
  growth_monthly?: Maybe<Scalars['float8']>;
  growth_score_adaptative?: Maybe<Scalars['float8']>;
  growth_score_dau?: Maybe<Scalars['numeric']>;
  growth_score_downloads?: Maybe<Scalars['numeric']>;
  growth_score_employee?: Maybe<Scalars['numeric']>;
  growth_score_g2?: Maybe<Scalars['numeric']>;
  growth_score_github?: Maybe<Scalars['numeric']>;
  growth_score_linkedin?: Maybe<Scalars['numeric']>;
  growth_score_producthunt?: Maybe<Scalars['numeric']>;
  growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  growth_score_twitter?: Maybe<Scalars['numeric']>;
  growth_score_web_visit?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  impact_score?: Maybe<Scalars['float8']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['numeric']>;
  percentile_impact_score?: Maybe<Scalars['numeric']>;
  percentile_us_presence_score?: Maybe<Scalars['numeric']>;
  priorityPercentile?: Maybe<Scalars['float8']>;
  priorityScore?: Maybe<Scalars['float8']>;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid?: Maybe<Scalars['uuid']>;
  three_months_growth_score_dau?: Maybe<Scalars['numeric']>;
  three_months_growth_score_downloads?: Maybe<Scalars['numeric']>;
  three_months_growth_score_employee?: Maybe<Scalars['numeric']>;
  three_months_growth_score_g2?: Maybe<Scalars['numeric']>;
  three_months_growth_score_github?: Maybe<Scalars['numeric']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['numeric']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['numeric']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  three_months_growth_score_twitter?: Maybe<Scalars['numeric']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  us_potential_score?: Maybe<Scalars['numeric']>;
  us_potential_score_percentile?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "scores" */
export type Scores_Max_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  growthEstimation?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Scores_Min_Fields = {
  __typename?: 'scores_min_fields';
  USGroundPresenceScore?: Maybe<Scalars['float8']>;
  USpresenceScore?: Maybe<Scalars['float8']>;
  annual_growth_score_dau?: Maybe<Scalars['numeric']>;
  annual_growth_score_downloads?: Maybe<Scalars['numeric']>;
  annual_growth_score_employee?: Maybe<Scalars['numeric']>;
  annual_growth_score_g2?: Maybe<Scalars['numeric']>;
  annual_growth_score_github?: Maybe<Scalars['numeric']>;
  annual_growth_score_linkedin?: Maybe<Scalars['numeric']>;
  annual_growth_score_producthunt?: Maybe<Scalars['numeric']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  annual_growth_score_twitter?: Maybe<Scalars['numeric']>;
  annual_growth_score_web_visit?: Maybe<Scalars['numeric']>;
  confidenceIndex?: Maybe<Scalars['float8']>;
  confidence_impact_score?: Maybe<Scalars['numeric']>;
  confidence_priority_score?: Maybe<Scalars['numeric']>;
  confidence_us_potential_score?: Maybe<Scalars['numeric']>;
  confidence_us_presence_score?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date?: Maybe<Scalars['date']>;
  growthEstimation?: Maybe<Scalars['String']>;
  growthPercentile?: Maybe<Scalars['float8']>;
  growthPrediction?: Maybe<Scalars['float8']>;
  growthPriority?: Maybe<Scalars['float8']>;
  growth_monthly?: Maybe<Scalars['float8']>;
  growth_score_adaptative?: Maybe<Scalars['float8']>;
  growth_score_dau?: Maybe<Scalars['numeric']>;
  growth_score_downloads?: Maybe<Scalars['numeric']>;
  growth_score_employee?: Maybe<Scalars['numeric']>;
  growth_score_g2?: Maybe<Scalars['numeric']>;
  growth_score_github?: Maybe<Scalars['numeric']>;
  growth_score_linkedin?: Maybe<Scalars['numeric']>;
  growth_score_producthunt?: Maybe<Scalars['numeric']>;
  growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  growth_score_twitter?: Maybe<Scalars['numeric']>;
  growth_score_web_visit?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  impact_score?: Maybe<Scalars['float8']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['numeric']>;
  percentile_impact_score?: Maybe<Scalars['numeric']>;
  percentile_us_presence_score?: Maybe<Scalars['numeric']>;
  priorityPercentile?: Maybe<Scalars['float8']>;
  priorityScore?: Maybe<Scalars['float8']>;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid?: Maybe<Scalars['uuid']>;
  three_months_growth_score_dau?: Maybe<Scalars['numeric']>;
  three_months_growth_score_downloads?: Maybe<Scalars['numeric']>;
  three_months_growth_score_employee?: Maybe<Scalars['numeric']>;
  three_months_growth_score_g2?: Maybe<Scalars['numeric']>;
  three_months_growth_score_github?: Maybe<Scalars['numeric']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['numeric']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['numeric']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  three_months_growth_score_twitter?: Maybe<Scalars['numeric']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  us_potential_score?: Maybe<Scalars['numeric']>;
  us_potential_score_percentile?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "scores" */
export type Scores_Min_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  growthEstimation?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "scores". */
export type Scores_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  growthEstimation?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** select columns of table "scores" */
export enum Scores_Select_Column {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  AnnualGrowthScoreDau = 'annual_growth_score_dau',
  /** column name */
  AnnualGrowthScoreDownloads = 'annual_growth_score_downloads',
  /** column name */
  AnnualGrowthScoreEmployee = 'annual_growth_score_employee',
  /** column name */
  AnnualGrowthScoreG2 = 'annual_growth_score_g2',
  /** column name */
  AnnualGrowthScoreGithub = 'annual_growth_score_github',
  /** column name */
  AnnualGrowthScoreLinkedin = 'annual_growth_score_linkedin',
  /** column name */
  AnnualGrowthScoreProducthunt = 'annual_growth_score_producthunt',
  /** column name */
  AnnualGrowthScoreTrustpilot = 'annual_growth_score_trustpilot',
  /** column name */
  AnnualGrowthScoreTwitter = 'annual_growth_score_twitter',
  /** column name */
  AnnualGrowthScoreWebVisit = 'annual_growth_score_web_visit',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  ConfidenceImpactScore = 'confidence_impact_score',
  /** column name */
  ConfidencePriorityScore = 'confidence_priority_score',
  /** column name */
  ConfidenceUsPotentialScore = 'confidence_us_potential_score',
  /** column name */
  ConfidenceUsPresenceScore = 'confidence_us_presence_score',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Date = 'date',
  /** column name */
  GrowthEstimation = 'growthEstimation',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  GrowthScoreDau = 'growth_score_dau',
  /** column name */
  GrowthScoreDownloads = 'growth_score_downloads',
  /** column name */
  GrowthScoreEmployee = 'growth_score_employee',
  /** column name */
  GrowthScoreG2 = 'growth_score_g2',
  /** column name */
  GrowthScoreGithub = 'growth_score_github',
  /** column name */
  GrowthScoreLinkedin = 'growth_score_linkedin',
  /** column name */
  GrowthScoreProducthunt = 'growth_score_producthunt',
  /** column name */
  GrowthScoreTrustpilot = 'growth_score_trustpilot',
  /** column name */
  GrowthScoreTwitter = 'growth_score_twitter',
  /** column name */
  GrowthScoreWebVisit = 'growth_score_web_visit',
  /** column name */
  Id = 'id',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PercentileGrowthScoreAdaptative = 'percentile_growth_score_adaptative',
  /** column name */
  PercentileImpactScore = 'percentile_impact_score',
  /** column name */
  PercentileUsPresenceScore = 'percentile_us_presence_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  ThreeMonthsGrowthScoreDau = 'three_months_growth_score_dau',
  /** column name */
  ThreeMonthsGrowthScoreDownloads = 'three_months_growth_score_downloads',
  /** column name */
  ThreeMonthsGrowthScoreEmployee = 'three_months_growth_score_employee',
  /** column name */
  ThreeMonthsGrowthScoreG2 = 'three_months_growth_score_g2',
  /** column name */
  ThreeMonthsGrowthScoreGithub = 'three_months_growth_score_github',
  /** column name */
  ThreeMonthsGrowthScoreLinkedin = 'three_months_growth_score_linkedin',
  /** column name */
  ThreeMonthsGrowthScoreProducthunt = 'three_months_growth_score_producthunt',
  /** column name */
  ThreeMonthsGrowthScoreTrustpilot = 'three_months_growth_score_trustpilot',
  /** column name */
  ThreeMonthsGrowthScoreTwitter = 'three_months_growth_score_twitter',
  /** column name */
  ThreeMonthsGrowthScoreWebVisit = 'three_months_growth_score_web_visit',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsPotentialScore = 'us_potential_score',
  /** column name */
  UsPotentialScorePercentile = 'us_potential_score_percentile',
}

/** select "scores_aggregate_bool_exp_avg_arguments_columns" columns of table "scores" */
export enum Scores_Select_Column_Scores_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
}

/** select "scores_aggregate_bool_exp_corr_arguments_columns" columns of table "scores" */
export enum Scores_Select_Column_Scores_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
}

/** select "scores_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "scores" */
export enum Scores_Select_Column_Scores_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
}

/** select "scores_aggregate_bool_exp_max_arguments_columns" columns of table "scores" */
export enum Scores_Select_Column_Scores_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
}

/** select "scores_aggregate_bool_exp_min_arguments_columns" columns of table "scores" */
export enum Scores_Select_Column_Scores_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
}

/** select "scores_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "scores" */
export enum Scores_Select_Column_Scores_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
}

/** select "scores_aggregate_bool_exp_sum_arguments_columns" columns of table "scores" */
export enum Scores_Select_Column_Scores_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
}

/** select "scores_aggregate_bool_exp_var_samp_arguments_columns" columns of table "scores" */
export enum Scores_Select_Column_Scores_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  UsGroundPresenceScore = 'USGroundPresenceScore',
  /** column name */
  USpresenceScore = 'USpresenceScore',
  /** column name */
  ConfidenceIndex = 'confidenceIndex',
  /** column name */
  GrowthPercentile = 'growthPercentile',
  /** column name */
  GrowthPrediction = 'growthPrediction',
  /** column name */
  GrowthPriority = 'growthPriority',
  /** column name */
  GrowthMonthly = 'growth_monthly',
  /** column name */
  GrowthScoreAdaptative = 'growth_score_adaptative',
  /** column name */
  ImpactScore = 'impact_score',
  /** column name */
  PriorityPercentile = 'priorityPercentile',
  /** column name */
  PriorityScore = 'priorityScore',
}

/** aggregate stddev on columns */
export type Scores_Stddev_Fields = {
  __typename?: 'scores_stddev_fields';
  USGroundPresenceScore?: Maybe<Scalars['Float']>;
  USpresenceScore?: Maybe<Scalars['Float']>;
  annual_growth_score_dau?: Maybe<Scalars['Float']>;
  annual_growth_score_downloads?: Maybe<Scalars['Float']>;
  annual_growth_score_employee?: Maybe<Scalars['Float']>;
  annual_growth_score_g2?: Maybe<Scalars['Float']>;
  annual_growth_score_github?: Maybe<Scalars['Float']>;
  annual_growth_score_linkedin?: Maybe<Scalars['Float']>;
  annual_growth_score_producthunt?: Maybe<Scalars['Float']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  annual_growth_score_twitter?: Maybe<Scalars['Float']>;
  annual_growth_score_web_visit?: Maybe<Scalars['Float']>;
  confidenceIndex?: Maybe<Scalars['Float']>;
  confidence_impact_score?: Maybe<Scalars['Float']>;
  confidence_priority_score?: Maybe<Scalars['Float']>;
  confidence_us_potential_score?: Maybe<Scalars['Float']>;
  confidence_us_presence_score?: Maybe<Scalars['Float']>;
  growthPercentile?: Maybe<Scalars['Float']>;
  growthPrediction?: Maybe<Scalars['Float']>;
  growthPriority?: Maybe<Scalars['Float']>;
  growth_monthly?: Maybe<Scalars['Float']>;
  growth_score_adaptative?: Maybe<Scalars['Float']>;
  growth_score_dau?: Maybe<Scalars['Float']>;
  growth_score_downloads?: Maybe<Scalars['Float']>;
  growth_score_employee?: Maybe<Scalars['Float']>;
  growth_score_g2?: Maybe<Scalars['Float']>;
  growth_score_github?: Maybe<Scalars['Float']>;
  growth_score_linkedin?: Maybe<Scalars['Float']>;
  growth_score_producthunt?: Maybe<Scalars['Float']>;
  growth_score_trustpilot?: Maybe<Scalars['Float']>;
  growth_score_twitter?: Maybe<Scalars['Float']>;
  growth_score_web_visit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact_score?: Maybe<Scalars['Float']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['Float']>;
  percentile_impact_score?: Maybe<Scalars['Float']>;
  percentile_us_presence_score?: Maybe<Scalars['Float']>;
  priorityPercentile?: Maybe<Scalars['Float']>;
  priorityScore?: Maybe<Scalars['Float']>;
  three_months_growth_score_dau?: Maybe<Scalars['Float']>;
  three_months_growth_score_downloads?: Maybe<Scalars['Float']>;
  three_months_growth_score_employee?: Maybe<Scalars['Float']>;
  three_months_growth_score_g2?: Maybe<Scalars['Float']>;
  three_months_growth_score_github?: Maybe<Scalars['Float']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['Float']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['Float']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  three_months_growth_score_twitter?: Maybe<Scalars['Float']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['Float']>;
  us_potential_score?: Maybe<Scalars['Float']>;
  us_potential_score_percentile?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "scores" */
export type Scores_Stddev_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scores_Stddev_Pop_Fields = {
  __typename?: 'scores_stddev_pop_fields';
  USGroundPresenceScore?: Maybe<Scalars['Float']>;
  USpresenceScore?: Maybe<Scalars['Float']>;
  annual_growth_score_dau?: Maybe<Scalars['Float']>;
  annual_growth_score_downloads?: Maybe<Scalars['Float']>;
  annual_growth_score_employee?: Maybe<Scalars['Float']>;
  annual_growth_score_g2?: Maybe<Scalars['Float']>;
  annual_growth_score_github?: Maybe<Scalars['Float']>;
  annual_growth_score_linkedin?: Maybe<Scalars['Float']>;
  annual_growth_score_producthunt?: Maybe<Scalars['Float']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  annual_growth_score_twitter?: Maybe<Scalars['Float']>;
  annual_growth_score_web_visit?: Maybe<Scalars['Float']>;
  confidenceIndex?: Maybe<Scalars['Float']>;
  confidence_impact_score?: Maybe<Scalars['Float']>;
  confidence_priority_score?: Maybe<Scalars['Float']>;
  confidence_us_potential_score?: Maybe<Scalars['Float']>;
  confidence_us_presence_score?: Maybe<Scalars['Float']>;
  growthPercentile?: Maybe<Scalars['Float']>;
  growthPrediction?: Maybe<Scalars['Float']>;
  growthPriority?: Maybe<Scalars['Float']>;
  growth_monthly?: Maybe<Scalars['Float']>;
  growth_score_adaptative?: Maybe<Scalars['Float']>;
  growth_score_dau?: Maybe<Scalars['Float']>;
  growth_score_downloads?: Maybe<Scalars['Float']>;
  growth_score_employee?: Maybe<Scalars['Float']>;
  growth_score_g2?: Maybe<Scalars['Float']>;
  growth_score_github?: Maybe<Scalars['Float']>;
  growth_score_linkedin?: Maybe<Scalars['Float']>;
  growth_score_producthunt?: Maybe<Scalars['Float']>;
  growth_score_trustpilot?: Maybe<Scalars['Float']>;
  growth_score_twitter?: Maybe<Scalars['Float']>;
  growth_score_web_visit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact_score?: Maybe<Scalars['Float']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['Float']>;
  percentile_impact_score?: Maybe<Scalars['Float']>;
  percentile_us_presence_score?: Maybe<Scalars['Float']>;
  priorityPercentile?: Maybe<Scalars['Float']>;
  priorityScore?: Maybe<Scalars['Float']>;
  three_months_growth_score_dau?: Maybe<Scalars['Float']>;
  three_months_growth_score_downloads?: Maybe<Scalars['Float']>;
  three_months_growth_score_employee?: Maybe<Scalars['Float']>;
  three_months_growth_score_g2?: Maybe<Scalars['Float']>;
  three_months_growth_score_github?: Maybe<Scalars['Float']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['Float']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['Float']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  three_months_growth_score_twitter?: Maybe<Scalars['Float']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['Float']>;
  us_potential_score?: Maybe<Scalars['Float']>;
  us_potential_score_percentile?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "scores" */
export type Scores_Stddev_Pop_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scores_Stddev_Samp_Fields = {
  __typename?: 'scores_stddev_samp_fields';
  USGroundPresenceScore?: Maybe<Scalars['Float']>;
  USpresenceScore?: Maybe<Scalars['Float']>;
  annual_growth_score_dau?: Maybe<Scalars['Float']>;
  annual_growth_score_downloads?: Maybe<Scalars['Float']>;
  annual_growth_score_employee?: Maybe<Scalars['Float']>;
  annual_growth_score_g2?: Maybe<Scalars['Float']>;
  annual_growth_score_github?: Maybe<Scalars['Float']>;
  annual_growth_score_linkedin?: Maybe<Scalars['Float']>;
  annual_growth_score_producthunt?: Maybe<Scalars['Float']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  annual_growth_score_twitter?: Maybe<Scalars['Float']>;
  annual_growth_score_web_visit?: Maybe<Scalars['Float']>;
  confidenceIndex?: Maybe<Scalars['Float']>;
  confidence_impact_score?: Maybe<Scalars['Float']>;
  confidence_priority_score?: Maybe<Scalars['Float']>;
  confidence_us_potential_score?: Maybe<Scalars['Float']>;
  confidence_us_presence_score?: Maybe<Scalars['Float']>;
  growthPercentile?: Maybe<Scalars['Float']>;
  growthPrediction?: Maybe<Scalars['Float']>;
  growthPriority?: Maybe<Scalars['Float']>;
  growth_monthly?: Maybe<Scalars['Float']>;
  growth_score_adaptative?: Maybe<Scalars['Float']>;
  growth_score_dau?: Maybe<Scalars['Float']>;
  growth_score_downloads?: Maybe<Scalars['Float']>;
  growth_score_employee?: Maybe<Scalars['Float']>;
  growth_score_g2?: Maybe<Scalars['Float']>;
  growth_score_github?: Maybe<Scalars['Float']>;
  growth_score_linkedin?: Maybe<Scalars['Float']>;
  growth_score_producthunt?: Maybe<Scalars['Float']>;
  growth_score_trustpilot?: Maybe<Scalars['Float']>;
  growth_score_twitter?: Maybe<Scalars['Float']>;
  growth_score_web_visit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact_score?: Maybe<Scalars['Float']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['Float']>;
  percentile_impact_score?: Maybe<Scalars['Float']>;
  percentile_us_presence_score?: Maybe<Scalars['Float']>;
  priorityPercentile?: Maybe<Scalars['Float']>;
  priorityScore?: Maybe<Scalars['Float']>;
  three_months_growth_score_dau?: Maybe<Scalars['Float']>;
  three_months_growth_score_downloads?: Maybe<Scalars['Float']>;
  three_months_growth_score_employee?: Maybe<Scalars['Float']>;
  three_months_growth_score_g2?: Maybe<Scalars['Float']>;
  three_months_growth_score_github?: Maybe<Scalars['Float']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['Float']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['Float']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  three_months_growth_score_twitter?: Maybe<Scalars['Float']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['Float']>;
  us_potential_score?: Maybe<Scalars['Float']>;
  us_potential_score_percentile?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "scores" */
export type Scores_Stddev_Samp_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "scores" */
export type Scores_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Scores_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Scores_Stream_Cursor_Value_Input = {
  USGroundPresenceScore?: InputMaybe<Scalars['float8']>;
  USpresenceScore?: InputMaybe<Scalars['float8']>;
  annual_growth_score_dau?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_downloads?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_employee?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_g2?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_github?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_linkedin?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_producthunt?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_trustpilot?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_twitter?: InputMaybe<Scalars['numeric']>;
  annual_growth_score_web_visit?: InputMaybe<Scalars['numeric']>;
  confidenceIndex?: InputMaybe<Scalars['float8']>;
  confidence_impact_score?: InputMaybe<Scalars['numeric']>;
  confidence_priority_score?: InputMaybe<Scalars['numeric']>;
  confidence_us_potential_score?: InputMaybe<Scalars['numeric']>;
  confidence_us_presence_score?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  date?: InputMaybe<Scalars['date']>;
  growthEstimation?: InputMaybe<Scalars['String']>;
  growthPercentile?: InputMaybe<Scalars['float8']>;
  growthPrediction?: InputMaybe<Scalars['float8']>;
  growthPriority?: InputMaybe<Scalars['float8']>;
  growth_monthly?: InputMaybe<Scalars['float8']>;
  growth_score_adaptative?: InputMaybe<Scalars['float8']>;
  growth_score_dau?: InputMaybe<Scalars['numeric']>;
  growth_score_downloads?: InputMaybe<Scalars['numeric']>;
  growth_score_employee?: InputMaybe<Scalars['numeric']>;
  growth_score_g2?: InputMaybe<Scalars['numeric']>;
  growth_score_github?: InputMaybe<Scalars['numeric']>;
  growth_score_linkedin?: InputMaybe<Scalars['numeric']>;
  growth_score_producthunt?: InputMaybe<Scalars['numeric']>;
  growth_score_trustpilot?: InputMaybe<Scalars['numeric']>;
  growth_score_twitter?: InputMaybe<Scalars['numeric']>;
  growth_score_web_visit?: InputMaybe<Scalars['numeric']>;
  id?: InputMaybe<Scalars['Int']>;
  impact_score?: InputMaybe<Scalars['float8']>;
  percentile_growth_score_adaptative?: InputMaybe<Scalars['numeric']>;
  percentile_impact_score?: InputMaybe<Scalars['numeric']>;
  percentile_us_presence_score?: InputMaybe<Scalars['numeric']>;
  priorityPercentile?: InputMaybe<Scalars['float8']>;
  priorityScore?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  three_months_growth_score_dau?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_downloads?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_employee?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_g2?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_github?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_linkedin?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_producthunt?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_trustpilot?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_twitter?: InputMaybe<Scalars['numeric']>;
  three_months_growth_score_web_visit?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  us_potential_score?: InputMaybe<Scalars['numeric']>;
  us_potential_score_percentile?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Scores_Sum_Fields = {
  __typename?: 'scores_sum_fields';
  USGroundPresenceScore?: Maybe<Scalars['float8']>;
  USpresenceScore?: Maybe<Scalars['float8']>;
  annual_growth_score_dau?: Maybe<Scalars['numeric']>;
  annual_growth_score_downloads?: Maybe<Scalars['numeric']>;
  annual_growth_score_employee?: Maybe<Scalars['numeric']>;
  annual_growth_score_g2?: Maybe<Scalars['numeric']>;
  annual_growth_score_github?: Maybe<Scalars['numeric']>;
  annual_growth_score_linkedin?: Maybe<Scalars['numeric']>;
  annual_growth_score_producthunt?: Maybe<Scalars['numeric']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  annual_growth_score_twitter?: Maybe<Scalars['numeric']>;
  annual_growth_score_web_visit?: Maybe<Scalars['numeric']>;
  confidenceIndex?: Maybe<Scalars['float8']>;
  confidence_impact_score?: Maybe<Scalars['numeric']>;
  confidence_priority_score?: Maybe<Scalars['numeric']>;
  confidence_us_potential_score?: Maybe<Scalars['numeric']>;
  confidence_us_presence_score?: Maybe<Scalars['numeric']>;
  growthPercentile?: Maybe<Scalars['float8']>;
  growthPrediction?: Maybe<Scalars['float8']>;
  growthPriority?: Maybe<Scalars['float8']>;
  growth_monthly?: Maybe<Scalars['float8']>;
  growth_score_adaptative?: Maybe<Scalars['float8']>;
  growth_score_dau?: Maybe<Scalars['numeric']>;
  growth_score_downloads?: Maybe<Scalars['numeric']>;
  growth_score_employee?: Maybe<Scalars['numeric']>;
  growth_score_g2?: Maybe<Scalars['numeric']>;
  growth_score_github?: Maybe<Scalars['numeric']>;
  growth_score_linkedin?: Maybe<Scalars['numeric']>;
  growth_score_producthunt?: Maybe<Scalars['numeric']>;
  growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  growth_score_twitter?: Maybe<Scalars['numeric']>;
  growth_score_web_visit?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  impact_score?: Maybe<Scalars['float8']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['numeric']>;
  percentile_impact_score?: Maybe<Scalars['numeric']>;
  percentile_us_presence_score?: Maybe<Scalars['numeric']>;
  priorityPercentile?: Maybe<Scalars['float8']>;
  priorityScore?: Maybe<Scalars['float8']>;
  three_months_growth_score_dau?: Maybe<Scalars['numeric']>;
  three_months_growth_score_downloads?: Maybe<Scalars['numeric']>;
  three_months_growth_score_employee?: Maybe<Scalars['numeric']>;
  three_months_growth_score_g2?: Maybe<Scalars['numeric']>;
  three_months_growth_score_github?: Maybe<Scalars['numeric']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['numeric']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['numeric']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['numeric']>;
  three_months_growth_score_twitter?: Maybe<Scalars['numeric']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['numeric']>;
  us_potential_score?: Maybe<Scalars['numeric']>;
  us_potential_score_percentile?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "scores" */
export type Scores_Sum_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Scores_Var_Pop_Fields = {
  __typename?: 'scores_var_pop_fields';
  USGroundPresenceScore?: Maybe<Scalars['Float']>;
  USpresenceScore?: Maybe<Scalars['Float']>;
  annual_growth_score_dau?: Maybe<Scalars['Float']>;
  annual_growth_score_downloads?: Maybe<Scalars['Float']>;
  annual_growth_score_employee?: Maybe<Scalars['Float']>;
  annual_growth_score_g2?: Maybe<Scalars['Float']>;
  annual_growth_score_github?: Maybe<Scalars['Float']>;
  annual_growth_score_linkedin?: Maybe<Scalars['Float']>;
  annual_growth_score_producthunt?: Maybe<Scalars['Float']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  annual_growth_score_twitter?: Maybe<Scalars['Float']>;
  annual_growth_score_web_visit?: Maybe<Scalars['Float']>;
  confidenceIndex?: Maybe<Scalars['Float']>;
  confidence_impact_score?: Maybe<Scalars['Float']>;
  confidence_priority_score?: Maybe<Scalars['Float']>;
  confidence_us_potential_score?: Maybe<Scalars['Float']>;
  confidence_us_presence_score?: Maybe<Scalars['Float']>;
  growthPercentile?: Maybe<Scalars['Float']>;
  growthPrediction?: Maybe<Scalars['Float']>;
  growthPriority?: Maybe<Scalars['Float']>;
  growth_monthly?: Maybe<Scalars['Float']>;
  growth_score_adaptative?: Maybe<Scalars['Float']>;
  growth_score_dau?: Maybe<Scalars['Float']>;
  growth_score_downloads?: Maybe<Scalars['Float']>;
  growth_score_employee?: Maybe<Scalars['Float']>;
  growth_score_g2?: Maybe<Scalars['Float']>;
  growth_score_github?: Maybe<Scalars['Float']>;
  growth_score_linkedin?: Maybe<Scalars['Float']>;
  growth_score_producthunt?: Maybe<Scalars['Float']>;
  growth_score_trustpilot?: Maybe<Scalars['Float']>;
  growth_score_twitter?: Maybe<Scalars['Float']>;
  growth_score_web_visit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact_score?: Maybe<Scalars['Float']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['Float']>;
  percentile_impact_score?: Maybe<Scalars['Float']>;
  percentile_us_presence_score?: Maybe<Scalars['Float']>;
  priorityPercentile?: Maybe<Scalars['Float']>;
  priorityScore?: Maybe<Scalars['Float']>;
  three_months_growth_score_dau?: Maybe<Scalars['Float']>;
  three_months_growth_score_downloads?: Maybe<Scalars['Float']>;
  three_months_growth_score_employee?: Maybe<Scalars['Float']>;
  three_months_growth_score_g2?: Maybe<Scalars['Float']>;
  three_months_growth_score_github?: Maybe<Scalars['Float']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['Float']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['Float']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  three_months_growth_score_twitter?: Maybe<Scalars['Float']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['Float']>;
  us_potential_score?: Maybe<Scalars['Float']>;
  us_potential_score_percentile?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "scores" */
export type Scores_Var_Pop_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scores_Var_Samp_Fields = {
  __typename?: 'scores_var_samp_fields';
  USGroundPresenceScore?: Maybe<Scalars['Float']>;
  USpresenceScore?: Maybe<Scalars['Float']>;
  annual_growth_score_dau?: Maybe<Scalars['Float']>;
  annual_growth_score_downloads?: Maybe<Scalars['Float']>;
  annual_growth_score_employee?: Maybe<Scalars['Float']>;
  annual_growth_score_g2?: Maybe<Scalars['Float']>;
  annual_growth_score_github?: Maybe<Scalars['Float']>;
  annual_growth_score_linkedin?: Maybe<Scalars['Float']>;
  annual_growth_score_producthunt?: Maybe<Scalars['Float']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  annual_growth_score_twitter?: Maybe<Scalars['Float']>;
  annual_growth_score_web_visit?: Maybe<Scalars['Float']>;
  confidenceIndex?: Maybe<Scalars['Float']>;
  confidence_impact_score?: Maybe<Scalars['Float']>;
  confidence_priority_score?: Maybe<Scalars['Float']>;
  confidence_us_potential_score?: Maybe<Scalars['Float']>;
  confidence_us_presence_score?: Maybe<Scalars['Float']>;
  growthPercentile?: Maybe<Scalars['Float']>;
  growthPrediction?: Maybe<Scalars['Float']>;
  growthPriority?: Maybe<Scalars['Float']>;
  growth_monthly?: Maybe<Scalars['Float']>;
  growth_score_adaptative?: Maybe<Scalars['Float']>;
  growth_score_dau?: Maybe<Scalars['Float']>;
  growth_score_downloads?: Maybe<Scalars['Float']>;
  growth_score_employee?: Maybe<Scalars['Float']>;
  growth_score_g2?: Maybe<Scalars['Float']>;
  growth_score_github?: Maybe<Scalars['Float']>;
  growth_score_linkedin?: Maybe<Scalars['Float']>;
  growth_score_producthunt?: Maybe<Scalars['Float']>;
  growth_score_trustpilot?: Maybe<Scalars['Float']>;
  growth_score_twitter?: Maybe<Scalars['Float']>;
  growth_score_web_visit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact_score?: Maybe<Scalars['Float']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['Float']>;
  percentile_impact_score?: Maybe<Scalars['Float']>;
  percentile_us_presence_score?: Maybe<Scalars['Float']>;
  priorityPercentile?: Maybe<Scalars['Float']>;
  priorityScore?: Maybe<Scalars['Float']>;
  three_months_growth_score_dau?: Maybe<Scalars['Float']>;
  three_months_growth_score_downloads?: Maybe<Scalars['Float']>;
  three_months_growth_score_employee?: Maybe<Scalars['Float']>;
  three_months_growth_score_g2?: Maybe<Scalars['Float']>;
  three_months_growth_score_github?: Maybe<Scalars['Float']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['Float']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['Float']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  three_months_growth_score_twitter?: Maybe<Scalars['Float']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['Float']>;
  us_potential_score?: Maybe<Scalars['Float']>;
  us_potential_score_percentile?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "scores" */
export type Scores_Var_Samp_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Scores_Variance_Fields = {
  __typename?: 'scores_variance_fields';
  USGroundPresenceScore?: Maybe<Scalars['Float']>;
  USpresenceScore?: Maybe<Scalars['Float']>;
  annual_growth_score_dau?: Maybe<Scalars['Float']>;
  annual_growth_score_downloads?: Maybe<Scalars['Float']>;
  annual_growth_score_employee?: Maybe<Scalars['Float']>;
  annual_growth_score_g2?: Maybe<Scalars['Float']>;
  annual_growth_score_github?: Maybe<Scalars['Float']>;
  annual_growth_score_linkedin?: Maybe<Scalars['Float']>;
  annual_growth_score_producthunt?: Maybe<Scalars['Float']>;
  annual_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  annual_growth_score_twitter?: Maybe<Scalars['Float']>;
  annual_growth_score_web_visit?: Maybe<Scalars['Float']>;
  confidenceIndex?: Maybe<Scalars['Float']>;
  confidence_impact_score?: Maybe<Scalars['Float']>;
  confidence_priority_score?: Maybe<Scalars['Float']>;
  confidence_us_potential_score?: Maybe<Scalars['Float']>;
  confidence_us_presence_score?: Maybe<Scalars['Float']>;
  growthPercentile?: Maybe<Scalars['Float']>;
  growthPrediction?: Maybe<Scalars['Float']>;
  growthPriority?: Maybe<Scalars['Float']>;
  growth_monthly?: Maybe<Scalars['Float']>;
  growth_score_adaptative?: Maybe<Scalars['Float']>;
  growth_score_dau?: Maybe<Scalars['Float']>;
  growth_score_downloads?: Maybe<Scalars['Float']>;
  growth_score_employee?: Maybe<Scalars['Float']>;
  growth_score_g2?: Maybe<Scalars['Float']>;
  growth_score_github?: Maybe<Scalars['Float']>;
  growth_score_linkedin?: Maybe<Scalars['Float']>;
  growth_score_producthunt?: Maybe<Scalars['Float']>;
  growth_score_trustpilot?: Maybe<Scalars['Float']>;
  growth_score_twitter?: Maybe<Scalars['Float']>;
  growth_score_web_visit?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  impact_score?: Maybe<Scalars['Float']>;
  percentile_growth_score_adaptative?: Maybe<Scalars['Float']>;
  percentile_impact_score?: Maybe<Scalars['Float']>;
  percentile_us_presence_score?: Maybe<Scalars['Float']>;
  priorityPercentile?: Maybe<Scalars['Float']>;
  priorityScore?: Maybe<Scalars['Float']>;
  three_months_growth_score_dau?: Maybe<Scalars['Float']>;
  three_months_growth_score_downloads?: Maybe<Scalars['Float']>;
  three_months_growth_score_employee?: Maybe<Scalars['Float']>;
  three_months_growth_score_g2?: Maybe<Scalars['Float']>;
  three_months_growth_score_github?: Maybe<Scalars['Float']>;
  three_months_growth_score_linkedin?: Maybe<Scalars['Float']>;
  three_months_growth_score_producthunt?: Maybe<Scalars['Float']>;
  three_months_growth_score_trustpilot?: Maybe<Scalars['Float']>;
  three_months_growth_score_twitter?: Maybe<Scalars['Float']>;
  three_months_growth_score_web_visit?: Maybe<Scalars['Float']>;
  us_potential_score?: Maybe<Scalars['Float']>;
  us_potential_score_percentile?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "scores" */
export type Scores_Variance_Order_By = {
  USGroundPresenceScore?: InputMaybe<Order_By>;
  USpresenceScore?: InputMaybe<Order_By>;
  annual_growth_score_dau?: InputMaybe<Order_By>;
  annual_growth_score_downloads?: InputMaybe<Order_By>;
  annual_growth_score_employee?: InputMaybe<Order_By>;
  annual_growth_score_g2?: InputMaybe<Order_By>;
  annual_growth_score_github?: InputMaybe<Order_By>;
  annual_growth_score_linkedin?: InputMaybe<Order_By>;
  annual_growth_score_producthunt?: InputMaybe<Order_By>;
  annual_growth_score_trustpilot?: InputMaybe<Order_By>;
  annual_growth_score_twitter?: InputMaybe<Order_By>;
  annual_growth_score_web_visit?: InputMaybe<Order_By>;
  confidenceIndex?: InputMaybe<Order_By>;
  confidence_impact_score?: InputMaybe<Order_By>;
  confidence_priority_score?: InputMaybe<Order_By>;
  confidence_us_potential_score?: InputMaybe<Order_By>;
  confidence_us_presence_score?: InputMaybe<Order_By>;
  growthPercentile?: InputMaybe<Order_By>;
  growthPrediction?: InputMaybe<Order_By>;
  growthPriority?: InputMaybe<Order_By>;
  growth_monthly?: InputMaybe<Order_By>;
  growth_score_adaptative?: InputMaybe<Order_By>;
  growth_score_dau?: InputMaybe<Order_By>;
  growth_score_downloads?: InputMaybe<Order_By>;
  growth_score_employee?: InputMaybe<Order_By>;
  growth_score_g2?: InputMaybe<Order_By>;
  growth_score_github?: InputMaybe<Order_By>;
  growth_score_linkedin?: InputMaybe<Order_By>;
  growth_score_producthunt?: InputMaybe<Order_By>;
  growth_score_trustpilot?: InputMaybe<Order_By>;
  growth_score_twitter?: InputMaybe<Order_By>;
  growth_score_web_visit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  impact_score?: InputMaybe<Order_By>;
  percentile_growth_score_adaptative?: InputMaybe<Order_By>;
  percentile_impact_score?: InputMaybe<Order_By>;
  percentile_us_presence_score?: InputMaybe<Order_By>;
  priorityPercentile?: InputMaybe<Order_By>;
  priorityScore?: InputMaybe<Order_By>;
  three_months_growth_score_dau?: InputMaybe<Order_By>;
  three_months_growth_score_downloads?: InputMaybe<Order_By>;
  three_months_growth_score_employee?: InputMaybe<Order_By>;
  three_months_growth_score_g2?: InputMaybe<Order_By>;
  three_months_growth_score_github?: InputMaybe<Order_By>;
  three_months_growth_score_linkedin?: InputMaybe<Order_By>;
  three_months_growth_score_producthunt?: InputMaybe<Order_By>;
  three_months_growth_score_trustpilot?: InputMaybe<Order_By>;
  three_months_growth_score_twitter?: InputMaybe<Order_By>;
  three_months_growth_score_web_visit?: InputMaybe<Order_By>;
  us_potential_score?: InputMaybe<Order_By>;
  us_potential_score_percentile?: InputMaybe<Order_By>;
};

export type Search_Startup_Name_Args = {
  search?: InputMaybe<Scalars['String']>;
};

export type Search_Startup_Name_In_Identity_Cards_Args = {
  search?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "similar_sites" */
export type Similar_Sites = {
  __typename?: 'similar_sites';
  rank?: Maybe<Scalars['Int']>;
  similar_site?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  startup: Startups;
  /** An object relationship */
  startupByStartupUuid2: Startups;
  startup_domain_1?: Maybe<Scalars['String']>;
  startup_domain_2?: Maybe<Scalars['String']>;
  startup_name_1?: Maybe<Scalars['String']>;
  startup_name_2?: Maybe<Scalars['String']>;
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
};

/** order by aggregate values of table "similar_sites" */
export type Similar_Sites_Aggregate_Order_By = {
  avg?: InputMaybe<Similar_Sites_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Similar_Sites_Max_Order_By>;
  min?: InputMaybe<Similar_Sites_Min_Order_By>;
  stddev?: InputMaybe<Similar_Sites_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Similar_Sites_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Similar_Sites_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Similar_Sites_Sum_Order_By>;
  var_pop?: InputMaybe<Similar_Sites_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Similar_Sites_Var_Samp_Order_By>;
  variance?: InputMaybe<Similar_Sites_Variance_Order_By>;
};

/** order by avg() on columns of table "similar_sites" */
export type Similar_Sites_Avg_Order_By = {
  rank?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "similar_sites". All fields are combined with a logical 'AND'. */
export type Similar_Sites_Bool_Exp = {
  _and?: InputMaybe<Array<Similar_Sites_Bool_Exp>>;
  _not?: InputMaybe<Similar_Sites_Bool_Exp>;
  _or?: InputMaybe<Array<Similar_Sites_Bool_Exp>>;
  rank?: InputMaybe<Int_Comparison_Exp>;
  similar_site?: InputMaybe<Boolean_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startupByStartupUuid2?: InputMaybe<Startups_Bool_Exp>;
  startup_domain_1?: InputMaybe<String_Comparison_Exp>;
  startup_domain_2?: InputMaybe<String_Comparison_Exp>;
  startup_name_1?: InputMaybe<String_Comparison_Exp>;
  startup_name_2?: InputMaybe<String_Comparison_Exp>;
  startup_uuid_1?: InputMaybe<Uuid_Comparison_Exp>;
  startup_uuid_2?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "similar_sites" */
export type Similar_Sites_Max_Order_By = {
  rank?: InputMaybe<Order_By>;
  startup_domain_1?: InputMaybe<Order_By>;
  startup_domain_2?: InputMaybe<Order_By>;
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "similar_sites" */
export type Similar_Sites_Min_Order_By = {
  rank?: InputMaybe<Order_By>;
  startup_domain_1?: InputMaybe<Order_By>;
  startup_domain_2?: InputMaybe<Order_By>;
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "similar_sites". */
export type Similar_Sites_Order_By = {
  rank?: InputMaybe<Order_By>;
  similar_site?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startupByStartupUuid2?: InputMaybe<Startups_Order_By>;
  startup_domain_1?: InputMaybe<Order_By>;
  startup_domain_2?: InputMaybe<Order_By>;
  startup_name_1?: InputMaybe<Order_By>;
  startup_name_2?: InputMaybe<Order_By>;
  startup_uuid_1?: InputMaybe<Order_By>;
  startup_uuid_2?: InputMaybe<Order_By>;
};

/** select columns of table "similar_sites" */
export enum Similar_Sites_Select_Column {
  /** column name */
  Rank = 'rank',
  /** column name */
  SimilarSite = 'similar_site',
  /** column name */
  StartupDomain_1 = 'startup_domain_1',
  /** column name */
  StartupDomain_2 = 'startup_domain_2',
  /** column name */
  StartupName_1 = 'startup_name_1',
  /** column name */
  StartupName_2 = 'startup_name_2',
  /** column name */
  StartupUuid_1 = 'startup_uuid_1',
  /** column name */
  StartupUuid_2 = 'startup_uuid_2',
}

/** order by stddev() on columns of table "similar_sites" */
export type Similar_Sites_Stddev_Order_By = {
  rank?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "similar_sites" */
export type Similar_Sites_Stddev_Pop_Order_By = {
  rank?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "similar_sites" */
export type Similar_Sites_Stddev_Samp_Order_By = {
  rank?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "similar_sites" */
export type Similar_Sites_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Similar_Sites_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Similar_Sites_Stream_Cursor_Value_Input = {
  rank?: InputMaybe<Scalars['Int']>;
  similar_site?: InputMaybe<Scalars['Boolean']>;
  startup_domain_1?: InputMaybe<Scalars['String']>;
  startup_domain_2?: InputMaybe<Scalars['String']>;
  startup_name_1?: InputMaybe<Scalars['String']>;
  startup_name_2?: InputMaybe<Scalars['String']>;
  startup_uuid_1?: InputMaybe<Scalars['uuid']>;
  startup_uuid_2?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "similar_sites" */
export type Similar_Sites_Sum_Order_By = {
  rank?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "similar_sites" */
export type Similar_Sites_Var_Pop_Order_By = {
  rank?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "similar_sites" */
export type Similar_Sites_Var_Samp_Order_By = {
  rank?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "similar_sites" */
export type Similar_Sites_Variance_Order_By = {
  rank?: InputMaybe<Order_By>;
};

/** columns and relationships of "sourcing" */
export type Sourcing = {
  __typename?: 'sourcing';
  airtableID?: Maybe<Scalars['String']>;
  lastStatus?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  sourcingOpportunityName: Scalars['String'];
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** Boolean expression to filter rows from the table "sourcing". All fields are combined with a logical 'AND'. */
export type Sourcing_Bool_Exp = {
  _and?: InputMaybe<Array<Sourcing_Bool_Exp>>;
  _not?: InputMaybe<Sourcing_Bool_Exp>;
  _or?: InputMaybe<Array<Sourcing_Bool_Exp>>;
  airtableID?: InputMaybe<String_Comparison_Exp>;
  lastStatus?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<String_Comparison_Exp>;
  sourcingOpportunityName?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "sourcing". */
export type Sourcing_Order_By = {
  airtableID?: InputMaybe<Order_By>;
  lastStatus?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  owner?: InputMaybe<Order_By>;
  sourcingOpportunityName?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "sourcing" */
export enum Sourcing_Select_Column {
  /** column name */
  AirtableId = 'airtableID',
  /** column name */
  LastStatus = 'lastStatus',
  /** column name */
  Notes = 'notes',
  /** column name */
  Owner = 'owner',
  /** column name */
  SourcingOpportunityName = 'sourcingOpportunityName',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** columns and relationships of "sourcing_status" */
export type Sourcing_Status = {
  __typename?: 'sourcing_status';
  date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  name_id: Scalars['String'];
  /** An object relationship */
  startup: Startups;
  startup_uuid: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "sourcing_status" */
export type Sourcing_Status_Aggregate_Order_By = {
  avg?: InputMaybe<Sourcing_Status_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Sourcing_Status_Max_Order_By>;
  min?: InputMaybe<Sourcing_Status_Min_Order_By>;
  stddev?: InputMaybe<Sourcing_Status_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Sourcing_Status_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Sourcing_Status_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Sourcing_Status_Sum_Order_By>;
  var_pop?: InputMaybe<Sourcing_Status_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Sourcing_Status_Var_Samp_Order_By>;
  variance?: InputMaybe<Sourcing_Status_Variance_Order_By>;
};

/** order by avg() on columns of table "sourcing_status" */
export type Sourcing_Status_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "sourcing_status". All fields are combined with a logical 'AND'. */
export type Sourcing_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Sourcing_Status_Bool_Exp>>;
  _not?: InputMaybe<Sourcing_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Sourcing_Status_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name_id?: InputMaybe<String_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "sourcing_status" */
export type Sourcing_Status_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_id?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "sourcing_status" */
export type Sourcing_Status_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_id?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "sourcing_status". */
export type Sourcing_Status_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name_id?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** select columns of table "sourcing_status" */
export enum Sourcing_Status_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  NameId = 'name_id',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  Status = 'status',
}

/** order by stddev() on columns of table "sourcing_status" */
export type Sourcing_Status_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "sourcing_status" */
export type Sourcing_Status_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "sourcing_status" */
export type Sourcing_Status_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sourcing_status" */
export type Sourcing_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sourcing_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sourcing_Status_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  name_id?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "sourcing_status" */
export type Sourcing_Status_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "sourcing_status" */
export type Sourcing_Status_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "sourcing_status" */
export type Sourcing_Status_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "sourcing_status" */
export type Sourcing_Status_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "sourcing" */
export type Sourcing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Sourcing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sourcing_Stream_Cursor_Value_Input = {
  airtableID?: InputMaybe<Scalars['String']>;
  lastStatus?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  sourcingOpportunityName?: InputMaybe<Scalars['String']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "startups" */
export type Startups = {
  __typename?: 'startups';
  In_portfolio?: Maybe<Scalars['Int']>;
  added_manually?: Maybe<Scalars['Boolean']>;
  airtableID?: Maybe<Scalars['String']>;
  attio_list_entry_id?: Maybe<Scalars['String']>;
  attio_record_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  bcorp_startups: Array<Bcorp_Startups>;
  closedDate?: Maybe<Scalars['date']>;
  /** An object relationship */
  cofounder?: Maybe<Cofounders>;
  /** An array relationship */
  competitionClassificationsByStartupUuid2: Array<Competition_Classification>;
  /** An array relationship */
  competition_classifications: Array<Competition_Classification>;
  contactEmail?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  crunchbaseRank?: Maybe<Scalars['Int']>;
  crunchbase_link?: Maybe<Scalars['String']>;
  /** An object relationship */
  data_sources_last_update?: Maybe<Data_Sources_Last_Update>;
  date_of_last_update?: Maybe<Scalars['timestamptz']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Int']>;
  /** An array relationship */
  dealflows: Array<Dealflow>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  employee_country_repartitions: Array<Employee_Country_Repartition>;
  /** An array relationship */
  employee_post_repartitions: Array<Employee_Post_Repartition>;
  /** An array relationship */
  employees: Array<Employees>;
  estimatedRevenueRange?: Maybe<Scalars['String']>;
  /** An array relationship */
  events_news: Array<Events_News>;
  facebook_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  follow_relation_users_startups: Array<Follow_Relation_Users_Startups>;
  foundedDate?: Maybe<Scalars['String']>;
  /** An array relationship */
  founders: Array<Founders>;
  fundingStatus?: Maybe<Scalars['String']>;
  /** An array relationship */
  g2ProductCompetitorsByStartupUuid: Array<G2_Product_Competitors>;
  /** An array relationship */
  g2_historical_data: Array<G2_Historical_Data>;
  /** An array relationship */
  g2_industries: Array<G2_Industry>;
  g2_link?: Maybe<Scalars['String']>;
  /** An array relationship */
  g2_product_categories: Array<G2_Product_Category>;
  /** An array relationship */
  g2_product_competitors: Array<G2_Product_Competitors>;
  /** An array relationship */
  g2_product_reviews: Array<G2_Product_Reviews>;
  glassdoor?: Maybe<Scalars['String']>;
  /** An array relationship */
  glassdoor_data: Array<Glassdoor_Data>;
  /** An array relationship */
  glassdoor_trends: Array<Glassdoor_Trend>;
  /** An array relationship */
  googleSerpsByStartupUuid2: Array<Google_Serp>;
  googleSheetLink?: Maybe<Scalars['String']>;
  googleTrendsIntelsWereUpdated?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  google_serps: Array<Google_Serp>;
  /** An array relationship */
  growths: Array<Growth>;
  headquartersCountry?: Maybe<Scalars['String']>;
  hubTag?: Maybe<Scalars['String']>;
  /** An object relationship */
  identity_card?: Maybe<Identity_Cards>;
  inception?: Maybe<Scalars['date']>;
  industries?: Maybe<Scalars['String']>;
  /** An array relationship */
  investor_startup_relations: Array<Investor_Startup_Relation>;
  investors_fetched: Scalars['Boolean'];
  investors_names?: Maybe<Scalars['String']>;
  invyo_id?: Maybe<Scalars['Int']>;
  isClientUpdated: Scalars['Boolean'];
  isGNewsUpdated?: Maybe<Scalars['Boolean']>;
  isGlassdoorUpdated?: Maybe<Scalars['Boolean']>;
  isIndustryUpdated: Scalars['Boolean'];
  isLinkedinUpdated?: Maybe<Scalars['Boolean']>;
  isPatentUpdated?: Maybe<Scalars['Boolean']>;
  isProductUpdated: Scalars['Boolean'];
  is_fitting_sourcing_criterias?: Maybe<Scalars['Boolean']>;
  is_fitting_specter_criterias?: Maybe<Scalars['Boolean']>;
  is_led_by_europeans?: Maybe<Scalars['String']>;
  lastFundingAmountInUSD?: Maybe<Scalars['bigint']>;
  lastFundingDate?: Maybe<Scalars['date']>;
  lastFundingType?: Maybe<Scalars['String']>;
  /** An array relationship */
  last_search_relation_users_startups: Array<Last_Search_Relation_Users_Startups>;
  link?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  /** An array relationship */
  linkedinEmployeesByStartupUuid: Array<Linkedin_Employees>;
  /** An array relationship */
  linkedinFollowersByStartupUuid: Array<Linkedin_Followers>;
  /** An array relationship */
  linkedin_employee_avg_tenures: Array<Linkedin_Employee_Avg_Tenure>;
  linkedin_employees?: Maybe<Scalars['Int']>;
  linkedin_followers?: Maybe<Scalars['Int']>;
  /** An array relationship */
  linkedin_headquarters: Array<Linkedin_Headquarters>;
  linkedin_industry?: Maybe<Scalars['String']>;
  /** An array relationship */
  linkedin_publications: Array<Linkedin_Publications>;
  /** An array relationship */
  linkedin_school_repartitions: Array<Linkedin_School_Repartition>;
  /** An array relationship */
  linkedin_skill_repartitions: Array<Linkedin_Skill_Repartition>;
  logo_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  mobile_apps: Array<Mobile_App>;
  /** An aggregate relationship */
  mobile_apps_aggregate: Mobile_App_Aggregate;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  num: Scalars['Int'];
  numberOfAcquisitions?: Maybe<Scalars['Int']>;
  numberOfArticles?: Maybe<Scalars['Int']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfEventAppearances?: Maybe<Scalars['Int']>;
  numberOfFounders?: Maybe<Scalars['Int']>;
  numberOfFundingRounds?: Maybe<Scalars['Int']>;
  number_us_investors?: Maybe<Scalars['Int']>;
  number_us_related_investors?: Maybe<Scalars['Int']>;
  operatingStatus?: Maybe<Scalars['String']>;
  /** An array relationship */
  predictleads_job_openings: Array<Predictleads_Job_Openings>;
  raisemorethan100?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  scores: Array<Scores>;
  /** An aggregate relationship */
  scores_aggregate: Scores_Aggregate;
  shortDescription?: Maybe<Scalars['String']>;
  /** An array relationship */
  similarSitesByStartupUuid2: Array<Similar_Sites>;
  /** An array relationship */
  similar_sites: Array<Similar_Sites>;
  /** An object relationship */
  sourcing?: Maybe<Sourcing>;
  /** An array relationship */
  sourcing_statuses: Array<Sourcing_Status>;
  startupID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['float8']>;
  totalFundingAmountInUSD?: Maybe<Scalars['bigint']>;
  trendScore7Days?: Maybe<Scalars['float8']>;
  trendScore30Days?: Maybe<Scalars['float8']>;
  trendScore90Days?: Maybe<Scalars['float8']>;
  twitter?: Maybe<Scalars['String']>;
  /** An array relationship */
  twitter_followers: Array<Twitter_Followers>;
  uuid: Scalars['uuid'];
  /** An array relationship */
  web_visits_country_repartitions: Array<Web_Visits_Country_Repartition>;
  /** An array relationship */
  web_visits_general_data: Array<Web_Visits_General_Data>;
  /** An array relationship */
  web_visits_historical_data: Array<Web_Visits_Historical_Data>;
  /** An aggregate relationship */
  web_visits_historical_data_aggregate: Web_Visits_Historical_Data_Aggregate;
};

/** columns and relationships of "startups" */
export type StartupsBcorp_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Bcorp_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bcorp_Startups_Order_By>>;
  where?: InputMaybe<Bcorp_Startups_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsCompetitionClassificationsByStartupUuid2Args = {
  distinct_on?: InputMaybe<Array<Competition_Classification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Competition_Classification_Order_By>>;
  where?: InputMaybe<Competition_Classification_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsCompetition_ClassificationsArgs = {
  distinct_on?: InputMaybe<Array<Competition_Classification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Competition_Classification_Order_By>>;
  where?: InputMaybe<Competition_Classification_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsDealflowsArgs = {
  distinct_on?: InputMaybe<Array<Dealflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dealflow_Order_By>>;
  where?: InputMaybe<Dealflow_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsEmployee_Country_RepartitionsArgs = {
  distinct_on?: InputMaybe<Array<Employee_Country_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Country_Repartition_Order_By>>;
  where?: InputMaybe<Employee_Country_Repartition_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsEmployee_Post_RepartitionsArgs = {
  distinct_on?: InputMaybe<Array<Employee_Post_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Post_Repartition_Order_By>>;
  where?: InputMaybe<Employee_Post_Repartition_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsEmployeesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsEvents_NewsArgs = {
  distinct_on?: InputMaybe<Array<Events_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_News_Order_By>>;
  where?: InputMaybe<Events_News_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsFollow_Relation_Users_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Follow_Relation_Users_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Follow_Relation_Users_Startups_Order_By>>;
  where?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsFoundersArgs = {
  distinct_on?: InputMaybe<Array<Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Founders_Order_By>>;
  where?: InputMaybe<Founders_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsG2ProductCompetitorsByStartupUuidArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Competitors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Competitors_Order_By>>;
  where?: InputMaybe<G2_Product_Competitors_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsG2_Historical_DataArgs = {
  distinct_on?: InputMaybe<Array<G2_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Historical_Data_Order_By>>;
  where?: InputMaybe<G2_Historical_Data_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsG2_IndustriesArgs = {
  distinct_on?: InputMaybe<Array<G2_Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Industry_Order_By>>;
  where?: InputMaybe<G2_Industry_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsG2_Product_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Category_Order_By>>;
  where?: InputMaybe<G2_Product_Category_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsG2_Product_CompetitorsArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Competitors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Competitors_Order_By>>;
  where?: InputMaybe<G2_Product_Competitors_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsG2_Product_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Reviews_Order_By>>;
  where?: InputMaybe<G2_Product_Reviews_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsGlassdoor_DataArgs = {
  distinct_on?: InputMaybe<Array<Glassdoor_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Glassdoor_Data_Order_By>>;
  where?: InputMaybe<Glassdoor_Data_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsGlassdoor_TrendsArgs = {
  distinct_on?: InputMaybe<Array<Glassdoor_Trend_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Glassdoor_Trend_Order_By>>;
  where?: InputMaybe<Glassdoor_Trend_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsGoogleSerpsByStartupUuid2Args = {
  distinct_on?: InputMaybe<Array<Google_Serp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Serp_Order_By>>;
  where?: InputMaybe<Google_Serp_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsGoogle_SerpsArgs = {
  distinct_on?: InputMaybe<Array<Google_Serp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Serp_Order_By>>;
  where?: InputMaybe<Google_Serp_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsGrowthsArgs = {
  distinct_on?: InputMaybe<Array<Growth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Growth_Order_By>>;
  where?: InputMaybe<Growth_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsInvestor_Startup_RelationsArgs = {
  distinct_on?: InputMaybe<Array<Investor_Startup_Relation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Startup_Relation_Order_By>>;
  where?: InputMaybe<Investor_Startup_Relation_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsLast_Search_Relation_Users_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Order_By>>;
  where?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsLinkedinEmployeesByStartupUuidArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Employees_Order_By>>;
  where?: InputMaybe<Linkedin_Employees_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsLinkedinFollowersByStartupUuidArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Followers_Order_By>>;
  where?: InputMaybe<Linkedin_Followers_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsLinkedin_Employee_Avg_TenuresArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Employee_Avg_Tenure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Employee_Avg_Tenure_Order_By>>;
  where?: InputMaybe<Linkedin_Employee_Avg_Tenure_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsLinkedin_HeadquartersArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Headquarters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Headquarters_Order_By>>;
  where?: InputMaybe<Linkedin_Headquarters_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsLinkedin_PublicationsArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Publications_Order_By>>;
  where?: InputMaybe<Linkedin_Publications_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsLinkedin_School_RepartitionsArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_School_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_School_Repartition_Order_By>>;
  where?: InputMaybe<Linkedin_School_Repartition_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsLinkedin_Skill_RepartitionsArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Skill_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Skill_Repartition_Order_By>>;
  where?: InputMaybe<Linkedin_Skill_Repartition_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsMobile_AppsArgs = {
  distinct_on?: InputMaybe<Array<Mobile_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobile_App_Order_By>>;
  where?: InputMaybe<Mobile_App_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsMobile_Apps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobile_App_Order_By>>;
  where?: InputMaybe<Mobile_App_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsPredictleads_Job_OpeningsArgs = {
  distinct_on?: InputMaybe<Array<Predictleads_Job_Openings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Predictleads_Job_Openings_Order_By>>;
  where?: InputMaybe<Predictleads_Job_Openings_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsScoresArgs = {
  distinct_on?: InputMaybe<Array<Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scores_Order_By>>;
  where?: InputMaybe<Scores_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsScores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scores_Order_By>>;
  where?: InputMaybe<Scores_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsSimilarSitesByStartupUuid2Args = {
  distinct_on?: InputMaybe<Array<Similar_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Similar_Sites_Order_By>>;
  where?: InputMaybe<Similar_Sites_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsSimilar_SitesArgs = {
  distinct_on?: InputMaybe<Array<Similar_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Similar_Sites_Order_By>>;
  where?: InputMaybe<Similar_Sites_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsSourcing_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Sourcing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sourcing_Status_Order_By>>;
  where?: InputMaybe<Sourcing_Status_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsTwitter_FollowersArgs = {
  distinct_on?: InputMaybe<Array<Twitter_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Twitter_Followers_Order_By>>;
  where?: InputMaybe<Twitter_Followers_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsWeb_Visits_Country_RepartitionsArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Country_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Country_Repartition_Order_By>>;
  where?: InputMaybe<Web_Visits_Country_Repartition_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsWeb_Visits_General_DataArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_General_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_General_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_General_Data_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsWeb_Visits_Historical_DataArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Historical_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
};

/** columns and relationships of "startups" */
export type StartupsWeb_Visits_Historical_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Historical_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
};

export type Startups_Aggregate = {
  __typename?: 'startups_aggregate';
  aggregate?: Maybe<Startups_Aggregate_Fields>;
  nodes: Array<Startups>;
};

/** aggregate fields of "startups" */
export type Startups_Aggregate_Fields = {
  __typename?: 'startups_aggregate_fields';
  avg?: Maybe<Startups_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Startups_Max_Fields>;
  min?: Maybe<Startups_Min_Fields>;
  stddev?: Maybe<Startups_Stddev_Fields>;
  stddev_pop?: Maybe<Startups_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Startups_Stddev_Samp_Fields>;
  sum?: Maybe<Startups_Sum_Fields>;
  var_pop?: Maybe<Startups_Var_Pop_Fields>;
  var_samp?: Maybe<Startups_Var_Samp_Fields>;
  variance?: Maybe<Startups_Variance_Fields>;
};

/** aggregate fields of "startups" */
export type Startups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Startups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Startups_Avg_Fields = {
  __typename?: 'startups_avg_fields';
  In_portfolio?: Maybe<Scalars['Float']>;
  crunchbaseRank?: Maybe<Scalars['Float']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Float']>;
  invyo_id?: Maybe<Scalars['Float']>;
  lastFundingAmountInUSD?: Maybe<Scalars['Float']>;
  linkedin_employees?: Maybe<Scalars['Float']>;
  linkedin_followers?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  numberOfAcquisitions?: Maybe<Scalars['Float']>;
  numberOfArticles?: Maybe<Scalars['Float']>;
  numberOfEmployees?: Maybe<Scalars['Float']>;
  numberOfEventAppearances?: Maybe<Scalars['Float']>;
  numberOfFounders?: Maybe<Scalars['Float']>;
  numberOfFundingRounds?: Maybe<Scalars['Float']>;
  number_us_investors?: Maybe<Scalars['Float']>;
  number_us_related_investors?: Maybe<Scalars['Float']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['Float']>;
  totalFundingAmountInUSD?: Maybe<Scalars['Float']>;
  trendScore7Days?: Maybe<Scalars['Float']>;
  trendScore30Days?: Maybe<Scalars['Float']>;
  trendScore90Days?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "startups". All fields are combined with a logical 'AND'. */
export type Startups_Bool_Exp = {
  In_portfolio?: InputMaybe<Int_Comparison_Exp>;
  _and?: InputMaybe<Array<Startups_Bool_Exp>>;
  _not?: InputMaybe<Startups_Bool_Exp>;
  _or?: InputMaybe<Array<Startups_Bool_Exp>>;
  added_manually?: InputMaybe<Boolean_Comparison_Exp>;
  airtableID?: InputMaybe<String_Comparison_Exp>;
  attio_list_entry_id?: InputMaybe<String_Comparison_Exp>;
  attio_record_id?: InputMaybe<String_Comparison_Exp>;
  bcorp_startups?: InputMaybe<Bcorp_Startups_Bool_Exp>;
  closedDate?: InputMaybe<Date_Comparison_Exp>;
  cofounder?: InputMaybe<Cofounders_Bool_Exp>;
  competitionClassificationsByStartupUuid2?: InputMaybe<Competition_Classification_Bool_Exp>;
  competition_classifications?: InputMaybe<Competition_Classification_Bool_Exp>;
  contactEmail?: InputMaybe<String_Comparison_Exp>;
  countryOfOrigin?: InputMaybe<String_Comparison_Exp>;
  crunchbaseRank?: InputMaybe<Int_Comparison_Exp>;
  crunchbase_link?: InputMaybe<String_Comparison_Exp>;
  data_sources_last_update?: InputMaybe<Data_Sources_Last_Update_Bool_Exp>;
  date_of_last_update?: InputMaybe<Timestamptz_Comparison_Exp>;
  daysSinceGoogleTrendsIntelsWereUpdated?: InputMaybe<Int_Comparison_Exp>;
  dealflows?: InputMaybe<Dealflow_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  employee_country_repartitions?: InputMaybe<Employee_Country_Repartition_Bool_Exp>;
  employee_post_repartitions?: InputMaybe<Employee_Post_Repartition_Bool_Exp>;
  employees?: InputMaybe<Employees_Bool_Exp>;
  estimatedRevenueRange?: InputMaybe<String_Comparison_Exp>;
  events_news?: InputMaybe<Events_News_Bool_Exp>;
  facebook_url?: InputMaybe<String_Comparison_Exp>;
  follow_relation_users_startups?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
  foundedDate?: InputMaybe<String_Comparison_Exp>;
  founders?: InputMaybe<Founders_Bool_Exp>;
  fundingStatus?: InputMaybe<String_Comparison_Exp>;
  g2ProductCompetitorsByStartupUuid?: InputMaybe<G2_Product_Competitors_Bool_Exp>;
  g2_historical_data?: InputMaybe<G2_Historical_Data_Bool_Exp>;
  g2_industries?: InputMaybe<G2_Industry_Bool_Exp>;
  g2_link?: InputMaybe<String_Comparison_Exp>;
  g2_product_categories?: InputMaybe<G2_Product_Category_Bool_Exp>;
  g2_product_competitors?: InputMaybe<G2_Product_Competitors_Bool_Exp>;
  g2_product_reviews?: InputMaybe<G2_Product_Reviews_Bool_Exp>;
  glassdoor?: InputMaybe<String_Comparison_Exp>;
  glassdoor_data?: InputMaybe<Glassdoor_Data_Bool_Exp>;
  glassdoor_trends?: InputMaybe<Glassdoor_Trend_Bool_Exp>;
  googleSerpsByStartupUuid2?: InputMaybe<Google_Serp_Bool_Exp>;
  googleSheetLink?: InputMaybe<String_Comparison_Exp>;
  googleTrendsIntelsWereUpdated?: InputMaybe<Boolean_Comparison_Exp>;
  google_serps?: InputMaybe<Google_Serp_Bool_Exp>;
  growths?: InputMaybe<Growth_Bool_Exp>;
  headquartersCountry?: InputMaybe<String_Comparison_Exp>;
  hubTag?: InputMaybe<String_Comparison_Exp>;
  identity_card?: InputMaybe<Identity_Cards_Bool_Exp>;
  inception?: InputMaybe<Date_Comparison_Exp>;
  industries?: InputMaybe<String_Comparison_Exp>;
  investor_startup_relations?: InputMaybe<Investor_Startup_Relation_Bool_Exp>;
  investors_fetched?: InputMaybe<Boolean_Comparison_Exp>;
  investors_names?: InputMaybe<String_Comparison_Exp>;
  invyo_id?: InputMaybe<Int_Comparison_Exp>;
  isClientUpdated?: InputMaybe<Boolean_Comparison_Exp>;
  isGNewsUpdated?: InputMaybe<Boolean_Comparison_Exp>;
  isGlassdoorUpdated?: InputMaybe<Boolean_Comparison_Exp>;
  isIndustryUpdated?: InputMaybe<Boolean_Comparison_Exp>;
  isLinkedinUpdated?: InputMaybe<Boolean_Comparison_Exp>;
  isPatentUpdated?: InputMaybe<Boolean_Comparison_Exp>;
  isProductUpdated?: InputMaybe<Boolean_Comparison_Exp>;
  is_fitting_sourcing_criterias?: InputMaybe<Boolean_Comparison_Exp>;
  is_fitting_specter_criterias?: InputMaybe<Boolean_Comparison_Exp>;
  is_led_by_europeans?: InputMaybe<String_Comparison_Exp>;
  lastFundingAmountInUSD?: InputMaybe<Bigint_Comparison_Exp>;
  lastFundingDate?: InputMaybe<Date_Comparison_Exp>;
  lastFundingType?: InputMaybe<String_Comparison_Exp>;
  last_search_relation_users_startups?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
  link?: InputMaybe<String_Comparison_Exp>;
  linkedin?: InputMaybe<String_Comparison_Exp>;
  linkedinEmployeesByStartupUuid?: InputMaybe<Linkedin_Employees_Bool_Exp>;
  linkedinFollowersByStartupUuid?: InputMaybe<Linkedin_Followers_Bool_Exp>;
  linkedin_employee_avg_tenures?: InputMaybe<Linkedin_Employee_Avg_Tenure_Bool_Exp>;
  linkedin_employees?: InputMaybe<Int_Comparison_Exp>;
  linkedin_followers?: InputMaybe<Int_Comparison_Exp>;
  linkedin_headquarters?: InputMaybe<Linkedin_Headquarters_Bool_Exp>;
  linkedin_industry?: InputMaybe<String_Comparison_Exp>;
  linkedin_publications?: InputMaybe<Linkedin_Publications_Bool_Exp>;
  linkedin_school_repartitions?: InputMaybe<Linkedin_School_Repartition_Bool_Exp>;
  linkedin_skill_repartitions?: InputMaybe<Linkedin_Skill_Repartition_Bool_Exp>;
  logo_url?: InputMaybe<String_Comparison_Exp>;
  mobile_apps?: InputMaybe<Mobile_App_Bool_Exp>;
  mobile_apps_aggregate?: InputMaybe<Mobile_App_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  num?: InputMaybe<Int_Comparison_Exp>;
  numberOfAcquisitions?: InputMaybe<Int_Comparison_Exp>;
  numberOfArticles?: InputMaybe<Int_Comparison_Exp>;
  numberOfEmployees?: InputMaybe<Int_Comparison_Exp>;
  numberOfEventAppearances?: InputMaybe<Int_Comparison_Exp>;
  numberOfFounders?: InputMaybe<Int_Comparison_Exp>;
  numberOfFundingRounds?: InputMaybe<Int_Comparison_Exp>;
  number_us_investors?: InputMaybe<Int_Comparison_Exp>;
  number_us_related_investors?: InputMaybe<Int_Comparison_Exp>;
  operatingStatus?: InputMaybe<String_Comparison_Exp>;
  predictleads_job_openings?: InputMaybe<Predictleads_Job_Openings_Bool_Exp>;
  raisemorethan100?: InputMaybe<Boolean_Comparison_Exp>;
  scores?: InputMaybe<Scores_Bool_Exp>;
  scores_aggregate?: InputMaybe<Scores_Aggregate_Bool_Exp>;
  shortDescription?: InputMaybe<String_Comparison_Exp>;
  similarSitesByStartupUuid2?: InputMaybe<Similar_Sites_Bool_Exp>;
  similar_sites?: InputMaybe<Similar_Sites_Bool_Exp>;
  sourcing?: InputMaybe<Sourcing_Bool_Exp>;
  sourcing_statuses?: InputMaybe<Sourcing_Status_Bool_Exp>;
  startupID?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  test?: InputMaybe<Float8_Comparison_Exp>;
  totalFundingAmountInUSD?: InputMaybe<Bigint_Comparison_Exp>;
  trendScore7Days?: InputMaybe<Float8_Comparison_Exp>;
  trendScore30Days?: InputMaybe<Float8_Comparison_Exp>;
  trendScore90Days?: InputMaybe<Float8_Comparison_Exp>;
  twitter?: InputMaybe<String_Comparison_Exp>;
  twitter_followers?: InputMaybe<Twitter_Followers_Bool_Exp>;
  uuid?: InputMaybe<Uuid_Comparison_Exp>;
  web_visits_country_repartitions?: InputMaybe<Web_Visits_Country_Repartition_Bool_Exp>;
  web_visits_general_data?: InputMaybe<Web_Visits_General_Data_Bool_Exp>;
  web_visits_historical_data?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
  web_visits_historical_data_aggregate?: InputMaybe<Web_Visits_Historical_Data_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Startups_Max_Fields = {
  __typename?: 'startups_max_fields';
  In_portfolio?: Maybe<Scalars['Int']>;
  airtableID?: Maybe<Scalars['String']>;
  attio_list_entry_id?: Maybe<Scalars['String']>;
  attio_record_id?: Maybe<Scalars['String']>;
  closedDate?: Maybe<Scalars['date']>;
  contactEmail?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  crunchbaseRank?: Maybe<Scalars['Int']>;
  crunchbase_link?: Maybe<Scalars['String']>;
  date_of_last_update?: Maybe<Scalars['timestamptz']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  estimatedRevenueRange?: Maybe<Scalars['String']>;
  facebook_url?: Maybe<Scalars['String']>;
  foundedDate?: Maybe<Scalars['String']>;
  fundingStatus?: Maybe<Scalars['String']>;
  g2_link?: Maybe<Scalars['String']>;
  glassdoor?: Maybe<Scalars['String']>;
  googleSheetLink?: Maybe<Scalars['String']>;
  headquartersCountry?: Maybe<Scalars['String']>;
  hubTag?: Maybe<Scalars['String']>;
  inception?: Maybe<Scalars['date']>;
  industries?: Maybe<Scalars['String']>;
  investors_names?: Maybe<Scalars['String']>;
  invyo_id?: Maybe<Scalars['Int']>;
  is_led_by_europeans?: Maybe<Scalars['String']>;
  lastFundingAmountInUSD?: Maybe<Scalars['bigint']>;
  lastFundingDate?: Maybe<Scalars['date']>;
  lastFundingType?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  linkedin_employees?: Maybe<Scalars['Int']>;
  linkedin_followers?: Maybe<Scalars['Int']>;
  linkedin_industry?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['Int']>;
  numberOfAcquisitions?: Maybe<Scalars['Int']>;
  numberOfArticles?: Maybe<Scalars['Int']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfEventAppearances?: Maybe<Scalars['Int']>;
  numberOfFounders?: Maybe<Scalars['Int']>;
  numberOfFundingRounds?: Maybe<Scalars['Int']>;
  number_us_investors?: Maybe<Scalars['Int']>;
  number_us_related_investors?: Maybe<Scalars['Int']>;
  operatingStatus?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  startupID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['float8']>;
  totalFundingAmountInUSD?: Maybe<Scalars['bigint']>;
  trendScore7Days?: Maybe<Scalars['float8']>;
  trendScore30Days?: Maybe<Scalars['float8']>;
  trendScore90Days?: Maybe<Scalars['float8']>;
  twitter?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Startups_Min_Fields = {
  __typename?: 'startups_min_fields';
  In_portfolio?: Maybe<Scalars['Int']>;
  airtableID?: Maybe<Scalars['String']>;
  attio_list_entry_id?: Maybe<Scalars['String']>;
  attio_record_id?: Maybe<Scalars['String']>;
  closedDate?: Maybe<Scalars['date']>;
  contactEmail?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  crunchbaseRank?: Maybe<Scalars['Int']>;
  crunchbase_link?: Maybe<Scalars['String']>;
  date_of_last_update?: Maybe<Scalars['timestamptz']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  estimatedRevenueRange?: Maybe<Scalars['String']>;
  facebook_url?: Maybe<Scalars['String']>;
  foundedDate?: Maybe<Scalars['String']>;
  fundingStatus?: Maybe<Scalars['String']>;
  g2_link?: Maybe<Scalars['String']>;
  glassdoor?: Maybe<Scalars['String']>;
  googleSheetLink?: Maybe<Scalars['String']>;
  headquartersCountry?: Maybe<Scalars['String']>;
  hubTag?: Maybe<Scalars['String']>;
  inception?: Maybe<Scalars['date']>;
  industries?: Maybe<Scalars['String']>;
  investors_names?: Maybe<Scalars['String']>;
  invyo_id?: Maybe<Scalars['Int']>;
  is_led_by_europeans?: Maybe<Scalars['String']>;
  lastFundingAmountInUSD?: Maybe<Scalars['bigint']>;
  lastFundingDate?: Maybe<Scalars['date']>;
  lastFundingType?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  linkedin_employees?: Maybe<Scalars['Int']>;
  linkedin_followers?: Maybe<Scalars['Int']>;
  linkedin_industry?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['Int']>;
  numberOfAcquisitions?: Maybe<Scalars['Int']>;
  numberOfArticles?: Maybe<Scalars['Int']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfEventAppearances?: Maybe<Scalars['Int']>;
  numberOfFounders?: Maybe<Scalars['Int']>;
  numberOfFundingRounds?: Maybe<Scalars['Int']>;
  number_us_investors?: Maybe<Scalars['Int']>;
  number_us_related_investors?: Maybe<Scalars['Int']>;
  operatingStatus?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  startupID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['float8']>;
  totalFundingAmountInUSD?: Maybe<Scalars['bigint']>;
  trendScore7Days?: Maybe<Scalars['float8']>;
  trendScore30Days?: Maybe<Scalars['float8']>;
  trendScore90Days?: Maybe<Scalars['float8']>;
  twitter?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "startups". */
export type Startups_Order_By = {
  In_portfolio?: InputMaybe<Order_By>;
  added_manually?: InputMaybe<Order_By>;
  airtableID?: InputMaybe<Order_By>;
  attio_list_entry_id?: InputMaybe<Order_By>;
  attio_record_id?: InputMaybe<Order_By>;
  bcorp_startups_aggregate?: InputMaybe<Bcorp_Startups_Aggregate_Order_By>;
  closedDate?: InputMaybe<Order_By>;
  cofounder?: InputMaybe<Cofounders_Order_By>;
  competitionClassificationsByStartupUuid2_aggregate?: InputMaybe<Competition_Classification_Aggregate_Order_By>;
  competition_classifications_aggregate?: InputMaybe<Competition_Classification_Aggregate_Order_By>;
  contactEmail?: InputMaybe<Order_By>;
  countryOfOrigin?: InputMaybe<Order_By>;
  crunchbaseRank?: InputMaybe<Order_By>;
  crunchbase_link?: InputMaybe<Order_By>;
  data_sources_last_update?: InputMaybe<Data_Sources_Last_Update_Order_By>;
  date_of_last_update?: InputMaybe<Order_By>;
  daysSinceGoogleTrendsIntelsWereUpdated?: InputMaybe<Order_By>;
  dealflows_aggregate?: InputMaybe<Dealflow_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  employee_country_repartitions_aggregate?: InputMaybe<Employee_Country_Repartition_Aggregate_Order_By>;
  employee_post_repartitions_aggregate?: InputMaybe<Employee_Post_Repartition_Aggregate_Order_By>;
  employees_aggregate?: InputMaybe<Employees_Aggregate_Order_By>;
  estimatedRevenueRange?: InputMaybe<Order_By>;
  events_news_aggregate?: InputMaybe<Events_News_Aggregate_Order_By>;
  facebook_url?: InputMaybe<Order_By>;
  follow_relation_users_startups_aggregate?: InputMaybe<Follow_Relation_Users_Startups_Aggregate_Order_By>;
  foundedDate?: InputMaybe<Order_By>;
  founders_aggregate?: InputMaybe<Founders_Aggregate_Order_By>;
  fundingStatus?: InputMaybe<Order_By>;
  g2ProductCompetitorsByStartupUuid_aggregate?: InputMaybe<G2_Product_Competitors_Aggregate_Order_By>;
  g2_historical_data_aggregate?: InputMaybe<G2_Historical_Data_Aggregate_Order_By>;
  g2_industries_aggregate?: InputMaybe<G2_Industry_Aggregate_Order_By>;
  g2_link?: InputMaybe<Order_By>;
  g2_product_categories_aggregate?: InputMaybe<G2_Product_Category_Aggregate_Order_By>;
  g2_product_competitors_aggregate?: InputMaybe<G2_Product_Competitors_Aggregate_Order_By>;
  g2_product_reviews_aggregate?: InputMaybe<G2_Product_Reviews_Aggregate_Order_By>;
  glassdoor?: InputMaybe<Order_By>;
  glassdoor_data_aggregate?: InputMaybe<Glassdoor_Data_Aggregate_Order_By>;
  glassdoor_trends_aggregate?: InputMaybe<Glassdoor_Trend_Aggregate_Order_By>;
  googleSerpsByStartupUuid2_aggregate?: InputMaybe<Google_Serp_Aggregate_Order_By>;
  googleSheetLink?: InputMaybe<Order_By>;
  googleTrendsIntelsWereUpdated?: InputMaybe<Order_By>;
  google_serps_aggregate?: InputMaybe<Google_Serp_Aggregate_Order_By>;
  growths_aggregate?: InputMaybe<Growth_Aggregate_Order_By>;
  headquartersCountry?: InputMaybe<Order_By>;
  hubTag?: InputMaybe<Order_By>;
  identity_card?: InputMaybe<Identity_Cards_Order_By>;
  inception?: InputMaybe<Order_By>;
  industries?: InputMaybe<Order_By>;
  investor_startup_relations_aggregate?: InputMaybe<Investor_Startup_Relation_Aggregate_Order_By>;
  investors_fetched?: InputMaybe<Order_By>;
  investors_names?: InputMaybe<Order_By>;
  invyo_id?: InputMaybe<Order_By>;
  isClientUpdated?: InputMaybe<Order_By>;
  isGNewsUpdated?: InputMaybe<Order_By>;
  isGlassdoorUpdated?: InputMaybe<Order_By>;
  isIndustryUpdated?: InputMaybe<Order_By>;
  isLinkedinUpdated?: InputMaybe<Order_By>;
  isPatentUpdated?: InputMaybe<Order_By>;
  isProductUpdated?: InputMaybe<Order_By>;
  is_fitting_sourcing_criterias?: InputMaybe<Order_By>;
  is_fitting_specter_criterias?: InputMaybe<Order_By>;
  is_led_by_europeans?: InputMaybe<Order_By>;
  lastFundingAmountInUSD?: InputMaybe<Order_By>;
  lastFundingDate?: InputMaybe<Order_By>;
  lastFundingType?: InputMaybe<Order_By>;
  last_search_relation_users_startups_aggregate?: InputMaybe<Last_Search_Relation_Users_Startups_Aggregate_Order_By>;
  link?: InputMaybe<Order_By>;
  linkedin?: InputMaybe<Order_By>;
  linkedinEmployeesByStartupUuid_aggregate?: InputMaybe<Linkedin_Employees_Aggregate_Order_By>;
  linkedinFollowersByStartupUuid_aggregate?: InputMaybe<Linkedin_Followers_Aggregate_Order_By>;
  linkedin_employee_avg_tenures_aggregate?: InputMaybe<Linkedin_Employee_Avg_Tenure_Aggregate_Order_By>;
  linkedin_employees?: InputMaybe<Order_By>;
  linkedin_followers?: InputMaybe<Order_By>;
  linkedin_headquarters_aggregate?: InputMaybe<Linkedin_Headquarters_Aggregate_Order_By>;
  linkedin_industry?: InputMaybe<Order_By>;
  linkedin_publications_aggregate?: InputMaybe<Linkedin_Publications_Aggregate_Order_By>;
  linkedin_school_repartitions_aggregate?: InputMaybe<Linkedin_School_Repartition_Aggregate_Order_By>;
  linkedin_skill_repartitions_aggregate?: InputMaybe<Linkedin_Skill_Repartition_Aggregate_Order_By>;
  logo_url?: InputMaybe<Order_By>;
  mobile_apps_aggregate?: InputMaybe<Mobile_App_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  num?: InputMaybe<Order_By>;
  numberOfAcquisitions?: InputMaybe<Order_By>;
  numberOfArticles?: InputMaybe<Order_By>;
  numberOfEmployees?: InputMaybe<Order_By>;
  numberOfEventAppearances?: InputMaybe<Order_By>;
  numberOfFounders?: InputMaybe<Order_By>;
  numberOfFundingRounds?: InputMaybe<Order_By>;
  number_us_investors?: InputMaybe<Order_By>;
  number_us_related_investors?: InputMaybe<Order_By>;
  operatingStatus?: InputMaybe<Order_By>;
  predictleads_job_openings_aggregate?: InputMaybe<Predictleads_Job_Openings_Aggregate_Order_By>;
  raisemorethan100?: InputMaybe<Order_By>;
  scores_aggregate?: InputMaybe<Scores_Aggregate_Order_By>;
  shortDescription?: InputMaybe<Order_By>;
  similarSitesByStartupUuid2_aggregate?: InputMaybe<Similar_Sites_Aggregate_Order_By>;
  similar_sites_aggregate?: InputMaybe<Similar_Sites_Aggregate_Order_By>;
  sourcing?: InputMaybe<Sourcing_Order_By>;
  sourcing_statuses_aggregate?: InputMaybe<Sourcing_Status_Aggregate_Order_By>;
  startupID?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  test?: InputMaybe<Order_By>;
  totalFundingAmountInUSD?: InputMaybe<Order_By>;
  trendScore7Days?: InputMaybe<Order_By>;
  trendScore30Days?: InputMaybe<Order_By>;
  trendScore90Days?: InputMaybe<Order_By>;
  twitter?: InputMaybe<Order_By>;
  twitter_followers_aggregate?: InputMaybe<Twitter_Followers_Aggregate_Order_By>;
  uuid?: InputMaybe<Order_By>;
  web_visits_country_repartitions_aggregate?: InputMaybe<Web_Visits_Country_Repartition_Aggregate_Order_By>;
  web_visits_general_data_aggregate?: InputMaybe<Web_Visits_General_Data_Aggregate_Order_By>;
  web_visits_historical_data_aggregate?: InputMaybe<Web_Visits_Historical_Data_Aggregate_Order_By>;
};

/** select columns of table "startups" */
export enum Startups_Select_Column {
  /** column name */
  InPortfolio = 'In_portfolio',
  /** column name */
  AddedManually = 'added_manually',
  /** column name */
  AirtableId = 'airtableID',
  /** column name */
  AttioListEntryId = 'attio_list_entry_id',
  /** column name */
  AttioRecordId = 'attio_record_id',
  /** column name */
  ClosedDate = 'closedDate',
  /** column name */
  ContactEmail = 'contactEmail',
  /** column name */
  CountryOfOrigin = 'countryOfOrigin',
  /** column name */
  CrunchbaseRank = 'crunchbaseRank',
  /** column name */
  CrunchbaseLink = 'crunchbase_link',
  /** column name */
  DateOfLastUpdate = 'date_of_last_update',
  /** column name */
  DaysSinceGoogleTrendsIntelsWereUpdated = 'daysSinceGoogleTrendsIntelsWereUpdated',
  /** column name */
  Description = 'description',
  /** column name */
  EstimatedRevenueRange = 'estimatedRevenueRange',
  /** column name */
  FacebookUrl = 'facebook_url',
  /** column name */
  FoundedDate = 'foundedDate',
  /** column name */
  FundingStatus = 'fundingStatus',
  /** column name */
  G2Link = 'g2_link',
  /** column name */
  Glassdoor = 'glassdoor',
  /** column name */
  GoogleSheetLink = 'googleSheetLink',
  /** column name */
  GoogleTrendsIntelsWereUpdated = 'googleTrendsIntelsWereUpdated',
  /** column name */
  HeadquartersCountry = 'headquartersCountry',
  /** column name */
  HubTag = 'hubTag',
  /** column name */
  Inception = 'inception',
  /** column name */
  Industries = 'industries',
  /** column name */
  InvestorsFetched = 'investors_fetched',
  /** column name */
  InvestorsNames = 'investors_names',
  /** column name */
  InvyoId = 'invyo_id',
  /** column name */
  IsClientUpdated = 'isClientUpdated',
  /** column name */
  IsGNewsUpdated = 'isGNewsUpdated',
  /** column name */
  IsGlassdoorUpdated = 'isGlassdoorUpdated',
  /** column name */
  IsIndustryUpdated = 'isIndustryUpdated',
  /** column name */
  IsLinkedinUpdated = 'isLinkedinUpdated',
  /** column name */
  IsPatentUpdated = 'isPatentUpdated',
  /** column name */
  IsProductUpdated = 'isProductUpdated',
  /** column name */
  IsFittingSourcingCriterias = 'is_fitting_sourcing_criterias',
  /** column name */
  IsFittingSpecterCriterias = 'is_fitting_specter_criterias',
  /** column name */
  IsLedByEuropeans = 'is_led_by_europeans',
  /** column name */
  LastFundingAmountInUsd = 'lastFundingAmountInUSD',
  /** column name */
  LastFundingDate = 'lastFundingDate',
  /** column name */
  LastFundingType = 'lastFundingType',
  /** column name */
  Link = 'link',
  /** column name */
  Linkedin = 'linkedin',
  /** column name */
  LinkedinEmployees = 'linkedin_employees',
  /** column name */
  LinkedinFollowers = 'linkedin_followers',
  /** column name */
  LinkedinIndustry = 'linkedin_industry',
  /** column name */
  LogoUrl = 'logo_url',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  Num = 'num',
  /** column name */
  NumberOfAcquisitions = 'numberOfAcquisitions',
  /** column name */
  NumberOfArticles = 'numberOfArticles',
  /** column name */
  NumberOfEmployees = 'numberOfEmployees',
  /** column name */
  NumberOfEventAppearances = 'numberOfEventAppearances',
  /** column name */
  NumberOfFounders = 'numberOfFounders',
  /** column name */
  NumberOfFundingRounds = 'numberOfFundingRounds',
  /** column name */
  NumberUsInvestors = 'number_us_investors',
  /** column name */
  NumberUsRelatedInvestors = 'number_us_related_investors',
  /** column name */
  OperatingStatus = 'operatingStatus',
  /** column name */
  Raisemorethan100 = 'raisemorethan100',
  /** column name */
  ShortDescription = 'shortDescription',
  /** column name */
  StartupId = 'startupID',
  /** column name */
  Status = 'status',
  /** column name */
  Test = 'test',
  /** column name */
  TotalFundingAmountInUsd = 'totalFundingAmountInUSD',
  /** column name */
  TrendScore7Days = 'trendScore7Days',
  /** column name */
  TrendScore30Days = 'trendScore30Days',
  /** column name */
  TrendScore90Days = 'trendScore90Days',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  Uuid = 'uuid',
}

/** aggregate stddev on columns */
export type Startups_Stddev_Fields = {
  __typename?: 'startups_stddev_fields';
  In_portfolio?: Maybe<Scalars['Float']>;
  crunchbaseRank?: Maybe<Scalars['Float']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Float']>;
  invyo_id?: Maybe<Scalars['Float']>;
  lastFundingAmountInUSD?: Maybe<Scalars['Float']>;
  linkedin_employees?: Maybe<Scalars['Float']>;
  linkedin_followers?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  numberOfAcquisitions?: Maybe<Scalars['Float']>;
  numberOfArticles?: Maybe<Scalars['Float']>;
  numberOfEmployees?: Maybe<Scalars['Float']>;
  numberOfEventAppearances?: Maybe<Scalars['Float']>;
  numberOfFounders?: Maybe<Scalars['Float']>;
  numberOfFundingRounds?: Maybe<Scalars['Float']>;
  number_us_investors?: Maybe<Scalars['Float']>;
  number_us_related_investors?: Maybe<Scalars['Float']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['Float']>;
  totalFundingAmountInUSD?: Maybe<Scalars['Float']>;
  trendScore7Days?: Maybe<Scalars['Float']>;
  trendScore30Days?: Maybe<Scalars['Float']>;
  trendScore90Days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Startups_Stddev_Pop_Fields = {
  __typename?: 'startups_stddev_pop_fields';
  In_portfolio?: Maybe<Scalars['Float']>;
  crunchbaseRank?: Maybe<Scalars['Float']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Float']>;
  invyo_id?: Maybe<Scalars['Float']>;
  lastFundingAmountInUSD?: Maybe<Scalars['Float']>;
  linkedin_employees?: Maybe<Scalars['Float']>;
  linkedin_followers?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  numberOfAcquisitions?: Maybe<Scalars['Float']>;
  numberOfArticles?: Maybe<Scalars['Float']>;
  numberOfEmployees?: Maybe<Scalars['Float']>;
  numberOfEventAppearances?: Maybe<Scalars['Float']>;
  numberOfFounders?: Maybe<Scalars['Float']>;
  numberOfFundingRounds?: Maybe<Scalars['Float']>;
  number_us_investors?: Maybe<Scalars['Float']>;
  number_us_related_investors?: Maybe<Scalars['Float']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['Float']>;
  totalFundingAmountInUSD?: Maybe<Scalars['Float']>;
  trendScore7Days?: Maybe<Scalars['Float']>;
  trendScore30Days?: Maybe<Scalars['Float']>;
  trendScore90Days?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Startups_Stddev_Samp_Fields = {
  __typename?: 'startups_stddev_samp_fields';
  In_portfolio?: Maybe<Scalars['Float']>;
  crunchbaseRank?: Maybe<Scalars['Float']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Float']>;
  invyo_id?: Maybe<Scalars['Float']>;
  lastFundingAmountInUSD?: Maybe<Scalars['Float']>;
  linkedin_employees?: Maybe<Scalars['Float']>;
  linkedin_followers?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  numberOfAcquisitions?: Maybe<Scalars['Float']>;
  numberOfArticles?: Maybe<Scalars['Float']>;
  numberOfEmployees?: Maybe<Scalars['Float']>;
  numberOfEventAppearances?: Maybe<Scalars['Float']>;
  numberOfFounders?: Maybe<Scalars['Float']>;
  numberOfFundingRounds?: Maybe<Scalars['Float']>;
  number_us_investors?: Maybe<Scalars['Float']>;
  number_us_related_investors?: Maybe<Scalars['Float']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['Float']>;
  totalFundingAmountInUSD?: Maybe<Scalars['Float']>;
  trendScore7Days?: Maybe<Scalars['Float']>;
  trendScore30Days?: Maybe<Scalars['Float']>;
  trendScore90Days?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "startups" */
export type Startups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Startups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Startups_Stream_Cursor_Value_Input = {
  In_portfolio?: InputMaybe<Scalars['Int']>;
  added_manually?: InputMaybe<Scalars['Boolean']>;
  airtableID?: InputMaybe<Scalars['String']>;
  attio_list_entry_id?: InputMaybe<Scalars['String']>;
  attio_record_id?: InputMaybe<Scalars['String']>;
  closedDate?: InputMaybe<Scalars['date']>;
  contactEmail?: InputMaybe<Scalars['String']>;
  countryOfOrigin?: InputMaybe<Scalars['String']>;
  crunchbaseRank?: InputMaybe<Scalars['Int']>;
  crunchbase_link?: InputMaybe<Scalars['String']>;
  date_of_last_update?: InputMaybe<Scalars['timestamptz']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  estimatedRevenueRange?: InputMaybe<Scalars['String']>;
  facebook_url?: InputMaybe<Scalars['String']>;
  foundedDate?: InputMaybe<Scalars['String']>;
  fundingStatus?: InputMaybe<Scalars['String']>;
  g2_link?: InputMaybe<Scalars['String']>;
  glassdoor?: InputMaybe<Scalars['String']>;
  googleSheetLink?: InputMaybe<Scalars['String']>;
  googleTrendsIntelsWereUpdated?: InputMaybe<Scalars['Boolean']>;
  headquartersCountry?: InputMaybe<Scalars['String']>;
  hubTag?: InputMaybe<Scalars['String']>;
  inception?: InputMaybe<Scalars['date']>;
  industries?: InputMaybe<Scalars['String']>;
  investors_fetched?: InputMaybe<Scalars['Boolean']>;
  investors_names?: InputMaybe<Scalars['String']>;
  invyo_id?: InputMaybe<Scalars['Int']>;
  isClientUpdated?: InputMaybe<Scalars['Boolean']>;
  isGNewsUpdated?: InputMaybe<Scalars['Boolean']>;
  isGlassdoorUpdated?: InputMaybe<Scalars['Boolean']>;
  isIndustryUpdated?: InputMaybe<Scalars['Boolean']>;
  isLinkedinUpdated?: InputMaybe<Scalars['Boolean']>;
  isPatentUpdated?: InputMaybe<Scalars['Boolean']>;
  isProductUpdated?: InputMaybe<Scalars['Boolean']>;
  is_fitting_sourcing_criterias?: InputMaybe<Scalars['Boolean']>;
  is_fitting_specter_criterias?: InputMaybe<Scalars['Boolean']>;
  is_led_by_europeans?: InputMaybe<Scalars['String']>;
  lastFundingAmountInUSD?: InputMaybe<Scalars['bigint']>;
  lastFundingDate?: InputMaybe<Scalars['date']>;
  lastFundingType?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  linkedin?: InputMaybe<Scalars['String']>;
  linkedin_employees?: InputMaybe<Scalars['Int']>;
  linkedin_followers?: InputMaybe<Scalars['Int']>;
  linkedin_industry?: InputMaybe<Scalars['String']>;
  logo_url?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  num?: InputMaybe<Scalars['Int']>;
  numberOfAcquisitions?: InputMaybe<Scalars['Int']>;
  numberOfArticles?: InputMaybe<Scalars['Int']>;
  numberOfEmployees?: InputMaybe<Scalars['Int']>;
  numberOfEventAppearances?: InputMaybe<Scalars['Int']>;
  numberOfFounders?: InputMaybe<Scalars['Int']>;
  numberOfFundingRounds?: InputMaybe<Scalars['Int']>;
  number_us_investors?: InputMaybe<Scalars['Int']>;
  number_us_related_investors?: InputMaybe<Scalars['Int']>;
  operatingStatus?: InputMaybe<Scalars['String']>;
  raisemorethan100?: InputMaybe<Scalars['Boolean']>;
  shortDescription?: InputMaybe<Scalars['String']>;
  startupID?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  /** fdfdfdfd */
  test?: InputMaybe<Scalars['float8']>;
  totalFundingAmountInUSD?: InputMaybe<Scalars['bigint']>;
  trendScore7Days?: InputMaybe<Scalars['float8']>;
  trendScore30Days?: InputMaybe<Scalars['float8']>;
  trendScore90Days?: InputMaybe<Scalars['float8']>;
  twitter?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Startups_Sum_Fields = {
  __typename?: 'startups_sum_fields';
  In_portfolio?: Maybe<Scalars['Int']>;
  crunchbaseRank?: Maybe<Scalars['Int']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Int']>;
  invyo_id?: Maybe<Scalars['Int']>;
  lastFundingAmountInUSD?: Maybe<Scalars['bigint']>;
  linkedin_employees?: Maybe<Scalars['Int']>;
  linkedin_followers?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
  numberOfAcquisitions?: Maybe<Scalars['Int']>;
  numberOfArticles?: Maybe<Scalars['Int']>;
  numberOfEmployees?: Maybe<Scalars['Int']>;
  numberOfEventAppearances?: Maybe<Scalars['Int']>;
  numberOfFounders?: Maybe<Scalars['Int']>;
  numberOfFundingRounds?: Maybe<Scalars['Int']>;
  number_us_investors?: Maybe<Scalars['Int']>;
  number_us_related_investors?: Maybe<Scalars['Int']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['float8']>;
  totalFundingAmountInUSD?: Maybe<Scalars['bigint']>;
  trendScore7Days?: Maybe<Scalars['float8']>;
  trendScore30Days?: Maybe<Scalars['float8']>;
  trendScore90Days?: Maybe<Scalars['float8']>;
};

/** aggregate var_pop on columns */
export type Startups_Var_Pop_Fields = {
  __typename?: 'startups_var_pop_fields';
  In_portfolio?: Maybe<Scalars['Float']>;
  crunchbaseRank?: Maybe<Scalars['Float']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Float']>;
  invyo_id?: Maybe<Scalars['Float']>;
  lastFundingAmountInUSD?: Maybe<Scalars['Float']>;
  linkedin_employees?: Maybe<Scalars['Float']>;
  linkedin_followers?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  numberOfAcquisitions?: Maybe<Scalars['Float']>;
  numberOfArticles?: Maybe<Scalars['Float']>;
  numberOfEmployees?: Maybe<Scalars['Float']>;
  numberOfEventAppearances?: Maybe<Scalars['Float']>;
  numberOfFounders?: Maybe<Scalars['Float']>;
  numberOfFundingRounds?: Maybe<Scalars['Float']>;
  number_us_investors?: Maybe<Scalars['Float']>;
  number_us_related_investors?: Maybe<Scalars['Float']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['Float']>;
  totalFundingAmountInUSD?: Maybe<Scalars['Float']>;
  trendScore7Days?: Maybe<Scalars['Float']>;
  trendScore30Days?: Maybe<Scalars['Float']>;
  trendScore90Days?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Startups_Var_Samp_Fields = {
  __typename?: 'startups_var_samp_fields';
  In_portfolio?: Maybe<Scalars['Float']>;
  crunchbaseRank?: Maybe<Scalars['Float']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Float']>;
  invyo_id?: Maybe<Scalars['Float']>;
  lastFundingAmountInUSD?: Maybe<Scalars['Float']>;
  linkedin_employees?: Maybe<Scalars['Float']>;
  linkedin_followers?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  numberOfAcquisitions?: Maybe<Scalars['Float']>;
  numberOfArticles?: Maybe<Scalars['Float']>;
  numberOfEmployees?: Maybe<Scalars['Float']>;
  numberOfEventAppearances?: Maybe<Scalars['Float']>;
  numberOfFounders?: Maybe<Scalars['Float']>;
  numberOfFundingRounds?: Maybe<Scalars['Float']>;
  number_us_investors?: Maybe<Scalars['Float']>;
  number_us_related_investors?: Maybe<Scalars['Float']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['Float']>;
  totalFundingAmountInUSD?: Maybe<Scalars['Float']>;
  trendScore7Days?: Maybe<Scalars['Float']>;
  trendScore30Days?: Maybe<Scalars['Float']>;
  trendScore90Days?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Startups_Variance_Fields = {
  __typename?: 'startups_variance_fields';
  In_portfolio?: Maybe<Scalars['Float']>;
  crunchbaseRank?: Maybe<Scalars['Float']>;
  daysSinceGoogleTrendsIntelsWereUpdated?: Maybe<Scalars['Float']>;
  invyo_id?: Maybe<Scalars['Float']>;
  lastFundingAmountInUSD?: Maybe<Scalars['Float']>;
  linkedin_employees?: Maybe<Scalars['Float']>;
  linkedin_followers?: Maybe<Scalars['Float']>;
  num?: Maybe<Scalars['Float']>;
  numberOfAcquisitions?: Maybe<Scalars['Float']>;
  numberOfArticles?: Maybe<Scalars['Float']>;
  numberOfEmployees?: Maybe<Scalars['Float']>;
  numberOfEventAppearances?: Maybe<Scalars['Float']>;
  numberOfFounders?: Maybe<Scalars['Float']>;
  numberOfFundingRounds?: Maybe<Scalars['Float']>;
  number_us_investors?: Maybe<Scalars['Float']>;
  number_us_related_investors?: Maybe<Scalars['Float']>;
  /** fdfdfdfd */
  test?: Maybe<Scalars['Float']>;
  totalFundingAmountInUSD?: Maybe<Scalars['Float']>;
  trendScore7Days?: Maybe<Scalars['Float']>;
  trendScore30Days?: Maybe<Scalars['Float']>;
  trendScore90Days?: Maybe<Scalars['Float']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** An array relationship */
  bcorp_startups: Array<Bcorp_Startups>;
  /** fetch data from the table: "bcorp_startups" using primary key columns */
  bcorp_startups_by_pk?: Maybe<Bcorp_Startups>;
  /** fetch data from the table in a streaming manner: "bcorp_startups" */
  bcorp_startups_stream: Array<Bcorp_Startups>;
  /** fetch data from the table: "cofounders" */
  cofounders: Array<Cofounders>;
  /** fetch data from the table: "cofounders" using primary key columns */
  cofounders_by_pk?: Maybe<Cofounders>;
  /** fetch data from the table in a streaming manner: "cofounders" */
  cofounders_stream: Array<Cofounders>;
  /** fetch data from the table: "competition_classification" */
  competition_classification: Array<Competition_Classification>;
  /** fetch data from the table: "competition_classification" using primary key columns */
  competition_classification_by_pk?: Maybe<Competition_Classification>;
  /** fetch data from the table in a streaming manner: "competition_classification" */
  competition_classification_stream: Array<Competition_Classification>;
  /** fetch data from the table: "competitors" */
  competitors: Array<Competitors>;
  /** fetch data from the table: "competitors" using primary key columns */
  competitors_by_pk?: Maybe<Competitors>;
  /** fetch data from the table in a streaming manner: "competitors" */
  competitors_stream: Array<Competitors>;
  /** fetch data from the table: "country_names" */
  country_names: Array<Country_Names>;
  /** fetch data from the table: "country_names" using primary key columns */
  country_names_by_pk?: Maybe<Country_Names>;
  /** fetch data from the table in a streaming manner: "country_names" */
  country_names_stream: Array<Country_Names>;
  /** fetch data from the table: "country_of_startups_for_sourcing" */
  country_of_startups_for_sourcing: Array<Country_Of_Startups_For_Sourcing>;
  /** fetch data from the table: "country_of_startups_for_sourcing" using primary key columns */
  country_of_startups_for_sourcing_by_pk?: Maybe<Country_Of_Startups_For_Sourcing>;
  /** fetch data from the table in a streaming manner: "country_of_startups_for_sourcing" */
  country_of_startups_for_sourcing_stream: Array<Country_Of_Startups_For_Sourcing>;
  /** fetch data from the table: "country_of_startups_for_specter" */
  country_of_startups_for_specter: Array<Country_Of_Startups_For_Specter>;
  /** fetch data from the table: "country_of_startups_for_specter" using primary key columns */
  country_of_startups_for_specter_by_pk?: Maybe<Country_Of_Startups_For_Specter>;
  /** fetch data from the table in a streaming manner: "country_of_startups_for_specter" */
  country_of_startups_for_specter_stream: Array<Country_Of_Startups_For_Specter>;
  /** fetch data from the table: "data_sources_last_update" */
  data_sources_last_update: Array<Data_Sources_Last_Update>;
  /** fetch data from the table: "data_sources_last_update" using primary key columns */
  data_sources_last_update_by_pk?: Maybe<Data_Sources_Last_Update>;
  /** fetch data from the table in a streaming manner: "data_sources_last_update" */
  data_sources_last_update_stream: Array<Data_Sources_Last_Update>;
  /** fetch data from the table: "dealflow" */
  dealflow: Array<Dealflow>;
  /** fetch data from the table: "dealflow" using primary key columns */
  dealflow_by_pk?: Maybe<Dealflow>;
  /** fetch data from the table in a streaming manner: "dealflow" */
  dealflow_stream: Array<Dealflow>;
  /** fetch data from the table: "deals" */
  deals: Array<Deals>;
  /** fetch data from the table: "deals" using primary key columns */
  deals_by_pk?: Maybe<Deals>;
  /** fetch data from the table in a streaming manner: "deals" */
  deals_stream: Array<Deals>;
  /** fetch data from the table: "education" */
  education: Array<Education>;
  /** fetch data from the table: "education" using primary key columns */
  education_by_pk?: Maybe<Education>;
  /** fetch data from the table in a streaming manner: "education" */
  education_stream: Array<Education>;
  /** fetch data from the table: "employee_country_repartition" */
  employee_country_repartition: Array<Employee_Country_Repartition>;
  /** fetch data from the table: "employee_country_repartition" using primary key columns */
  employee_country_repartition_by_pk?: Maybe<Employee_Country_Repartition>;
  /** fetch data from the table in a streaming manner: "employee_country_repartition" */
  employee_country_repartition_stream: Array<Employee_Country_Repartition>;
  /** fetch data from the table: "employee_post_repartition" */
  employee_post_repartition: Array<Employee_Post_Repartition>;
  /** fetch data from the table: "employee_post_repartition" using primary key columns */
  employee_post_repartition_by_pk?: Maybe<Employee_Post_Repartition>;
  /** fetch data from the table in a streaming manner: "employee_post_repartition" */
  employee_post_repartition_stream: Array<Employee_Post_Repartition>;
  /** An array relationship */
  employees: Array<Employees>;
  /** fetch data from the table: "employees" using primary key columns */
  employees_by_pk?: Maybe<Employees>;
  /** fetch data from the table: "employees_from_us" */
  employees_from_us: Array<Employees_From_Us>;
  /** fetch data from the table: "employees_from_us" using primary key columns */
  employees_from_us_by_pk?: Maybe<Employees_From_Us>;
  /** fetch data from the table in a streaming manner: "employees_from_us" */
  employees_from_us_stream: Array<Employees_From_Us>;
  /** fetch data from the table in a streaming manner: "employees" */
  employees_stream: Array<Employees>;
  /** An array relationship */
  events_news: Array<Events_News>;
  /** fetch data from the table: "events_news" using primary key columns */
  events_news_by_pk?: Maybe<Events_News>;
  /** fetch data from the table in a streaming manner: "events_news" */
  events_news_stream: Array<Events_News>;
  /** An array relationship */
  follow_relation_users_startups: Array<Follow_Relation_Users_Startups>;
  /** fetch data from the table: "follow_relation_users_startups" using primary key columns */
  follow_relation_users_startups_by_pk?: Maybe<Follow_Relation_Users_Startups>;
  /** fetch data from the table in a streaming manner: "follow_relation_users_startups" */
  follow_relation_users_startups_stream: Array<Follow_Relation_Users_Startups>;
  /** An array relationship */
  founder_jobs: Array<Founder_Jobs>;
  /** fetch data from the table: "founder_jobs" using primary key columns */
  founder_jobs_by_pk?: Maybe<Founder_Jobs>;
  /** fetch data from the table in a streaming manner: "founder_jobs" */
  founder_jobs_stream: Array<Founder_Jobs>;
  /** fetch data from the table: "founder_score_weights" */
  founder_score_weights: Array<Founder_Score_Weights>;
  /** fetch data from the table: "founder_score_weights" using primary key columns */
  founder_score_weights_by_pk?: Maybe<Founder_Score_Weights>;
  /** fetch data from the table in a streaming manner: "founder_score_weights" */
  founder_score_weights_stream: Array<Founder_Score_Weights>;
  /** An array relationship */
  founders: Array<Founders>;
  /** fetch data from the table: "founders" using primary key columns */
  founders_by_pk?: Maybe<Founders>;
  /** fetch data from the table in a streaming manner: "founders" */
  founders_stream: Array<Founders>;
  /** An array relationship */
  g2_historical_data: Array<G2_Historical_Data>;
  /** fetch data from the table: "g2_historical_data" using primary key columns */
  g2_historical_data_by_pk?: Maybe<G2_Historical_Data>;
  /** fetch data from the table in a streaming manner: "g2_historical_data" */
  g2_historical_data_stream: Array<G2_Historical_Data>;
  /** fetch data from the table: "g2_industry" */
  g2_industry: Array<G2_Industry>;
  /** fetch data from the table: "g2_industry" using primary key columns */
  g2_industry_by_pk?: Maybe<G2_Industry>;
  /** fetch data from the table in a streaming manner: "g2_industry" */
  g2_industry_stream: Array<G2_Industry>;
  /** fetch data from the table: "g2_product_category" */
  g2_product_category: Array<G2_Product_Category>;
  /** fetch data from the table: "g2_product_category" using primary key columns */
  g2_product_category_by_pk?: Maybe<G2_Product_Category>;
  /** fetch data from the table in a streaming manner: "g2_product_category" */
  g2_product_category_stream: Array<G2_Product_Category>;
  /** An array relationship */
  g2_product_competitors: Array<G2_Product_Competitors>;
  /** fetch data from the table: "g2_product_competitors" using primary key columns */
  g2_product_competitors_by_pk?: Maybe<G2_Product_Competitors>;
  /** fetch data from the table in a streaming manner: "g2_product_competitors" */
  g2_product_competitors_stream: Array<G2_Product_Competitors>;
  /** An array relationship */
  g2_product_reviews: Array<G2_Product_Reviews>;
  /** fetch data from the table: "g2_product_reviews" using primary key columns */
  g2_product_reviews_by_pk?: Maybe<G2_Product_Reviews>;
  /** fetch data from the table in a streaming manner: "g2_product_reviews" */
  g2_product_reviews_stream: Array<G2_Product_Reviews>;
  /** An array relationship */
  glassdoor_data: Array<Glassdoor_Data>;
  /** fetch data from the table: "glassdoor_data" using primary key columns */
  glassdoor_data_by_pk?: Maybe<Glassdoor_Data>;
  /** fetch data from the table in a streaming manner: "glassdoor_data" */
  glassdoor_data_stream: Array<Glassdoor_Data>;
  /** fetch data from the table: "glassdoor_trend" */
  glassdoor_trend: Array<Glassdoor_Trend>;
  /** fetch data from the table: "glassdoor_trend" using primary key columns */
  glassdoor_trend_by_pk?: Maybe<Glassdoor_Trend>;
  /** fetch data from the table in a streaming manner: "glassdoor_trend" */
  glassdoor_trend_stream: Array<Glassdoor_Trend>;
  /** fetch data from the table: "google_serp" */
  google_serp: Array<Google_Serp>;
  /** fetch data from the table: "google_serp" using primary key columns */
  google_serp_by_pk?: Maybe<Google_Serp>;
  /** fetch data from the table in a streaming manner: "google_serp" */
  google_serp_stream: Array<Google_Serp>;
  /** fetch data from the table: "growth" */
  growth: Array<Growth>;
  /** fetch data from the table: "growth" using primary key columns */
  growth_by_pk?: Maybe<Growth>;
  /** fetch data from the table in a streaming manner: "growth" */
  growth_stream: Array<Growth>;
  /** fetch data from the table: "identity_cards" */
  identity_cards: Array<Identity_Cards>;
  /** fetch data from the table: "identity_cards" using primary key columns */
  identity_cards_by_pk?: Maybe<Identity_Cards>;
  /** fetch data from the table in a streaming manner: "identity_cards" */
  identity_cards_stream: Array<Identity_Cards>;
  /** fetch data from the table: "investments" */
  investments: Array<Investments>;
  /** fetch data from the table: "investments" using primary key columns */
  investments_by_pk?: Maybe<Investments>;
  /** fetch data from the table in a streaming manner: "investments" */
  investments_stream: Array<Investments>;
  /** An array relationship */
  investor_startup_relation: Array<Investor_Startup_Relation>;
  /** fetch data from the table: "investor_startup_relation" using primary key columns */
  investor_startup_relation_by_pk?: Maybe<Investor_Startup_Relation>;
  /** fetch data from the table in a streaming manner: "investor_startup_relation" */
  investor_startup_relation_stream: Array<Investor_Startup_Relation>;
  /** fetch data from the table: "investors" */
  investors: Array<Investors>;
  /** fetch data from the table: "investors" using primary key columns */
  investors_by_pk?: Maybe<Investors>;
  /** fetch data from the table in a streaming manner: "investors" */
  investors_stream: Array<Investors>;
  /** An array relationship */
  last_search_relation_users_startups: Array<Last_Search_Relation_Users_Startups>;
  /** fetch data from the table: "last_search_relation_users_startups" using primary key columns */
  last_search_relation_users_startups_by_pk?: Maybe<Last_Search_Relation_Users_Startups>;
  /** fetch data from the table in a streaming manner: "last_search_relation_users_startups" */
  last_search_relation_users_startups_stream: Array<Last_Search_Relation_Users_Startups>;
  /** fetch data from the table: "linkedin_employee_avg_tenure" */
  linkedin_employee_avg_tenure: Array<Linkedin_Employee_Avg_Tenure>;
  /** fetch data from the table: "linkedin_employee_avg_tenure" using primary key columns */
  linkedin_employee_avg_tenure_by_pk?: Maybe<Linkedin_Employee_Avg_Tenure>;
  /** fetch data from the table in a streaming manner: "linkedin_employee_avg_tenure" */
  linkedin_employee_avg_tenure_stream: Array<Linkedin_Employee_Avg_Tenure>;
  /** fetch data from the table: "linkedin_employees" */
  linkedin_employees: Array<Linkedin_Employees>;
  /** fetch data from the table: "linkedin_employees" using primary key columns */
  linkedin_employees_by_pk?: Maybe<Linkedin_Employees>;
  /** fetch data from the table in a streaming manner: "linkedin_employees" */
  linkedin_employees_stream: Array<Linkedin_Employees>;
  /** fetch data from the table: "linkedin_followers" */
  linkedin_followers: Array<Linkedin_Followers>;
  /** fetch data from the table: "linkedin_followers" using primary key columns */
  linkedin_followers_by_pk?: Maybe<Linkedin_Followers>;
  /** fetch data from the table in a streaming manner: "linkedin_followers" */
  linkedin_followers_stream: Array<Linkedin_Followers>;
  /** An array relationship */
  linkedin_headquarters: Array<Linkedin_Headquarters>;
  /** fetch data from the table: "linkedin_headquarters" using primary key columns */
  linkedin_headquarters_by_pk?: Maybe<Linkedin_Headquarters>;
  /** fetch data from the table in a streaming manner: "linkedin_headquarters" */
  linkedin_headquarters_stream: Array<Linkedin_Headquarters>;
  /** An array relationship */
  linkedin_publications: Array<Linkedin_Publications>;
  /** fetch data from the table: "linkedin_publications" using primary key columns */
  linkedin_publications_by_pk?: Maybe<Linkedin_Publications>;
  /** fetch data from the table in a streaming manner: "linkedin_publications" */
  linkedin_publications_stream: Array<Linkedin_Publications>;
  /** fetch data from the table: "linkedin_school_repartition" */
  linkedin_school_repartition: Array<Linkedin_School_Repartition>;
  /** fetch data from the table: "linkedin_school_repartition" using primary key columns */
  linkedin_school_repartition_by_pk?: Maybe<Linkedin_School_Repartition>;
  /** fetch data from the table in a streaming manner: "linkedin_school_repartition" */
  linkedin_school_repartition_stream: Array<Linkedin_School_Repartition>;
  /** fetch data from the table: "linkedin_skill_repartition" */
  linkedin_skill_repartition: Array<Linkedin_Skill_Repartition>;
  /** fetch data from the table: "linkedin_skill_repartition" using primary key columns */
  linkedin_skill_repartition_by_pk?: Maybe<Linkedin_Skill_Repartition>;
  /** fetch data from the table in a streaming manner: "linkedin_skill_repartition" */
  linkedin_skill_repartition_stream: Array<Linkedin_Skill_Repartition>;
  /** fetch data from the table: "mobile_app" */
  mobile_app: Array<Mobile_App>;
  /** fetch aggregated fields from the table: "mobile_app" */
  mobile_app_aggregate: Mobile_App_Aggregate;
  /** fetch data from the table: "mobile_app" using primary key columns */
  mobile_app_by_pk?: Maybe<Mobile_App>;
  /** fetch data from the table in a streaming manner: "mobile_app" */
  mobile_app_stream: Array<Mobile_App>;
  /** fetch data from the table: "percentiles" */
  percentiles: Array<Percentiles>;
  /** fetch data from the table: "percentiles" using primary key columns */
  percentiles_by_pk?: Maybe<Percentiles>;
  /** fetch data from the table in a streaming manner: "percentiles" */
  percentiles_stream: Array<Percentiles>;
  /** An array relationship */
  predictleads_job_openings: Array<Predictleads_Job_Openings>;
  /** fetch data from the table: "predictleads_job_openings" using primary key columns */
  predictleads_job_openings_by_pk?: Maybe<Predictleads_Job_Openings>;
  /** fetch data from the table in a streaming manner: "predictleads_job_openings" */
  predictleads_job_openings_stream: Array<Predictleads_Job_Openings>;
  /** fetch data from the table: "schools" */
  schools: Array<Schools>;
  /** fetch data from the table: "schools" using primary key columns */
  schools_by_pk?: Maybe<Schools>;
  /** fetch data from the table in a streaming manner: "schools" */
  schools_stream: Array<Schools>;
  /** An array relationship */
  scores: Array<Scores>;
  /** An aggregate relationship */
  scores_aggregate: Scores_Aggregate;
  /** fetch data from the table: "scores" using primary key columns */
  scores_by_pk?: Maybe<Scores>;
  /** fetch data from the table in a streaming manner: "scores" */
  scores_stream: Array<Scores>;
  /** execute function "search_startup_name" which returns "startups" */
  search_startup_name: Array<Startups>;
  /** execute function "search_startup_name" and query aggregates on result of table type "startups" */
  search_startup_name_aggregate: Startups_Aggregate;
  /** execute function "search_startup_name_in_identity_cards" which returns "identity_cards" */
  search_startup_name_in_identity_cards: Array<Identity_Cards>;
  /** An array relationship */
  similar_sites: Array<Similar_Sites>;
  /** fetch data from the table: "similar_sites" using primary key columns */
  similar_sites_by_pk?: Maybe<Similar_Sites>;
  /** fetch data from the table in a streaming manner: "similar_sites" */
  similar_sites_stream: Array<Similar_Sites>;
  /** fetch data from the table: "sourcing" */
  sourcing: Array<Sourcing>;
  /** fetch data from the table: "sourcing" using primary key columns */
  sourcing_by_pk?: Maybe<Sourcing>;
  /** fetch data from the table: "sourcing_status" */
  sourcing_status: Array<Sourcing_Status>;
  /** fetch data from the table: "sourcing_status" using primary key columns */
  sourcing_status_by_pk?: Maybe<Sourcing_Status>;
  /** fetch data from the table in a streaming manner: "sourcing_status" */
  sourcing_status_stream: Array<Sourcing_Status>;
  /** fetch data from the table in a streaming manner: "sourcing" */
  sourcing_stream: Array<Sourcing>;
  /** fetch data from the table: "startups" */
  startups: Array<Startups>;
  /** fetch aggregated fields from the table: "startups" */
  startups_aggregate: Startups_Aggregate;
  /** fetch data from the table: "startups" using primary key columns */
  startups_by_pk?: Maybe<Startups>;
  /** fetch data from the table in a streaming manner: "startups" */
  startups_stream: Array<Startups>;
  /** An array relationship */
  twitter_followers: Array<Twitter_Followers>;
  /** fetch data from the table: "twitter_followers" using primary key columns */
  twitter_followers_by_pk?: Maybe<Twitter_Followers>;
  /** fetch data from the table in a streaming manner: "twitter_followers" */
  twitter_followers_stream: Array<Twitter_Followers>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "web_visits_country_repartition" */
  web_visits_country_repartition: Array<Web_Visits_Country_Repartition>;
  /** fetch data from the table: "web_visits_country_repartition" using primary key columns */
  web_visits_country_repartition_by_pk?: Maybe<Web_Visits_Country_Repartition>;
  /** fetch data from the table in a streaming manner: "web_visits_country_repartition" */
  web_visits_country_repartition_stream: Array<Web_Visits_Country_Repartition>;
  /** An array relationship */
  web_visits_general_data: Array<Web_Visits_General_Data>;
  /** fetch data from the table: "web_visits_general_data" using primary key columns */
  web_visits_general_data_by_pk?: Maybe<Web_Visits_General_Data>;
  /** fetch data from the table in a streaming manner: "web_visits_general_data" */
  web_visits_general_data_stream: Array<Web_Visits_General_Data>;
  /** An array relationship */
  web_visits_historical_data: Array<Web_Visits_Historical_Data>;
  /** An aggregate relationship */
  web_visits_historical_data_aggregate: Web_Visits_Historical_Data_Aggregate;
  /** fetch data from the table: "web_visits_historical_data" using primary key columns */
  web_visits_historical_data_by_pk?: Maybe<Web_Visits_Historical_Data>;
  /** fetch data from the table in a streaming manner: "web_visits_historical_data" */
  web_visits_historical_data_stream: Array<Web_Visits_Historical_Data>;
};

export type Subscription_RootBcorp_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Bcorp_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Bcorp_Startups_Order_By>>;
  where?: InputMaybe<Bcorp_Startups_Bool_Exp>;
};

export type Subscription_RootBcorp_Startups_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootBcorp_Startups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Bcorp_Startups_Stream_Cursor_Input>>;
  where?: InputMaybe<Bcorp_Startups_Bool_Exp>;
};

export type Subscription_RootCofoundersArgs = {
  distinct_on?: InputMaybe<Array<Cofounders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cofounders_Order_By>>;
  where?: InputMaybe<Cofounders_Bool_Exp>;
};

export type Subscription_RootCofounders_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCofounders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cofounders_Stream_Cursor_Input>>;
  where?: InputMaybe<Cofounders_Bool_Exp>;
};

export type Subscription_RootCompetition_ClassificationArgs = {
  distinct_on?: InputMaybe<Array<Competition_Classification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Competition_Classification_Order_By>>;
  where?: InputMaybe<Competition_Classification_Bool_Exp>;
};

export type Subscription_RootCompetition_Classification_By_PkArgs = {
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
};

export type Subscription_RootCompetition_Classification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Competition_Classification_Stream_Cursor_Input>>;
  where?: InputMaybe<Competition_Classification_Bool_Exp>;
};

export type Subscription_RootCompetitorsArgs = {
  distinct_on?: InputMaybe<Array<Competitors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Competitors_Order_By>>;
  where?: InputMaybe<Competitors_Bool_Exp>;
};

export type Subscription_RootCompetitors_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootCompetitors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Competitors_Stream_Cursor_Input>>;
  where?: InputMaybe<Competitors_Bool_Exp>;
};

export type Subscription_RootCountry_NamesArgs = {
  distinct_on?: InputMaybe<Array<Country_Names_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Names_Order_By>>;
  where?: InputMaybe<Country_Names_Bool_Exp>;
};

export type Subscription_RootCountry_Names_By_PkArgs = {
  country_id: Scalars['Int'];
};

export type Subscription_RootCountry_Names_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Country_Names_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Names_Bool_Exp>;
};

export type Subscription_RootCountry_Of_Startups_For_SourcingArgs = {
  distinct_on?: InputMaybe<Array<Country_Of_Startups_For_Sourcing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Of_Startups_For_Sourcing_Order_By>>;
  where?: InputMaybe<Country_Of_Startups_For_Sourcing_Bool_Exp>;
};

export type Subscription_RootCountry_Of_Startups_For_Sourcing_By_PkArgs = {
  countryName: Scalars['String'];
};

export type Subscription_RootCountry_Of_Startups_For_Sourcing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Country_Of_Startups_For_Sourcing_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Of_Startups_For_Sourcing_Bool_Exp>;
};

export type Subscription_RootCountry_Of_Startups_For_SpecterArgs = {
  distinct_on?: InputMaybe<Array<Country_Of_Startups_For_Specter_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Country_Of_Startups_For_Specter_Order_By>>;
  where?: InputMaybe<Country_Of_Startups_For_Specter_Bool_Exp>;
};

export type Subscription_RootCountry_Of_Startups_For_Specter_By_PkArgs = {
  countryName: Scalars['String'];
};

export type Subscription_RootCountry_Of_Startups_For_Specter_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Country_Of_Startups_For_Specter_Stream_Cursor_Input>>;
  where?: InputMaybe<Country_Of_Startups_For_Specter_Bool_Exp>;
};

export type Subscription_RootData_Sources_Last_UpdateArgs = {
  distinct_on?: InputMaybe<Array<Data_Sources_Last_Update_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Data_Sources_Last_Update_Order_By>>;
  where?: InputMaybe<Data_Sources_Last_Update_Bool_Exp>;
};

export type Subscription_RootData_Sources_Last_Update_By_PkArgs = {
  startup_uuid: Scalars['uuid'];
};

export type Subscription_RootData_Sources_Last_Update_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Data_Sources_Last_Update_Stream_Cursor_Input>>;
  where?: InputMaybe<Data_Sources_Last_Update_Bool_Exp>;
};

export type Subscription_RootDealflowArgs = {
  distinct_on?: InputMaybe<Array<Dealflow_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dealflow_Order_By>>;
  where?: InputMaybe<Dealflow_Bool_Exp>;
};

export type Subscription_RootDealflow_By_PkArgs = {
  opportunity: Scalars['String'];
};

export type Subscription_RootDealflow_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dealflow_Stream_Cursor_Input>>;
  where?: InputMaybe<Dealflow_Bool_Exp>;
};

export type Subscription_RootDealsArgs = {
  distinct_on?: InputMaybe<Array<Deals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Deals_Order_By>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

export type Subscription_RootDeals_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootDeals_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Deals_Stream_Cursor_Input>>;
  where?: InputMaybe<Deals_Bool_Exp>;
};

export type Subscription_RootEducationArgs = {
  distinct_on?: InputMaybe<Array<Education_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Education_Order_By>>;
  where?: InputMaybe<Education_Bool_Exp>;
};

export type Subscription_RootEducation_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEducation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Education_Stream_Cursor_Input>>;
  where?: InputMaybe<Education_Bool_Exp>;
};

export type Subscription_RootEmployee_Country_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Employee_Country_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Country_Repartition_Order_By>>;
  where?: InputMaybe<Employee_Country_Repartition_Bool_Exp>;
};

export type Subscription_RootEmployee_Country_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEmployee_Country_Repartition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Country_Repartition_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Country_Repartition_Bool_Exp>;
};

export type Subscription_RootEmployee_Post_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Employee_Post_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employee_Post_Repartition_Order_By>>;
  where?: InputMaybe<Employee_Post_Repartition_Bool_Exp>;
};

export type Subscription_RootEmployee_Post_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEmployee_Post_Repartition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employee_Post_Repartition_Stream_Cursor_Input>>;
  where?: InputMaybe<Employee_Post_Repartition_Bool_Exp>;
};

export type Subscription_RootEmployeesArgs = {
  distinct_on?: InputMaybe<Array<Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_Order_By>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};

export type Subscription_RootEmployees_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEmployees_From_UsArgs = {
  distinct_on?: InputMaybe<Array<Employees_From_Us_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Employees_From_Us_Order_By>>;
  where?: InputMaybe<Employees_From_Us_Bool_Exp>;
};

export type Subscription_RootEmployees_From_Us_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEmployees_From_Us_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employees_From_Us_Stream_Cursor_Input>>;
  where?: InputMaybe<Employees_From_Us_Bool_Exp>;
};

export type Subscription_RootEmployees_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Employees_Stream_Cursor_Input>>;
  where?: InputMaybe<Employees_Bool_Exp>;
};

export type Subscription_RootEvents_NewsArgs = {
  distinct_on?: InputMaybe<Array<Events_News_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_News_Order_By>>;
  where?: InputMaybe<Events_News_Bool_Exp>;
};

export type Subscription_RootEvents_News_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootEvents_News_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Events_News_Stream_Cursor_Input>>;
  where?: InputMaybe<Events_News_Bool_Exp>;
};

export type Subscription_RootFollow_Relation_Users_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Follow_Relation_Users_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Follow_Relation_Users_Startups_Order_By>>;
  where?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
};

export type Subscription_RootFollow_Relation_Users_Startups_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootFollow_Relation_Users_Startups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Follow_Relation_Users_Startups_Stream_Cursor_Input>>;
  where?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
};

export type Subscription_RootFounder_JobsArgs = {
  distinct_on?: InputMaybe<Array<Founder_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Founder_Jobs_Order_By>>;
  where?: InputMaybe<Founder_Jobs_Bool_Exp>;
};

export type Subscription_RootFounder_Jobs_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootFounder_Jobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Founder_Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Founder_Jobs_Bool_Exp>;
};

export type Subscription_RootFounder_Score_WeightsArgs = {
  distinct_on?: InputMaybe<Array<Founder_Score_Weights_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Founder_Score_Weights_Order_By>>;
  where?: InputMaybe<Founder_Score_Weights_Bool_Exp>;
};

export type Subscription_RootFounder_Score_Weights_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootFounder_Score_Weights_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Founder_Score_Weights_Stream_Cursor_Input>>;
  where?: InputMaybe<Founder_Score_Weights_Bool_Exp>;
};

export type Subscription_RootFoundersArgs = {
  distinct_on?: InputMaybe<Array<Founders_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Founders_Order_By>>;
  where?: InputMaybe<Founders_Bool_Exp>;
};

export type Subscription_RootFounders_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootFounders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Founders_Stream_Cursor_Input>>;
  where?: InputMaybe<Founders_Bool_Exp>;
};

export type Subscription_RootG2_Historical_DataArgs = {
  distinct_on?: InputMaybe<Array<G2_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Historical_Data_Order_By>>;
  where?: InputMaybe<G2_Historical_Data_Bool_Exp>;
};

export type Subscription_RootG2_Historical_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootG2_Historical_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<G2_Historical_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<G2_Historical_Data_Bool_Exp>;
};

export type Subscription_RootG2_IndustryArgs = {
  distinct_on?: InputMaybe<Array<G2_Industry_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Industry_Order_By>>;
  where?: InputMaybe<G2_Industry_Bool_Exp>;
};

export type Subscription_RootG2_Industry_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootG2_Industry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<G2_Industry_Stream_Cursor_Input>>;
  where?: InputMaybe<G2_Industry_Bool_Exp>;
};

export type Subscription_RootG2_Product_CategoryArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Category_Order_By>>;
  where?: InputMaybe<G2_Product_Category_Bool_Exp>;
};

export type Subscription_RootG2_Product_Category_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootG2_Product_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<G2_Product_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<G2_Product_Category_Bool_Exp>;
};

export type Subscription_RootG2_Product_CompetitorsArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Competitors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Competitors_Order_By>>;
  where?: InputMaybe<G2_Product_Competitors_Bool_Exp>;
};

export type Subscription_RootG2_Product_Competitors_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootG2_Product_Competitors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<G2_Product_Competitors_Stream_Cursor_Input>>;
  where?: InputMaybe<G2_Product_Competitors_Bool_Exp>;
};

export type Subscription_RootG2_Product_ReviewsArgs = {
  distinct_on?: InputMaybe<Array<G2_Product_Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<G2_Product_Reviews_Order_By>>;
  where?: InputMaybe<G2_Product_Reviews_Bool_Exp>;
};

export type Subscription_RootG2_Product_Reviews_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootG2_Product_Reviews_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<G2_Product_Reviews_Stream_Cursor_Input>>;
  where?: InputMaybe<G2_Product_Reviews_Bool_Exp>;
};

export type Subscription_RootGlassdoor_DataArgs = {
  distinct_on?: InputMaybe<Array<Glassdoor_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Glassdoor_Data_Order_By>>;
  where?: InputMaybe<Glassdoor_Data_Bool_Exp>;
};

export type Subscription_RootGlassdoor_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGlassdoor_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Glassdoor_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Glassdoor_Data_Bool_Exp>;
};

export type Subscription_RootGlassdoor_TrendArgs = {
  distinct_on?: InputMaybe<Array<Glassdoor_Trend_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Glassdoor_Trend_Order_By>>;
  where?: InputMaybe<Glassdoor_Trend_Bool_Exp>;
};

export type Subscription_RootGlassdoor_Trend_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootGlassdoor_Trend_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Glassdoor_Trend_Stream_Cursor_Input>>;
  where?: InputMaybe<Glassdoor_Trend_Bool_Exp>;
};

export type Subscription_RootGoogle_SerpArgs = {
  distinct_on?: InputMaybe<Array<Google_Serp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Google_Serp_Order_By>>;
  where?: InputMaybe<Google_Serp_Bool_Exp>;
};

export type Subscription_RootGoogle_Serp_By_PkArgs = {
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
};

export type Subscription_RootGoogle_Serp_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Google_Serp_Stream_Cursor_Input>>;
  where?: InputMaybe<Google_Serp_Bool_Exp>;
};

export type Subscription_RootGrowthArgs = {
  distinct_on?: InputMaybe<Array<Growth_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Growth_Order_By>>;
  where?: InputMaybe<Growth_Bool_Exp>;
};

export type Subscription_RootGrowth_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootGrowth_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Growth_Stream_Cursor_Input>>;
  where?: InputMaybe<Growth_Bool_Exp>;
};

export type Subscription_RootIdentity_CardsArgs = {
  distinct_on?: InputMaybe<Array<Identity_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Cards_Order_By>>;
  where?: InputMaybe<Identity_Cards_Bool_Exp>;
};

export type Subscription_RootIdentity_Cards_By_PkArgs = {
  startup_uuid: Scalars['uuid'];
};

export type Subscription_RootIdentity_Cards_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Identity_Cards_Stream_Cursor_Input>>;
  where?: InputMaybe<Identity_Cards_Bool_Exp>;
};

export type Subscription_RootInvestmentsArgs = {
  distinct_on?: InputMaybe<Array<Investments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investments_Order_By>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};

export type Subscription_RootInvestments_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootInvestments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investments_Stream_Cursor_Input>>;
  where?: InputMaybe<Investments_Bool_Exp>;
};

export type Subscription_RootInvestor_Startup_RelationArgs = {
  distinct_on?: InputMaybe<Array<Investor_Startup_Relation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investor_Startup_Relation_Order_By>>;
  where?: InputMaybe<Investor_Startup_Relation_Bool_Exp>;
};

export type Subscription_RootInvestor_Startup_Relation_By_PkArgs = {
  investor_uuid: Scalars['uuid'];
  startup_uuid: Scalars['uuid'];
};

export type Subscription_RootInvestor_Startup_Relation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investor_Startup_Relation_Stream_Cursor_Input>>;
  where?: InputMaybe<Investor_Startup_Relation_Bool_Exp>;
};

export type Subscription_RootInvestorsArgs = {
  distinct_on?: InputMaybe<Array<Investors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Investors_Order_By>>;
  where?: InputMaybe<Investors_Bool_Exp>;
};

export type Subscription_RootInvestors_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootInvestors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Investors_Stream_Cursor_Input>>;
  where?: InputMaybe<Investors_Bool_Exp>;
};

export type Subscription_RootLast_Search_Relation_Users_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Order_By>>;
  where?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
};

export type Subscription_RootLast_Search_Relation_Users_Startups_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLast_Search_Relation_Users_Startups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Last_Search_Relation_Users_Startups_Stream_Cursor_Input>>;
  where?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
};

export type Subscription_RootLinkedin_Employee_Avg_TenureArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Employee_Avg_Tenure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Employee_Avg_Tenure_Order_By>>;
  where?: InputMaybe<Linkedin_Employee_Avg_Tenure_Bool_Exp>;
};

export type Subscription_RootLinkedin_Employee_Avg_Tenure_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLinkedin_Employee_Avg_Tenure_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Linkedin_Employee_Avg_Tenure_Stream_Cursor_Input>>;
  where?: InputMaybe<Linkedin_Employee_Avg_Tenure_Bool_Exp>;
};

export type Subscription_RootLinkedin_EmployeesArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Employees_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Employees_Order_By>>;
  where?: InputMaybe<Linkedin_Employees_Bool_Exp>;
};

export type Subscription_RootLinkedin_Employees_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLinkedin_Employees_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Linkedin_Employees_Stream_Cursor_Input>>;
  where?: InputMaybe<Linkedin_Employees_Bool_Exp>;
};

export type Subscription_RootLinkedin_FollowersArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Followers_Order_By>>;
  where?: InputMaybe<Linkedin_Followers_Bool_Exp>;
};

export type Subscription_RootLinkedin_Followers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLinkedin_Followers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Linkedin_Followers_Stream_Cursor_Input>>;
  where?: InputMaybe<Linkedin_Followers_Bool_Exp>;
};

export type Subscription_RootLinkedin_HeadquartersArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Headquarters_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Headquarters_Order_By>>;
  where?: InputMaybe<Linkedin_Headquarters_Bool_Exp>;
};

export type Subscription_RootLinkedin_Headquarters_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLinkedin_Headquarters_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Linkedin_Headquarters_Stream_Cursor_Input>>;
  where?: InputMaybe<Linkedin_Headquarters_Bool_Exp>;
};

export type Subscription_RootLinkedin_PublicationsArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Publications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Publications_Order_By>>;
  where?: InputMaybe<Linkedin_Publications_Bool_Exp>;
};

export type Subscription_RootLinkedin_Publications_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLinkedin_Publications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Linkedin_Publications_Stream_Cursor_Input>>;
  where?: InputMaybe<Linkedin_Publications_Bool_Exp>;
};

export type Subscription_RootLinkedin_School_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_School_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_School_Repartition_Order_By>>;
  where?: InputMaybe<Linkedin_School_Repartition_Bool_Exp>;
};

export type Subscription_RootLinkedin_School_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLinkedin_School_Repartition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Linkedin_School_Repartition_Stream_Cursor_Input>>;
  where?: InputMaybe<Linkedin_School_Repartition_Bool_Exp>;
};

export type Subscription_RootLinkedin_Skill_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Linkedin_Skill_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Linkedin_Skill_Repartition_Order_By>>;
  where?: InputMaybe<Linkedin_Skill_Repartition_Bool_Exp>;
};

export type Subscription_RootLinkedin_Skill_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootLinkedin_Skill_Repartition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Linkedin_Skill_Repartition_Stream_Cursor_Input>>;
  where?: InputMaybe<Linkedin_Skill_Repartition_Bool_Exp>;
};

export type Subscription_RootMobile_AppArgs = {
  distinct_on?: InputMaybe<Array<Mobile_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobile_App_Order_By>>;
  where?: InputMaybe<Mobile_App_Bool_Exp>;
};

export type Subscription_RootMobile_App_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Mobile_App_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Mobile_App_Order_By>>;
  where?: InputMaybe<Mobile_App_Bool_Exp>;
};

export type Subscription_RootMobile_App_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootMobile_App_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Mobile_App_Stream_Cursor_Input>>;
  where?: InputMaybe<Mobile_App_Bool_Exp>;
};

export type Subscription_RootPercentilesArgs = {
  distinct_on?: InputMaybe<Array<Percentiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Percentiles_Order_By>>;
  where?: InputMaybe<Percentiles_Bool_Exp>;
};

export type Subscription_RootPercentiles_By_PkArgs = {
  category: Scalars['String'];
  percentile: Scalars['Int'];
};

export type Subscription_RootPercentiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Percentiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Percentiles_Bool_Exp>;
};

export type Subscription_RootPredictleads_Job_OpeningsArgs = {
  distinct_on?: InputMaybe<Array<Predictleads_Job_Openings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Predictleads_Job_Openings_Order_By>>;
  where?: InputMaybe<Predictleads_Job_Openings_Bool_Exp>;
};

export type Subscription_RootPredictleads_Job_Openings_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPredictleads_Job_Openings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Predictleads_Job_Openings_Stream_Cursor_Input>>;
  where?: InputMaybe<Predictleads_Job_Openings_Bool_Exp>;
};

export type Subscription_RootSchoolsArgs = {
  distinct_on?: InputMaybe<Array<Schools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Schools_Order_By>>;
  where?: InputMaybe<Schools_Bool_Exp>;
};

export type Subscription_RootSchools_By_PkArgs = {
  schoolName: Scalars['String'];
};

export type Subscription_RootSchools_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Schools_Stream_Cursor_Input>>;
  where?: InputMaybe<Schools_Bool_Exp>;
};

export type Subscription_RootScoresArgs = {
  distinct_on?: InputMaybe<Array<Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scores_Order_By>>;
  where?: InputMaybe<Scores_Bool_Exp>;
};

export type Subscription_RootScores_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Scores_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Scores_Order_By>>;
  where?: InputMaybe<Scores_Bool_Exp>;
};

export type Subscription_RootScores_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootScores_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Scores_Stream_Cursor_Input>>;
  where?: InputMaybe<Scores_Bool_Exp>;
};

export type Subscription_RootSearch_Startup_NameArgs = {
  args: Search_Startup_Name_Args;
  distinct_on?: InputMaybe<Array<Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Startups_Order_By>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Subscription_RootSearch_Startup_Name_AggregateArgs = {
  args: Search_Startup_Name_Args;
  distinct_on?: InputMaybe<Array<Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Startups_Order_By>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Subscription_RootSearch_Startup_Name_In_Identity_CardsArgs = {
  args: Search_Startup_Name_In_Identity_Cards_Args;
  distinct_on?: InputMaybe<Array<Identity_Cards_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Identity_Cards_Order_By>>;
  where?: InputMaybe<Identity_Cards_Bool_Exp>;
};

export type Subscription_RootSimilar_SitesArgs = {
  distinct_on?: InputMaybe<Array<Similar_Sites_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Similar_Sites_Order_By>>;
  where?: InputMaybe<Similar_Sites_Bool_Exp>;
};

export type Subscription_RootSimilar_Sites_By_PkArgs = {
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
};

export type Subscription_RootSimilar_Sites_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Similar_Sites_Stream_Cursor_Input>>;
  where?: InputMaybe<Similar_Sites_Bool_Exp>;
};

export type Subscription_RootSourcingArgs = {
  distinct_on?: InputMaybe<Array<Sourcing_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sourcing_Order_By>>;
  where?: InputMaybe<Sourcing_Bool_Exp>;
};

export type Subscription_RootSourcing_By_PkArgs = {
  sourcingOpportunityName: Scalars['String'];
};

export type Subscription_RootSourcing_StatusArgs = {
  distinct_on?: InputMaybe<Array<Sourcing_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Sourcing_Status_Order_By>>;
  where?: InputMaybe<Sourcing_Status_Bool_Exp>;
};

export type Subscription_RootSourcing_Status_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootSourcing_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sourcing_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Sourcing_Status_Bool_Exp>;
};

export type Subscription_RootSourcing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Sourcing_Stream_Cursor_Input>>;
  where?: InputMaybe<Sourcing_Bool_Exp>;
};

export type Subscription_RootStartupsArgs = {
  distinct_on?: InputMaybe<Array<Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Startups_Order_By>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Subscription_RootStartups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Startups_Order_By>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Subscription_RootStartups_By_PkArgs = {
  uuid: Scalars['uuid'];
};

export type Subscription_RootStartups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Startups_Stream_Cursor_Input>>;
  where?: InputMaybe<Startups_Bool_Exp>;
};

export type Subscription_RootTwitter_FollowersArgs = {
  distinct_on?: InputMaybe<Array<Twitter_Followers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Twitter_Followers_Order_By>>;
  where?: InputMaybe<Twitter_Followers_Bool_Exp>;
};

export type Subscription_RootTwitter_Followers_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootTwitter_Followers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Twitter_Followers_Stream_Cursor_Input>>;
  where?: InputMaybe<Twitter_Followers_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootWeb_Visits_Country_RepartitionArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Country_Repartition_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Country_Repartition_Order_By>>;
  where?: InputMaybe<Web_Visits_Country_Repartition_Bool_Exp>;
};

export type Subscription_RootWeb_Visits_Country_Repartition_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootWeb_Visits_Country_Repartition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Web_Visits_Country_Repartition_Stream_Cursor_Input>>;
  where?: InputMaybe<Web_Visits_Country_Repartition_Bool_Exp>;
};

export type Subscription_RootWeb_Visits_General_DataArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_General_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_General_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_General_Data_Bool_Exp>;
};

export type Subscription_RootWeb_Visits_General_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootWeb_Visits_General_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Web_Visits_General_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Web_Visits_General_Data_Bool_Exp>;
};

export type Subscription_RootWeb_Visits_Historical_DataArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Historical_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
};

export type Subscription_RootWeb_Visits_Historical_Data_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Web_Visits_Historical_Data_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Web_Visits_Historical_Data_Order_By>>;
  where?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
};

export type Subscription_RootWeb_Visits_Historical_Data_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootWeb_Visits_Historical_Data_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Web_Visits_Historical_Data_Stream_Cursor_Input>>;
  where?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "twitter_followers" */
export type Twitter_Followers = {
  __typename?: 'twitter_followers';
  date: Scalars['date'];
  id: Scalars['Int'];
  obs?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** order by aggregate values of table "twitter_followers" */
export type Twitter_Followers_Aggregate_Order_By = {
  avg?: InputMaybe<Twitter_Followers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Twitter_Followers_Max_Order_By>;
  min?: InputMaybe<Twitter_Followers_Min_Order_By>;
  stddev?: InputMaybe<Twitter_Followers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Twitter_Followers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Twitter_Followers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Twitter_Followers_Sum_Order_By>;
  var_pop?: InputMaybe<Twitter_Followers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Twitter_Followers_Var_Samp_Order_By>;
  variance?: InputMaybe<Twitter_Followers_Variance_Order_By>;
};

/** order by avg() on columns of table "twitter_followers" */
export type Twitter_Followers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "twitter_followers". All fields are combined with a logical 'AND'. */
export type Twitter_Followers_Bool_Exp = {
  _and?: InputMaybe<Array<Twitter_Followers_Bool_Exp>>;
  _not?: InputMaybe<Twitter_Followers_Bool_Exp>;
  _or?: InputMaybe<Array<Twitter_Followers_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  obs?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "twitter_followers" */
export type Twitter_Followers_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "twitter_followers" */
export type Twitter_Followers_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "twitter_followers". */
export type Twitter_Followers_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "twitter_followers" */
export enum Twitter_Followers_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  Obs = 'obs',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** order by stddev() on columns of table "twitter_followers" */
export type Twitter_Followers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "twitter_followers" */
export type Twitter_Followers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "twitter_followers" */
export type Twitter_Followers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "twitter_followers" */
export type Twitter_Followers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Twitter_Followers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Twitter_Followers_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  obs?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "twitter_followers" */
export type Twitter_Followers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "twitter_followers" */
export type Twitter_Followers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "twitter_followers" */
export type Twitter_Followers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "twitter_followers" */
export type Twitter_Followers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  obs?: InputMaybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  follow_relation_users_startups: Array<Follow_Relation_Users_Startups>;
  id: Scalars['String'];
  /** An array relationship */
  last_search_relation_users_startups: Array<Last_Search_Relation_Users_Startups>;
  last_seen: Scalars['timestamptz'];
  name: Scalars['String'];
};

/** columns and relationships of "users" */
export type UsersFollow_Relation_Users_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Follow_Relation_Users_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Follow_Relation_Users_Startups_Order_By>>;
  where?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
};

/** columns and relationships of "users" */
export type UsersLast_Search_Relation_Users_StartupsArgs = {
  distinct_on?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Last_Search_Relation_Users_Startups_Order_By>>;
  where?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  follow_relation_users_startups?: InputMaybe<Follow_Relation_Users_Startups_Bool_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  last_search_relation_users_startups?: InputMaybe<Last_Search_Relation_Users_Startups_Bool_Exp>;
  last_seen?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  follow_relation_users_startups_aggregate?: InputMaybe<Follow_Relation_Users_Startups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  last_search_relation_users_startups_aggregate?: InputMaybe<Last_Search_Relation_Users_Startups_Aggregate_Order_By>;
  last_seen?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  LastSeen = 'last_seen',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['String']>;
  last_seen?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition = {
  __typename?: 'web_visits_country_repartition';
  all_time_share_of_visits?: Maybe<Scalars['float8']>;
  /** An object relationship */
  country?: Maybe<Country_Names>;
  country_id?: Maybe<Scalars['Int']>;
  country_name?: Maybe<Scalars['String']>;
  date: Scalars['date'];
  domain?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  top: Scalars['Int'];
};

/** order by aggregate values of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Aggregate_Order_By = {
  avg?: InputMaybe<Web_Visits_Country_Repartition_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Web_Visits_Country_Repartition_Max_Order_By>;
  min?: InputMaybe<Web_Visits_Country_Repartition_Min_Order_By>;
  stddev?: InputMaybe<Web_Visits_Country_Repartition_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Web_Visits_Country_Repartition_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Web_Visits_Country_Repartition_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Web_Visits_Country_Repartition_Sum_Order_By>;
  var_pop?: InputMaybe<Web_Visits_Country_Repartition_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Web_Visits_Country_Repartition_Var_Samp_Order_By>;
  variance?: InputMaybe<Web_Visits_Country_Repartition_Variance_Order_By>;
};

/** order by avg() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Avg_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "web_visits_country_repartition". All fields are combined with a logical 'AND'. */
export type Web_Visits_Country_Repartition_Bool_Exp = {
  _and?: InputMaybe<Array<Web_Visits_Country_Repartition_Bool_Exp>>;
  _not?: InputMaybe<Web_Visits_Country_Repartition_Bool_Exp>;
  _or?: InputMaybe<Array<Web_Visits_Country_Repartition_Bool_Exp>>;
  all_time_share_of_visits?: InputMaybe<Float8_Comparison_Exp>;
  country?: InputMaybe<Country_Names_Bool_Exp>;
  country_id?: InputMaybe<Int_Comparison_Exp>;
  country_name?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  top?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Max_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Min_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "web_visits_country_repartition". */
export type Web_Visits_Country_Repartition_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country?: InputMaybe<Country_Names_Order_By>;
  country_id?: InputMaybe<Order_By>;
  country_name?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** select columns of table "web_visits_country_repartition" */
export enum Web_Visits_Country_Repartition_Select_Column {
  /** column name */
  AllTimeShareOfVisits = 'all_time_share_of_visits',
  /** column name */
  CountryId = 'country_id',
  /** column name */
  CountryName = 'country_name',
  /** column name */
  Date = 'date',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  Top = 'top',
}

/** order by stddev() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Stddev_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Stddev_Pop_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Stddev_Samp_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Web_Visits_Country_Repartition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Web_Visits_Country_Repartition_Stream_Cursor_Value_Input = {
  all_time_share_of_visits?: InputMaybe<Scalars['float8']>;
  country_id?: InputMaybe<Scalars['Int']>;
  country_name?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  top?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Sum_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Var_Pop_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Var_Samp_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "web_visits_country_repartition" */
export type Web_Visits_Country_Repartition_Variance_Order_By = {
  all_time_share_of_visits?: InputMaybe<Order_By>;
  country_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  top?: InputMaybe<Order_By>;
};

/** columns and relationships of "web_visits_general_data" */
export type Web_Visits_General_Data = {
  __typename?: 'web_visits_general_data';
  bounce_rate?: Maybe<Scalars['float8']>;
  date: Scalars['date'];
  domain?: Maybe<Scalars['String']>;
  global_rank?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  number_of_refering_websites?: Maybe<Scalars['Int']>;
  organic_search_share?: Maybe<Scalars['float8']>;
  page_per_visits?: Maybe<Scalars['float8']>;
  paid_search_share?: Maybe<Scalars['float8']>;
  percentage_of_direct_traffic?: Maybe<Scalars['float8']>;
  percentage_of_traffic_from_mail?: Maybe<Scalars['float8']>;
  percentage_of_traffic_from_paid_referrals?: Maybe<Scalars['float8']>;
  percentage_of_traffic_from_referrals?: Maybe<Scalars['float8']>;
  percentage_of_traffic_from_search?: Maybe<Scalars['float8']>;
  percentage_of_traffic_from_social?: Maybe<Scalars['float8']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
  time_on_website?: Maybe<Scalars['float8']>;
};

/** order by aggregate values of table "web_visits_general_data" */
export type Web_Visits_General_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Web_Visits_General_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Web_Visits_General_Data_Max_Order_By>;
  min?: InputMaybe<Web_Visits_General_Data_Min_Order_By>;
  stddev?: InputMaybe<Web_Visits_General_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Web_Visits_General_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Web_Visits_General_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Web_Visits_General_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Web_Visits_General_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Web_Visits_General_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Web_Visits_General_Data_Variance_Order_By>;
};

/** order by avg() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Avg_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "web_visits_general_data". All fields are combined with a logical 'AND'. */
export type Web_Visits_General_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Web_Visits_General_Data_Bool_Exp>>;
  _not?: InputMaybe<Web_Visits_General_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Web_Visits_General_Data_Bool_Exp>>;
  bounce_rate?: InputMaybe<Float8_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  global_rank?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  number_of_refering_websites?: InputMaybe<Int_Comparison_Exp>;
  organic_search_share?: InputMaybe<Float8_Comparison_Exp>;
  page_per_visits?: InputMaybe<Float8_Comparison_Exp>;
  paid_search_share?: InputMaybe<Float8_Comparison_Exp>;
  percentage_of_direct_traffic?: InputMaybe<Float8_Comparison_Exp>;
  percentage_of_traffic_from_mail?: InputMaybe<Float8_Comparison_Exp>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Float8_Comparison_Exp>;
  percentage_of_traffic_from_referrals?: InputMaybe<Float8_Comparison_Exp>;
  percentage_of_traffic_from_search?: InputMaybe<Float8_Comparison_Exp>;
  percentage_of_traffic_from_social?: InputMaybe<Float8_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
  time_on_website?: InputMaybe<Float8_Comparison_Exp>;
};

/** order by max() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Max_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Min_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "web_visits_general_data". */
export type Web_Visits_General_Data_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** select columns of table "web_visits_general_data" */
export enum Web_Visits_General_Data_Select_Column {
  /** column name */
  BounceRate = 'bounce_rate',
  /** column name */
  Date = 'date',
  /** column name */
  Domain = 'domain',
  /** column name */
  GlobalRank = 'global_rank',
  /** column name */
  Id = 'id',
  /** column name */
  NumberOfReferingWebsites = 'number_of_refering_websites',
  /** column name */
  OrganicSearchShare = 'organic_search_share',
  /** column name */
  PagePerVisits = 'page_per_visits',
  /** column name */
  PaidSearchShare = 'paid_search_share',
  /** column name */
  PercentageOfDirectTraffic = 'percentage_of_direct_traffic',
  /** column name */
  PercentageOfTrafficFromMail = 'percentage_of_traffic_from_mail',
  /** column name */
  PercentageOfTrafficFromPaidReferrals = 'percentage_of_traffic_from_paid_referrals',
  /** column name */
  PercentageOfTrafficFromReferrals = 'percentage_of_traffic_from_referrals',
  /** column name */
  PercentageOfTrafficFromSearch = 'percentage_of_traffic_from_search',
  /** column name */
  PercentageOfTrafficFromSocial = 'percentage_of_traffic_from_social',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
  /** column name */
  TimeOnWebsite = 'time_on_website',
}

/** order by stddev() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Stddev_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Stddev_Pop_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Stddev_Samp_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "web_visits_general_data" */
export type Web_Visits_General_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Web_Visits_General_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Web_Visits_General_Data_Stream_Cursor_Value_Input = {
  bounce_rate?: InputMaybe<Scalars['float8']>;
  date?: InputMaybe<Scalars['date']>;
  domain?: InputMaybe<Scalars['String']>;
  global_rank?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  number_of_refering_websites?: InputMaybe<Scalars['Int']>;
  organic_search_share?: InputMaybe<Scalars['float8']>;
  page_per_visits?: InputMaybe<Scalars['float8']>;
  paid_search_share?: InputMaybe<Scalars['float8']>;
  percentage_of_direct_traffic?: InputMaybe<Scalars['float8']>;
  percentage_of_traffic_from_mail?: InputMaybe<Scalars['float8']>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Scalars['float8']>;
  percentage_of_traffic_from_referrals?: InputMaybe<Scalars['float8']>;
  percentage_of_traffic_from_search?: InputMaybe<Scalars['float8']>;
  percentage_of_traffic_from_social?: InputMaybe<Scalars['float8']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
  time_on_website?: InputMaybe<Scalars['float8']>;
};

/** order by sum() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Sum_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Var_Pop_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Var_Samp_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "web_visits_general_data" */
export type Web_Visits_General_Data_Variance_Order_By = {
  bounce_rate?: InputMaybe<Order_By>;
  global_rank?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_refering_websites?: InputMaybe<Order_By>;
  organic_search_share?: InputMaybe<Order_By>;
  page_per_visits?: InputMaybe<Order_By>;
  paid_search_share?: InputMaybe<Order_By>;
  percentage_of_direct_traffic?: InputMaybe<Order_By>;
  percentage_of_traffic_from_mail?: InputMaybe<Order_By>;
  percentage_of_traffic_from_paid_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_referrals?: InputMaybe<Order_By>;
  percentage_of_traffic_from_search?: InputMaybe<Order_By>;
  percentage_of_traffic_from_social?: InputMaybe<Order_By>;
  time_on_website?: InputMaybe<Order_By>;
};

/** columns and relationships of "web_visits_historical_data" */
export type Web_Visits_Historical_Data = {
  __typename?: 'web_visits_historical_data';
  date: Scalars['date'];
  domain?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  inBubble?: Maybe<Scalars['Boolean']>;
  number_of_visits?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  startup: Startups;
  startup_name: Scalars['String'];
  startup_uuid: Scalars['uuid'];
};

/** aggregated selection of "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Aggregate = {
  __typename?: 'web_visits_historical_data_aggregate';
  aggregate?: Maybe<Web_Visits_Historical_Data_Aggregate_Fields>;
  nodes: Array<Web_Visits_Historical_Data>;
};

export type Web_Visits_Historical_Data_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Web_Visits_Historical_Data_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Web_Visits_Historical_Data_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Web_Visits_Historical_Data_Aggregate_Bool_Exp_Count>;
};

export type Web_Visits_Historical_Data_Aggregate_Bool_Exp_Bool_And = {
  arguments: Web_Visits_Historical_Data_Select_Column_Web_Visits_Historical_Data_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Web_Visits_Historical_Data_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Web_Visits_Historical_Data_Select_Column_Web_Visits_Historical_Data_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Web_Visits_Historical_Data_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Web_Visits_Historical_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Aggregate_Fields = {
  __typename?: 'web_visits_historical_data_aggregate_fields';
  avg?: Maybe<Web_Visits_Historical_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Web_Visits_Historical_Data_Max_Fields>;
  min?: Maybe<Web_Visits_Historical_Data_Min_Fields>;
  stddev?: Maybe<Web_Visits_Historical_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Web_Visits_Historical_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Web_Visits_Historical_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Web_Visits_Historical_Data_Sum_Fields>;
  var_pop?: Maybe<Web_Visits_Historical_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Web_Visits_Historical_Data_Var_Samp_Fields>;
  variance?: Maybe<Web_Visits_Historical_Data_Variance_Fields>;
};

/** aggregate fields of "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Web_Visits_Historical_Data_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Aggregate_Order_By = {
  avg?: InputMaybe<Web_Visits_Historical_Data_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Web_Visits_Historical_Data_Max_Order_By>;
  min?: InputMaybe<Web_Visits_Historical_Data_Min_Order_By>;
  stddev?: InputMaybe<Web_Visits_Historical_Data_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Web_Visits_Historical_Data_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Web_Visits_Historical_Data_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Web_Visits_Historical_Data_Sum_Order_By>;
  var_pop?: InputMaybe<Web_Visits_Historical_Data_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Web_Visits_Historical_Data_Var_Samp_Order_By>;
  variance?: InputMaybe<Web_Visits_Historical_Data_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Web_Visits_Historical_Data_Avg_Fields = {
  __typename?: 'web_visits_historical_data_avg_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_visits?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "web_visits_historical_data". All fields are combined with a logical 'AND'. */
export type Web_Visits_Historical_Data_Bool_Exp = {
  _and?: InputMaybe<Array<Web_Visits_Historical_Data_Bool_Exp>>;
  _not?: InputMaybe<Web_Visits_Historical_Data_Bool_Exp>;
  _or?: InputMaybe<Array<Web_Visits_Historical_Data_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  inBubble?: InputMaybe<Boolean_Comparison_Exp>;
  number_of_visits?: InputMaybe<Bigint_Comparison_Exp>;
  startup?: InputMaybe<Startups_Bool_Exp>;
  startup_name?: InputMaybe<String_Comparison_Exp>;
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Web_Visits_Historical_Data_Max_Fields = {
  __typename?: 'web_visits_historical_data_max_fields';
  date?: Maybe<Scalars['date']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  number_of_visits?: Maybe<Scalars['bigint']>;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Web_Visits_Historical_Data_Min_Fields = {
  __typename?: 'web_visits_historical_data_min_fields';
  date?: Maybe<Scalars['date']>;
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  number_of_visits?: Maybe<Scalars['bigint']>;
  startup_name?: Maybe<Scalars['String']>;
  startup_uuid?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "web_visits_historical_data". */
export type Web_Visits_Historical_Data_Order_By = {
  date?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inBubble?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
  startup?: InputMaybe<Startups_Order_By>;
  startup_name?: InputMaybe<Order_By>;
  startup_uuid?: InputMaybe<Order_By>;
};

/** select columns of table "web_visits_historical_data" */
export enum Web_Visits_Historical_Data_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  InBubble = 'inBubble',
  /** column name */
  NumberOfVisits = 'number_of_visits',
  /** column name */
  StartupName = 'startup_name',
  /** column name */
  StartupUuid = 'startup_uuid',
}

/** select "web_visits_historical_data_aggregate_bool_exp_bool_and_arguments_columns" columns of table "web_visits_historical_data" */
export enum Web_Visits_Historical_Data_Select_Column_Web_Visits_Historical_Data_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  InBubble = 'inBubble',
}

/** select "web_visits_historical_data_aggregate_bool_exp_bool_or_arguments_columns" columns of table "web_visits_historical_data" */
export enum Web_Visits_Historical_Data_Select_Column_Web_Visits_Historical_Data_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  InBubble = 'inBubble',
}

/** aggregate stddev on columns */
export type Web_Visits_Historical_Data_Stddev_Fields = {
  __typename?: 'web_visits_historical_data_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_visits?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Web_Visits_Historical_Data_Stddev_Pop_Fields = {
  __typename?: 'web_visits_historical_data_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_visits?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Web_Visits_Historical_Data_Stddev_Samp_Fields = {
  __typename?: 'web_visits_historical_data_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_visits?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Web_Visits_Historical_Data_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Web_Visits_Historical_Data_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  inBubble?: InputMaybe<Scalars['Boolean']>;
  number_of_visits?: InputMaybe<Scalars['bigint']>;
  startup_name?: InputMaybe<Scalars['String']>;
  startup_uuid?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Web_Visits_Historical_Data_Sum_Fields = {
  __typename?: 'web_visits_historical_data_sum_fields';
  id?: Maybe<Scalars['Int']>;
  number_of_visits?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Web_Visits_Historical_Data_Var_Pop_Fields = {
  __typename?: 'web_visits_historical_data_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_visits?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Web_Visits_Historical_Data_Var_Samp_Fields = {
  __typename?: 'web_visits_historical_data_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_visits?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Web_Visits_Historical_Data_Variance_Fields = {
  __typename?: 'web_visits_historical_data_variance_fields';
  id?: Maybe<Scalars['Float']>;
  number_of_visits?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "web_visits_historical_data" */
export type Web_Visits_Historical_Data_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  number_of_visits?: InputMaybe<Order_By>;
};

export type Get_Nb_Startups_In_ScopeQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Nb_Startups_In_ScopeQuery = {
  __typename?: 'query_root';
  startups_aggregate: {
    __typename?: 'startups_aggregate';
    aggregate?: { __typename?: 'startups_aggregate_fields'; count: number } | null;
  };
};

export type Get_Last_NewsQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Last_NewsQuery = {
  __typename?: 'query_root';
  news: Array<{
    __typename?: 'events_news';
    startup_uuid: any;
    startup_name: string;
    type_of_event?: string | null;
    url?: string | null;
    publisher?: string | null;
    event_description?: string | null;
    date: any;
    startup: { __typename?: 'startups'; logo_url?: string | null };
  }>;
};

export type Get_Portfolio_NewsQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Portfolio_NewsQuery = {
  __typename?: 'query_root';
  news: Array<{
    __typename?: 'events_news';
    startup_uuid: any;
    startup_name: string;
    type_of_event?: string | null;
    url?: string | null;
    publisher?: string | null;
    event_description?: string | null;
    date: any;
    startup: { __typename?: 'startups'; logo_url?: string | null };
  }>;
};

export type Get_Followed_Startups_NewsQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Followed_Startups_NewsQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    follow_relation_users_startups: Array<{
      __typename?: 'follow_relation_users_startups';
      startup: {
        __typename?: 'startups';
        events_news: Array<{
          __typename?: 'events_news';
          startup_uuid: any;
          startup_name: string;
          type_of_event?: string | null;
          url?: string | null;
          publisher?: string | null;
          event_description?: string | null;
          date: any;
          startup: { __typename?: 'startups'; logo_url?: string | null };
        }>;
      };
    }>;
  }>;
};

export type Get_Top_Startups_On_Priority_ScoreQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['date']>;
}>;

export type Get_Top_Startups_On_Priority_ScoreQuery = {
  __typename?: 'query_root';
  scores: Array<{
    __typename?: 'scores';
    startup_uuid: any;
    startup_name: string;
    priorityScore?: any | null;
    growth_score_adaptative?: any | null;
    USpresenceScore?: any | null;
    startup: {
      __typename?: 'startups';
      totalFundingAmountInUSD?: any | null;
      shortDescription?: string | null;
      logo_url?: string | null;
    };
  }>;
};

export type Get_Top_Startups_On_Growth_Score_AdaptativeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['date']>;
}>;

export type Get_Top_Startups_On_Growth_Score_AdaptativeQuery = {
  __typename?: 'query_root';
  scores: Array<{
    __typename?: 'scores';
    startup_uuid: any;
    startup_name: string;
    priorityScore?: any | null;
    growth_score_adaptative?: any | null;
    USpresenceScore?: any | null;
    startup: {
      __typename?: 'startups';
      totalFundingAmountInUSD?: any | null;
      shortDescription?: string | null;
      logo_url?: string | null;
    };
  }>;
};

export type Top_Last_Searched_StartupsQueryVariables = Exact<{ [key: string]: never }>;

export type Top_Last_Searched_StartupsQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    last_search_relation_users_startups: Array<{
      __typename?: 'last_search_relation_users_startups';
      startup: {
        __typename?: 'startups';
        name: string;
        uuid: any;
        totalFundingAmountInUSD?: any | null;
        shortDescription?: string | null;
        logo_url?: string | null;
        scores: Array<{
          __typename?: 'scores';
          priorityScore?: any | null;
          growth_score_adaptative?: any | null;
          USpresenceScore?: any | null;
        }>;
      };
    }>;
  }>;
};

export type Search_BarQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
}>;

export type Search_BarQuery = {
  __typename?: 'query_root';
  search_startup_name: Array<{
    __typename?: 'startups';
    uuid: any;
    name: string;
    logo_url?: string | null;
    link?: string | null;
  }>;
};

export type Get_Last_Searched_Startups_By_UserQueryVariables = Exact<{ [key: string]: never }>;

export type Get_Last_Searched_Startups_By_UserQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    last_search_relation_users_startups: Array<{
      __typename?: 'last_search_relation_users_startups';
      startup: { __typename?: 'startups'; uuid: any; name: string; logo_url?: string | null };
    }>;
  }>;
};

export type Save_Searched_Startup_For_UserMutationVariables = Exact<{
  startup_uuid?: InputMaybe<Scalars['uuid']>;
}>;

export type Save_Searched_Startup_For_UserMutation = {
  __typename?: 'mutation_root';
  insert_last_search_relation_users_startups_one?: {
    __typename?: 'last_search_relation_users_startups';
    startup: { __typename?: 'startups'; name: string; logo_url?: string | null; uuid: any };
  } | null;
};

export type Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery = {
  __typename?: 'query_root';
  glassdoor_data: Array<{
    __typename?: 'glassdoor_data';
    date: any;
    ratings_overall?: any | null;
    ratings_all_review_count?: any | null;
  }>;
};

export type Get_Glassdoor_ScoresQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Glassdoor_ScoresQuery = {
  __typename?: 'query_root';
  glassdoor_data: Array<{
    __typename?: 'glassdoor_data';
    date: any;
    ratings_work_life_balance?: any | null;
    ratings_ceo_approval?: any | null;
    ratings_culture_values?: any | null;
    ratings_career_opportunities?: any | null;
  }>;
};

export type Get_Bcorp_ScoresQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Bcorp_ScoresQuery = {
  __typename?: 'query_root';
  bcorp_startups: Array<{
    __typename?: 'bcorp_startups';
    bcorp_score?: any | null;
    community_score?: any | null;
    environment_score?: any | null;
    governance_score?: any | null;
    worker_score?: any | null;
    customer_score?: any | null;
    date: any;
  }>;
};

export type Impact_Investors_ListQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Impact_Investors_ListQuery = {
  __typename?: 'query_root';
  investor_startup_relation: Array<{
    __typename?: 'investor_startup_relation';
    investor_name: string;
    investor: {
      __typename?: 'investors';
      investor_country?: string | null;
      is_top_investor?: boolean | null;
      is_impact_fund?: boolean | null;
      crunchbase_link?: string | null;
      crm_link?: string | null;
      linkedin_link?: string | null;
      website_url?: string | null;
    };
  }>;
};

export type Get_Startup_Identity_CardQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Startup_Identity_CardQuery = {
  __typename?: 'query_root';
  identity_cards: Array<{
    __typename?: 'identity_cards';
    product_features?: string | null;
    technologies?: string | null;
    industries?: string | null;
    business_model?: string | null;
    target_clients?: string | null;
    startup_name: string;
    startup_uuid: any;
  }>;
};

export type Get_Startup_CompetitorsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Startup_CompetitorsQuery = {
  __typename?: 'query_root';
  competition_classification: Array<{
    __typename?: 'competition_classification';
    startup_uuid_1: any;
    startup_name_1: string;
    startup_uuid_2: any;
    startup_name_2: string;
    feedback?: string | null;
    prediction: boolean;
    probability?: any | null;
    confidence?: any | null;
  }>;
};

export type Get_Startup_Competitors_DataQueryVariables = Exact<{
  uuids: Array<Scalars['uuid']> | Scalars['uuid'];
  country_repartition_last_date?: InputMaybe<Scalars['date']>;
  historical_data_last_date?: InputMaybe<Scalars['date']>;
}>;

export type Get_Startup_Competitors_DataQuery = {
  __typename?: 'query_root';
  startups: Array<{
    __typename?: 'startups';
    name: string;
    uuid: any;
    logo_url?: string | null;
    link?: string | null;
    linkedin?: string | null;
    description?: string | null;
    shortDescription?: string | null;
    headquartersCountry?: string | null;
    inception?: any | null;
    totalFundingAmountInUSD?: any | null;
    identity_card?: {
      __typename?: 'identity_cards';
      product_features?: string | null;
      technologies?: string | null;
      industries?: string | null;
      business_model?: string | null;
      target_clients?: string | null;
      startup_name: string;
      startup_uuid: any;
    } | null;
    scores: Array<{
      __typename?: 'scores';
      priorityScore?: any | null;
      growth_score_adaptative?: any | null;
      us_potential_score?: any | null;
      USpresenceScore?: any | null;
      impact_score?: any | null;
      date: any;
      startup_uuid: any;
      startup_name: string;
    }>;
    employees: Array<{
      __typename?: 'employees';
      date: any;
      obs?: any | null;
      startup_uuid: any;
      startup_name: string;
      data_source?: string | null;
    }>;
    employee_country_repartitions: Array<{
      __typename?: 'employee_country_repartition';
      date: any;
      obs?: any | null;
      country?: string | null;
      startup_uuid: any;
      startup_name: string;
    }>;
    growths: Array<{
      __typename?: 'growth';
      date: any;
      startup_uuid: any;
      startup_name: string;
      annualized_growth_employee?: any | null;
      three_month_growth_employee?: any | null;
      annualized_growth_web_visit?: any | null;
      three_month_growth_web_visit?: any | null;
      weight_employee?: any | null;
      weight_web_visit?: any | null;
    }>;
  }>;
};

export type Get_Similarweb_CompetitorsQueryVariables = Exact<{
  uuids: Array<Scalars['uuid']> | Scalars['uuid'];
  ref_uuid: Scalars['uuid'];
}>;

export type Get_Similarweb_CompetitorsQuery = {
  __typename?: 'query_root';
  similar_sites: Array<{
    __typename?: 'similar_sites';
    startup_uuid_1: any;
    startup_uuid_2: any;
    startup_name_1?: string | null;
    startup_name_2?: string | null;
  }>;
};

export type Get_Google_CompetitorsQueryVariables = Exact<{
  uuids: Array<Scalars['uuid']> | Scalars['uuid'];
  ref_uuid: Scalars['uuid'];
}>;

export type Get_Google_CompetitorsQuery = {
  __typename?: 'query_root';
  google_serp: Array<{
    __typename?: 'google_serp';
    startup_uuid_1: any;
    startup_uuid_2: any;
    startup_name_1?: string | null;
    startup_name_2?: string | null;
    suggestion?: string | null;
  }>;
};

export type Get_G2_CompetitorsQueryVariables = Exact<{
  uuids: Array<Scalars['uuid']> | Scalars['uuid'];
  ref_uuid: Scalars['uuid'];
}>;

export type Get_G2_CompetitorsQuery = {
  __typename?: 'query_root';
  g2_product_competitors: Array<{
    __typename?: 'g2_product_competitors';
    competitor_uuid?: any | null;
    competitorName?: string | null;
    startup_uuid: any;
    startup_name: string;
  }>;
};

export type Set_FeedbackMutationVariables = Exact<{
  startup_uuid_1: Scalars['uuid'];
  startup_uuid_2: Scalars['uuid'];
  feedback: Scalars['String'];
}>;

export type Set_FeedbackMutation = {
  __typename?: 'mutation_root';
  update_competition_classification_by_pk?: {
    __typename?: 'competition_classification';
    startup_uuid_1: any;
    startup_uuid_2: any;
    startup_name_1: string;
    startup_name_2: string;
    feedback?: string | null;
  } | null;
};

export type Get_Growth_Score_WeightsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Growth_Score_WeightsQuery = {
  __typename?: 'query_root';
  growth: Array<{
    __typename?: 'growth';
    date: any;
    weight_dau?: any | null;
    weight_downloads?: any | null;
    weight_employee?: any | null;
    weight_linkedin?: any | null;
    weight_twitter?: any | null;
    weight_web_visit?: any | null;
    weight_g2?: any | null;
    weight_producthunt?: any | null;
    weight_trustpilot?: any | null;
    weight_github?: any | null;
  }>;
};

export type Get_Growth_Score_Adaptative_HistoryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Growth_Score_Adaptative_HistoryQuery = {
  __typename?: 'query_root';
  scores: Array<{ __typename?: 'scores'; growth_score_adaptative?: any | null; date: any }>;
};

export type Get_Webvisits_HistoryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Webvisits_HistoryQuery = {
  __typename?: 'query_root';
  web_visits_historical_data: Array<{
    __typename?: 'web_visits_historical_data';
    date: any;
    number_of_visits?: any | null;
    domain?: string | null;
  }>;
};

export type Get_Linkedin_Followers_HistoryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Linkedin_Followers_HistoryQuery = {
  __typename?: 'query_root';
  linkedin_followers: Array<{ __typename?: 'linkedin_followers'; date: any; obs?: any | null }>;
};

export type Get_Employees_HistoryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Employees_HistoryQuery = {
  __typename?: 'query_root';
  employees: Array<{ __typename?: 'employees'; obs?: any | null; date: any }>;
};

export type Get_Twitter_Followers_HistoryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Twitter_Followers_HistoryQuery = {
  __typename?: 'query_root';
  twitter_followers: Array<{ __typename?: 'twitter_followers'; obs?: any | null; date: any }>;
};

export type Get_Mobile_App_HistoryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Mobile_App_HistoryQuery = {
  __typename?: 'query_root';
  mobile_app: Array<{
    __typename?: 'mobile_app';
    nb_dau?: number | null;
    nb_downloads?: number | null;
    date: any;
    app_id: string;
  }>;
};

export type Get_G2_HistoryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_G2_HistoryQuery = {
  __typename?: 'query_root';
  g2_historical_data: Array<{ __typename?: 'g2_historical_data'; number_of_reviews?: number | null; date: any }>;
};

export type Get_Metric_Growth_MomentumsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Metric_Growth_MomentumsQuery = {
  __typename?: 'query_root';
  growth: Array<{
    __typename?: 'growth';
    date: any;
    annualized_growth_dau?: any | null;
    annualized_growth_downloads?: any | null;
    annualized_growth_employee?: any | null;
    annualized_growth_linkedin?: any | null;
    annualized_growth_twitter?: any | null;
    annualized_growth_web_visit?: any | null;
    annualized_growth_g2?: any | null;
    three_month_growth_dau?: any | null;
    three_month_growth_downloads?: any | null;
    three_month_growth_employee?: any | null;
    three_month_growth_linkedin?: any | null;
    three_month_growth_percentile?: any | null;
    three_month_growth_twitter?: any | null;
    three_month_growth_web_visit?: any | null;
    three_month_growth_g2?: any | null;
  }>;
  scores: Array<{
    __typename?: 'scores';
    annual_growth_score_dau?: any | null;
    annual_growth_score_downloads?: any | null;
    annual_growth_score_employee?: any | null;
    annual_growth_score_linkedin?: any | null;
    annual_growth_score_twitter?: any | null;
    annual_growth_score_web_visit?: any | null;
    annual_growth_score_g2?: any | null;
    three_months_growth_score_dau?: any | null;
    three_months_growth_score_downloads?: any | null;
    three_months_growth_score_employee?: any | null;
    three_months_growth_score_linkedin?: any | null;
    three_months_growth_score_twitter?: any | null;
    three_months_growth_score_web_visit?: any | null;
    three_months_growth_score_g2?: any | null;
  }>;
};

export type America_Investors_ListQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type America_Investors_ListQuery = {
  __typename?: 'query_root';
  investor_startup_relation: Array<{
    __typename?: 'investor_startup_relation';
    investor_name: string;
    investor: {
      __typename?: 'investors';
      investor_country?: string | null;
      is_top_investor?: boolean | null;
      is_impact_fund?: boolean | null;
      crunchbase_link?: string | null;
      crm_link?: string | null;
      linkedin_link?: string | null;
      website_url?: string | null;
      logo_url?: string | null;
    };
  }>;
};

export type Get_Us_Webvisits_Proportion_And_GrowthQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Us_Webvisits_Proportion_And_GrowthQuery = {
  __typename?: 'query_root';
  growth: Array<{
    __typename?: 'growth';
    monthly_growth_us_web_visits?: any | null;
    quarterly_growth_us_web_visits?: any | null;
    date: any;
  }>;
  web_visits_country_repartition: Array<{
    __typename?: 'web_visits_country_repartition';
    date: any;
    all_time_share_of_visits?: any | null;
    country?: { __typename?: 'country_names'; country_name: string } | null;
  }>;
};

export type Get_Webvisit_Country_Repartition_Last_DateQueryVariables = Exact<{
  startup?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Webvisit_Country_Repartition_Last_DateQuery = {
  __typename?: 'query_root';
  web_visits_country_repartition: Array<{ __typename?: 'web_visits_country_repartition'; date: any }>;
};

export type Get_Webvisit_Country_RepartitionQueryVariables = Exact<{
  startup?: InputMaybe<Scalars['uuid']>;
  _date?: InputMaybe<Scalars['date']>;
}>;

export type Get_Webvisit_Country_RepartitionQuery = {
  __typename?: 'query_root';
  web_visits_country_repartition: Array<{
    __typename?: 'web_visits_country_repartition';
    date: any;
    top: number;
    all_time_share_of_visits?: any | null;
    country?: { __typename?: 'country_names'; country_name: string } | null;
  }>;
};

export type Get_Startup_Name_Logo_LinksQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Startup_Name_Logo_LinksQuery = {
  __typename?: 'query_root';
  startups: Array<{
    __typename?: 'startups';
    uuid: any;
    logo_url?: string | null;
    name: string;
    link?: string | null;
    linkedin?: string | null;
    twitter?: string | null;
    glassdoor?: string | null;
    crunchbase_link?: string | null;
    g2_link?: string | null;
    description?: string | null;
    is_fitting_sourcing_criterias?: boolean | null;
    totalFundingAmountInUSD?: any | null;
    headquartersCountry?: string | null;
    added_manually?: boolean | null;
    attio_record_id?: string | null;
    is_led_by_europeans?: string | null;
    data_sources_last_update?: {
      __typename?: 'data_sources_last_update';
      linkedIn?: any | null;
      glassdoor?: any | null;
      crunchbase?: any | null;
      similarweb?: any | null;
      linkedin_founders?: any | null;
      g2?: any | null;
      twitter?: any | null;
    } | null;
  }>;
};

export type Get_Sourcing_Criteria_For_CountryQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['String']>;
}>;

export type Get_Sourcing_Criteria_For_CountryQuery = {
  __typename?: 'query_root';
  country_of_startups_for_sourcing: Array<{
    __typename?: 'country_of_startups_for_sourcing';
    minimumAmountRaised: number;
    countryName: string;
  }>;
};

export type Get_Funding_And_HqQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Funding_And_HqQuery = {
  __typename?: 'query_root';
  startups: Array<{
    __typename?: 'startups';
    uuid: any;
    lastFundingAmountInUSD?: any | null;
    headquartersCountry?: string | null;
    lastFundingType?: string | null;
    totalFundingAmountInUSD?: any | null;
  }>;
};

export type Get_NewsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_NewsQuery = {
  __typename?: 'query_root';
  news: Array<{
    __typename?: 'events_news';
    startup_name: string;
    type_of_event?: string | null;
    url?: string | null;
    publisher?: string | null;
    event_description?: string | null;
    date: any;
    icon_url?: string | null;
  }>;
};

export type Get_Last_Growth_Score_AdaptativeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Last_Growth_Score_AdaptativeQuery = {
  __typename?: 'query_root';
  scores: Array<{
    __typename?: 'scores';
    growth_score_adaptative?: any | null;
    confidenceIndex?: any | null;
    date: any;
    percentile_growth_score_adaptative?: any | null;
  }>;
};

export type Get_Last_Priority_ScoreQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Last_Priority_ScoreQuery = {
  __typename?: 'query_root';
  scores: Array<{
    __typename?: 'scores';
    date: any;
    priorityScore?: any | null;
    priorityPercentile?: any | null;
    confidence_priority_score?: any | null;
  }>;
};

export type Get_Last_USpresence_ScoreQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Last_USpresence_ScoreQuery = {
  __typename?: 'query_root';
  scores: Array<{
    __typename?: 'scores';
    date: any;
    USpresenceScore?: any | null;
    confidence_us_presence_score?: any | null;
    percentile_us_presence_score?: any | null;
  }>;
};

export type Get_UsPotentialQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_UsPotentialQuery = {
  __typename?: 'query_root';
  scores: Array<{
    __typename?: 'scores';
    date: any;
    us_potential_score?: any | null;
    us_potential_score_percentile?: any | null;
  }>;
};

export type Get_Last_Impact_ScoreQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Last_Impact_ScoreQuery = {
  __typename?: 'query_root';
  scores: Array<{
    __typename?: 'scores';
    date: any;
    impact_score?: any | null;
    percentile_impact_score?: any | null;
    confidence_impact_score?: any | null;
  }>;
};

export type Get_Last_Cofounder_ScoreQueryVariables = Exact<{
  eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Last_Cofounder_ScoreQuery = {
  __typename?: 'query_root';
  cofounders: Array<{
    __typename?: 'cofounders';
    cofounderScore?: any | null;
    confidenceScore?: number | null;
    percentile_cofounder_score?: any | null;
  }>;
};

export type Get_Nb_And_Growth_EmployeeQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Nb_And_Growth_EmployeeQuery = {
  __typename?: 'query_root';
  startups: Array<{
    __typename?: 'startups';
    growths: Array<{ __typename?: 'growth'; annualized_growth_employee?: any | null }>;
    employees: Array<{ __typename?: 'employees'; obs?: any | null }>;
  }>;
};

export type Investors_ListQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Investors_ListQuery = {
  __typename?: 'query_root';
  investor_startup_relation: Array<{
    __typename?: 'investor_startup_relation';
    investor_name: string;
    investor: {
      __typename?: 'investors';
      investor_country?: string | null;
      is_top_investor?: boolean | null;
      is_impact_fund?: boolean | null;
      crunchbase_link?: string | null;
      logo_url?: string | null;
      crm_link?: string | null;
      linkedin_link?: string | null;
      website_url?: string | null;
    };
  }>;
};

export type Founders_ListQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Founders_ListQuery = {
  __typename?: 'query_root';
  founders: Array<{
    __typename?: 'founders';
    id: number;
    name?: string | null;
    jobTitle?: string | null;
    founderScoreWithBonus?: any | null;
  }>;
};

export type Unfollow_StartupsMutationVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Unfollow_StartupsMutation = {
  __typename?: 'mutation_root';
  delete_follow_relation_users_startups?: {
    __typename?: 'follow_relation_users_startups_mutation_response';
    affected_rows: number;
    returning: Array<{ __typename?: 'follow_relation_users_startups'; id: any }>;
  } | null;
};

export type Follow_StartupsMutationVariables = Exact<{
  startup_uuid?: InputMaybe<Scalars['uuid']>;
}>;

export type Follow_StartupsMutation = {
  __typename?: 'mutation_root';
  insert_follow_relation_users_startups_one?: { __typename?: 'follow_relation_users_startups'; id: any } | null;
};

export type Verify_If_Startup_FollowedQueryVariables = Exact<{
  startup_uuid?: InputMaybe<Uuid_Comparison_Exp>;
}>;

export type Verify_If_Startup_FollowedQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    follow_relation_users_startups: Array<{ __typename?: 'follow_relation_users_startups'; id: any }>;
  }>;
};

export type Get_Funding_And_Founded_DatesQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Funding_And_Founded_DatesQuery = {
  __typename?: 'query_root';
  startups: Array<{ __typename?: 'startups'; foundedDate?: string | null; lastFundingDate?: any | null }>;
};

export type Get_Job_Openings_DataQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Job_Openings_DataQuery = {
  __typename?: 'query_root';
  predictleads_job_openings: Array<{
    __typename?: 'predictleads_job_openings';
    startup_name?: string | null;
    startup_uuid: any;
    created_at: any;
    updated_at: any;
    id: any;
    type: string;
    title: string;
    url: string;
    first_seen_at: any;
    last_seen_at: any;
    closed: boolean;
    country_name?: string | null;
    country_id?: number | null;
    full_location?: string | null;
    full_salary?: string | null;
    salary_low?: any | null;
    salary_low_usd?: any | null;
    salary_high?: any | null;
    salary_high_usd?: any | null;
    salary_currency?: string | null;
    salary_time_unit?: string | null;
    description?: string | null;
    categories?: Array<string> | null;
    tags?: Array<string> | null;
    full_seniority?: string | null;
    seniority_types?: Array<string> | null;
    contract_types?: Array<string> | null;
  }>;
};

export type Get_Last_Employees_Observation_CountQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Last_Employees_Observation_CountQuery = {
  __typename?: 'query_root';
  employees: Array<{ __typename?: 'employees'; date: any; obs?: any | null }>;
};

export type Get_Employees_Average_TenureQueryVariables = Exact<{
  eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Employees_Average_TenureQuery = {
  __typename?: 'query_root';
  linkedin_employee_avg_tenure: Array<{
    __typename?: 'linkedin_employee_avg_tenure';
    average_tenure?: any | null;
    date?: any | null;
  }>;
};

export type Get_Last_Cofounder_Score_DetailsQueryVariables = Exact<{
  eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Last_Cofounder_Score_DetailsQuery = {
  __typename?: 'query_root';
  cofounders: Array<{
    __typename?: 'cofounders';
    cofounderScore?: any | null;
    confidenceScore?: number | null;
    cofounderScoreComplementary?: any | null;
    cofounderScoreTeamLevel?: any | null;
    percentile_cofounder_score?: any | null;
  }>;
};

export type Get_Employee_School_Repartition_Last_DateQueryVariables = Exact<{
  startup?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Employee_School_Repartition_Last_DateQuery = {
  __typename?: 'query_root';
  linkedin_school_repartition: Array<{ __typename?: 'linkedin_school_repartition'; date?: any | null }>;
};

export type Get_Employee_School_RepartitionQueryVariables = Exact<{
  startup?: InputMaybe<Scalars['uuid']>;
  _eq?: InputMaybe<Scalars['date']>;
}>;

export type Get_Employee_School_RepartitionQuery = {
  __typename?: 'query_root';
  linkedin_school_repartition: Array<{
    __typename?: 'linkedin_school_repartition';
    date?: any | null;
    linkedin_universal_name?: string | null;
    linkedin_url?: string | null;
    obs?: number | null;
    school_name?: string | null;
    startup_name?: string | null;
    startup_uuid: any;
  }>;
};

export type Get_Founders_DetailsQueryVariables = Exact<{
  eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Founders_DetailsQuery = {
  __typename?: 'query_root';
  founders: Array<{
    __typename?: 'founders';
    name?: string | null;
    jobTitle?: string | null;
    founderScoreWithBonus?: any | null;
    confidenceScore?: number | null;
    linkedin?: string | null;
    featureDidTopTierSchool?: boolean | null;
    numberOfFoundedOrganizations?: number | null;
    featureHasTopExperience?: boolean | null;
    featureYearsOfExperience?: number | null;
    featureHasStartupIndustryExperience?: boolean | null;
    featureExitsCategory?: number | null;
    numberOfArticlesBeforeStartup?: number | null;
    featureRaisedDuringAFounderExperienceCategory?: boolean | null;
    featureBonusWorkedInUS?: boolean | null;
    featureJobsCategory?: number | null;
    featureBonusWorkedInNrCountries?: number | null;
    featureBonusTopInvestors?: number | null;
    featureBonusCompanyExperienceRaisedBeforeAndDuring?: number | null;
    featureBonusHasCLvlGeneratingMoreThan100M?: boolean | null;
    featureBonusHasCLvlMinimum200MFundedBeforeAndDuring?: boolean | null;
    featureBonusHasCLvlGeneratingMoreThan10M?: boolean | null;
    featureBonusHasCLvlMinimum10MFundedBeforeAndDuring?: boolean | null;
    featureBonusHasManagerLvlGeneratingMoreThan10M?: boolean | null;
    featureBonusHasManagerLvlFundedBeforeAndDuring?: boolean | null;
    featureBonusNbIndustryExperiencesCrunchTop2Bonus?: number | null;
    featureFoundedOrganzationsCategory?: number | null;
    featureArticlesCategory?: number | null;
    educations: Array<{ __typename?: 'education'; title?: string | null }>;
  }>;
  founder_score_weights: Array<{
    __typename?: 'founder_score_weights';
    didTopTierSchool: any;
    foundedOrganzationsCategory: any;
    hasTopExperienceWeight: any;
    yearsOfExperienceWeight: any;
    hasStartupIndustryExperience: any;
    exitsCategory: any;
    articlesCategory: any;
    raisedDuringAFounderExperienceCategory: any;
    workedInUS: any;
    jobsCategory: any;
    workedInNrCountries: any;
    topInvestors: any;
    companyExperienceRaisedBeforeAndDuringMoreThan5M?: any | null;
    companyExperienceRaisedBeforeAndDuringPrevStartup?: any | null;
    hasCLvlGeneratingMoreThan100MOrhasCLvlMinimum200MFundedBeforeAn?: any | null;
    hasCLvlGeneratingMoreThan10MOrhasCLvlMinimum10MFundedBeforeAndD?: any | null;
    hasManagerLvlGeneratingMoreThan10M?: any | null;
    hasOneIndustryStartupMatch: any;
    hasTwoIndustryStartupMatch: any;
  }>;
};

export type Get_Employee_Post_Repartition_Last_DateQueryVariables = Exact<{
  startup?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Employee_Post_Repartition_Last_DateQuery = {
  __typename?: 'query_root';
  employee_post_repartition: Array<{ __typename?: 'employee_post_repartition'; date: any }>;
};

export type Get_Employee_Post_RepartitionQueryVariables = Exact<{
  startup?: InputMaybe<Scalars['uuid']>;
  _date?: InputMaybe<Scalars['date']>;
}>;

export type Get_Employee_Post_RepartitionQuery = {
  __typename?: 'query_root';
  employee_post_repartition: Array<{
    __typename?: 'employee_post_repartition';
    date: any;
    post?: any | null;
    obs?: any | null;
  }>;
};

export type Get_Employee_Country_Repartition_Last_DateQueryVariables = Exact<{
  startup?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Employee_Country_Repartition_Last_DateQuery = {
  __typename?: 'query_root';
  employee_country_repartition: Array<{ __typename?: 'employee_country_repartition'; date: any }>;
};

export type Get_Employee_Country_RepartitionQueryVariables = Exact<{
  startup?: InputMaybe<Scalars['uuid']>;
  _date?: InputMaybe<Scalars['date']>;
}>;

export type Get_Employee_Country_RepartitionQuery = {
  __typename?: 'query_root';
  employee_country_repartition: Array<{
    __typename?: 'employee_country_repartition';
    date: any;
    obs?: any | null;
    country_name?: { __typename?: 'country_names'; country_name: string } | null;
  }>;
};

export type Get_Website_Traffic_Sources_RepartitionQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Website_Traffic_Sources_RepartitionQuery = {
  __typename?: 'query_root';
  web_visits_general_data: Array<{
    __typename?: 'web_visits_general_data';
    date: any;
    percentage_of_direct_traffic?: any | null;
    percentage_of_traffic_from_mail?: any | null;
    percentage_of_traffic_from_paid_referrals?: any | null;
    percentage_of_traffic_from_referrals?: any | null;
    percentage_of_traffic_from_search?: any | null;
    percentage_of_traffic_from_social?: any | null;
  }>;
};

export type Get_Website_Share_Paid_TrafficQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Website_Share_Paid_TrafficQuery = {
  __typename?: 'query_root';
  web_visits_general_data: Array<{ __typename?: 'web_visits_general_data'; date: any; paid_search_share?: any | null }>;
};

export type Get_Downloads_Growth_And_YoY_AvgQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['date']>;
}>;

export type Get_Downloads_Growth_And_YoY_AvgQuery = {
  __typename?: 'query_root';
  growth: Array<{ __typename?: 'growth'; annualized_growth_downloads?: any | null; date: any }>;
  mobile_app_aggregate: {
    __typename?: 'mobile_app_aggregate';
    aggregate?: {
      __typename?: 'mobile_app_aggregate_fields';
      avg?: { __typename?: 'mobile_app_avg_fields'; nb_downloads?: number | null } | null;
    } | null;
  };
};

export type Get_Dau_Growth_And_YoY_AvgQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['date']>;
}>;

export type Get_Dau_Growth_And_YoY_AvgQuery = {
  __typename?: 'query_root';
  growth: Array<{ __typename?: 'growth'; annualized_growth_dau?: any | null; date: any }>;
  mobile_app_aggregate: {
    __typename?: 'mobile_app_aggregate';
    aggregate?: {
      __typename?: 'mobile_app_aggregate_fields';
      avg?: { __typename?: 'mobile_app_avg_fields'; nb_dau?: number | null } | null;
    } | null;
  };
};

export type Get_Webvisits_MetricsQueryVariables = Exact<{
  _eq?: InputMaybe<Scalars['uuid']>;
}>;

export type Get_Webvisits_MetricsQuery = {
  __typename?: 'query_root';
  web_visits_general_data: Array<{
    __typename?: 'web_visits_general_data';
    bounce_rate?: any | null;
    date: any;
    page_per_visits?: any | null;
    number_of_refering_websites?: number | null;
    time_on_website?: any | null;
  }>;
};

export const Get_Nb_Startups_In_ScopeDocument = gql`
  query get_nb_startups_in_scope {
    startups_aggregate(where: { is_fitting_specter_criterias: { _eq: true } }) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useGet_Nb_Startups_In_ScopeQuery__
 *
 * To run a query within a React component, call `useGet_Nb_Startups_In_ScopeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Nb_Startups_In_ScopeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Nb_Startups_In_ScopeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Nb_Startups_In_ScopeQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Nb_Startups_In_ScopeQuery, Get_Nb_Startups_In_ScopeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Nb_Startups_In_ScopeQuery, Get_Nb_Startups_In_ScopeQueryVariables>(
    Get_Nb_Startups_In_ScopeDocument,
    options,
  );
}
export function useGet_Nb_Startups_In_ScopeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Nb_Startups_In_ScopeQuery, Get_Nb_Startups_In_ScopeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Nb_Startups_In_ScopeQuery, Get_Nb_Startups_In_ScopeQueryVariables>(
    Get_Nb_Startups_In_ScopeDocument,
    options,
  );
}
export type Get_Nb_Startups_In_ScopeQueryHookResult = ReturnType<typeof useGet_Nb_Startups_In_ScopeQuery>;
export type Get_Nb_Startups_In_ScopeLazyQueryHookResult = ReturnType<typeof useGet_Nb_Startups_In_ScopeLazyQuery>;
export type Get_Nb_Startups_In_ScopeQueryResult = Apollo.QueryResult<
  Get_Nb_Startups_In_ScopeQuery,
  Get_Nb_Startups_In_ScopeQueryVariables
>;
export const Get_Last_NewsDocument = gql`
  query get_last_news {
    news: events_news(order_by: { date: desc_nulls_last }, limit: 50) {
      startup_uuid
      startup_name
      type_of_event
      url
      publisher
      event_description
      date
      startup {
        logo_url
      }
    }
  }
`;

/**
 * __useGet_Last_NewsQuery__
 *
 * To run a query within a React component, call `useGet_Last_NewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_NewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_NewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Last_NewsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Last_NewsQuery, Get_Last_NewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_NewsQuery, Get_Last_NewsQueryVariables>(Get_Last_NewsDocument, options);
}
export function useGet_Last_NewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Last_NewsQuery, Get_Last_NewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Last_NewsQuery, Get_Last_NewsQueryVariables>(Get_Last_NewsDocument, options);
}
export type Get_Last_NewsQueryHookResult = ReturnType<typeof useGet_Last_NewsQuery>;
export type Get_Last_NewsLazyQueryHookResult = ReturnType<typeof useGet_Last_NewsLazyQuery>;
export type Get_Last_NewsQueryResult = Apollo.QueryResult<Get_Last_NewsQuery, Get_Last_NewsQueryVariables>;
export const Get_Portfolio_NewsDocument = gql`
  query get_portfolio_news {
    news: events_news(
      order_by: { date: desc_nulls_last }
      limit: 50
      where: { startup: { dealflows: { status: { _like: "%Portfolio%" } } } }
    ) {
      startup_uuid
      startup_name
      type_of_event
      url
      publisher
      event_description
      date
      startup {
        logo_url
      }
    }
  }
`;

/**
 * __useGet_Portfolio_NewsQuery__
 *
 * To run a query within a React component, call `useGet_Portfolio_NewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Portfolio_NewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Portfolio_NewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Portfolio_NewsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Portfolio_NewsQuery, Get_Portfolio_NewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Portfolio_NewsQuery, Get_Portfolio_NewsQueryVariables>(
    Get_Portfolio_NewsDocument,
    options,
  );
}
export function useGet_Portfolio_NewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Portfolio_NewsQuery, Get_Portfolio_NewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Portfolio_NewsQuery, Get_Portfolio_NewsQueryVariables>(
    Get_Portfolio_NewsDocument,
    options,
  );
}
export type Get_Portfolio_NewsQueryHookResult = ReturnType<typeof useGet_Portfolio_NewsQuery>;
export type Get_Portfolio_NewsLazyQueryHookResult = ReturnType<typeof useGet_Portfolio_NewsLazyQuery>;
export type Get_Portfolio_NewsQueryResult = Apollo.QueryResult<
  Get_Portfolio_NewsQuery,
  Get_Portfolio_NewsQueryVariables
>;
export const Get_Followed_Startups_NewsDocument = gql`
  query get_followed_startups_news {
    users {
      follow_relation_users_startups(order_by: { updated_at: desc_nulls_last }) {
        startup {
          events_news(order_by: { date: desc_nulls_last }, limit: 1) {
            startup_uuid
            startup_name
            type_of_event
            url
            publisher
            event_description
            date
            startup {
              logo_url
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGet_Followed_Startups_NewsQuery__
 *
 * To run a query within a React component, call `useGet_Followed_Startups_NewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Followed_Startups_NewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Followed_Startups_NewsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Followed_Startups_NewsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Followed_Startups_NewsQuery, Get_Followed_Startups_NewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Followed_Startups_NewsQuery, Get_Followed_Startups_NewsQueryVariables>(
    Get_Followed_Startups_NewsDocument,
    options,
  );
}
export function useGet_Followed_Startups_NewsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Followed_Startups_NewsQuery, Get_Followed_Startups_NewsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Followed_Startups_NewsQuery, Get_Followed_Startups_NewsQueryVariables>(
    Get_Followed_Startups_NewsDocument,
    options,
  );
}
export type Get_Followed_Startups_NewsQueryHookResult = ReturnType<typeof useGet_Followed_Startups_NewsQuery>;
export type Get_Followed_Startups_NewsLazyQueryHookResult = ReturnType<typeof useGet_Followed_Startups_NewsLazyQuery>;
export type Get_Followed_Startups_NewsQueryResult = Apollo.QueryResult<
  Get_Followed_Startups_NewsQuery,
  Get_Followed_Startups_NewsQueryVariables
>;
export const Get_Top_Startups_On_Priority_ScoreDocument = gql`
  query get_top_startups_on_priority_score($_eq: date) {
    scores(order_by: { priorityScore: desc_nulls_last }, limit: 50, where: { date: { _eq: $_eq } }) {
      startup_uuid
      startup_name
      priorityScore
      growth_score_adaptative
      USpresenceScore
      startup {
        totalFundingAmountInUSD
        shortDescription
        logo_url
      }
    }
  }
`;

/**
 * __useGet_Top_Startups_On_Priority_ScoreQuery__
 *
 * To run a query within a React component, call `useGet_Top_Startups_On_Priority_ScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Top_Startups_On_Priority_ScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Top_Startups_On_Priority_ScoreQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Top_Startups_On_Priority_ScoreQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Top_Startups_On_Priority_ScoreQuery,
    Get_Top_Startups_On_Priority_ScoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Top_Startups_On_Priority_ScoreQuery, Get_Top_Startups_On_Priority_ScoreQueryVariables>(
    Get_Top_Startups_On_Priority_ScoreDocument,
    options,
  );
}
export function useGet_Top_Startups_On_Priority_ScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Top_Startups_On_Priority_ScoreQuery,
    Get_Top_Startups_On_Priority_ScoreQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Top_Startups_On_Priority_ScoreQuery, Get_Top_Startups_On_Priority_ScoreQueryVariables>(
    Get_Top_Startups_On_Priority_ScoreDocument,
    options,
  );
}
export type Get_Top_Startups_On_Priority_ScoreQueryHookResult = ReturnType<
  typeof useGet_Top_Startups_On_Priority_ScoreQuery
>;
export type Get_Top_Startups_On_Priority_ScoreLazyQueryHookResult = ReturnType<
  typeof useGet_Top_Startups_On_Priority_ScoreLazyQuery
>;
export type Get_Top_Startups_On_Priority_ScoreQueryResult = Apollo.QueryResult<
  Get_Top_Startups_On_Priority_ScoreQuery,
  Get_Top_Startups_On_Priority_ScoreQueryVariables
>;
export const Get_Top_Startups_On_Growth_Score_AdaptativeDocument = gql`
  query get_top_startups_on_growth_score_adaptative($_eq: date) {
    scores(order_by: { growth_score_adaptative: desc_nulls_last }, limit: 50, where: { date: { _eq: $_eq } }) {
      startup_uuid
      startup_name
      priorityScore
      growth_score_adaptative
      USpresenceScore
      startup {
        totalFundingAmountInUSD
        shortDescription
        logo_url
      }
    }
  }
`;

/**
 * __useGet_Top_Startups_On_Growth_Score_AdaptativeQuery__
 *
 * To run a query within a React component, call `useGet_Top_Startups_On_Growth_Score_AdaptativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Top_Startups_On_Growth_Score_AdaptativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Top_Startups_On_Growth_Score_AdaptativeQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Top_Startups_On_Growth_Score_AdaptativeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Top_Startups_On_Growth_Score_AdaptativeQuery,
    Get_Top_Startups_On_Growth_Score_AdaptativeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Top_Startups_On_Growth_Score_AdaptativeQuery,
    Get_Top_Startups_On_Growth_Score_AdaptativeQueryVariables
  >(Get_Top_Startups_On_Growth_Score_AdaptativeDocument, options);
}
export function useGet_Top_Startups_On_Growth_Score_AdaptativeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Top_Startups_On_Growth_Score_AdaptativeQuery,
    Get_Top_Startups_On_Growth_Score_AdaptativeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Top_Startups_On_Growth_Score_AdaptativeQuery,
    Get_Top_Startups_On_Growth_Score_AdaptativeQueryVariables
  >(Get_Top_Startups_On_Growth_Score_AdaptativeDocument, options);
}
export type Get_Top_Startups_On_Growth_Score_AdaptativeQueryHookResult = ReturnType<
  typeof useGet_Top_Startups_On_Growth_Score_AdaptativeQuery
>;
export type Get_Top_Startups_On_Growth_Score_AdaptativeLazyQueryHookResult = ReturnType<
  typeof useGet_Top_Startups_On_Growth_Score_AdaptativeLazyQuery
>;
export type Get_Top_Startups_On_Growth_Score_AdaptativeQueryResult = Apollo.QueryResult<
  Get_Top_Startups_On_Growth_Score_AdaptativeQuery,
  Get_Top_Startups_On_Growth_Score_AdaptativeQueryVariables
>;
export const Top_Last_Searched_StartupsDocument = gql`
  query top_last_searched_startups {
    users {
      last_search_relation_users_startups(order_by: { updated_at: desc_nulls_last }, limit: 20) {
        startup {
          name
          uuid
          totalFundingAmountInUSD
          shortDescription
          logo_url
          scores(order_by: { date: desc_nulls_last }, limit: 1) {
            priorityScore
            growth_score_adaptative
            USpresenceScore
          }
        }
      }
    }
  }
`;

/**
 * __useTop_Last_Searched_StartupsQuery__
 *
 * To run a query within a React component, call `useTop_Last_Searched_StartupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTop_Last_Searched_StartupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTop_Last_Searched_StartupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTop_Last_Searched_StartupsQuery(
  baseOptions?: Apollo.QueryHookOptions<Top_Last_Searched_StartupsQuery, Top_Last_Searched_StartupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Top_Last_Searched_StartupsQuery, Top_Last_Searched_StartupsQueryVariables>(
    Top_Last_Searched_StartupsDocument,
    options,
  );
}
export function useTop_Last_Searched_StartupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Top_Last_Searched_StartupsQuery, Top_Last_Searched_StartupsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Top_Last_Searched_StartupsQuery, Top_Last_Searched_StartupsQueryVariables>(
    Top_Last_Searched_StartupsDocument,
    options,
  );
}
export type Top_Last_Searched_StartupsQueryHookResult = ReturnType<typeof useTop_Last_Searched_StartupsQuery>;
export type Top_Last_Searched_StartupsLazyQueryHookResult = ReturnType<typeof useTop_Last_Searched_StartupsLazyQuery>;
export type Top_Last_Searched_StartupsQueryResult = Apollo.QueryResult<
  Top_Last_Searched_StartupsQuery,
  Top_Last_Searched_StartupsQueryVariables
>;
export const Search_BarDocument = gql`
  query search_bar($search: String) {
    search_startup_name(args: { search: $search }) {
      uuid
      name
      logo_url
      link
    }
  }
`;

/**
 * __useSearch_BarQuery__
 *
 * To run a query within a React component, call `useSearch_BarQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearch_BarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearch_BarQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearch_BarQuery(baseOptions?: Apollo.QueryHookOptions<Search_BarQuery, Search_BarQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Search_BarQuery, Search_BarQueryVariables>(Search_BarDocument, options);
}
export function useSearch_BarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Search_BarQuery, Search_BarQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Search_BarQuery, Search_BarQueryVariables>(Search_BarDocument, options);
}
export type Search_BarQueryHookResult = ReturnType<typeof useSearch_BarQuery>;
export type Search_BarLazyQueryHookResult = ReturnType<typeof useSearch_BarLazyQuery>;
export type Search_BarQueryResult = Apollo.QueryResult<Search_BarQuery, Search_BarQueryVariables>;
export const Get_Last_Searched_Startups_By_UserDocument = gql`
  query get_last_searched_startups_by_user {
    users {
      last_search_relation_users_startups(order_by: { updated_at: desc }) {
        startup {
          uuid
          name
          logo_url
        }
      }
    }
  }
`;

/**
 * __useGet_Last_Searched_Startups_By_UserQuery__
 *
 * To run a query within a React component, call `useGet_Last_Searched_Startups_By_UserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_Searched_Startups_By_UserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_Searched_Startups_By_UserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGet_Last_Searched_Startups_By_UserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Last_Searched_Startups_By_UserQuery,
    Get_Last_Searched_Startups_By_UserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_Searched_Startups_By_UserQuery, Get_Last_Searched_Startups_By_UserQueryVariables>(
    Get_Last_Searched_Startups_By_UserDocument,
    options,
  );
}
export function useGet_Last_Searched_Startups_By_UserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Last_Searched_Startups_By_UserQuery,
    Get_Last_Searched_Startups_By_UserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Last_Searched_Startups_By_UserQuery, Get_Last_Searched_Startups_By_UserQueryVariables>(
    Get_Last_Searched_Startups_By_UserDocument,
    options,
  );
}
export type Get_Last_Searched_Startups_By_UserQueryHookResult = ReturnType<
  typeof useGet_Last_Searched_Startups_By_UserQuery
>;
export type Get_Last_Searched_Startups_By_UserLazyQueryHookResult = ReturnType<
  typeof useGet_Last_Searched_Startups_By_UserLazyQuery
>;
export type Get_Last_Searched_Startups_By_UserQueryResult = Apollo.QueryResult<
  Get_Last_Searched_Startups_By_UserQuery,
  Get_Last_Searched_Startups_By_UserQueryVariables
>;
export const Save_Searched_Startup_For_UserDocument = gql`
  mutation save_searched_startup_for_user($startup_uuid: uuid) {
    insert_last_search_relation_users_startups_one(
      object: { startup_uuid: $startup_uuid }
      on_conflict: {
        constraint: last_search_relation_users_startups_user_id_startup_uuid_key
        update_columns: updated_at
      }
    ) {
      startup {
        name
        logo_url
        uuid
      }
    }
  }
`;
export type Save_Searched_Startup_For_UserMutationFn = Apollo.MutationFunction<
  Save_Searched_Startup_For_UserMutation,
  Save_Searched_Startup_For_UserMutationVariables
>;

/**
 * __useSave_Searched_Startup_For_UserMutation__
 *
 * To run a mutation, you first call `useSave_Searched_Startup_For_UserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSave_Searched_Startup_For_UserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSearchedStartupForUserMutation, { data, loading, error }] = useSave_Searched_Startup_For_UserMutation({
 *   variables: {
 *      startup_uuid: // value for 'startup_uuid'
 *   },
 * });
 */
export function useSave_Searched_Startup_For_UserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Save_Searched_Startup_For_UserMutation,
    Save_Searched_Startup_For_UserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Save_Searched_Startup_For_UserMutation, Save_Searched_Startup_For_UserMutationVariables>(
    Save_Searched_Startup_For_UserDocument,
    options,
  );
}
export type Save_Searched_Startup_For_UserMutationHookResult = ReturnType<
  typeof useSave_Searched_Startup_For_UserMutation
>;
export type Save_Searched_Startup_For_UserMutationResult =
  Apollo.MutationResult<Save_Searched_Startup_For_UserMutation>;
export type Save_Searched_Startup_For_UserMutationOptions = Apollo.BaseMutationOptions<
  Save_Searched_Startup_For_UserMutation,
  Save_Searched_Startup_For_UserMutationVariables
>;
export const Get_Glassdoor_Rating_And_Nb_Reviews_HistoryDocument = gql`
  query get_glassdoor_rating_and_nb_reviews_history($_eq: uuid) {
    glassdoor_data(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }) {
      date
      ratings_overall
      ratings_all_review_count
    }
  }
`;

/**
 * __useGet_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery,
    Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery,
    Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQueryVariables
  >(Get_Glassdoor_Rating_And_Nb_Reviews_HistoryDocument, options);
}
export function useGet_Glassdoor_Rating_And_Nb_Reviews_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery,
    Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery,
    Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQueryVariables
  >(Get_Glassdoor_Rating_And_Nb_Reviews_HistoryDocument, options);
}
export type Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQueryHookResult = ReturnType<
  typeof useGet_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery
>;
export type Get_Glassdoor_Rating_And_Nb_Reviews_HistoryLazyQueryHookResult = ReturnType<
  typeof useGet_Glassdoor_Rating_And_Nb_Reviews_HistoryLazyQuery
>;
export type Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQueryResult = Apollo.QueryResult<
  Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQuery,
  Get_Glassdoor_Rating_And_Nb_Reviews_HistoryQueryVariables
>;
export const Get_Glassdoor_ScoresDocument = gql`
  query get_glassdoor_scores($_eq: uuid) {
    glassdoor_data(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      date
      ratings_work_life_balance
      ratings_ceo_approval
      ratings_culture_values
      ratings_career_opportunities
    }
  }
`;

/**
 * __useGet_Glassdoor_ScoresQuery__
 *
 * To run a query within a React component, call `useGet_Glassdoor_ScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Glassdoor_ScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Glassdoor_ScoresQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Glassdoor_ScoresQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Glassdoor_ScoresQuery, Get_Glassdoor_ScoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Glassdoor_ScoresQuery, Get_Glassdoor_ScoresQueryVariables>(
    Get_Glassdoor_ScoresDocument,
    options,
  );
}
export function useGet_Glassdoor_ScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Glassdoor_ScoresQuery, Get_Glassdoor_ScoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Glassdoor_ScoresQuery, Get_Glassdoor_ScoresQueryVariables>(
    Get_Glassdoor_ScoresDocument,
    options,
  );
}
export type Get_Glassdoor_ScoresQueryHookResult = ReturnType<typeof useGet_Glassdoor_ScoresQuery>;
export type Get_Glassdoor_ScoresLazyQueryHookResult = ReturnType<typeof useGet_Glassdoor_ScoresLazyQuery>;
export type Get_Glassdoor_ScoresQueryResult = Apollo.QueryResult<
  Get_Glassdoor_ScoresQuery,
  Get_Glassdoor_ScoresQueryVariables
>;
export const Get_Bcorp_ScoresDocument = gql`
  query get_bcorp_scores($_eq: uuid) {
    bcorp_startups(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      bcorp_score
      community_score
      environment_score
      governance_score
      worker_score
      customer_score
      date
    }
  }
`;

/**
 * __useGet_Bcorp_ScoresQuery__
 *
 * To run a query within a React component, call `useGet_Bcorp_ScoresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Bcorp_ScoresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Bcorp_ScoresQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Bcorp_ScoresQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Bcorp_ScoresQuery, Get_Bcorp_ScoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Bcorp_ScoresQuery, Get_Bcorp_ScoresQueryVariables>(Get_Bcorp_ScoresDocument, options);
}
export function useGet_Bcorp_ScoresLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Bcorp_ScoresQuery, Get_Bcorp_ScoresQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Bcorp_ScoresQuery, Get_Bcorp_ScoresQueryVariables>(Get_Bcorp_ScoresDocument, options);
}
export type Get_Bcorp_ScoresQueryHookResult = ReturnType<typeof useGet_Bcorp_ScoresQuery>;
export type Get_Bcorp_ScoresLazyQueryHookResult = ReturnType<typeof useGet_Bcorp_ScoresLazyQuery>;
export type Get_Bcorp_ScoresQueryResult = Apollo.QueryResult<Get_Bcorp_ScoresQuery, Get_Bcorp_ScoresQueryVariables>;
export const Impact_Investors_ListDocument = gql`
  query impact_investors_list($_eq: uuid) {
    investor_startup_relation(where: { startup_uuid: { _eq: $_eq }, investor: { is_impact_fund: { _eq: true } } }) {
      investor_name
      investor {
        investor_country
        is_top_investor
        is_impact_fund
        crunchbase_link
        crm_link
        linkedin_link
        website_url
      }
    }
  }
`;

/**
 * __useImpact_Investors_ListQuery__
 *
 * To run a query within a React component, call `useImpact_Investors_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useImpact_Investors_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImpact_Investors_ListQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useImpact_Investors_ListQuery(
  baseOptions?: Apollo.QueryHookOptions<Impact_Investors_ListQuery, Impact_Investors_ListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Impact_Investors_ListQuery, Impact_Investors_ListQueryVariables>(
    Impact_Investors_ListDocument,
    options,
  );
}
export function useImpact_Investors_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Impact_Investors_ListQuery, Impact_Investors_ListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Impact_Investors_ListQuery, Impact_Investors_ListQueryVariables>(
    Impact_Investors_ListDocument,
    options,
  );
}
export type Impact_Investors_ListQueryHookResult = ReturnType<typeof useImpact_Investors_ListQuery>;
export type Impact_Investors_ListLazyQueryHookResult = ReturnType<typeof useImpact_Investors_ListLazyQuery>;
export type Impact_Investors_ListQueryResult = Apollo.QueryResult<
  Impact_Investors_ListQuery,
  Impact_Investors_ListQueryVariables
>;
export const Get_Startup_Identity_CardDocument = gql`
  query get_startup_identity_card($_eq: uuid) {
    identity_cards(where: { startup_uuid: { _eq: $_eq } }) {
      product_features
      technologies
      industries
      business_model
      target_clients
      startup_name
      startup_uuid
    }
  }
`;

/**
 * __useGet_Startup_Identity_CardQuery__
 *
 * To run a query within a React component, call `useGet_Startup_Identity_CardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Startup_Identity_CardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Startup_Identity_CardQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Startup_Identity_CardQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Startup_Identity_CardQuery, Get_Startup_Identity_CardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Startup_Identity_CardQuery, Get_Startup_Identity_CardQueryVariables>(
    Get_Startup_Identity_CardDocument,
    options,
  );
}
export function useGet_Startup_Identity_CardLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Startup_Identity_CardQuery, Get_Startup_Identity_CardQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Startup_Identity_CardQuery, Get_Startup_Identity_CardQueryVariables>(
    Get_Startup_Identity_CardDocument,
    options,
  );
}
export type Get_Startup_Identity_CardQueryHookResult = ReturnType<typeof useGet_Startup_Identity_CardQuery>;
export type Get_Startup_Identity_CardLazyQueryHookResult = ReturnType<typeof useGet_Startup_Identity_CardLazyQuery>;
export type Get_Startup_Identity_CardQueryResult = Apollo.QueryResult<
  Get_Startup_Identity_CardQuery,
  Get_Startup_Identity_CardQueryVariables
>;
export const Get_Startup_CompetitorsDocument = gql`
  query get_startup_competitors($_eq: uuid) {
    competition_classification(
      where: {
        _and: [
          { _or: [{ startup_uuid_1: { _eq: $_eq } }, { startup_uuid_2: { _eq: $_eq } }] }
          { prediction: { _eq: true } }
        ]
      }
      order_by: { confidence: desc }
      limit: 49
    ) {
      startup_uuid_1
      startup_name_1
      startup_uuid_2
      startup_name_2
      feedback
      prediction
      probability
      confidence
    }
  }
`;

/**
 * __useGet_Startup_CompetitorsQuery__
 *
 * To run a query within a React component, call `useGet_Startup_CompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Startup_CompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Startup_CompetitorsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Startup_CompetitorsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Startup_CompetitorsQuery, Get_Startup_CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Startup_CompetitorsQuery, Get_Startup_CompetitorsQueryVariables>(
    Get_Startup_CompetitorsDocument,
    options,
  );
}
export function useGet_Startup_CompetitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Startup_CompetitorsQuery, Get_Startup_CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Startup_CompetitorsQuery, Get_Startup_CompetitorsQueryVariables>(
    Get_Startup_CompetitorsDocument,
    options,
  );
}
export type Get_Startup_CompetitorsQueryHookResult = ReturnType<typeof useGet_Startup_CompetitorsQuery>;
export type Get_Startup_CompetitorsLazyQueryHookResult = ReturnType<typeof useGet_Startup_CompetitorsLazyQuery>;
export type Get_Startup_CompetitorsQueryResult = Apollo.QueryResult<
  Get_Startup_CompetitorsQuery,
  Get_Startup_CompetitorsQueryVariables
>;
export const Get_Startup_Competitors_DataDocument = gql`
  query get_startup_competitors_data(
    $uuids: [uuid!]!
    $country_repartition_last_date: date
    $historical_data_last_date: date
  ) {
    startups(where: { uuid: { _in: $uuids } }) {
      name
      uuid
      logo_url
      link
      linkedin
      description
      shortDescription
      headquartersCountry
      inception
      totalFundingAmountInUSD
      identity_card {
        product_features
        technologies
        industries
        business_model
        target_clients
        startup_name
        startup_uuid
      }
      scores(limit: 1, order_by: { date: desc }) {
        priorityScore
        growth_score_adaptative
        us_potential_score
        USpresenceScore
        impact_score
        date
        startup_uuid
        startup_name
      }
      employees(limit: 1, order_by: { date: desc }) {
        date
        obs
        startup_uuid
        startup_name
        data_source
      }
      employee_country_repartitions(limit: 1, order_by: { date: desc }, where: { country_id: { _eq: 840 } }) {
        date
        obs
        country
        startup_uuid
        startup_name
      }
      growths(limit: 1, order_by: { date: desc }) {
        date
        startup_uuid
        startup_name
        annualized_growth_employee
        three_month_growth_employee
        annualized_growth_web_visit
        three_month_growth_web_visit
        weight_employee
        weight_web_visit
      }
    }
  }
`;

/**
 * __useGet_Startup_Competitors_DataQuery__
 *
 * To run a query within a React component, call `useGet_Startup_Competitors_DataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Startup_Competitors_DataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Startup_Competitors_DataQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *      country_repartition_last_date: // value for 'country_repartition_last_date'
 *      historical_data_last_date: // value for 'historical_data_last_date'
 *   },
 * });
 */
export function useGet_Startup_Competitors_DataQuery(
  baseOptions: Apollo.QueryHookOptions<Get_Startup_Competitors_DataQuery, Get_Startup_Competitors_DataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Startup_Competitors_DataQuery, Get_Startup_Competitors_DataQueryVariables>(
    Get_Startup_Competitors_DataDocument,
    options,
  );
}
export function useGet_Startup_Competitors_DataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Startup_Competitors_DataQuery,
    Get_Startup_Competitors_DataQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Startup_Competitors_DataQuery, Get_Startup_Competitors_DataQueryVariables>(
    Get_Startup_Competitors_DataDocument,
    options,
  );
}
export type Get_Startup_Competitors_DataQueryHookResult = ReturnType<typeof useGet_Startup_Competitors_DataQuery>;
export type Get_Startup_Competitors_DataLazyQueryHookResult = ReturnType<
  typeof useGet_Startup_Competitors_DataLazyQuery
>;
export type Get_Startup_Competitors_DataQueryResult = Apollo.QueryResult<
  Get_Startup_Competitors_DataQuery,
  Get_Startup_Competitors_DataQueryVariables
>;
export const Get_Similarweb_CompetitorsDocument = gql`
  query get_similarweb_competitors($uuids: [uuid!]!, $ref_uuid: uuid!) {
    similar_sites(
      where: {
        _or: [
          { _and: [{ startup_uuid_1: { _in: $uuids } }, { startup_uuid_2: { _eq: $ref_uuid } }] }
          { _and: [{ startup_uuid_2: { _in: $uuids } }, { startup_uuid_1: { _eq: $ref_uuid } }] }
        ]
      }
    ) {
      startup_uuid_1
      startup_uuid_2
      startup_name_1
      startup_name_2
    }
  }
`;

/**
 * __useGet_Similarweb_CompetitorsQuery__
 *
 * To run a query within a React component, call `useGet_Similarweb_CompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Similarweb_CompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Similarweb_CompetitorsQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *      ref_uuid: // value for 'ref_uuid'
 *   },
 * });
 */
export function useGet_Similarweb_CompetitorsQuery(
  baseOptions: Apollo.QueryHookOptions<Get_Similarweb_CompetitorsQuery, Get_Similarweb_CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Similarweb_CompetitorsQuery, Get_Similarweb_CompetitorsQueryVariables>(
    Get_Similarweb_CompetitorsDocument,
    options,
  );
}
export function useGet_Similarweb_CompetitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Similarweb_CompetitorsQuery, Get_Similarweb_CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Similarweb_CompetitorsQuery, Get_Similarweb_CompetitorsQueryVariables>(
    Get_Similarweb_CompetitorsDocument,
    options,
  );
}
export type Get_Similarweb_CompetitorsQueryHookResult = ReturnType<typeof useGet_Similarweb_CompetitorsQuery>;
export type Get_Similarweb_CompetitorsLazyQueryHookResult = ReturnType<typeof useGet_Similarweb_CompetitorsLazyQuery>;
export type Get_Similarweb_CompetitorsQueryResult = Apollo.QueryResult<
  Get_Similarweb_CompetitorsQuery,
  Get_Similarweb_CompetitorsQueryVariables
>;
export const Get_Google_CompetitorsDocument = gql`
  query get_google_competitors($uuids: [uuid!]!, $ref_uuid: uuid!) {
    google_serp(
      where: {
        _or: [
          { _and: [{ startup_uuid_1: { _in: $uuids } }, { startup_uuid_2: { _eq: $ref_uuid } }] }
          { _and: [{ startup_uuid_2: { _in: $uuids } }, { startup_uuid_1: { _eq: $ref_uuid } }] }
        ]
      }
    ) {
      startup_uuid_1
      startup_uuid_2
      startup_name_1
      startup_name_2
      suggestion
    }
  }
`;

/**
 * __useGet_Google_CompetitorsQuery__
 *
 * To run a query within a React component, call `useGet_Google_CompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Google_CompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Google_CompetitorsQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *      ref_uuid: // value for 'ref_uuid'
 *   },
 * });
 */
export function useGet_Google_CompetitorsQuery(
  baseOptions: Apollo.QueryHookOptions<Get_Google_CompetitorsQuery, Get_Google_CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Google_CompetitorsQuery, Get_Google_CompetitorsQueryVariables>(
    Get_Google_CompetitorsDocument,
    options,
  );
}
export function useGet_Google_CompetitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Google_CompetitorsQuery, Get_Google_CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Google_CompetitorsQuery, Get_Google_CompetitorsQueryVariables>(
    Get_Google_CompetitorsDocument,
    options,
  );
}
export type Get_Google_CompetitorsQueryHookResult = ReturnType<typeof useGet_Google_CompetitorsQuery>;
export type Get_Google_CompetitorsLazyQueryHookResult = ReturnType<typeof useGet_Google_CompetitorsLazyQuery>;
export type Get_Google_CompetitorsQueryResult = Apollo.QueryResult<
  Get_Google_CompetitorsQuery,
  Get_Google_CompetitorsQueryVariables
>;
export const Get_G2_CompetitorsDocument = gql`
  query get_g2_competitors($uuids: [uuid!]!, $ref_uuid: uuid!) {
    g2_product_competitors(
      where: { _and: [{ startup_uuid: { _eq: $ref_uuid } }, { competitor_uuid: { _in: $uuids } }] }
    ) {
      competitor_uuid
      competitorName
      startup_uuid
      startup_name
    }
  }
`;

/**
 * __useGet_G2_CompetitorsQuery__
 *
 * To run a query within a React component, call `useGet_G2_CompetitorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_G2_CompetitorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_G2_CompetitorsQuery({
 *   variables: {
 *      uuids: // value for 'uuids'
 *      ref_uuid: // value for 'ref_uuid'
 *   },
 * });
 */
export function useGet_G2_CompetitorsQuery(
  baseOptions: Apollo.QueryHookOptions<Get_G2_CompetitorsQuery, Get_G2_CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_G2_CompetitorsQuery, Get_G2_CompetitorsQueryVariables>(
    Get_G2_CompetitorsDocument,
    options,
  );
}
export function useGet_G2_CompetitorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_G2_CompetitorsQuery, Get_G2_CompetitorsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_G2_CompetitorsQuery, Get_G2_CompetitorsQueryVariables>(
    Get_G2_CompetitorsDocument,
    options,
  );
}
export type Get_G2_CompetitorsQueryHookResult = ReturnType<typeof useGet_G2_CompetitorsQuery>;
export type Get_G2_CompetitorsLazyQueryHookResult = ReturnType<typeof useGet_G2_CompetitorsLazyQuery>;
export type Get_G2_CompetitorsQueryResult = Apollo.QueryResult<
  Get_G2_CompetitorsQuery,
  Get_G2_CompetitorsQueryVariables
>;
export const Set_FeedbackDocument = gql`
  mutation set_feedback($startup_uuid_1: uuid!, $startup_uuid_2: uuid!, $feedback: String!) {
    update_competition_classification_by_pk(
      pk_columns: { startup_uuid_1: $startup_uuid_1, startup_uuid_2: $startup_uuid_2 }
      _set: { feedback: $feedback }
    ) {
      startup_uuid_1
      startup_uuid_2
      startup_name_1
      startup_name_2
      feedback
    }
  }
`;
export type Set_FeedbackMutationFn = Apollo.MutationFunction<Set_FeedbackMutation, Set_FeedbackMutationVariables>;

/**
 * __useSet_FeedbackMutation__
 *
 * To run a mutation, you first call `useSet_FeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSet_FeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setFeedbackMutation, { data, loading, error }] = useSet_FeedbackMutation({
 *   variables: {
 *      startup_uuid_1: // value for 'startup_uuid_1'
 *      startup_uuid_2: // value for 'startup_uuid_2'
 *      feedback: // value for 'feedback'
 *   },
 * });
 */
export function useSet_FeedbackMutation(
  baseOptions?: Apollo.MutationHookOptions<Set_FeedbackMutation, Set_FeedbackMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Set_FeedbackMutation, Set_FeedbackMutationVariables>(Set_FeedbackDocument, options);
}
export type Set_FeedbackMutationHookResult = ReturnType<typeof useSet_FeedbackMutation>;
export type Set_FeedbackMutationResult = Apollo.MutationResult<Set_FeedbackMutation>;
export type Set_FeedbackMutationOptions = Apollo.BaseMutationOptions<
  Set_FeedbackMutation,
  Set_FeedbackMutationVariables
>;
export const Get_Growth_Score_WeightsDocument = gql`
  query get_growth_score_weights($_eq: uuid) {
    growth(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      date
      weight_dau
      weight_downloads
      weight_employee
      weight_linkedin
      weight_twitter
      weight_web_visit
      weight_g2
      weight_producthunt
      weight_trustpilot
      weight_github
    }
  }
`;

/**
 * __useGet_Growth_Score_WeightsQuery__
 *
 * To run a query within a React component, call `useGet_Growth_Score_WeightsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Growth_Score_WeightsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Growth_Score_WeightsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Growth_Score_WeightsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Growth_Score_WeightsQuery, Get_Growth_Score_WeightsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Growth_Score_WeightsQuery, Get_Growth_Score_WeightsQueryVariables>(
    Get_Growth_Score_WeightsDocument,
    options,
  );
}
export function useGet_Growth_Score_WeightsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Growth_Score_WeightsQuery, Get_Growth_Score_WeightsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Growth_Score_WeightsQuery, Get_Growth_Score_WeightsQueryVariables>(
    Get_Growth_Score_WeightsDocument,
    options,
  );
}
export type Get_Growth_Score_WeightsQueryHookResult = ReturnType<typeof useGet_Growth_Score_WeightsQuery>;
export type Get_Growth_Score_WeightsLazyQueryHookResult = ReturnType<typeof useGet_Growth_Score_WeightsLazyQuery>;
export type Get_Growth_Score_WeightsQueryResult = Apollo.QueryResult<
  Get_Growth_Score_WeightsQuery,
  Get_Growth_Score_WeightsQueryVariables
>;
export const Get_Growth_Score_Adaptative_HistoryDocument = gql`
  query get_growth_score_adaptative_history($_eq: uuid) {
    scores(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 50) {
      growth_score_adaptative
      date
    }
  }
`;

/**
 * __useGet_Growth_Score_Adaptative_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_Growth_Score_Adaptative_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Growth_Score_Adaptative_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Growth_Score_Adaptative_HistoryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Growth_Score_Adaptative_HistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Growth_Score_Adaptative_HistoryQuery,
    Get_Growth_Score_Adaptative_HistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Growth_Score_Adaptative_HistoryQuery, Get_Growth_Score_Adaptative_HistoryQueryVariables>(
    Get_Growth_Score_Adaptative_HistoryDocument,
    options,
  );
}
export function useGet_Growth_Score_Adaptative_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Growth_Score_Adaptative_HistoryQuery,
    Get_Growth_Score_Adaptative_HistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Growth_Score_Adaptative_HistoryQuery,
    Get_Growth_Score_Adaptative_HistoryQueryVariables
  >(Get_Growth_Score_Adaptative_HistoryDocument, options);
}
export type Get_Growth_Score_Adaptative_HistoryQueryHookResult = ReturnType<
  typeof useGet_Growth_Score_Adaptative_HistoryQuery
>;
export type Get_Growth_Score_Adaptative_HistoryLazyQueryHookResult = ReturnType<
  typeof useGet_Growth_Score_Adaptative_HistoryLazyQuery
>;
export type Get_Growth_Score_Adaptative_HistoryQueryResult = Apollo.QueryResult<
  Get_Growth_Score_Adaptative_HistoryQuery,
  Get_Growth_Score_Adaptative_HistoryQueryVariables
>;
export const Get_Webvisits_HistoryDocument = gql`
  query get_webvisits_history($_eq: uuid) {
    web_visits_historical_data(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }) {
      date
      number_of_visits
      domain
    }
  }
`;

/**
 * __useGet_Webvisits_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_Webvisits_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Webvisits_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Webvisits_HistoryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Webvisits_HistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Webvisits_HistoryQuery, Get_Webvisits_HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Webvisits_HistoryQuery, Get_Webvisits_HistoryQueryVariables>(
    Get_Webvisits_HistoryDocument,
    options,
  );
}
export function useGet_Webvisits_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Webvisits_HistoryQuery, Get_Webvisits_HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Webvisits_HistoryQuery, Get_Webvisits_HistoryQueryVariables>(
    Get_Webvisits_HistoryDocument,
    options,
  );
}
export type Get_Webvisits_HistoryQueryHookResult = ReturnType<typeof useGet_Webvisits_HistoryQuery>;
export type Get_Webvisits_HistoryLazyQueryHookResult = ReturnType<typeof useGet_Webvisits_HistoryLazyQuery>;
export type Get_Webvisits_HistoryQueryResult = Apollo.QueryResult<
  Get_Webvisits_HistoryQuery,
  Get_Webvisits_HistoryQueryVariables
>;
export const Get_Linkedin_Followers_HistoryDocument = gql`
  query get_linkedin_followers_history($_eq: uuid) {
    linkedin_followers(where: { startup_uuid: { _eq: $_eq } }, order_by: { obs: desc_nulls_last }) {
      date
      obs
    }
  }
`;

/**
 * __useGet_Linkedin_Followers_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_Linkedin_Followers_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Linkedin_Followers_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Linkedin_Followers_HistoryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Linkedin_Followers_HistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Linkedin_Followers_HistoryQuery,
    Get_Linkedin_Followers_HistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Linkedin_Followers_HistoryQuery, Get_Linkedin_Followers_HistoryQueryVariables>(
    Get_Linkedin_Followers_HistoryDocument,
    options,
  );
}
export function useGet_Linkedin_Followers_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Linkedin_Followers_HistoryQuery,
    Get_Linkedin_Followers_HistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Linkedin_Followers_HistoryQuery, Get_Linkedin_Followers_HistoryQueryVariables>(
    Get_Linkedin_Followers_HistoryDocument,
    options,
  );
}
export type Get_Linkedin_Followers_HistoryQueryHookResult = ReturnType<typeof useGet_Linkedin_Followers_HistoryQuery>;
export type Get_Linkedin_Followers_HistoryLazyQueryHookResult = ReturnType<
  typeof useGet_Linkedin_Followers_HistoryLazyQuery
>;
export type Get_Linkedin_Followers_HistoryQueryResult = Apollo.QueryResult<
  Get_Linkedin_Followers_HistoryQuery,
  Get_Linkedin_Followers_HistoryQueryVariables
>;
export const Get_Employees_HistoryDocument = gql`
  query get_employees_history($_eq: uuid) {
    employees(order_by: { date: desc_nulls_last }, where: { startup_uuid: { _eq: $_eq } }) {
      obs
      date
    }
  }
`;

/**
 * __useGet_Employees_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_Employees_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Employees_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Employees_HistoryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Employees_HistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Employees_HistoryQuery, Get_Employees_HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Employees_HistoryQuery, Get_Employees_HistoryQueryVariables>(
    Get_Employees_HistoryDocument,
    options,
  );
}
export function useGet_Employees_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Employees_HistoryQuery, Get_Employees_HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Employees_HistoryQuery, Get_Employees_HistoryQueryVariables>(
    Get_Employees_HistoryDocument,
    options,
  );
}
export type Get_Employees_HistoryQueryHookResult = ReturnType<typeof useGet_Employees_HistoryQuery>;
export type Get_Employees_HistoryLazyQueryHookResult = ReturnType<typeof useGet_Employees_HistoryLazyQuery>;
export type Get_Employees_HistoryQueryResult = Apollo.QueryResult<
  Get_Employees_HistoryQuery,
  Get_Employees_HistoryQueryVariables
>;
export const Get_Twitter_Followers_HistoryDocument = gql`
  query get_twitter_followers_history($_eq: uuid) {
    twitter_followers(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }) {
      obs
      date
    }
  }
`;

/**
 * __useGet_Twitter_Followers_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_Twitter_Followers_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Twitter_Followers_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Twitter_Followers_HistoryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Twitter_Followers_HistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Twitter_Followers_HistoryQuery,
    Get_Twitter_Followers_HistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Twitter_Followers_HistoryQuery, Get_Twitter_Followers_HistoryQueryVariables>(
    Get_Twitter_Followers_HistoryDocument,
    options,
  );
}
export function useGet_Twitter_Followers_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Twitter_Followers_HistoryQuery,
    Get_Twitter_Followers_HistoryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Twitter_Followers_HistoryQuery, Get_Twitter_Followers_HistoryQueryVariables>(
    Get_Twitter_Followers_HistoryDocument,
    options,
  );
}
export type Get_Twitter_Followers_HistoryQueryHookResult = ReturnType<typeof useGet_Twitter_Followers_HistoryQuery>;
export type Get_Twitter_Followers_HistoryLazyQueryHookResult = ReturnType<
  typeof useGet_Twitter_Followers_HistoryLazyQuery
>;
export type Get_Twitter_Followers_HistoryQueryResult = Apollo.QueryResult<
  Get_Twitter_Followers_HistoryQuery,
  Get_Twitter_Followers_HistoryQueryVariables
>;
export const Get_Mobile_App_HistoryDocument = gql`
  query get_mobile_app_history($_eq: uuid) {
    mobile_app(order_by: { date: desc_nulls_last }, where: { startup_uuid: { _eq: $_eq } }) {
      nb_dau
      nb_downloads
      date
      app_id
    }
  }
`;

/**
 * __useGet_Mobile_App_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_Mobile_App_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Mobile_App_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Mobile_App_HistoryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Mobile_App_HistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Mobile_App_HistoryQuery, Get_Mobile_App_HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Mobile_App_HistoryQuery, Get_Mobile_App_HistoryQueryVariables>(
    Get_Mobile_App_HistoryDocument,
    options,
  );
}
export function useGet_Mobile_App_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Mobile_App_HistoryQuery, Get_Mobile_App_HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Mobile_App_HistoryQuery, Get_Mobile_App_HistoryQueryVariables>(
    Get_Mobile_App_HistoryDocument,
    options,
  );
}
export type Get_Mobile_App_HistoryQueryHookResult = ReturnType<typeof useGet_Mobile_App_HistoryQuery>;
export type Get_Mobile_App_HistoryLazyQueryHookResult = ReturnType<typeof useGet_Mobile_App_HistoryLazyQuery>;
export type Get_Mobile_App_HistoryQueryResult = Apollo.QueryResult<
  Get_Mobile_App_HistoryQuery,
  Get_Mobile_App_HistoryQueryVariables
>;
export const Get_G2_HistoryDocument = gql`
  query get_g2_history($_eq: uuid) {
    g2_historical_data(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }) {
      number_of_reviews
      date
    }
  }
`;

/**
 * __useGet_G2_HistoryQuery__
 *
 * To run a query within a React component, call `useGet_G2_HistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_G2_HistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_G2_HistoryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_G2_HistoryQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_G2_HistoryQuery, Get_G2_HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_G2_HistoryQuery, Get_G2_HistoryQueryVariables>(Get_G2_HistoryDocument, options);
}
export function useGet_G2_HistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_G2_HistoryQuery, Get_G2_HistoryQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_G2_HistoryQuery, Get_G2_HistoryQueryVariables>(Get_G2_HistoryDocument, options);
}
export type Get_G2_HistoryQueryHookResult = ReturnType<typeof useGet_G2_HistoryQuery>;
export type Get_G2_HistoryLazyQueryHookResult = ReturnType<typeof useGet_G2_HistoryLazyQuery>;
export type Get_G2_HistoryQueryResult = Apollo.QueryResult<Get_G2_HistoryQuery, Get_G2_HistoryQueryVariables>;
export const Get_Metric_Growth_MomentumsDocument = gql`
  query get_metric_growth_momentums($_eq: uuid) {
    growth(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      date
      annualized_growth_dau
      annualized_growth_downloads
      annualized_growth_employee
      annualized_growth_linkedin
      annualized_growth_twitter
      annualized_growth_web_visit
      annualized_growth_g2
      three_month_growth_dau
      three_month_growth_downloads
      three_month_growth_employee
      three_month_growth_linkedin
      three_month_growth_percentile
      three_month_growth_twitter
      three_month_growth_web_visit
      three_month_growth_g2
    }
    scores(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      annual_growth_score_dau
      annual_growth_score_downloads
      annual_growth_score_employee
      annual_growth_score_linkedin
      annual_growth_score_twitter
      annual_growth_score_web_visit
      annual_growth_score_g2
      three_months_growth_score_dau
      three_months_growth_score_downloads
      three_months_growth_score_employee
      three_months_growth_score_linkedin
      three_months_growth_score_twitter
      three_months_growth_score_web_visit
      three_months_growth_score_g2
    }
  }
`;

/**
 * __useGet_Metric_Growth_MomentumsQuery__
 *
 * To run a query within a React component, call `useGet_Metric_Growth_MomentumsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Metric_Growth_MomentumsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Metric_Growth_MomentumsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Metric_Growth_MomentumsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Metric_Growth_MomentumsQuery, Get_Metric_Growth_MomentumsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Metric_Growth_MomentumsQuery, Get_Metric_Growth_MomentumsQueryVariables>(
    Get_Metric_Growth_MomentumsDocument,
    options,
  );
}
export function useGet_Metric_Growth_MomentumsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Metric_Growth_MomentumsQuery,
    Get_Metric_Growth_MomentumsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Metric_Growth_MomentumsQuery, Get_Metric_Growth_MomentumsQueryVariables>(
    Get_Metric_Growth_MomentumsDocument,
    options,
  );
}
export type Get_Metric_Growth_MomentumsQueryHookResult = ReturnType<typeof useGet_Metric_Growth_MomentumsQuery>;
export type Get_Metric_Growth_MomentumsLazyQueryHookResult = ReturnType<typeof useGet_Metric_Growth_MomentumsLazyQuery>;
export type Get_Metric_Growth_MomentumsQueryResult = Apollo.QueryResult<
  Get_Metric_Growth_MomentumsQuery,
  Get_Metric_Growth_MomentumsQueryVariables
>;
export const America_Investors_ListDocument = gql`
  query america_investors_list($_eq: uuid) {
    investor_startup_relation(
      where: { startup_uuid: { _eq: $_eq }, investor: { investor_country: { _eq: "United States" } } }
    ) {
      investor_name
      investor {
        investor_country
        is_top_investor
        is_impact_fund
        crunchbase_link
        crm_link
        linkedin_link
        website_url
        logo_url
      }
    }
  }
`;

/**
 * __useAmerica_Investors_ListQuery__
 *
 * To run a query within a React component, call `useAmerica_Investors_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAmerica_Investors_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAmerica_Investors_ListQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useAmerica_Investors_ListQuery(
  baseOptions?: Apollo.QueryHookOptions<America_Investors_ListQuery, America_Investors_ListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<America_Investors_ListQuery, America_Investors_ListQueryVariables>(
    America_Investors_ListDocument,
    options,
  );
}
export function useAmerica_Investors_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<America_Investors_ListQuery, America_Investors_ListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<America_Investors_ListQuery, America_Investors_ListQueryVariables>(
    America_Investors_ListDocument,
    options,
  );
}
export type America_Investors_ListQueryHookResult = ReturnType<typeof useAmerica_Investors_ListQuery>;
export type America_Investors_ListLazyQueryHookResult = ReturnType<typeof useAmerica_Investors_ListLazyQuery>;
export type America_Investors_ListQueryResult = Apollo.QueryResult<
  America_Investors_ListQuery,
  America_Investors_ListQueryVariables
>;
export const Get_Us_Webvisits_Proportion_And_GrowthDocument = gql`
  query get_US_webvisits_proportion_and_growth($_eq: uuid) {
    growth(order_by: { date: desc_nulls_last }, where: { startup_uuid: { _eq: $_eq } }, limit: 1) {
      monthly_growth_us_web_visits
      quarterly_growth_us_web_visits
      date
    }
    web_visits_country_repartition(
      where: { startup_uuid: { _eq: $_eq }, country_id: { _eq: 840 } }
      order_by: { date: desc_nulls_last }
      limit: 1
    ) {
      date
      all_time_share_of_visits
      country {
        country_name
      }
    }
  }
`;

/**
 * __useGet_Us_Webvisits_Proportion_And_GrowthQuery__
 *
 * To run a query within a React component, call `useGet_Us_Webvisits_Proportion_And_GrowthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Us_Webvisits_Proportion_And_GrowthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Us_Webvisits_Proportion_And_GrowthQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Us_Webvisits_Proportion_And_GrowthQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Us_Webvisits_Proportion_And_GrowthQuery,
    Get_Us_Webvisits_Proportion_And_GrowthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Us_Webvisits_Proportion_And_GrowthQuery,
    Get_Us_Webvisits_Proportion_And_GrowthQueryVariables
  >(Get_Us_Webvisits_Proportion_And_GrowthDocument, options);
}
export function useGet_Us_Webvisits_Proportion_And_GrowthLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Us_Webvisits_Proportion_And_GrowthQuery,
    Get_Us_Webvisits_Proportion_And_GrowthQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Us_Webvisits_Proportion_And_GrowthQuery,
    Get_Us_Webvisits_Proportion_And_GrowthQueryVariables
  >(Get_Us_Webvisits_Proportion_And_GrowthDocument, options);
}
export type Get_Us_Webvisits_Proportion_And_GrowthQueryHookResult = ReturnType<
  typeof useGet_Us_Webvisits_Proportion_And_GrowthQuery
>;
export type Get_Us_Webvisits_Proportion_And_GrowthLazyQueryHookResult = ReturnType<
  typeof useGet_Us_Webvisits_Proportion_And_GrowthLazyQuery
>;
export type Get_Us_Webvisits_Proportion_And_GrowthQueryResult = Apollo.QueryResult<
  Get_Us_Webvisits_Proportion_And_GrowthQuery,
  Get_Us_Webvisits_Proportion_And_GrowthQueryVariables
>;
export const Get_Webvisit_Country_Repartition_Last_DateDocument = gql`
  query get_webvisit_country_repartition_last_date($startup: uuid) {
    web_visits_country_repartition(
      where: { startup_uuid: { _eq: $startup } }
      order_by: { date: desc_nulls_last }
      limit: 1
    ) {
      date
    }
  }
`;

/**
 * __useGet_Webvisit_Country_Repartition_Last_DateQuery__
 *
 * To run a query within a React component, call `useGet_Webvisit_Country_Repartition_Last_DateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Webvisit_Country_Repartition_Last_DateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Webvisit_Country_Repartition_Last_DateQuery({
 *   variables: {
 *      startup: // value for 'startup'
 *   },
 * });
 */
export function useGet_Webvisit_Country_Repartition_Last_DateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Webvisit_Country_Repartition_Last_DateQuery,
    Get_Webvisit_Country_Repartition_Last_DateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Webvisit_Country_Repartition_Last_DateQuery,
    Get_Webvisit_Country_Repartition_Last_DateQueryVariables
  >(Get_Webvisit_Country_Repartition_Last_DateDocument, options);
}
export function useGet_Webvisit_Country_Repartition_Last_DateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Webvisit_Country_Repartition_Last_DateQuery,
    Get_Webvisit_Country_Repartition_Last_DateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Webvisit_Country_Repartition_Last_DateQuery,
    Get_Webvisit_Country_Repartition_Last_DateQueryVariables
  >(Get_Webvisit_Country_Repartition_Last_DateDocument, options);
}
export type Get_Webvisit_Country_Repartition_Last_DateQueryHookResult = ReturnType<
  typeof useGet_Webvisit_Country_Repartition_Last_DateQuery
>;
export type Get_Webvisit_Country_Repartition_Last_DateLazyQueryHookResult = ReturnType<
  typeof useGet_Webvisit_Country_Repartition_Last_DateLazyQuery
>;
export type Get_Webvisit_Country_Repartition_Last_DateQueryResult = Apollo.QueryResult<
  Get_Webvisit_Country_Repartition_Last_DateQuery,
  Get_Webvisit_Country_Repartition_Last_DateQueryVariables
>;
export const Get_Webvisit_Country_RepartitionDocument = gql`
  query get_webvisit_country_repartition($startup: uuid, $_date: date) {
    web_visits_country_repartition(
      where: { startup_uuid: { _eq: $startup }, date: { _eq: $_date } }
      order_by: { top: asc }
    ) {
      date
      top
      all_time_share_of_visits
      country {
        country_name
      }
    }
  }
`;

/**
 * __useGet_Webvisit_Country_RepartitionQuery__
 *
 * To run a query within a React component, call `useGet_Webvisit_Country_RepartitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Webvisit_Country_RepartitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Webvisit_Country_RepartitionQuery({
 *   variables: {
 *      startup: // value for 'startup'
 *      _date: // value for '_date'
 *   },
 * });
 */
export function useGet_Webvisit_Country_RepartitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Webvisit_Country_RepartitionQuery,
    Get_Webvisit_Country_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Webvisit_Country_RepartitionQuery, Get_Webvisit_Country_RepartitionQueryVariables>(
    Get_Webvisit_Country_RepartitionDocument,
    options,
  );
}
export function useGet_Webvisit_Country_RepartitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Webvisit_Country_RepartitionQuery,
    Get_Webvisit_Country_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Webvisit_Country_RepartitionQuery, Get_Webvisit_Country_RepartitionQueryVariables>(
    Get_Webvisit_Country_RepartitionDocument,
    options,
  );
}
export type Get_Webvisit_Country_RepartitionQueryHookResult = ReturnType<
  typeof useGet_Webvisit_Country_RepartitionQuery
>;
export type Get_Webvisit_Country_RepartitionLazyQueryHookResult = ReturnType<
  typeof useGet_Webvisit_Country_RepartitionLazyQuery
>;
export type Get_Webvisit_Country_RepartitionQueryResult = Apollo.QueryResult<
  Get_Webvisit_Country_RepartitionQuery,
  Get_Webvisit_Country_RepartitionQueryVariables
>;
export const Get_Startup_Name_Logo_LinksDocument = gql`
  query get_startup_name_logo_links($_eq: uuid) {
    startups(where: { uuid: { _eq: $_eq } }) {
      uuid
      logo_url
      name
      link
      linkedin
      twitter
      glassdoor
      crunchbase_link
      g2_link
      description
      is_fitting_sourcing_criterias
      totalFundingAmountInUSD
      headquartersCountry
      added_manually
      attio_record_id
      is_led_by_europeans
      data_sources_last_update {
        linkedIn
        glassdoor
        crunchbase
        similarweb
        linkedin_founders
        g2
        twitter
      }
    }
  }
`;

/**
 * __useGet_Startup_Name_Logo_LinksQuery__
 *
 * To run a query within a React component, call `useGet_Startup_Name_Logo_LinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Startup_Name_Logo_LinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Startup_Name_Logo_LinksQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Startup_Name_Logo_LinksQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Startup_Name_Logo_LinksQuery, Get_Startup_Name_Logo_LinksQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Startup_Name_Logo_LinksQuery, Get_Startup_Name_Logo_LinksQueryVariables>(
    Get_Startup_Name_Logo_LinksDocument,
    options,
  );
}
export function useGet_Startup_Name_Logo_LinksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Startup_Name_Logo_LinksQuery,
    Get_Startup_Name_Logo_LinksQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Startup_Name_Logo_LinksQuery, Get_Startup_Name_Logo_LinksQueryVariables>(
    Get_Startup_Name_Logo_LinksDocument,
    options,
  );
}
export type Get_Startup_Name_Logo_LinksQueryHookResult = ReturnType<typeof useGet_Startup_Name_Logo_LinksQuery>;
export type Get_Startup_Name_Logo_LinksLazyQueryHookResult = ReturnType<typeof useGet_Startup_Name_Logo_LinksLazyQuery>;
export type Get_Startup_Name_Logo_LinksQueryResult = Apollo.QueryResult<
  Get_Startup_Name_Logo_LinksQuery,
  Get_Startup_Name_Logo_LinksQueryVariables
>;
export const Get_Sourcing_Criteria_For_CountryDocument = gql`
  query get_sourcing_criteria_for_country($_eq: String) {
    country_of_startups_for_sourcing(where: { countryName: { _eq: $_eq } }) {
      minimumAmountRaised
      countryName
    }
  }
`;

/**
 * __useGet_Sourcing_Criteria_For_CountryQuery__
 *
 * To run a query within a React component, call `useGet_Sourcing_Criteria_For_CountryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Sourcing_Criteria_For_CountryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Sourcing_Criteria_For_CountryQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Sourcing_Criteria_For_CountryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Sourcing_Criteria_For_CountryQuery,
    Get_Sourcing_Criteria_For_CountryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Sourcing_Criteria_For_CountryQuery, Get_Sourcing_Criteria_For_CountryQueryVariables>(
    Get_Sourcing_Criteria_For_CountryDocument,
    options,
  );
}
export function useGet_Sourcing_Criteria_For_CountryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Sourcing_Criteria_For_CountryQuery,
    Get_Sourcing_Criteria_For_CountryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Sourcing_Criteria_For_CountryQuery, Get_Sourcing_Criteria_For_CountryQueryVariables>(
    Get_Sourcing_Criteria_For_CountryDocument,
    options,
  );
}
export type Get_Sourcing_Criteria_For_CountryQueryHookResult = ReturnType<
  typeof useGet_Sourcing_Criteria_For_CountryQuery
>;
export type Get_Sourcing_Criteria_For_CountryLazyQueryHookResult = ReturnType<
  typeof useGet_Sourcing_Criteria_For_CountryLazyQuery
>;
export type Get_Sourcing_Criteria_For_CountryQueryResult = Apollo.QueryResult<
  Get_Sourcing_Criteria_For_CountryQuery,
  Get_Sourcing_Criteria_For_CountryQueryVariables
>;
export const Get_Funding_And_HqDocument = gql`
  query get_funding_and_hq($_eq: uuid) {
    startups(where: { uuid: { _eq: $_eq } }) {
      uuid
      lastFundingAmountInUSD
      headquartersCountry
      lastFundingType
      totalFundingAmountInUSD
    }
  }
`;

/**
 * __useGet_Funding_And_HqQuery__
 *
 * To run a query within a React component, call `useGet_Funding_And_HqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Funding_And_HqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Funding_And_HqQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Funding_And_HqQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Funding_And_HqQuery, Get_Funding_And_HqQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Funding_And_HqQuery, Get_Funding_And_HqQueryVariables>(
    Get_Funding_And_HqDocument,
    options,
  );
}
export function useGet_Funding_And_HqLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Funding_And_HqQuery, Get_Funding_And_HqQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Funding_And_HqQuery, Get_Funding_And_HqQueryVariables>(
    Get_Funding_And_HqDocument,
    options,
  );
}
export type Get_Funding_And_HqQueryHookResult = ReturnType<typeof useGet_Funding_And_HqQuery>;
export type Get_Funding_And_HqLazyQueryHookResult = ReturnType<typeof useGet_Funding_And_HqLazyQuery>;
export type Get_Funding_And_HqQueryResult = Apollo.QueryResult<
  Get_Funding_And_HqQuery,
  Get_Funding_And_HqQueryVariables
>;
export const Get_NewsDocument = gql`
  query get_news($_eq: uuid) {
    news: events_news(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }) {
      startup_name
      type_of_event
      url
      publisher
      event_description
      date
      icon_url
    }
  }
`;

/**
 * __useGet_NewsQuery__
 *
 * To run a query within a React component, call `useGet_NewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_NewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_NewsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_NewsQuery(baseOptions?: Apollo.QueryHookOptions<Get_NewsQuery, Get_NewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_NewsQuery, Get_NewsQueryVariables>(Get_NewsDocument, options);
}
export function useGet_NewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Get_NewsQuery, Get_NewsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_NewsQuery, Get_NewsQueryVariables>(Get_NewsDocument, options);
}
export type Get_NewsQueryHookResult = ReturnType<typeof useGet_NewsQuery>;
export type Get_NewsLazyQueryHookResult = ReturnType<typeof useGet_NewsLazyQuery>;
export type Get_NewsQueryResult = Apollo.QueryResult<Get_NewsQuery, Get_NewsQueryVariables>;
export const Get_Last_Growth_Score_AdaptativeDocument = gql`
  query get_last_growth_score_adaptative($_eq: uuid) {
    scores(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      growth_score_adaptative
      confidenceIndex
      date
      percentile_growth_score_adaptative
    }
  }
`;

/**
 * __useGet_Last_Growth_Score_AdaptativeQuery__
 *
 * To run a query within a React component, call `useGet_Last_Growth_Score_AdaptativeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_Growth_Score_AdaptativeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_Growth_Score_AdaptativeQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Last_Growth_Score_AdaptativeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Last_Growth_Score_AdaptativeQuery,
    Get_Last_Growth_Score_AdaptativeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_Growth_Score_AdaptativeQuery, Get_Last_Growth_Score_AdaptativeQueryVariables>(
    Get_Last_Growth_Score_AdaptativeDocument,
    options,
  );
}
export function useGet_Last_Growth_Score_AdaptativeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Last_Growth_Score_AdaptativeQuery,
    Get_Last_Growth_Score_AdaptativeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Last_Growth_Score_AdaptativeQuery, Get_Last_Growth_Score_AdaptativeQueryVariables>(
    Get_Last_Growth_Score_AdaptativeDocument,
    options,
  );
}
export type Get_Last_Growth_Score_AdaptativeQueryHookResult = ReturnType<
  typeof useGet_Last_Growth_Score_AdaptativeQuery
>;
export type Get_Last_Growth_Score_AdaptativeLazyQueryHookResult = ReturnType<
  typeof useGet_Last_Growth_Score_AdaptativeLazyQuery
>;
export type Get_Last_Growth_Score_AdaptativeQueryResult = Apollo.QueryResult<
  Get_Last_Growth_Score_AdaptativeQuery,
  Get_Last_Growth_Score_AdaptativeQueryVariables
>;
export const Get_Last_Priority_ScoreDocument = gql`
  query get_last_priority_score($_eq: uuid) {
    scores(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      date
      priorityScore
      priorityPercentile
      confidence_priority_score
    }
  }
`;

/**
 * __useGet_Last_Priority_ScoreQuery__
 *
 * To run a query within a React component, call `useGet_Last_Priority_ScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_Priority_ScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_Priority_ScoreQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Last_Priority_ScoreQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Last_Priority_ScoreQuery, Get_Last_Priority_ScoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_Priority_ScoreQuery, Get_Last_Priority_ScoreQueryVariables>(
    Get_Last_Priority_ScoreDocument,
    options,
  );
}
export function useGet_Last_Priority_ScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Last_Priority_ScoreQuery, Get_Last_Priority_ScoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Last_Priority_ScoreQuery, Get_Last_Priority_ScoreQueryVariables>(
    Get_Last_Priority_ScoreDocument,
    options,
  );
}
export type Get_Last_Priority_ScoreQueryHookResult = ReturnType<typeof useGet_Last_Priority_ScoreQuery>;
export type Get_Last_Priority_ScoreLazyQueryHookResult = ReturnType<typeof useGet_Last_Priority_ScoreLazyQuery>;
export type Get_Last_Priority_ScoreQueryResult = Apollo.QueryResult<
  Get_Last_Priority_ScoreQuery,
  Get_Last_Priority_ScoreQueryVariables
>;
export const Get_Last_USpresence_ScoreDocument = gql`
  query get_last_USpresence_score($_eq: uuid) {
    scores(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      date
      USpresenceScore
      confidence_us_presence_score
      percentile_us_presence_score
    }
  }
`;

/**
 * __useGet_Last_USpresence_ScoreQuery__
 *
 * To run a query within a React component, call `useGet_Last_USpresence_ScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_USpresence_ScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_USpresence_ScoreQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Last_USpresence_ScoreQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Last_USpresence_ScoreQuery, Get_Last_USpresence_ScoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_USpresence_ScoreQuery, Get_Last_USpresence_ScoreQueryVariables>(
    Get_Last_USpresence_ScoreDocument,
    options,
  );
}
export function useGet_Last_USpresence_ScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Last_USpresence_ScoreQuery, Get_Last_USpresence_ScoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Last_USpresence_ScoreQuery, Get_Last_USpresence_ScoreQueryVariables>(
    Get_Last_USpresence_ScoreDocument,
    options,
  );
}
export type Get_Last_USpresence_ScoreQueryHookResult = ReturnType<typeof useGet_Last_USpresence_ScoreQuery>;
export type Get_Last_USpresence_ScoreLazyQueryHookResult = ReturnType<typeof useGet_Last_USpresence_ScoreLazyQuery>;
export type Get_Last_USpresence_ScoreQueryResult = Apollo.QueryResult<
  Get_Last_USpresence_ScoreQuery,
  Get_Last_USpresence_ScoreQueryVariables
>;
export const Get_UsPotentialDocument = gql`
  query get_USPotential($_eq: uuid) {
    scores(
      order_by: { date: desc_nulls_last }
      where: { startup_uuid: { _eq: $_eq }, us_potential_score: { _is_null: false } }
      limit: 1
    ) {
      date
      us_potential_score
      us_potential_score_percentile
    }
  }
`;

/**
 * __useGet_UsPotentialQuery__
 *
 * To run a query within a React component, call `useGet_UsPotentialQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_UsPotentialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_UsPotentialQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_UsPotentialQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_UsPotentialQuery, Get_UsPotentialQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_UsPotentialQuery, Get_UsPotentialQueryVariables>(Get_UsPotentialDocument, options);
}
export function useGet_UsPotentialLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_UsPotentialQuery, Get_UsPotentialQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_UsPotentialQuery, Get_UsPotentialQueryVariables>(Get_UsPotentialDocument, options);
}
export type Get_UsPotentialQueryHookResult = ReturnType<typeof useGet_UsPotentialQuery>;
export type Get_UsPotentialLazyQueryHookResult = ReturnType<typeof useGet_UsPotentialLazyQuery>;
export type Get_UsPotentialQueryResult = Apollo.QueryResult<Get_UsPotentialQuery, Get_UsPotentialQueryVariables>;
export const Get_Last_Impact_ScoreDocument = gql`
  query get_last_impact_score($_eq: uuid) {
    scores(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      date
      impact_score
      percentile_impact_score
      confidence_impact_score
    }
  }
`;

/**
 * __useGet_Last_Impact_ScoreQuery__
 *
 * To run a query within a React component, call `useGet_Last_Impact_ScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_Impact_ScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_Impact_ScoreQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Last_Impact_ScoreQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Last_Impact_ScoreQuery, Get_Last_Impact_ScoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_Impact_ScoreQuery, Get_Last_Impact_ScoreQueryVariables>(
    Get_Last_Impact_ScoreDocument,
    options,
  );
}
export function useGet_Last_Impact_ScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Last_Impact_ScoreQuery, Get_Last_Impact_ScoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Last_Impact_ScoreQuery, Get_Last_Impact_ScoreQueryVariables>(
    Get_Last_Impact_ScoreDocument,
    options,
  );
}
export type Get_Last_Impact_ScoreQueryHookResult = ReturnType<typeof useGet_Last_Impact_ScoreQuery>;
export type Get_Last_Impact_ScoreLazyQueryHookResult = ReturnType<typeof useGet_Last_Impact_ScoreLazyQuery>;
export type Get_Last_Impact_ScoreQueryResult = Apollo.QueryResult<
  Get_Last_Impact_ScoreQuery,
  Get_Last_Impact_ScoreQueryVariables
>;
export const Get_Last_Cofounder_ScoreDocument = gql`
  query get_last_cofounder_score($eq: uuid) {
    cofounders(where: { startup_uuid: { _eq: $eq } }, limit: 1) {
      cofounderScore
      confidenceScore
      percentile_cofounder_score
    }
  }
`;

/**
 * __useGet_Last_Cofounder_ScoreQuery__
 *
 * To run a query within a React component, call `useGet_Last_Cofounder_ScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_Cofounder_ScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_Cofounder_ScoreQuery({
 *   variables: {
 *      eq: // value for 'eq'
 *   },
 * });
 */
export function useGet_Last_Cofounder_ScoreQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Last_Cofounder_ScoreQuery, Get_Last_Cofounder_ScoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_Cofounder_ScoreQuery, Get_Last_Cofounder_ScoreQueryVariables>(
    Get_Last_Cofounder_ScoreDocument,
    options,
  );
}
export function useGet_Last_Cofounder_ScoreLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Last_Cofounder_ScoreQuery, Get_Last_Cofounder_ScoreQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Last_Cofounder_ScoreQuery, Get_Last_Cofounder_ScoreQueryVariables>(
    Get_Last_Cofounder_ScoreDocument,
    options,
  );
}
export type Get_Last_Cofounder_ScoreQueryHookResult = ReturnType<typeof useGet_Last_Cofounder_ScoreQuery>;
export type Get_Last_Cofounder_ScoreLazyQueryHookResult = ReturnType<typeof useGet_Last_Cofounder_ScoreLazyQuery>;
export type Get_Last_Cofounder_ScoreQueryResult = Apollo.QueryResult<
  Get_Last_Cofounder_ScoreQuery,
  Get_Last_Cofounder_ScoreQueryVariables
>;
export const Get_Nb_And_Growth_EmployeeDocument = gql`
  query get_nb_and_growth_employee($_eq: uuid) {
    startups(where: { uuid: { _eq: $_eq } }) {
      growths(order_by: { date: desc_nulls_last }, limit: 1) {
        annualized_growth_employee
      }
      employees(order_by: { date: desc_nulls_last }, limit: 1) {
        obs
      }
    }
  }
`;

/**
 * __useGet_Nb_And_Growth_EmployeeQuery__
 *
 * To run a query within a React component, call `useGet_Nb_And_Growth_EmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Nb_And_Growth_EmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Nb_And_Growth_EmployeeQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Nb_And_Growth_EmployeeQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Nb_And_Growth_EmployeeQuery, Get_Nb_And_Growth_EmployeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Nb_And_Growth_EmployeeQuery, Get_Nb_And_Growth_EmployeeQueryVariables>(
    Get_Nb_And_Growth_EmployeeDocument,
    options,
  );
}
export function useGet_Nb_And_Growth_EmployeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Nb_And_Growth_EmployeeQuery, Get_Nb_And_Growth_EmployeeQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Nb_And_Growth_EmployeeQuery, Get_Nb_And_Growth_EmployeeQueryVariables>(
    Get_Nb_And_Growth_EmployeeDocument,
    options,
  );
}
export type Get_Nb_And_Growth_EmployeeQueryHookResult = ReturnType<typeof useGet_Nb_And_Growth_EmployeeQuery>;
export type Get_Nb_And_Growth_EmployeeLazyQueryHookResult = ReturnType<typeof useGet_Nb_And_Growth_EmployeeLazyQuery>;
export type Get_Nb_And_Growth_EmployeeQueryResult = Apollo.QueryResult<
  Get_Nb_And_Growth_EmployeeQuery,
  Get_Nb_And_Growth_EmployeeQueryVariables
>;
export const Investors_ListDocument = gql`
  query investors_list($_eq: uuid) {
    investor_startup_relation(where: { startup_uuid: { _eq: $_eq } }) {
      investor_name
      investor {
        investor_country
        is_top_investor
        is_impact_fund
        crunchbase_link
        logo_url
        crm_link
        linkedin_link
        website_url
      }
    }
  }
`;

/**
 * __useInvestors_ListQuery__
 *
 * To run a query within a React component, call `useInvestors_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestors_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestors_ListQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useInvestors_ListQuery(
  baseOptions?: Apollo.QueryHookOptions<Investors_ListQuery, Investors_ListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Investors_ListQuery, Investors_ListQueryVariables>(Investors_ListDocument, options);
}
export function useInvestors_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Investors_ListQuery, Investors_ListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Investors_ListQuery, Investors_ListQueryVariables>(Investors_ListDocument, options);
}
export type Investors_ListQueryHookResult = ReturnType<typeof useInvestors_ListQuery>;
export type Investors_ListLazyQueryHookResult = ReturnType<typeof useInvestors_ListLazyQuery>;
export type Investors_ListQueryResult = Apollo.QueryResult<Investors_ListQuery, Investors_ListQueryVariables>;
export const Founders_ListDocument = gql`
  query founders_list($_eq: uuid) {
    founders(where: { startup_uuid: { _eq: $_eq } }) {
      id
      name
      jobTitle
      founderScoreWithBonus
    }
  }
`;

/**
 * __useFounders_ListQuery__
 *
 * To run a query within a React component, call `useFounders_ListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFounders_ListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFounders_ListQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useFounders_ListQuery(
  baseOptions?: Apollo.QueryHookOptions<Founders_ListQuery, Founders_ListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Founders_ListQuery, Founders_ListQueryVariables>(Founders_ListDocument, options);
}
export function useFounders_ListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Founders_ListQuery, Founders_ListQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Founders_ListQuery, Founders_ListQueryVariables>(Founders_ListDocument, options);
}
export type Founders_ListQueryHookResult = ReturnType<typeof useFounders_ListQuery>;
export type Founders_ListLazyQueryHookResult = ReturnType<typeof useFounders_ListLazyQuery>;
export type Founders_ListQueryResult = Apollo.QueryResult<Founders_ListQuery, Founders_ListQueryVariables>;
export const Unfollow_StartupsDocument = gql`
  mutation unfollow_startups($_eq: uuid) {
    delete_follow_relation_users_startups(where: { startup_uuid: { _eq: $_eq } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
export type Unfollow_StartupsMutationFn = Apollo.MutationFunction<
  Unfollow_StartupsMutation,
  Unfollow_StartupsMutationVariables
>;

/**
 * __useUnfollow_StartupsMutation__
 *
 * To run a mutation, you first call `useUnfollow_StartupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnfollow_StartupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unfollowStartupsMutation, { data, loading, error }] = useUnfollow_StartupsMutation({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useUnfollow_StartupsMutation(
  baseOptions?: Apollo.MutationHookOptions<Unfollow_StartupsMutation, Unfollow_StartupsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Unfollow_StartupsMutation, Unfollow_StartupsMutationVariables>(
    Unfollow_StartupsDocument,
    options,
  );
}
export type Unfollow_StartupsMutationHookResult = ReturnType<typeof useUnfollow_StartupsMutation>;
export type Unfollow_StartupsMutationResult = Apollo.MutationResult<Unfollow_StartupsMutation>;
export type Unfollow_StartupsMutationOptions = Apollo.BaseMutationOptions<
  Unfollow_StartupsMutation,
  Unfollow_StartupsMutationVariables
>;
export const Follow_StartupsDocument = gql`
  mutation follow_startups($startup_uuid: uuid) {
    insert_follow_relation_users_startups_one(
      object: { startup_uuid: $startup_uuid }
      on_conflict: { constraint: follow_relation_users_startups_user_id_startup_uuid_key, update_columns: updated_at }
    ) {
      id
    }
  }
`;
export type Follow_StartupsMutationFn = Apollo.MutationFunction<
  Follow_StartupsMutation,
  Follow_StartupsMutationVariables
>;

/**
 * __useFollow_StartupsMutation__
 *
 * To run a mutation, you first call `useFollow_StartupsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFollow_StartupsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [followStartupsMutation, { data, loading, error }] = useFollow_StartupsMutation({
 *   variables: {
 *      startup_uuid: // value for 'startup_uuid'
 *   },
 * });
 */
export function useFollow_StartupsMutation(
  baseOptions?: Apollo.MutationHookOptions<Follow_StartupsMutation, Follow_StartupsMutationVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<Follow_StartupsMutation, Follow_StartupsMutationVariables>(
    Follow_StartupsDocument,
    options,
  );
}
export type Follow_StartupsMutationHookResult = ReturnType<typeof useFollow_StartupsMutation>;
export type Follow_StartupsMutationResult = Apollo.MutationResult<Follow_StartupsMutation>;
export type Follow_StartupsMutationOptions = Apollo.BaseMutationOptions<
  Follow_StartupsMutation,
  Follow_StartupsMutationVariables
>;
export const Verify_If_Startup_FollowedDocument = gql`
  query verify_if_startup_followed($startup_uuid: uuid_comparison_exp) {
    users {
      follow_relation_users_startups(where: { startup_uuid: $startup_uuid }) {
        id
      }
    }
  }
`;

/**
 * __useVerify_If_Startup_FollowedQuery__
 *
 * To run a query within a React component, call `useVerify_If_Startup_FollowedQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerify_If_Startup_FollowedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerify_If_Startup_FollowedQuery({
 *   variables: {
 *      startup_uuid: // value for 'startup_uuid'
 *   },
 * });
 */
export function useVerify_If_Startup_FollowedQuery(
  baseOptions?: Apollo.QueryHookOptions<Verify_If_Startup_FollowedQuery, Verify_If_Startup_FollowedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Verify_If_Startup_FollowedQuery, Verify_If_Startup_FollowedQueryVariables>(
    Verify_If_Startup_FollowedDocument,
    options,
  );
}
export function useVerify_If_Startup_FollowedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Verify_If_Startup_FollowedQuery, Verify_If_Startup_FollowedQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Verify_If_Startup_FollowedQuery, Verify_If_Startup_FollowedQueryVariables>(
    Verify_If_Startup_FollowedDocument,
    options,
  );
}
export type Verify_If_Startup_FollowedQueryHookResult = ReturnType<typeof useVerify_If_Startup_FollowedQuery>;
export type Verify_If_Startup_FollowedLazyQueryHookResult = ReturnType<typeof useVerify_If_Startup_FollowedLazyQuery>;
export type Verify_If_Startup_FollowedQueryResult = Apollo.QueryResult<
  Verify_If_Startup_FollowedQuery,
  Verify_If_Startup_FollowedQueryVariables
>;
export const Get_Funding_And_Founded_DatesDocument = gql`
  query get_funding_and_founded_dates($_eq: uuid) {
    startups(where: { uuid: { _eq: $_eq } }) {
      foundedDate
      lastFundingDate
    }
  }
`;

/**
 * __useGet_Funding_And_Founded_DatesQuery__
 *
 * To run a query within a React component, call `useGet_Funding_And_Founded_DatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Funding_And_Founded_DatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Funding_And_Founded_DatesQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Funding_And_Founded_DatesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Funding_And_Founded_DatesQuery,
    Get_Funding_And_Founded_DatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Funding_And_Founded_DatesQuery, Get_Funding_And_Founded_DatesQueryVariables>(
    Get_Funding_And_Founded_DatesDocument,
    options,
  );
}
export function useGet_Funding_And_Founded_DatesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Funding_And_Founded_DatesQuery,
    Get_Funding_And_Founded_DatesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Funding_And_Founded_DatesQuery, Get_Funding_And_Founded_DatesQueryVariables>(
    Get_Funding_And_Founded_DatesDocument,
    options,
  );
}
export type Get_Funding_And_Founded_DatesQueryHookResult = ReturnType<typeof useGet_Funding_And_Founded_DatesQuery>;
export type Get_Funding_And_Founded_DatesLazyQueryHookResult = ReturnType<
  typeof useGet_Funding_And_Founded_DatesLazyQuery
>;
export type Get_Funding_And_Founded_DatesQueryResult = Apollo.QueryResult<
  Get_Funding_And_Founded_DatesQuery,
  Get_Funding_And_Founded_DatesQueryVariables
>;
export const Get_Job_Openings_DataDocument = gql`
  query get_job_openings_data($_eq: uuid) {
    predictleads_job_openings(where: { startup_uuid: { _eq: $_eq } }) {
      startup_name
      startup_uuid
      created_at
      updated_at
      id
      type
      title
      url
      first_seen_at
      last_seen_at
      closed
      country_name
      country_id
      full_location
      full_salary
      salary_low
      salary_low_usd
      salary_high
      salary_high_usd
      salary_currency
      salary_time_unit
      description
      categories
      tags
      full_seniority
      seniority_types
      contract_types
    }
  }
`;

/**
 * __useGet_Job_Openings_DataQuery__
 *
 * To run a query within a React component, call `useGet_Job_Openings_DataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Job_Openings_DataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Job_Openings_DataQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Job_Openings_DataQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Job_Openings_DataQuery, Get_Job_Openings_DataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Job_Openings_DataQuery, Get_Job_Openings_DataQueryVariables>(
    Get_Job_Openings_DataDocument,
    options,
  );
}
export function useGet_Job_Openings_DataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Job_Openings_DataQuery, Get_Job_Openings_DataQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Job_Openings_DataQuery, Get_Job_Openings_DataQueryVariables>(
    Get_Job_Openings_DataDocument,
    options,
  );
}
export type Get_Job_Openings_DataQueryHookResult = ReturnType<typeof useGet_Job_Openings_DataQuery>;
export type Get_Job_Openings_DataLazyQueryHookResult = ReturnType<typeof useGet_Job_Openings_DataLazyQuery>;
export type Get_Job_Openings_DataQueryResult = Apollo.QueryResult<
  Get_Job_Openings_DataQuery,
  Get_Job_Openings_DataQueryVariables
>;
export const Get_Last_Employees_Observation_CountDocument = gql`
  query get_last_employees_observation_count($_eq: uuid) {
    employees(limit: 1, order_by: { date: desc }, where: { startup_uuid: { _eq: $_eq } }) {
      date
      obs
    }
  }
`;

/**
 * __useGet_Last_Employees_Observation_CountQuery__
 *
 * To run a query within a React component, call `useGet_Last_Employees_Observation_CountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_Employees_Observation_CountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_Employees_Observation_CountQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Last_Employees_Observation_CountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Last_Employees_Observation_CountQuery,
    Get_Last_Employees_Observation_CountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_Employees_Observation_CountQuery, Get_Last_Employees_Observation_CountQueryVariables>(
    Get_Last_Employees_Observation_CountDocument,
    options,
  );
}
export function useGet_Last_Employees_Observation_CountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Last_Employees_Observation_CountQuery,
    Get_Last_Employees_Observation_CountQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Last_Employees_Observation_CountQuery,
    Get_Last_Employees_Observation_CountQueryVariables
  >(Get_Last_Employees_Observation_CountDocument, options);
}
export type Get_Last_Employees_Observation_CountQueryHookResult = ReturnType<
  typeof useGet_Last_Employees_Observation_CountQuery
>;
export type Get_Last_Employees_Observation_CountLazyQueryHookResult = ReturnType<
  typeof useGet_Last_Employees_Observation_CountLazyQuery
>;
export type Get_Last_Employees_Observation_CountQueryResult = Apollo.QueryResult<
  Get_Last_Employees_Observation_CountQuery,
  Get_Last_Employees_Observation_CountQueryVariables
>;
export const Get_Employees_Average_TenureDocument = gql`
  query get_employees_average_tenure($eq: uuid) {
    linkedin_employee_avg_tenure(where: { startup_uuid: { _eq: $eq } }, order_by: { date: desc_nulls_last }) {
      average_tenure
      date
    }
  }
`;

/**
 * __useGet_Employees_Average_TenureQuery__
 *
 * To run a query within a React component, call `useGet_Employees_Average_TenureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Employees_Average_TenureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Employees_Average_TenureQuery({
 *   variables: {
 *      eq: // value for 'eq'
 *   },
 * });
 */
export function useGet_Employees_Average_TenureQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Employees_Average_TenureQuery, Get_Employees_Average_TenureQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Employees_Average_TenureQuery, Get_Employees_Average_TenureQueryVariables>(
    Get_Employees_Average_TenureDocument,
    options,
  );
}
export function useGet_Employees_Average_TenureLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Employees_Average_TenureQuery,
    Get_Employees_Average_TenureQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Employees_Average_TenureQuery, Get_Employees_Average_TenureQueryVariables>(
    Get_Employees_Average_TenureDocument,
    options,
  );
}
export type Get_Employees_Average_TenureQueryHookResult = ReturnType<typeof useGet_Employees_Average_TenureQuery>;
export type Get_Employees_Average_TenureLazyQueryHookResult = ReturnType<
  typeof useGet_Employees_Average_TenureLazyQuery
>;
export type Get_Employees_Average_TenureQueryResult = Apollo.QueryResult<
  Get_Employees_Average_TenureQuery,
  Get_Employees_Average_TenureQueryVariables
>;
export const Get_Last_Cofounder_Score_DetailsDocument = gql`
  query get_last_cofounder_score_details($eq: uuid) {
    cofounders(where: { startup_uuid: { _eq: $eq } }) {
      cofounderScore
      confidenceScore
      cofounderScoreComplementary
      cofounderScoreTeamLevel
      percentile_cofounder_score
    }
  }
`;

/**
 * __useGet_Last_Cofounder_Score_DetailsQuery__
 *
 * To run a query within a React component, call `useGet_Last_Cofounder_Score_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Last_Cofounder_Score_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Last_Cofounder_Score_DetailsQuery({
 *   variables: {
 *      eq: // value for 'eq'
 *   },
 * });
 */
export function useGet_Last_Cofounder_Score_DetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Last_Cofounder_Score_DetailsQuery,
    Get_Last_Cofounder_Score_DetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Last_Cofounder_Score_DetailsQuery, Get_Last_Cofounder_Score_DetailsQueryVariables>(
    Get_Last_Cofounder_Score_DetailsDocument,
    options,
  );
}
export function useGet_Last_Cofounder_Score_DetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Last_Cofounder_Score_DetailsQuery,
    Get_Last_Cofounder_Score_DetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Last_Cofounder_Score_DetailsQuery, Get_Last_Cofounder_Score_DetailsQueryVariables>(
    Get_Last_Cofounder_Score_DetailsDocument,
    options,
  );
}
export type Get_Last_Cofounder_Score_DetailsQueryHookResult = ReturnType<
  typeof useGet_Last_Cofounder_Score_DetailsQuery
>;
export type Get_Last_Cofounder_Score_DetailsLazyQueryHookResult = ReturnType<
  typeof useGet_Last_Cofounder_Score_DetailsLazyQuery
>;
export type Get_Last_Cofounder_Score_DetailsQueryResult = Apollo.QueryResult<
  Get_Last_Cofounder_Score_DetailsQuery,
  Get_Last_Cofounder_Score_DetailsQueryVariables
>;
export const Get_Employee_School_Repartition_Last_DateDocument = gql`
  query get_employee_school_repartition_last_date($startup: uuid) {
    linkedin_school_repartition(
      where: { startup_uuid: { _eq: $startup } }
      order_by: { date: desc_nulls_last }
      limit: 1
    ) {
      date
    }
  }
`;

/**
 * __useGet_Employee_School_Repartition_Last_DateQuery__
 *
 * To run a query within a React component, call `useGet_Employee_School_Repartition_Last_DateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Employee_School_Repartition_Last_DateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Employee_School_Repartition_Last_DateQuery({
 *   variables: {
 *      startup: // value for 'startup'
 *   },
 * });
 */
export function useGet_Employee_School_Repartition_Last_DateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Employee_School_Repartition_Last_DateQuery,
    Get_Employee_School_Repartition_Last_DateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Employee_School_Repartition_Last_DateQuery,
    Get_Employee_School_Repartition_Last_DateQueryVariables
  >(Get_Employee_School_Repartition_Last_DateDocument, options);
}
export function useGet_Employee_School_Repartition_Last_DateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Employee_School_Repartition_Last_DateQuery,
    Get_Employee_School_Repartition_Last_DateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Employee_School_Repartition_Last_DateQuery,
    Get_Employee_School_Repartition_Last_DateQueryVariables
  >(Get_Employee_School_Repartition_Last_DateDocument, options);
}
export type Get_Employee_School_Repartition_Last_DateQueryHookResult = ReturnType<
  typeof useGet_Employee_School_Repartition_Last_DateQuery
>;
export type Get_Employee_School_Repartition_Last_DateLazyQueryHookResult = ReturnType<
  typeof useGet_Employee_School_Repartition_Last_DateLazyQuery
>;
export type Get_Employee_School_Repartition_Last_DateQueryResult = Apollo.QueryResult<
  Get_Employee_School_Repartition_Last_DateQuery,
  Get_Employee_School_Repartition_Last_DateQueryVariables
>;
export const Get_Employee_School_RepartitionDocument = gql`
  query get_employee_school_repartition($startup: uuid, $_eq: date) {
    linkedin_school_repartition(where: { startup_uuid: { _eq: $startup }, date: { _eq: $_eq } }) {
      date
      linkedin_universal_name
      linkedin_url
      obs
      school_name
      startup_name
      startup_uuid
    }
  }
`;

/**
 * __useGet_Employee_School_RepartitionQuery__
 *
 * To run a query within a React component, call `useGet_Employee_School_RepartitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Employee_School_RepartitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Employee_School_RepartitionQuery({
 *   variables: {
 *      startup: // value for 'startup'
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Employee_School_RepartitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Employee_School_RepartitionQuery,
    Get_Employee_School_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Employee_School_RepartitionQuery, Get_Employee_School_RepartitionQueryVariables>(
    Get_Employee_School_RepartitionDocument,
    options,
  );
}
export function useGet_Employee_School_RepartitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Employee_School_RepartitionQuery,
    Get_Employee_School_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Employee_School_RepartitionQuery, Get_Employee_School_RepartitionQueryVariables>(
    Get_Employee_School_RepartitionDocument,
    options,
  );
}
export type Get_Employee_School_RepartitionQueryHookResult = ReturnType<typeof useGet_Employee_School_RepartitionQuery>;
export type Get_Employee_School_RepartitionLazyQueryHookResult = ReturnType<
  typeof useGet_Employee_School_RepartitionLazyQuery
>;
export type Get_Employee_School_RepartitionQueryResult = Apollo.QueryResult<
  Get_Employee_School_RepartitionQuery,
  Get_Employee_School_RepartitionQueryVariables
>;
export const Get_Founders_DetailsDocument = gql`
  query get_founders_details($eq: uuid) {
    founders(where: { startup_uuid: { _eq: $eq } }) {
      name
      jobTitle
      founderScoreWithBonus
      confidenceScore
      linkedin
      featureDidTopTierSchool
      educations(where: { founder: { featureDidTopTierSchool: { _eq: true } } }) {
        title
      }
      numberOfFoundedOrganizations
      featureHasTopExperience
      featureYearsOfExperience
      featureHasStartupIndustryExperience
      featureExitsCategory
      numberOfArticlesBeforeStartup
      featureRaisedDuringAFounderExperienceCategory
      featureBonusWorkedInUS
      featureJobsCategory
      featureBonusWorkedInNrCountries
      featureBonusTopInvestors
      featureBonusCompanyExperienceRaisedBeforeAndDuring
      featureBonusHasCLvlGeneratingMoreThan100M
      featureBonusHasCLvlMinimum200MFundedBeforeAndDuring
      featureBonusHasCLvlGeneratingMoreThan10M
      featureBonusHasCLvlMinimum10MFundedBeforeAndDuring
      featureBonusHasManagerLvlGeneratingMoreThan10M
      featureBonusHasManagerLvlFundedBeforeAndDuring
      featureBonusNbIndustryExperiencesCrunchTop2Bonus
      featureFoundedOrganzationsCategory
      featureArticlesCategory
    }
    founder_score_weights {
      didTopTierSchool
      foundedOrganzationsCategory
      hasTopExperienceWeight
      yearsOfExperienceWeight
      hasStartupIndustryExperience
      exitsCategory
      articlesCategory
      raisedDuringAFounderExperienceCategory
      workedInUS
      jobsCategory
      workedInNrCountries
      topInvestors
      companyExperienceRaisedBeforeAndDuringMoreThan5M
      companyExperienceRaisedBeforeAndDuringPrevStartup
      hasCLvlGeneratingMoreThan100MOrhasCLvlMinimum200MFundedBeforeAn
      hasCLvlGeneratingMoreThan10MOrhasCLvlMinimum10MFundedBeforeAndD
      hasManagerLvlGeneratingMoreThan10M
      hasOneIndustryStartupMatch
      hasTwoIndustryStartupMatch
    }
  }
`;

/**
 * __useGet_Founders_DetailsQuery__
 *
 * To run a query within a React component, call `useGet_Founders_DetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Founders_DetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Founders_DetailsQuery({
 *   variables: {
 *      eq: // value for 'eq'
 *   },
 * });
 */
export function useGet_Founders_DetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Founders_DetailsQuery, Get_Founders_DetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Founders_DetailsQuery, Get_Founders_DetailsQueryVariables>(
    Get_Founders_DetailsDocument,
    options,
  );
}
export function useGet_Founders_DetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Founders_DetailsQuery, Get_Founders_DetailsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Founders_DetailsQuery, Get_Founders_DetailsQueryVariables>(
    Get_Founders_DetailsDocument,
    options,
  );
}
export type Get_Founders_DetailsQueryHookResult = ReturnType<typeof useGet_Founders_DetailsQuery>;
export type Get_Founders_DetailsLazyQueryHookResult = ReturnType<typeof useGet_Founders_DetailsLazyQuery>;
export type Get_Founders_DetailsQueryResult = Apollo.QueryResult<
  Get_Founders_DetailsQuery,
  Get_Founders_DetailsQueryVariables
>;
export const Get_Employee_Post_Repartition_Last_DateDocument = gql`
  query get_employee_post_repartition_last_date($startup: uuid) {
    employee_post_repartition(
      where: { startup_uuid: { _eq: $startup } }
      order_by: { date: desc_nulls_last }
      limit: 1
    ) {
      date
    }
  }
`;

/**
 * __useGet_Employee_Post_Repartition_Last_DateQuery__
 *
 * To run a query within a React component, call `useGet_Employee_Post_Repartition_Last_DateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Employee_Post_Repartition_Last_DateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Employee_Post_Repartition_Last_DateQuery({
 *   variables: {
 *      startup: // value for 'startup'
 *   },
 * });
 */
export function useGet_Employee_Post_Repartition_Last_DateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Employee_Post_Repartition_Last_DateQuery,
    Get_Employee_Post_Repartition_Last_DateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Employee_Post_Repartition_Last_DateQuery,
    Get_Employee_Post_Repartition_Last_DateQueryVariables
  >(Get_Employee_Post_Repartition_Last_DateDocument, options);
}
export function useGet_Employee_Post_Repartition_Last_DateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Employee_Post_Repartition_Last_DateQuery,
    Get_Employee_Post_Repartition_Last_DateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Employee_Post_Repartition_Last_DateQuery,
    Get_Employee_Post_Repartition_Last_DateQueryVariables
  >(Get_Employee_Post_Repartition_Last_DateDocument, options);
}
export type Get_Employee_Post_Repartition_Last_DateQueryHookResult = ReturnType<
  typeof useGet_Employee_Post_Repartition_Last_DateQuery
>;
export type Get_Employee_Post_Repartition_Last_DateLazyQueryHookResult = ReturnType<
  typeof useGet_Employee_Post_Repartition_Last_DateLazyQuery
>;
export type Get_Employee_Post_Repartition_Last_DateQueryResult = Apollo.QueryResult<
  Get_Employee_Post_Repartition_Last_DateQuery,
  Get_Employee_Post_Repartition_Last_DateQueryVariables
>;
export const Get_Employee_Post_RepartitionDocument = gql`
  query get_employee_post_repartition($startup: uuid, $_date: date) {
    employee_post_repartition(where: { startup_uuid: { _eq: $startup }, date: { _eq: $_date } }) {
      date
      post
      obs
    }
  }
`;

/**
 * __useGet_Employee_Post_RepartitionQuery__
 *
 * To run a query within a React component, call `useGet_Employee_Post_RepartitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Employee_Post_RepartitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Employee_Post_RepartitionQuery({
 *   variables: {
 *      startup: // value for 'startup'
 *      _date: // value for '_date'
 *   },
 * });
 */
export function useGet_Employee_Post_RepartitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Employee_Post_RepartitionQuery,
    Get_Employee_Post_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Employee_Post_RepartitionQuery, Get_Employee_Post_RepartitionQueryVariables>(
    Get_Employee_Post_RepartitionDocument,
    options,
  );
}
export function useGet_Employee_Post_RepartitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Employee_Post_RepartitionQuery,
    Get_Employee_Post_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Employee_Post_RepartitionQuery, Get_Employee_Post_RepartitionQueryVariables>(
    Get_Employee_Post_RepartitionDocument,
    options,
  );
}
export type Get_Employee_Post_RepartitionQueryHookResult = ReturnType<typeof useGet_Employee_Post_RepartitionQuery>;
export type Get_Employee_Post_RepartitionLazyQueryHookResult = ReturnType<
  typeof useGet_Employee_Post_RepartitionLazyQuery
>;
export type Get_Employee_Post_RepartitionQueryResult = Apollo.QueryResult<
  Get_Employee_Post_RepartitionQuery,
  Get_Employee_Post_RepartitionQueryVariables
>;
export const Get_Employee_Country_Repartition_Last_DateDocument = gql`
  query get_employee_country_repartition_last_date($startup: uuid) {
    employee_country_repartition(
      where: { startup_uuid: { _eq: $startup } }
      order_by: { date: desc_nulls_last }
      limit: 1
    ) {
      date
    }
  }
`;

/**
 * __useGet_Employee_Country_Repartition_Last_DateQuery__
 *
 * To run a query within a React component, call `useGet_Employee_Country_Repartition_Last_DateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Employee_Country_Repartition_Last_DateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Employee_Country_Repartition_Last_DateQuery({
 *   variables: {
 *      startup: // value for 'startup'
 *   },
 * });
 */
export function useGet_Employee_Country_Repartition_Last_DateQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Employee_Country_Repartition_Last_DateQuery,
    Get_Employee_Country_Repartition_Last_DateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Employee_Country_Repartition_Last_DateQuery,
    Get_Employee_Country_Repartition_Last_DateQueryVariables
  >(Get_Employee_Country_Repartition_Last_DateDocument, options);
}
export function useGet_Employee_Country_Repartition_Last_DateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Employee_Country_Repartition_Last_DateQuery,
    Get_Employee_Country_Repartition_Last_DateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Employee_Country_Repartition_Last_DateQuery,
    Get_Employee_Country_Repartition_Last_DateQueryVariables
  >(Get_Employee_Country_Repartition_Last_DateDocument, options);
}
export type Get_Employee_Country_Repartition_Last_DateQueryHookResult = ReturnType<
  typeof useGet_Employee_Country_Repartition_Last_DateQuery
>;
export type Get_Employee_Country_Repartition_Last_DateLazyQueryHookResult = ReturnType<
  typeof useGet_Employee_Country_Repartition_Last_DateLazyQuery
>;
export type Get_Employee_Country_Repartition_Last_DateQueryResult = Apollo.QueryResult<
  Get_Employee_Country_Repartition_Last_DateQuery,
  Get_Employee_Country_Repartition_Last_DateQueryVariables
>;
export const Get_Employee_Country_RepartitionDocument = gql`
  query get_employee_country_repartition($startup: uuid, $_date: date) {
    employee_country_repartition(where: { startup_uuid: { _eq: $startup }, date: { _eq: $_date } }) {
      date
      obs
      country_name {
        country_name
      }
    }
  }
`;

/**
 * __useGet_Employee_Country_RepartitionQuery__
 *
 * To run a query within a React component, call `useGet_Employee_Country_RepartitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Employee_Country_RepartitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Employee_Country_RepartitionQuery({
 *   variables: {
 *      startup: // value for 'startup'
 *      _date: // value for '_date'
 *   },
 * });
 */
export function useGet_Employee_Country_RepartitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Employee_Country_RepartitionQuery,
    Get_Employee_Country_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Employee_Country_RepartitionQuery, Get_Employee_Country_RepartitionQueryVariables>(
    Get_Employee_Country_RepartitionDocument,
    options,
  );
}
export function useGet_Employee_Country_RepartitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Employee_Country_RepartitionQuery,
    Get_Employee_Country_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Employee_Country_RepartitionQuery, Get_Employee_Country_RepartitionQueryVariables>(
    Get_Employee_Country_RepartitionDocument,
    options,
  );
}
export type Get_Employee_Country_RepartitionQueryHookResult = ReturnType<
  typeof useGet_Employee_Country_RepartitionQuery
>;
export type Get_Employee_Country_RepartitionLazyQueryHookResult = ReturnType<
  typeof useGet_Employee_Country_RepartitionLazyQuery
>;
export type Get_Employee_Country_RepartitionQueryResult = Apollo.QueryResult<
  Get_Employee_Country_RepartitionQuery,
  Get_Employee_Country_RepartitionQueryVariables
>;
export const Get_Website_Traffic_Sources_RepartitionDocument = gql`
  query get_website_traffic_sources_repartition($_eq: uuid) {
    web_visits_general_data(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      date
      percentage_of_direct_traffic
      percentage_of_traffic_from_mail
      percentage_of_traffic_from_paid_referrals
      percentage_of_traffic_from_referrals
      percentage_of_traffic_from_search
      percentage_of_traffic_from_social
    }
  }
`;

/**
 * __useGet_Website_Traffic_Sources_RepartitionQuery__
 *
 * To run a query within a React component, call `useGet_Website_Traffic_Sources_RepartitionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Website_Traffic_Sources_RepartitionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Website_Traffic_Sources_RepartitionQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Website_Traffic_Sources_RepartitionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Website_Traffic_Sources_RepartitionQuery,
    Get_Website_Traffic_Sources_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Get_Website_Traffic_Sources_RepartitionQuery,
    Get_Website_Traffic_Sources_RepartitionQueryVariables
  >(Get_Website_Traffic_Sources_RepartitionDocument, options);
}
export function useGet_Website_Traffic_Sources_RepartitionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Website_Traffic_Sources_RepartitionQuery,
    Get_Website_Traffic_Sources_RepartitionQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Get_Website_Traffic_Sources_RepartitionQuery,
    Get_Website_Traffic_Sources_RepartitionQueryVariables
  >(Get_Website_Traffic_Sources_RepartitionDocument, options);
}
export type Get_Website_Traffic_Sources_RepartitionQueryHookResult = ReturnType<
  typeof useGet_Website_Traffic_Sources_RepartitionQuery
>;
export type Get_Website_Traffic_Sources_RepartitionLazyQueryHookResult = ReturnType<
  typeof useGet_Website_Traffic_Sources_RepartitionLazyQuery
>;
export type Get_Website_Traffic_Sources_RepartitionQueryResult = Apollo.QueryResult<
  Get_Website_Traffic_Sources_RepartitionQuery,
  Get_Website_Traffic_Sources_RepartitionQueryVariables
>;
export const Get_Website_Share_Paid_TrafficDocument = gql`
  query get_website_share_paid_traffic($_eq: uuid) {
    web_visits_general_data(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      date
      paid_search_share
    }
  }
`;

/**
 * __useGet_Website_Share_Paid_TrafficQuery__
 *
 * To run a query within a React component, call `useGet_Website_Share_Paid_TrafficQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Website_Share_Paid_TrafficQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Website_Share_Paid_TrafficQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Website_Share_Paid_TrafficQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Website_Share_Paid_TrafficQuery,
    Get_Website_Share_Paid_TrafficQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Website_Share_Paid_TrafficQuery, Get_Website_Share_Paid_TrafficQueryVariables>(
    Get_Website_Share_Paid_TrafficDocument,
    options,
  );
}
export function useGet_Website_Share_Paid_TrafficLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Website_Share_Paid_TrafficQuery,
    Get_Website_Share_Paid_TrafficQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Website_Share_Paid_TrafficQuery, Get_Website_Share_Paid_TrafficQueryVariables>(
    Get_Website_Share_Paid_TrafficDocument,
    options,
  );
}
export type Get_Website_Share_Paid_TrafficQueryHookResult = ReturnType<typeof useGet_Website_Share_Paid_TrafficQuery>;
export type Get_Website_Share_Paid_TrafficLazyQueryHookResult = ReturnType<
  typeof useGet_Website_Share_Paid_TrafficLazyQuery
>;
export type Get_Website_Share_Paid_TrafficQueryResult = Apollo.QueryResult<
  Get_Website_Share_Paid_TrafficQuery,
  Get_Website_Share_Paid_TrafficQueryVariables
>;
export const Get_Downloads_Growth_And_YoY_AvgDocument = gql`
  query get_downloads_growth_and_YoY_avg($_eq: uuid, $_gte: date) {
    growth(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      annualized_growth_downloads
      date
    }
    mobile_app_aggregate(where: { date: { _gte: $_gte }, startup_uuid: { _eq: $_eq } }) {
      aggregate {
        avg {
          nb_downloads
        }
      }
    }
  }
`;

/**
 * __useGet_Downloads_Growth_And_YoY_AvgQuery__
 *
 * To run a query within a React component, call `useGet_Downloads_Growth_And_YoY_AvgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Downloads_Growth_And_YoY_AvgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Downloads_Growth_And_YoY_AvgQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *      _gte: // value for '_gte'
 *   },
 * });
 */
export function useGet_Downloads_Growth_And_YoY_AvgQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Get_Downloads_Growth_And_YoY_AvgQuery,
    Get_Downloads_Growth_And_YoY_AvgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Downloads_Growth_And_YoY_AvgQuery, Get_Downloads_Growth_And_YoY_AvgQueryVariables>(
    Get_Downloads_Growth_And_YoY_AvgDocument,
    options,
  );
}
export function useGet_Downloads_Growth_And_YoY_AvgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Get_Downloads_Growth_And_YoY_AvgQuery,
    Get_Downloads_Growth_And_YoY_AvgQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Downloads_Growth_And_YoY_AvgQuery, Get_Downloads_Growth_And_YoY_AvgQueryVariables>(
    Get_Downloads_Growth_And_YoY_AvgDocument,
    options,
  );
}
export type Get_Downloads_Growth_And_YoY_AvgQueryHookResult = ReturnType<
  typeof useGet_Downloads_Growth_And_YoY_AvgQuery
>;
export type Get_Downloads_Growth_And_YoY_AvgLazyQueryHookResult = ReturnType<
  typeof useGet_Downloads_Growth_And_YoY_AvgLazyQuery
>;
export type Get_Downloads_Growth_And_YoY_AvgQueryResult = Apollo.QueryResult<
  Get_Downloads_Growth_And_YoY_AvgQuery,
  Get_Downloads_Growth_And_YoY_AvgQueryVariables
>;
export const Get_Dau_Growth_And_YoY_AvgDocument = gql`
  query get_dau_growth_and_YoY_avg($_eq: uuid, $_gte: date) {
    growth(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      annualized_growth_dau
      date
    }
    mobile_app_aggregate(where: { date: { _gte: $_gte }, startup_uuid: { _eq: $_eq } }) {
      aggregate {
        avg {
          nb_dau
        }
      }
    }
  }
`;

/**
 * __useGet_Dau_Growth_And_YoY_AvgQuery__
 *
 * To run a query within a React component, call `useGet_Dau_Growth_And_YoY_AvgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Dau_Growth_And_YoY_AvgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Dau_Growth_And_YoY_AvgQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *      _gte: // value for '_gte'
 *   },
 * });
 */
export function useGet_Dau_Growth_And_YoY_AvgQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Dau_Growth_And_YoY_AvgQuery, Get_Dau_Growth_And_YoY_AvgQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Dau_Growth_And_YoY_AvgQuery, Get_Dau_Growth_And_YoY_AvgQueryVariables>(
    Get_Dau_Growth_And_YoY_AvgDocument,
    options,
  );
}
export function useGet_Dau_Growth_And_YoY_AvgLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Dau_Growth_And_YoY_AvgQuery, Get_Dau_Growth_And_YoY_AvgQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Dau_Growth_And_YoY_AvgQuery, Get_Dau_Growth_And_YoY_AvgQueryVariables>(
    Get_Dau_Growth_And_YoY_AvgDocument,
    options,
  );
}
export type Get_Dau_Growth_And_YoY_AvgQueryHookResult = ReturnType<typeof useGet_Dau_Growth_And_YoY_AvgQuery>;
export type Get_Dau_Growth_And_YoY_AvgLazyQueryHookResult = ReturnType<typeof useGet_Dau_Growth_And_YoY_AvgLazyQuery>;
export type Get_Dau_Growth_And_YoY_AvgQueryResult = Apollo.QueryResult<
  Get_Dau_Growth_And_YoY_AvgQuery,
  Get_Dau_Growth_And_YoY_AvgQueryVariables
>;
export const Get_Webvisits_MetricsDocument = gql`
  query get_webvisits_metrics($_eq: uuid) {
    web_visits_general_data(where: { startup_uuid: { _eq: $_eq } }, order_by: { date: desc_nulls_last }, limit: 1) {
      bounce_rate
      date
      page_per_visits
      number_of_refering_websites
      time_on_website
    }
  }
`;

/**
 * __useGet_Webvisits_MetricsQuery__
 *
 * To run a query within a React component, call `useGet_Webvisits_MetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGet_Webvisits_MetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGet_Webvisits_MetricsQuery({
 *   variables: {
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useGet_Webvisits_MetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<Get_Webvisits_MetricsQuery, Get_Webvisits_MetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Get_Webvisits_MetricsQuery, Get_Webvisits_MetricsQueryVariables>(
    Get_Webvisits_MetricsDocument,
    options,
  );
}
export function useGet_Webvisits_MetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Get_Webvisits_MetricsQuery, Get_Webvisits_MetricsQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Get_Webvisits_MetricsQuery, Get_Webvisits_MetricsQueryVariables>(
    Get_Webvisits_MetricsDocument,
    options,
  );
}
export type Get_Webvisits_MetricsQueryHookResult = ReturnType<typeof useGet_Webvisits_MetricsQuery>;
export type Get_Webvisits_MetricsLazyQueryHookResult = ReturnType<typeof useGet_Webvisits_MetricsLazyQuery>;
export type Get_Webvisits_MetricsQueryResult = Apollo.QueryResult<
  Get_Webvisits_MetricsQuery,
  Get_Webvisits_MetricsQueryVariables
>;
