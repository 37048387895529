import { FC } from 'react';

import { NavList } from './NavList';
import { INavSubListProps } from './navList.types';

export const NavSubList: FC<INavSubListProps> = (props) => {
  const { data, depth, startupId } = props;
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          startupId={startupId}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
        />
      ))}
    </>
  );
};
