import { FC } from 'react';

import { alpha } from '@mui/material';

import { Iconify } from '../Iconify';
interface ITrendingInfoIconProps {
  percent?: number;
}
export const TrendingInfoIcon: FC<ITrendingInfoIconProps> = ({ percent }) => {
  return (
    <Iconify
      icon={
        (percent || 0) < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'
      }
      sx={{
        mr: 1,
        p: 0.5,
        width: 24,
        height: 24,
        borderRadius: '50%',
        color: 'success.main',
        bgcolor: (theme) => alpha(theme.palette.success.main, 0.16),
        ...((percent || 0) < 0 && {
          color: 'error.main',
          bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
        }),
      }}
    />
  );
};
