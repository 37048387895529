import { FC } from 'react';

import { IconButton, Stack } from '@mui/material';

import { CenteredIcon } from '../../Icon';
import { Iconify } from '../../Iconify';
import {
  AddStartupModalWindow,
  ResultMessageModalWindow,
} from '../../ModalWindows';
import {
  RefreshDataModalWindow,
  SuccessfullyRefreshedModalWindow,
} from '../../ModalWindows/RefreshData';

import { AccountPopover } from './components';
import { SearchBar } from './components/SearchBar';
import { useDashboardHeaderState } from './DashboardHeader.state';
import { DashboardHeaderStyles as Styled } from './DashboardHeader.styles';

interface IDashboardHeaderProps {
  onOpenNav?: VoidFunction;
}

export const DashboardHeader: FC<IDashboardHeaderProps> = (props) => {
  const { onOpenNav } = props;
  const {
    errorStatus,
    isAddStartupModalWindowOpen,
    isDesktop,
    startupName,
    isLoading,
    onFindStartupInputChangeHandler,
    searchValue,
    startups,
    onCloseModalWindow,
    onCloseResultModalWindowhandler,
    isRefreshModalWindowOpen,
    onCloseRefreshModalWindow,
    onRefreshButtonClickHandler,
    onToggleIsDataRefreshedModalWindowOpen,
    isDataRefreshedModalWindowOpen,
    handleLogout,
    onAddStartupHandler,
    onOpenAddStartupModalWindowHandler,
  } = useDashboardHeaderState();
  const renderContent = (
    <>
      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: 'text.primary' }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}
      <SearchBar />

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1.5 }}
      >
        <Styled.Button
          variant="contained"
          size="small"
          onClick={onOpenAddStartupModalWindowHandler}
        >
          Add startup
        </Styled.Button>

        <IconButton onClick={handleLogout}>
          <CenteredIcon
            sx={{ mb: 0, ':hover': { cursor: 'pointer' } }}
            type="logout"
          />
        </IconButton>

        <AccountPopover />
      </Stack>
    </>
  );

  return (
    <Styled.AppBar isDesktop={isDesktop} isOffset={false}>
      <AddStartupModalWindow
        isOpen={isAddStartupModalWindowOpen}
        onCloseWindowHandler={onCloseModalWindow}
        onAddStartupHandler={onAddStartupHandler}
        isLoading={isLoading}
        inputValue={searchValue}
        startups={startups}
        onChange={onFindStartupInputChangeHandler}
      />
      <ResultMessageModalWindow
        errorStatus={errorStatus || 0}
        isOpen={!!errorStatus}
        startupName={startupName}
        onCloseModalWondow={onCloseResultModalWindowhandler}
      />

      <RefreshDataModalWindow
        onApproveButtonClickHandler={onRefreshButtonClickHandler}
        onRejectButtonClickHandler={onCloseRefreshModalWindow}
        isRefreshModalWindowOpen={isRefreshModalWindowOpen}
        isLoading={isLoading}
      />

      <SuccessfullyRefreshedModalWindow
        isDataRefreshedModalWindowOpen={isDataRefreshedModalWindowOpen}
        onToggleIsDataRefreshedModalWindowOpen={
          onToggleIsDataRefreshedModalWindowOpen
        }
      />

      <Styled.ToolBar>{renderContent}</Styled.ToolBar>
    </Styled.AppBar>
  );
};
