import { Box, BoxProps, styled } from '@mui/material';

import { HEADER_CONFIG, NAV_VERTICAL_CONFIG, SPACING } from '@constants/config-global';

interface CustomBoxProps extends BoxProps {
  isDesktop: boolean;
}
export const MainLayoutStyles = {
  BoxSX: {
    display: { lg: 'flex' },
    minHeight: { lg: 1 },
    height: '100%',
  },
  ContentWrapper: styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isDesktop',
  })<CustomBoxProps>(({ isDesktop }) => ({
    height: '100%',
    flexGrow: 1,
    padding: `${HEADER_CONFIG.H_MOBILE + SPACING}px 0`,
    ...(isDesktop && {
      padding: `${HEADER_CONFIG.H_DASHBOARD_DESKTOP + SPACING}px 16px`,
      width: `calc(100% - ${NAV_VERTICAL_CONFIG.W_DASHBOARD}px)`,
    }),
  })),
};
