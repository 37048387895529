import { Theme } from '@mui/material';

import { fNumber } from '@utils/formatNumber';

import { INSIDE_SCOPE_MESSAGE } from './StartupLogoPopover.constants';
import {
  IGetFittingSourcingCriteriasScopeData,
  IGetScopeInfo,
} from './StartupLogoPopover.types';

export const getScopeInfo = (props: IGetScopeInfo) => {
  const { startup, minimumAmountRaised } = props;
  const scopeData = {
    isOutsideScope:
      !startup?.is_fitting_sourcing_criterias || !!startup?.added_manually,
    scopeTitle: 'Outside our scope',
    scopeSubtitle: 'According to Crunchbase data',
    color: (theme: Theme) => theme.palette.error.darker,
    isManualyAdded: !!startup?.added_manually,
    items: [] as string[],
  };

  if (startup?.is_fitting_sourcing_criterias) {
    return getFittingSourcingCriteriasScopeData({
      scopeData,
      startup,
      minimumAmountRaised,
    });
  }

  return {
    ...scopeData,
    items: [
      `Raised ${fNumber(startup?.totalFundingAmountInUSD)}$`,
      `From ${startup?.headquartersCountry}`,
    ],
  };
};

const getFittingSourcingCriteriasScopeData = (
  props: IGetFittingSourcingCriteriasScopeData
) => {
  const { scopeData, minimumAmountRaised, startup } = props;
  if (startup?.added_manually) {
    scopeData.isManualyAdded = true;
  } else {
    switch (startup?.is_led_by_europeans) {
      case 'both':
        scopeData.items.push(
          `${INSIDE_SCOPE_MESSAGE} (studied and lives in Europe)`
        );
        break;

      case 'founders_location':
        scopeData.items.push(`${INSIDE_SCOPE_MESSAGE} (lives in Europe)`);
        break;

      case 'founders_education':
        scopeData.items.push(`${INSIDE_SCOPE_MESSAGE} (studied in Europe)`);
        break;

      default:
        break;
    }
    scopeData.items.push(
      `Raised ${fNumber(minimumAmountRaised || 0)}$`,
      `${startup?.headquartersCountry} in sourcing criteria`
    );
  }
  return {
    ...scopeData,
    isOutsideScope: false,
    scopeTitle: 'Inside our scope',
    scopeSubtitle: 'Data points collected',
    color: (theme: Theme) => theme.palette.success.main,
  };
};
