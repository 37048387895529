import { FC, useMemo, useState } from 'react';

import { SearchBarContext, SearchBarContextProps } from '../Contexts';

import { IProvider } from './types';

export const SearchBarContextProvider: FC<IProvider> = ({ children }) => {
  const [isEmptyScreen, setIsEmptyScreen] = useState(false);

  const value: SearchBarContextProps = useMemo(
    () => ({
      setIsEmptyScreen,
      isEmptyScreen,
    }),
    [isEmptyScreen]
  );

  return (
    <SearchBarContext.Provider value={value}>
      {children}
    </SearchBarContext.Provider>
  );
};
