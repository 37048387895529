import { FC } from 'react';

import { LoadingButton } from '@mui/lab';
import { Button, Modal, Stack, Typography } from '@mui/material';

import { StyledCard } from '@styles/overrides/Card';

import { AddStartupStyles as Styled } from '../AddStartup/AddStartup.styles';

interface IRefreshDataModalWindowProps {
  onApproveButtonClickHandler: () => Promise<void>;
  isRefreshModalWindowOpen: boolean;
  onRejectButtonClickHandler: () => void;
  isLoading: boolean;
}
export const RefreshDataModalWindow: FC<IRefreshDataModalWindowProps> = (
  props
) => {
  const {
    onApproveButtonClickHandler,
    isRefreshModalWindowOpen,
    onRejectButtonClickHandler,
    isLoading,
  } = props;
  return (
    <Modal
      open={isRefreshModalWindowOpen}
      onClose={onRejectButtonClickHandler}
      sx={{ m: 1 }}
    >
      <StyledCard sx={Styled.CardSX}>
        <Stack sx={{ maxWidth: 320, width: 1 }} spacing={3}>
          <Typography variant="h4" textAlign="center">
            Are you sure you want to refresh this startup data ?
          </Typography>

          <Stack
            direction="row"
            justifyContent="space-between"
            width="50%"
            alignSelf="center"
          >
            <LoadingButton
              onClick={onApproveButtonClickHandler}
              variant="contained"
              color="success"
              loading={isLoading}
            >
              Yes
            </LoadingButton>
            <Button onClick={onRejectButtonClickHandler} variant="contained">
              No
            </Button>
          </Stack>
        </Stack>
      </StyledCard>
    </Modal>
  );
};
