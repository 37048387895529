const X_API_KEY = process.env.REACT_APP_X_API_KEY;
const ENV_PARAM = 'env=prod';


export const BASE_API_URL = process.env.REACT_APP_AUTH_DOMAIN?.includes('staging')
  ? 'https://heroic-akita-52.hasura.app/v1/graphql'
  : 'https://solid-lizard-47.hasura.app/v1/graphql';

export const BASE_ATTIO_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/attio?x-api-key=${X_API_KEY}`;
export const BASE_SCORE_DISTRIBUTION_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/compute_score_distribution?x-api-key=${X_API_KEY}&${ENV_PARAM}`;
export const BASE_SEARCH_EMAIL_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/search_email?x-api-key=${X_API_KEY}`;
export const BASE_GET_SCORES_LAST_DATES_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/get_scores_last_dates?x-api-key=${X_API_KEY}&${ENV_PARAM}`;
export const BASE_WEBVISITS_HISTORY_PER_COUNTRY_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/web_visits_history_per_country?x-api-key=${X_API_KEY}&${ENV_PARAM}`;
export const BASE_WEBVISITS_YOY_DETAILS = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/web_visits_YoY_details?x-api-key=${X_API_KEY}&${ENV_PARAM}`;
export const BASE_ADD_REFRESH_STARTUP_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/pipeline-trigger-manual?x-api-key=${X_API_KEY}`;
export const BASE_CHANGE_USER_NICKNAME_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/change_user_nickname?x-api-key=${X_API_KEY}&${ENV_PARAM}`;
export const BASE_COUNTRY_REPARTITION_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/web_visits_country_repartition?x-api-key=${X_API_KEY}`;

export const BASE_AUTOCOMPLETE_URL = `https://ci1ir9clmd.execute-api.eu-west-3.amazonaws.com/crunchbase/autocomplete?x-api-key=${X_API_KEY}`;
