import { FC } from 'react';

import { Box, alpha } from '@mui/material';

import { Iconify } from '../Iconify';

import { ISnackbarIconProps } from './SnackBar.types';

export const SnackbarIcon: FC<ISnackbarIconProps> = (props) => {
  const { color, icon } = props;
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: 1.5,
        alignItems: 'center',
        justifyContent: 'center',
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      <Iconify icon={icon} width={24} />
    </Box>
  );
};
