import { FC } from 'react';

import { Stack, Typography } from '@mui/material';

import { useResponsive } from '@hooks/useResponsive';

import { NAV_VERTICAL_CONFIG } from '@constants/config-global';

export const NoSearchedStartupsScreen: FC = () => {
  const isDesktop = useResponsive('up', 'lg');

  const leftValue = isDesktop
    ? `calc(50% + ${NAV_VERTICAL_CONFIG.W_DASHBOARD / 2}px)`
    : '50%';
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        position: 'absolute',
        top: '50% ',
        left: leftValue,
        transform: 'translate(-50%, -50%)',
        width: 1,
      }}
    >
      <Typography
        variant="h5"
        sx={{ textAlign: 'center', maxWidth: '600px', mx: 1 }}
      >
        You haven’t search for a startup yet. Please use the search bar on top
        to get to a startup dashboard
      </Typography>
    </Stack>
  );
};
