import { FC, memo } from 'react';

import { Box } from '@mui/material';
import 'simplebar/dist/simplebar.min.css';

import { StyledRootScrollbar, StyledScrollbar } from './scrollBar.styles';
import { IScrollbarProps } from './types/scrollBar.types';

export const Scrollbar: FC<IScrollbarProps> = memo((props) => {
  const { children, sx, ...other } = props;

  const userAgent =
    typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent;

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  if (isMobile) {
    return (
      <Box sx={{ overflowX: 'auto', ...sx }} {...other}>
        {children}
      </Box>
    );
  }

  return (
    <StyledRootScrollbar>
      <StyledScrollbar clickOnTrack={false} sx={sx} {...other}>
        {children}
      </StyledScrollbar>
    </StyledRootScrollbar>
  );
});
