import { useEffect } from 'react';

import { useGet_Startup_Name_Logo_LinksQuery } from '@generated/graphql';

import { useNotistackError } from './useNotistackError';
import { useStartupInfo } from './useStartupInfo';

//fetch startup info
export const useGetStartupLogoLinks = (startupId?: string | null) => {
  const { data, loading, error } = useGet_Startup_Name_Logo_LinksQuery({
    variables: {
      _eq: startupId || '',
    },
  });

  const { setLoading, setStartup } = useStartupInfo();

  const startup = data?.startups[0];
  const dataSourcesLastUpdates = startup?.data_sources_last_update;

  useNotistackError(error);

  useEffect(() => {
    // use context to set loading data and use it in diff components
    setLoading(loading);
  }, [loading, setLoading]);

  useEffect(() => {
    // use context to set startup data and use it in diff components
    setStartup(startup);
  }, [startup, setStartup]);

  return {
    startup,
    loading,
    dataSourcesLastUpdates,
  };
};
