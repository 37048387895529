import { FC } from 'react';

import { BoxProps } from '@mui/material';

import { useResponsive } from '@hooks/useResponsive';

import { MainLayoutStyles as Styled } from './MainLayout.styles';

export const ContentWrapper: FC<BoxProps> = (props) => {
  const { children, sx, ...other } = props;

  const isDesktop = useResponsive('up', 'lg');

  return (
    <Styled.ContentWrapper
      component="main"
      isDesktop={isDesktop}
      sx={{ ...sx }}
      {...other}
    >
      {children}
    </Styled.ContentWrapper>
  );
};
