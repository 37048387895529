import { Theme, alpha } from '@mui/material';

export const AccountPopoverStyles = {
  IconButtonAnimateSX: {
    '&:before': {
      zIndex: 1,
      content: "''",
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      position: 'absolute',
      bgcolor: (theme: Theme) => alpha(theme.palette.grey[900], 0.8),
    },
  },
};
