import { TIconType } from '@components/Icon';

import { ROUTES } from './routes';

interface IItem {
  title: string;
  path: string;
  icon: TIconType;
  children?: { title: string; path: string }[];
}
interface INavLinkRoutesConfig {
  subheader: string;
  items: IItem[];
}
export const NavLinkRoutesConfig: INavLinkRoutesConfig[] = [
  {
    subheader: 'MENU',
    items: [
      {
        title: 'Home',
        path: ROUTES.home,
        icon: 'navHome',
      },
      {
        title: 'Startup',
        path: ROUTES.startups.root,
        icon: 'navStartups',
        children: [
          { title: 'overview', path: ROUTES.startups.overview },
          { title: 'growth', path: ROUTES.startups.growth },
          { title: 'team', path: ROUTES.startups.team },
          { title: 'international', path: ROUTES.startups.international },
          { title: 'competition', path: ROUTES.startups.competition },
          { title: 'ESG', path: ROUTES.startups.esg },
          { title: 'website & app', path: ROUTES.startups.websiteApp },
          { title: 'Chat startup', path: ROUTES.startups.chat },
          { title: 'recruitment', path: ROUTES.startups.recruitment },
        ],
      },
    ],
  },
];
