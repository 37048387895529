import { createContext } from 'react';

export interface SearchBarContextProps {
  isEmptyScreen: boolean;
  setIsEmptyScreen: (isEmptyScreen: boolean) => void;
}

export const SearchBarContext = createContext<SearchBarContextProps>({
  setIsEmptyScreen: (isEmptyScreen: boolean) => {},
  isEmptyScreen: false,
});
