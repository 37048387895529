import { FC, useMemo, useState } from 'react';

import { StartupInfoContext } from '../Contexts';

import { IProvider } from './types';

export const StartupInfoProvider: FC<IProvider> = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [startup, setStartup] = useState<TStartup>(null);
  const [isRefreshModalWindowOpen, setIsRefreshModalWindowOpen] =
    useState(false);

  const [isHiddenAlertPopup, setIsHiddenAlertPopup] = useState(true);

  const defaultValues = useMemo(
    () => ({
      loading,
      setLoading,
      setStartup,
      isHiddenAlertPopup,
      setIsHiddenAlertPopup,
      isRefreshModalWindowOpen,
      setIsRefreshModalWindowOpen,
      startup,
    }),
    [isHiddenAlertPopup, isRefreshModalWindowOpen, loading, startup]
  );
  return (
    <StartupInfoContext.Provider value={defaultValues}>
      {children}
    </StartupInfoContext.Provider>
  );
};
