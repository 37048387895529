import { FC } from 'react';

import { Box, Skeleton, Stack } from '@mui/material';

import { SkeletonStyles as Styled } from './SearchBar.styles';

export const SearchBarSkeleton: FC = () => {
  return (
    <Stack direction="column" spacing={2}>
      {[...Array(4)].map((_, index) => (
        <Stack
          key={index}
          direction="row"
          alignItems="flex-start"
          spacing={2}
          height={50}
          sx={{ p: 2 }}
        >
          <Skeleton variant="rounded" sx={Styled.ImageSX} />
          <Box sx={Styled.TextWrapperSX}>
            <Skeleton variant="rounded" sx={{ display: 'flex' }} />
            <Skeleton variant="rounded" sx={{ display: 'flex' }} />
          </Box>
        </Stack>
      ))}
    </Stack>
  );
};
