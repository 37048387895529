import { alpha, styled } from '@mui/material';

import { NAV_VERTICAL_CONFIG as NAV } from '@constants/config-global';

export const NavVerticalStyles = {
  DrawerSX: {
    zIndex: 0,
    width: NAV.W_DASHBOARD,
    bgcolor: 'transparent',
    borderRightStyle: 'dashed',
  },
  NotDesktopDrawerSX: {
    width: NAV.W_DASHBOARD,
  },
  AvatarWrapper: styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: +theme.shape.borderRadius * 1.5,
    backgroundColor: alpha(theme.palette.grey[500], 0.12),
  })),
};
