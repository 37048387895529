import { FC } from 'react';

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet } from 'react-router-dom';

import { LoadingScreen } from '../components/LoadingScreen';

export const PrivateRouter: FC = () => {
  const AuthenticatedOutlet = withAuthenticationRequired(Outlet, {
    onRedirecting: () => <LoadingScreen />,
  });

  return <AuthenticatedOutlet />;
};
