import { forwardRef } from 'react';

import { Avatar, Badge } from '@mui/material';

import { getCharAtName, getColorByName } from '@utils/helpers';

import { CustomAvatarStyles as Styled } from './CustomAvatar.styles';
import { ICustomAvatarProps } from './CustomAvatar.types';

export const CustomAvatar = forwardRef<HTMLDivElement, ICustomAvatarProps>(
  ({ color, name = '', BadgeProps, children, sx, ...other }, ref) => {
    const charAtName = getCharAtName(name);
    const colorByName = getColorByName(name);

    const colr = color || colorByName;

    const renderContent =
      colr === 'default' ? (
        <Avatar ref={ref} sx={sx} {...other}>
          {name && charAtName}
          {children}
        </Avatar>
      ) : (
        <Styled.Avatar ref={ref} colr={colr} sx={{ ...sx }} {...other}>
          {name && charAtName}
          {children}
        </Styled.Avatar>
      );

    return BadgeProps ? (
      <Badge overlap="circular" anchorOrigin={Styled.BadgeSX} {...BadgeProps}>
        {renderContent}
      </Badge>
    ) : (
      renderContent
    );
  }
);
