const ROOT = '/';
export const ROOT_STARTUPS = '/startup';
export const ID = ':id';

export const ROUTES = {
  home: ROOT,
  login: '/login',
  forgotPassword: '/forgot-password',
  newPassword: '/new-password',
  callback: '/callback',

  startups: {
    root: ROOT_STARTUPS,
    overview: `${ROOT_STARTUPS}/overview/${ID}`,
    growth: `${ROOT_STARTUPS}/growth/${ID}`,
    team: `${ROOT_STARTUPS}/team/${ID}`,
    international: `${ROOT_STARTUPS}/international/${ID}`,
    competition: `${ROOT_STARTUPS}/competition/${ID}`,
    esg: `${ROOT_STARTUPS}/esg/${ID}`,
    websiteApp: `${ROOT_STARTUPS}/website-app/${ID}`,
    chat: `${ROOT_STARTUPS}/chat/${ID}`,
    recruitment: `${ROOT_STARTUPS}/recruitment/${ID}`,
  },
  settings: '/settings',

  profile: 'user-profile',

  notFound: '*',
};
