import { FC, useCallback, useState } from 'react';

import {
  IData,
  InternationalContext,
  InternationalContextProps,
} from '../Contexts';

import { IProvider } from './types';

export const InternationalContextProvider: FC<IProvider> = ({ children }) => {
  const [
    isEmployeeCountryRepartitionLoading,
    setIsEmployeeCountryRepartitionLoading,
  ] = useState(true);

  const [isWebVisitsDistributionLoading, setisWebVisitsDistributionLoading] =
    useState(true);

  const [employeeData, setEmployeeData] = useState<IData[] | undefined>([]);
  const [webVisitsData, setWebVisitsData] = useState<IData[] | undefined>([]);

  const handleSetIsEmployeeCountryRepartitionLoading = useCallback(
    (loading: boolean) => setIsEmployeeCountryRepartitionLoading(loading),
    []
  );

  const handleSetWebVisitsDistributionLoading = useCallback(
    (loading: boolean) => setisWebVisitsDistributionLoading(loading),
    []
  );

  const handleSetEmployeeData = useCallback(
    (data?: IData[]) => setEmployeeData(data),
    []
  );
  const handleSetWebVisitsData = useCallback(
    (data?: IData[]) => setWebVisitsData(data),
    []
  );

  const value: InternationalContextProps = {
    isEmployeeCountryRepartitionLoading,
    isWebVisitsDistributionLoading,
    employeeData,
    webVisitsData,
    setIsEmployeeCountryRepartitionLoading:
      handleSetIsEmployeeCountryRepartitionLoading,
    setisWebVisitsDistributionLoading: handleSetWebVisitsDistributionLoading,
    setEmployeeData: handleSetEmployeeData,
    setWebVisitsData: handleSetWebVisitsData,
  };

  return (
    <InternationalContext.Provider value={value}>
      {children}
    </InternationalContext.Provider>
  );
};
