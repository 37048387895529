import { useContext } from 'react';

import { StartupInfoContext } from '../App/Contexts';

export const useStartupInfo = () => {
  const {
    loading,
    setLoading,
    setStartup,
    isHiddenAlertPopup,
    setIsHiddenAlertPopup,
    startup,
    isRefreshModalWindowOpen,
    setIsRefreshModalWindowOpen,
  } = useContext(StartupInfoContext);

  const onDismissButtonClickHandler = () => setIsHiddenAlertPopup(true);

  return {
    loading,
    startup,
    isHiddenAlertPopup,
    isRefreshModalWindowOpen,
    setIsRefreshModalWindowOpen,
    setIsHiddenAlertPopup,
    onDismissButtonClickHandler,
    setLoading,
    setStartup,
  };
};
