import { TStartupId } from '@components/VerticalNavSection/NavList/navList.types';

import { UUID_REGEXP } from '@constants/regex';
import { ID, ROUTES } from '@constants/routes';

export const getCharAtName = (name: string) => name && name.charAt(0).toUpperCase();

export const getColorByName = (name: string) => {
  if (['A', 'N', 'H', 'L', 'Q'].includes(getCharAtName(name))) return 'primary';
  if (['F', 'G', 'T', 'I', 'J'].includes(getCharAtName(name))) return 'info';
  if (['K', 'D', 'Y', 'B', 'O'].includes(getCharAtName(name))) return 'success';
  if (['P', 'E', 'R', 'S', 'U'].includes(getCharAtName(name))) return 'warning';
  if (['V', 'W', 'X', 'M', 'Z'].includes(getCharAtName(name))) return 'error';
  return 'default';
};

export const getPathTo = (path: string, id?: TStartupId) => {
  //use for sidebar. if user click to item menu that should to be with uuid, this logic add uuid to the end of the path
  const isStartupId = id?.match(UUID_REGEXP);
  if (!isStartupId || path === ROUTES.home || !path.includes(ID)) {
    return path;
  } else {
    return path.replace(ID, id || '');
  }
};

export const searchBarGetPathTo = (path: string, id: TStartupId) => {
  //find uuid in path and if it has uuid replace for a uuid from search in path and return new path.
  const matchUUID = path.match(UUID_REGEXP);
  if (!matchUUID) return `${ROUTES.startups.root}/overview/${id}`;
  const uuid = matchUUID[0].split('/')[1];
  return path.replace(uuid, id || '');
};

//sortHelper that by default sort by date
export function sortHelper<T extends { date: string }>(
  array?: T[],
  sortingFunction: (a: T, b: T) => number = (a, b) => Date.parse(a?.date) - Date.parse(b?.date),
): T[] {
  return [...(array || [])].sort(sortingFunction);
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
