import { FC, memo, useEffect } from 'react';

import { ClickAwayListener, MenuList, Paper, Slide } from '@mui/material';

import { Search_BarQuery } from '@generated/graphql';
import { useKeyboardSearch } from '@hooks/useKeyboardSearch';

import { IconButtonAnimate } from '../../../../animate';
import { Iconify } from '../../../../Iconify';
import { Scrollbar } from '../../../../ScrollBar';
import { SearchNotFound } from '../../../../SearchNotFound';

import { RenderInput } from './RenderInput';
import { RenderOption } from './RenderOption';
import { SearchBarStyles as Styled } from './SearchBar.styles';
import { SearchBarSkeleton } from './SearchBarSkeleton';
import { useSearchBarState } from './useSearchBar.state';

export const SearchBar: FC = memo(() => {
  const {
    open,
    pathname,
    data,
    startups,
    searchQuery,
    handleClose,
    handleOpen,
    onSearchItemClickHandler,
    anchorEl,
    isLoading,
    isSearchNotFound,
    onInputChangeHandler,
  } = useSearchBarState();

  useEffect(() => {
    if (open) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const { handleKeyDown, selectedOptionIndex, selectedOptionRef } =
    useKeyboardSearch({ items: startups, onSearchItemClickHandler });
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        <IconButtonAnimate
          onClick={handleOpen}
          sx={{
            ...(open && {
              opacity: 0,
            }),
          }}
        >
          <Iconify icon="eva:search-fill" />
        </IconButtonAnimate>

        <Slide direction="down" in={open} mountOnEnter unmountOnExit>
          <Styled.Searchbar>
            <RenderInput
              handleKeyDown={handleKeyDown}
              onInputChangeHandler={onInputChangeHandler}
            />
            <Styled.Popper
              open={!!anchorEl}
              anchorEl={anchorEl || null}
              placement="bottom"
              disablePortal
            >
              <Paper
                sx={{
                  padding: (theme) => theme.spacing(1, 0),
                }}
              >
                <Scrollbar
                  sx={{
                    maxHeight: 275,
                  }}
                >
                  {isLoading ? (
                    <SearchBarSkeleton />
                  ) : isSearchNotFound ? (
                    <SearchNotFound
                      isNoData={!data?.search_startup_name.length}
                      query={searchQuery}
                      sx={{ py: 10 }}
                    />
                  ) : (
                    <MenuList role="listbox">
                      {startups?.map(
                        (
                          option: Search_BarQuery['search_startup_name'][0],
                          index
                        ) => (
                          <RenderOption
                            key={option.uuid}
                            isKeyBoardSelected={selectedOptionIndex === index}
                            optionRef={
                              selectedOptionIndex === index
                                ? selectedOptionRef
                                : null
                            }
                            handleClick={onSearchItemClickHandler}
                            inputValue={searchQuery}
                            startupId={option.uuid}
                            logoURL={option?.logo_url}
                            websiteLink={option?.link || ''}
                            keyValue={`listItem-${index}-${option.uuid}`}
                            startupName={option.name}
                          />
                        )
                      )}
                    </MenuList>
                  )}
                </Scrollbar>
              </Paper>
            </Styled.Popper>
          </Styled.Searchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
});
