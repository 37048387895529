import { Theme } from '@mui/material';

export const RESULT_MESSAGE_STRINGS = (startupName: string) => ({
  successfull: {
    color: (theme: Theme) => theme.palette.success.main,
    title: 'Startup successfully added to Ramp',
    subtitle: `We are retrieving the data on ${startupName} please wait from 15 to 20 minutes before it has been updated.`,
  },
  unsuccessfully: {
    color: (theme: Theme) => theme.palette.error.main,
    title: 'Startup already in our database ',
    subtitle: 'Please add only startups that are not in our database.',
  },
});
