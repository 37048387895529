export const LOCAL_STORAGE_LAST_SEARCHED_STARTUP_ID_KEY =
  'last_searched_startup_id';

export const CONFIDENCE_NAMES = {
  low: 'Low Confidence',
  middle: 'Medium Confidence',
  high: 'High Confidence',
} as const;

export const DATE_FORMATS = {
  'dd/MM/yyyy': 'dd/MM/yyyy',
  'yyyy-MM-dd': 'yyyy-MM-dd',
} as const;

export const BASE_CRANCHBASE_IMAGE_URL =
  'https://res.cloudinary.com/crunchbase-production/image/upload/';
