import axios from 'axios';

export const apiServices = {
  postData: (requestUrl: string, payload?: any, params?: {}) => {
    return axios.post(requestUrl, payload, {
      params,
    });
  },
  fetchData: (requestUrl: string, params?: {}) => {
    return axios.get(`${requestUrl}`, { params });
  },
};
