import { FC } from 'react';

import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { DashboardHeader } from '../../Header/DashboardHeader';
import { NavVertical } from '../../NavVertical';

import { ContentWrapper } from './ContentWrapper';
import { MainLayoutStyles as Styled } from './MainLayout.styles';
import { useMainLayoutState } from './useMainLayout.state';

export const MainLayout: FC = () => {
  const { onHandleCloseNav, onHandleOpenNav, open } = useMainLayoutState();

  return (
    <>
      <DashboardHeader onOpenNav={onHandleOpenNav} />
      <Box sx={Styled.BoxSX}>
        <NavVertical openNav={open} onCloseNav={onHandleCloseNav} />
        <ContentWrapper>
          <Outlet />
        </ContentWrapper>
      </Box>
    </>
  );
};
