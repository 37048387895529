import { FC, useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';

import { StyledCard } from '@styles/overrides/Card';

import { Iconify } from '../../Iconify';
import { InfoIcon2 } from '../../InfoIcon2';
import { PopoverWrapper } from '../../popovers/PopoverWrapper';
import { StartupLogoPopover } from '../../popovers/StartupLogoPopover/StartupLogoPopover';

import { StartupHeaderSkeleton } from './StartupHeader.skeleton';
import { useStartupHeaderState } from './StartupHeader.state';
import { StartupHeaderStyles as Styled } from './StartupHeader.styles';
import { StartupHeaderLink } from './StartupHeaderLink';

import { ICONIFY_ICON_NAMES } from '@constants/icons';

export const StartupHeader: FC<IQueryComponentProps> = (props) => {
  const { startupId } = props;

  const { handleClosePopover, handleOpenPopover, onRefreshIconClickhandler, loading, openPopover, startup, links } =
    useStartupHeaderState(startupId);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isLaptop = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

  const [expanded, setExpanded] = useState(!isMobile && !isLaptop);
  useEffect(() => {
    if (!isMobile && !isLaptop) {
      setExpanded(true);
    }
  }, [isMobile, isLaptop]);

  return (
    <StyledCard sx={{ position: 'relative' }}>
      <Stack
        direction="column"
        alignItems="center" // left later
      >
        {loading ? (
          <StartupHeaderSkeleton />
        ) : (
          <>
            <Accordion
              sx={{
                width: '100%',
                ':hover': {
                  cursor: 'default!important;',
                  backgroundColor: 'white',
                },
              }}
              expanded={expanded}
              onChange={(event, newExpanded) => {
                // Only update the expanded state if on mobile
                if (isMobile) {
                  setExpanded(newExpanded);
                }
              }}
            >
              <AccordionSummary
                expandIcon={isMobile ? <Iconify icon="mdi:chevron-down" /> : null}
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ flexDirection: 'row-reverse' }} // Add this line to reverse the direction
                sx={{
                  ':hover': {
                    cursor: 'default',
                    backgroundColor: 'white',
                  },
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    alignItems: { xs: 'center', sm: 'space-between' },
                    justifyContent: { xs: 'center', sm: 'space-between' },
                    width: '100%',
                  }}
                >
                  <Stack direction="row" alignItems="center" spacing={1} sx={{ ml: { xs: 2, sm: 0 } }}>
                    <Box component="img" alt="company logo" src={startup?.logo_url || ''} sx={Styled.ImageSX} />
                    <Typography variant="h5" sx={{ color: 'inherit', cursor: 'default' }} textAlign="center">
                      {startup?.name}
                    </Typography>{' '}
                    <PopoverWrapper
                      open={!!openPopover}
                      anchorEl={openPopover}
                      onClose={handleClosePopover}
                      sx={{ width: 265, p: 0 }}
                    >
                      <StartupLogoPopover startup={startup} />
                    </PopoverWrapper>
                    {!loading && <InfoIcon2 handleOpenPopover={handleOpenPopover} />}
                  </Stack>

                  {isLaptop && (
                    <Stack
                      direction={{ xs: 'column', sm: 'row' }}
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'space-between',
                      }}
                      divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      {links
                        .sort((a, b) => (a.url && !b.url ? -1 : b.url && !a.url ? 1 : 0))
                        .map((link, index) => (
                          <Button
                            sx={{
                              color: (theme) => theme.palette.grey[700],
                              backgroundColor: 'white',
                              // borderLeft: index === 0 ? 'none' : 'lightgray 1px dotted',
                              borderRadius: '0px',
                              marginLeft: 0,
                              pr: 2,
                              pl: 2,
                              ':hover': {
                                color: (theme) => theme.palette.grey[900],
                                backgroundColor: 'white',
                              },
                            }}
                            disabled={!link.url}
                            key={link.name}
                            startIcon={<Iconify icon={link.iconName} />}
                            onClick={() => window.open(link.url as string, '_blank')}
                          >
                            {link.name}
                          </Button>
                        ))}
                    </Stack>
                  )}

                  {!isMobile && (
                    <Stack direction="row" alignItems="center" spacing={0}>
                      <Button
                        onClick={onRefreshIconClickhandler}
                        sx={{
                          background: (theme) => theme.palette.grey[700],
                          color: 'white',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          //cursor: 'pointer',
                          borderRadius: 1,
                          px: 2,
                          ':hover': {
                            backgroundColor: (theme) => theme.palette.grey[900],
                          },
                        }}
                        startIcon={<Iconify icon={ICONIFY_ICON_NAMES.refresh} width={18} color="white" />}
                      >
                        Refresh
                      </Button>
                    </Stack>
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'space-between',
                  }}
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={1}
                >
                  {links
                    .sort((a, b) => (a.url && !b.url ? -1 : b.url && !a.url ? 1 : 0))
                    .map((link, index) => (
                      <Button
                        sx={{
                          color: (theme) => theme.palette.grey[700],
                          backgroundColor: 'white',
                          // borderLeft: index === 0 ? 'none' : 'lightgray 1px dotted',
                          borderRadius: '0px',
                          marginLeft: 0,
                          pr: 2,
                          pl: 2,
                          ':hover': {
                            color: (theme) => theme.palette.grey[900],
                            backgroundColor: 'white',
                          },
                        }}
                        disabled={!link.url}
                        key={link.name}
                        startIcon={<Iconify icon={link.iconName} />}
                        onClick={() => window.open(link.url as string, '_blank')}
                      >
                        {link.name}
                      </Button>
                    ))}

                  {isMobile && (
                    <Button
                      onClick={onRefreshIconClickhandler}
                      sx={{
                        background: (theme) => theme.palette.grey[700],
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        //cursor: 'pointer',
                        borderRadius: 1,
                        px: 2,
                        ':hover': {
                          backgroundColor: (theme) => theme.palette.grey[900],
                        },
                      }}
                      startIcon={<Iconify icon={ICONIFY_ICON_NAMES.refresh} width={18} color="white" />}
                    >
                      Refresh
                    </Button>
                  )}
                </Stack>
              </AccordionDetails>
            </Accordion>

            {/*<Stack direction="row" alignItems="center" spacing={2}>
              <Box component="img" alt="company logo" src={startup?.logo_url || ''} sx={Styled.ImageSX} />
              <Typography variant="h4" sx={{ color: 'inherit' }} textAlign="center">
                {startup?.name}
              </Typography>{' '}
              <PopoverWrapper
                open={!!openPopover}
                anchorEl={openPopover}
                onClose={handleClosePopover}
                sx={{ width: 265, p: 0 }}
              >
                <StartupLogoPopover startup={startup} />
              </PopoverWrapper>
              {!loading && <InfoIcon2 handleOpenPopover={handleOpenPopover} />}
        </Stack>*/}
          </>
        )}
      </Stack>

      {/*<Stack direction="row" alignItems="center" spacing={2} justifyContent="space-between">
        {loading ? (
          <StartupHeaderSkeleton />
        ) : (
          <>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Box component="img" alt="company logo" src={startup?.logo_url || ''} sx={Styled.ImageSX} />
              <Typography variant="h4" sx={{ color: 'inherit' }} textAlign="center">
                {startup?.name}
              </Typography>{' '}
              <PopoverWrapper
                open={!!openPopover}
                anchorEl={openPopover}
                onClose={handleClosePopover}
                sx={{ width: 265, p: 0 }}
              >
                <StartupLogoPopover startup={startup} />
              </PopoverWrapper>
              {!loading && <InfoIcon2 handleOpenPopover={handleOpenPopover} />}
            </Stack>
            <Stack
              justifySelf="flex-end"
              direction="row"
              alignItems="center"
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
              spacing={0}
            >
              {links
                .sort((a, b) => (a.url && !b.url ? -1 : b.url && !a.url ? 1 : 0))
                .map((link, index) => (
                  <Button
                    sx={{
                      color: (theme) => theme.palette.grey[700],
                      backgroundColor: 'white',
                      borderLeft: index === 0 ? 'none' : 'lightgray 1px dotted',
                      borderRadius: '0px',
                      marginLeft: 0,
                      pr: 2,
                      pl: 2,
                      ':hover': {
                        color: (theme) => theme.palette.grey[900],
                        backgroundColor: 'white',
                      },
                    }}
                    disabled={!link.url}
                    key={link.name}
                    startIcon={<Iconify icon={link.iconName} />}
                    onClick={() => window.open(link.url as string, '_blank')}
                  >
                    {link.name}
                  </Button>
                ))}
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                onClick={onRefreshIconClickhandler}
                sx={{
                  background: (theme) => theme.palette.grey[700],
                  color: 'white',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  cursor: 'pointer',
                  borderRadius: 1,
                  px: 2,
                  ':hover': {
                    backgroundColor: (theme) => theme.palette.grey[900],
                  },
                }}
                startIcon={<Iconify icon={ICONIFY_ICON_NAMES.refresh} width={18} color="white" />}
              >
                Refresh
              </Button>
            </Stack>
          </>
        )}
      </Stack>*/}
    </StyledCard>
  );
};
