import { FC, useEffect, useState } from 'react';

import { Collapse } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useActiveLink } from '@hooks/useActiveLink';

import { NavItem } from '../NavItem';

import { INavListRootProps } from './navList.types';
import { NavSubList } from './NavSublist';

import { ROOT_STARTUPS } from '@constants/routes';

export const NavList: FC<INavListRootProps> = (props) => {
  const { data, depth, hasChild, startupId } = props;

  const { pathname } = useLocation();
  const { active, isExternalLink } = useActiveLink(data.path, hasChild);

  const isStartupPath = pathname.includes(ROOT_STARTUPS);
  const [isOpen, setIsOpen] = useState(active);

  useEffect(() => {
    if (!active && !isStartupPath) {
      handleClose();
    }
    if (isStartupPath && !isOpen) {
      setIsOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = () => setIsOpen(!isOpen);
  const handleClose = () => setIsOpen(false);

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={isOpen}
        active={active}
        isExternalLink={isExternalLink}
        startupId={startupId}
        onClick={handleToggle}
      />

      {hasChild && (
        <Collapse in={isOpen} unmountOnExit>
          <NavSubList data={data.children} depth={depth} startupId={startupId} />
        </Collapse>
      )}
    </>
  );
};
