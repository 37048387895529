import { FC } from 'react';

import { Button, Card } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { Iconify } from '@components/Iconify';

// import css
import './Message.css';

interface IChatMessageProps {
  role: string;
  content: string;
  id: number;
  sources?: any;
}

export const ChatMessage: FC<IChatMessageProps> = (props: any) => {
  function getSourceIcon(source: any) {
    switch (source.source_type) {
      case 'website':
        return <Iconify icon="mdi:web" />;
      case 'crunchbase':
        return <Iconify icon="cib:crunchbase" />;
      default:
        return <Iconify icon="mdi:web" />;
    }
  }

  /*<p
        style={{
          maxWidth: '50%',
          margin: 'auto',
        }}
      >
      </p>
      */

  return (
    <div
      key={props.id}
      style={{
        padding: '24px 0px 24px 0px',
        textAlign: 'left',
        backgroundColor: props.role == 'user' ? 'white' : '#f2f2f2',
      }}
    >
      <div
        style={{
          maxWidth: '50%',
          margin: 'auto',
        }}
      >
        <ReactMarkdown className="markdown-container" remarkPlugins={[remarkGfm]}>
          {props.content}
        </ReactMarkdown>
        {props.sources && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: '12px',
            }}
          >
            {props.sources.map((source: any, index: number) => (
              <Button
                style={{ backgroundColor: 'transparent', padding: '6px', marginBottom: '12px' }}
                variant="outlined"
                key={index}
                onClick={() => window.open(source.source_url, '_blank')}
              >
                {getSourceIcon(source)}

                <span style={{ textTransform: 'lowercase', marginLeft: '4px' }}>
                  {source.source.toLowerCase()} [{index + 1}]
                </span>
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
