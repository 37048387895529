import { gql } from '@apollo/client';

export const SEARCH_BAR = gql`
  query search_bar($search: String) {
    search_startup_name(args: { search: $search }) {
      uuid
      name
      logo_url
      link
    }
  }
`;

export const GET_LAST_SEARCHED_STARTUPS = gql`
  query get_last_searched_startups_by_user {
    users {
      last_search_relation_users_startups(order_by: { updated_at: desc }) {
        startup {
          uuid
          name
          logo_url
        }
      }
    }
  }
`;

export const SAVE_SEARCHED_STARTUPS = gql`
  mutation save_searched_startup_for_user($startup_uuid: uuid) {
    insert_last_search_relation_users_startups_one(
      object: { startup_uuid: $startup_uuid }
      on_conflict: {
        constraint: last_search_relation_users_startups_user_id_startup_uuid_key
        update_columns: updated_at
      }
    ) {
      startup {
        name
        logo_url
        uuid
      }
    }
  }
`;
