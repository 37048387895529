import { forwardRef } from 'react';

import { Box, BoxProps, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { Icon } from '../Icon';

import { LogoStyles as Styled } from './Logo.styles';

export interface ILogoProps extends BoxProps {
  disabledLink?: boolean;
}

export const Logo = forwardRef<HTMLDivElement, ILogoProps>(
  ({ disabledLink = false, sx, ...other }, ref) => {
    const logo = (
      <Box
        ref={ref}
        component="div"
        sx={{ ...Styled.MainWrapperSX, ...sx }}
        {...other}
      >
        <Icon type="logo" />

        <Box component="div" sx={Styled.TitleWrapperSX}>
          <Styled.Title>Ramp</Styled.Title>
          <Styled.Subtitle>By Red River West</Styled.Subtitle>
        </Box>
      </Box>
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);
