export const StartupHeaderStyles = {
  ImageWrapperSX: {
    width: 40,
    height: 40,
    flexShrink: 0,
    display: 'flex',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    bgcolor: 'background.neutral',
    marginBottom: '0px!important',
  },
  ImageSX: {
    width: 40,
    height: 'auto',
    borderRadius: '5px',
    opacity: 1,
  },
};
