import { FC } from 'react';

import { Button, CardHeader, Modal, Stack, TextField } from '@mui/material';

import { StyledCard } from '@styles/overrides/Card';

import { CardSX } from './AddUser.style';

interface IAddUser {
  isOpen: boolean;
  onCloseWindowHandler: () => void;
}
export const AddUser: FC<IAddUser> = (props) => {
  const { isOpen, onCloseWindowHandler } = props;
  return (
    <Modal open={isOpen} onClose={onCloseWindowHandler} sx={{ m: 1 }}>
      <StyledCard sx={CardSX}>
        <CardHeader title="Add user" />
        <Stack spacing={3} px={2}>
          <TextField value="" label="User Email" />
          <TextField value="" label="User Name" />

          <Button
            variant="contained"
            color="success"
            sx={{ width: 95, alignSelf: 'flex-end' }}
          >
            Add User
          </Button>
        </Stack>
      </StyledCard>
    </Modal>
  );
};
