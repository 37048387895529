import { FC, forwardRef } from 'react';

import { Box, IconButton, IconButtonProps } from '@mui/material';
import { m } from 'framer-motion';

import { VARIANTS } from './animate.constants';
import { IAnimateWrapProp } from './animate.types';

export const IconButtonAnimate = forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ children, size = 'medium', ...other }, ref) => (
    <AnimateWrap size={size}>
      <IconButton size={size} ref={ref} {...other}>
        {children}
      </IconButton>
    </AnimateWrap>
  )
);

export const AnimateWrap: FC<IAnimateWrapProp> = (props) => {
  const { size, children } = props;
  const isSmall = size === 'small';
  const isLarge = size === 'large';

  const currVariant =
    (isSmall && VARIANTS.varSmall) ||
    (isLarge && VARIANTS.varLarge) ||
    VARIANTS.varMedium;

  return (
    <Box
      component={m.div}
      whileTap="tap"
      whileHover="hover"
      variants={currVariant}
      sx={{
        display: 'inline-flex',
      }}
    >
      {children}
    </Box>
  );
};
