import { FC } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Box, Link, Typography } from '@mui/material';
import posthog from 'posthog-js';
import { Link as RouterLink } from 'react-router-dom';

import { CustomAvatar } from '../CustomAvatar';

import { NavVerticalStyles as Styled } from './NavVertical.styles';

import { ROUTES } from '@constants/routes';

export const NavAccount: FC = () => {
  const { user }: { user?: GlobalIHasuraUser.IUser } = useAuth0();

  posthog.identify(user?.sub, {
    name: user?.name,
    nickname: user?.nickname,
  });

  const userRole = user?.['https://hasura.io/jwt/claims']['x-hasura-default-role'];
  return (
    <Link component={RouterLink} to={ROUTES.profile} underline="none" color="inherit">
      <Styled.AvatarWrapper>
        <CustomAvatar src={user?.picture} alt={user?.nickname} name={user?.nickname} />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.nickname}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: 'text.secondary', textTransform: 'capitalize' }}>
            {userRole}
          </Typography>
        </Box>
      </Styled.AvatarWrapper>
    </Link>
  );
};
