import { FC } from 'react';

import { SnackbarProvider as NotistackProvider } from 'notistack';

import { SnackbarIcon } from './SnackbarIcon';
import { StyledNotistack } from './styles';
import { useSnackBarState } from './useSnackBar.state';

interface IProps {
  children: React.ReactNode;
}

export const SnackbarProvider: FC<IProps> = ({ children }) => {
  const { notistackRef, onAction } = useSnackBarState();

  return (
    <>
      <StyledNotistack />

      <NotistackProvider
        ref={notistackRef}
        dense
        maxSnack={5}
        autoHideDuration={4000}
        TransitionComponent={undefined}
        variant="success"
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        iconVariant={{
          info: <SnackbarIcon icon="eva:info-fill" color="info" />,
          success: (
            <SnackbarIcon icon="eva:checkmark-circle-2-fill" color="success" />
          ),
          warning: (
            <SnackbarIcon icon="eva:alert-triangle-fill" color="warning" />
          ),
          error: <SnackbarIcon icon="eva:alert-circle-fill" color="error" />,
        }}
        action={onAction}
      >
        {children}
      </NotistackProvider>
    </>
  );
};
