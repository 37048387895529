import { Theme, alpha } from '@mui/material';

import { IGetIconsProps } from './StartupHeader.types';

import { ICONIFY_ICON_NAMES } from '@constants/icons';

export const getIcons = (props: IGetIconsProps) => {
  const { crunchbaseURL, g2URL, glassdoorURL, linkedInURL, twitterURL, websiteURL } = props;
  return [
    {
      iconName: ICONIFY_ICON_NAMES.website,
      name: 'Website',
      url: websiteURL,
      color: (theme: Theme) => theme.palette.grey[600],
    },
    {
      name: 'LinkedIn',
      iconName: ICONIFY_ICON_NAMES.linkedin,
      url: linkedInURL,
      color: (theme: Theme) => alpha(theme.palette.secondary.main, 0.9),
    },
    {
      name: 'Crunchbase',
      iconName: ICONIFY_ICON_NAMES.crunchbase,
      url: crunchbaseURL,
      color: (theme: Theme) => theme.palette.secondary.main,
    },
    {
      name: 'G2',
      iconName: ICONIFY_ICON_NAMES.g2,
      url: g2URL,
      color: (theme: Theme) => theme.palette.error.main,
    },
    {
      name: 'Glassdoor',
      iconName: ICONIFY_ICON_NAMES.glassdoor,
      url: glassdoorURL,
      color: (theme: Theme) => theme.palette.success.dark,
    },
    {
      name: 'Twitter',
      iconName: ICONIFY_ICON_NAMES.twitter,
      url: twitterURL,
      color: (theme: Theme) => theme.palette.secondary.main,
    },
  ];
};
