import numeral from 'numeral';

type InputValue = string | number | null;

export function fNumber(number: InputValue, format?: string) {
  return numeral(number)
    .format(format || '0.0a')
    .toUpperCase();
}

export function fPercent(number: InputValue) {
  const format = number ? numeral(Number(number) / 100).format('0.0%') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export const formatScoreToPercent = (score?: number | null, multiplier: number = 100, decimalPlaces: number = 0) => {
  if (!score) {
    const newValue = 0;
    return toFixed(newValue, decimalPlaces);
  }

  return toFixed(score * multiplier, decimalPlaces) || 0;
};

export function toFixed(num: number, decimalPlaces: number = 2) {
  // if number is nan return 0
  return isNaN(num) ? 0 : +num.toFixed(decimalPlaces);
}
