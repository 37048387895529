import { ChangeEvent, useMemo, useState } from 'react';

import debounce from 'lodash/debounce';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  Get_Last_Searched_Startups_By_UserQuery,
  Get_Last_Searched_Startups_By_UserQueryVariables,
  useSave_Searched_Startup_For_UserMutation,
  useSearch_BarLazyQuery,
} from '@generated/graphql';
import { GET_LAST_SEARCHED_STARTUPS } from '@graphql/search';
import { useGetLastSearchedStartupsByUser } from '@hooks/useGetLastSearchedStartupsByUser';
import { useNotistackError } from '@hooks/useNotistackError';
import { searchBarGetPathTo } from '@utils/helpers';

export const useSearchBarState = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [getStartups, { loading, data, error }] = useSearch_BarLazyQuery();

  const { isLastSearchedLoading, lastSearchedStartups } = useGetLastSearchedStartupsByUser();

  const [saveSearchedStartup] = useSave_Searched_Startup_For_UserMutation({
    //when we save click on startup that we want to open. this function update cache value
    // with last searched startups. so we do not need refecth all startups again
    update(cache, { data }) {
      const lastSearchedStartups = cache.readQuery<
        Get_Last_Searched_Startups_By_UserQuery,
        Get_Last_Searched_Startups_By_UserQueryVariables
      >({
        query: GET_LAST_SEARCHED_STARTUPS,
      });

      const newData = {
        ...lastSearchedStartups,
        users: [
          {
            ...lastSearchedStartups?.users[0],
            last_search_relation_users_startups: [
              data?.insert_last_search_relation_users_startups_one,
              ...(lastSearchedStartups?.users[0]?.last_search_relation_users_startups?.filter(
                (startup) =>
                  startup?.startup?.uuid !== data?.insert_last_search_relation_users_startups_one?.startup?.uuid,
              ) || []),
            ],
          },
        ],
      };

      cache.writeQuery({
        query: GET_LAST_SEARCHED_STARTUPS,
        data: {
          ...newData,
          __typename: 'users',
        },
      });
    },
  });

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    searchQuery && setSearchQuery('');
  };

  const onSearchItemClickHandler = (startupId: string) => {
    //redirect to overview page and save searched startup
    if (!startupId) return null;
    saveSearchedStartup({
      variables: { startup_uuid: startupId },
    });
    setSearchQuery('');
    handleClose();
    setAnchorEl(null);
    const path = searchBarGetPathTo(pathname, startupId);
    navigate(path);
  };

  const onInputDebounceHandler = debounce((value: string) => {
    setSearchQuery(value);
    value && getStartups({ variables: { search: value } });
  }, 500);

  const onInputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => onInputDebounceHandler(event.target.value);

  const isLoading = isLastSearchedLoading || loading;

  const mappedLastSearchedStartups = useMemo(
    () =>
      lastSearchedStartups?.users?.[0]?.last_search_relation_users_startups.map((item) => ({
        ...item.startup,
      })),
    [lastSearchedStartups?.users],
  );

  const startups = (!searchQuery && mappedLastSearchedStartups) || data?.search_startup_name;

  useNotistackError(error);

  const isSearchNotFound = (searchQuery && !data?.search_startup_name.length) || !startups?.length;

  return {
    open,
    data,
    loading,
    pathname,
    searchQuery,
    isSearchNotFound,
    handleOpen,
    handleClose,
    onSearchItemClickHandler,
    onInputChangeHandler,
    isLoading,
    startups,
    anchorEl,
  };
};
