import { FC } from 'react';

import { Box, Stack, Typography } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';

import { SearchBarStyles } from './SearchBar.styles';
import { IRenderOptionProps } from './SearchBar.types';

export const RenderOption: FC<IRenderOptionProps> = (props) => {
  const {
    inputValue,
    startupId,
    startupName,
    startupWebsiteURL,
    handleClick,
    keyValue,
    logoURL,
    websiteLink,
    isKeyBoardSelected,
    optionRef,
    ...other
  } = props;

  const partsTitle = parse(startupName, match(startupName, inputValue));

  const onBoxClickHandler = () => handleClick(startupId);

  return (
    <SearchBarStyles.RenderOption
      component="li"
      id={startupId}
      ref={optionRef}
      sx={{
        ...(isKeyBoardSelected && {
          backgroundColor: (theme) => theme.palette.grey[200],
        }),
      }}
      onClick={onBoxClickHandler}
      key={keyValue}
      {...other}
    >
      <Stack direction="row" spacing={2} alignItems="center">
        <Box
          component="img"
          src={logoURL || ''}
          alt="logo"
          sx={{ width: 40, borderRadius: '10px' }}
        />

        <Box sx={{ minWidth: 200, flex: 1 }}>
          {partsTitle.map((part, index) => {
            return (
              <Box
                key={index}
                component="span"
                sx={{
                  typography: 'subtitle2',
                  textTransform: 'capitalize',
                  color: part.highlight ? 'primary.main' : 'text.primary',
                }}
              >
                {part.text}
              </Box>
            );
          })}

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {websiteLink}
          </Typography>
        </Box>
      </Stack>
    </SearchBarStyles.RenderOption>
  );
};
