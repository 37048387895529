import { useRef } from 'react';

import { IconButton } from '@mui/material';
import { SnackbarKey } from 'notistack';

import { Iconify } from '../Iconify';

export const useSnackBarState = () => {
  const notistackRef = useRef<any>(null);

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  const onAction = (key: SnackbarKey) => (
    <IconButton size="small" onClick={onClose(key)} sx={{ p: 0.5 }}>
      <Iconify icon="eva:close-fill" />
    </IconButton>
  );

  return {
    notistackRef,
    onAction,
  };
};
