import { FC } from 'react';

import { Box, Link, ListItemText, Tooltip } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { getPathTo } from '@utils/helpers';

import { Icon } from '../../Icon';
import { Iconify } from '../../Iconify';

import { StyledDotIcon, StyledIcon, StyledItem } from './NavItem.styles';
import { INavItemProps } from './navItem.types';

export const NavItem: FC<INavItemProps> = (props) => {
  const { item, depth, open, startupId, active, isExternalLink, ...other } = props;

  const { title, path, icon, info, children, disabled, caption } = item;

  const subItem = depth !== 1;

  const pathTo = getPathTo(path, startupId);

  const betaChip = pathTo.includes('/chat/') || pathTo.includes('/competition/') || pathTo.includes('/recruitment/');

  const renderContent = (
    <StyledItem depth={depth} active={active} disabled={disabled} caption={!!caption} {...other}>
      {icon && (
        <StyledIcon active={active && !!icon}>
          <Icon type={icon} />
        </StyledIcon>
      )}

      {subItem && (
        <StyledIcon>
          {betaChip ? (
            <span
              style={{
                fontSize: '10px',
                backgroundColor: active ? '#D34638' : 'gray',
                padding: '4px',
                borderRadius: '4px',
                color: 'white',
                fontWeight: 'bold',
                lineHeight: '1',
                marginTop: '3px',
              }}
            >
              BETA
            </span>
          ) : (
            <StyledDotIcon active={active && subItem} />
          )}
        </StyledIcon>
      )}

      <ListItemText
        sx={{
          marginBottom: 0,
          lineHeight: 1,
        }}
        primary={title}
        secondary={
          caption && (
            <Tooltip title={caption} placement="top-start">
              <span>{caption}</span>
            </Tooltip>
          )
        }
        primaryTypographyProps={{
          noWrap: true,
          component: 'span',
          variant: active ? 'subtitle2' : 'body2',
        }}
        secondaryTypographyProps={{
          noWrap: true,
          variant: 'caption',
        }}
      />

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );
    if (children) {
      return renderContent;
    }
    return (
      <Link component={RouterLink} to={pathTo} underline="none">
        {renderContent}
      </Link>
    );
  };

  return renderItem();
};
