import { FC } from 'react';

import { BrowserRouter } from 'react-router-dom';

import { GlobalStyledChart } from '@components/Chart';
import { SnackbarProvider } from '@components/SnackBar';
import { ThemeProvider } from '@styles/theme';

import { AppRouter } from './App.router';
import {
  AppApolloProvider,
  Auth0Provider,
  InternationalContextProvider,
  SearchBarContextProvider,
  StartupInfoProvider,
} from './Providers';
export const App: FC = () => {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Auth0Provider>
          <SnackbarProvider>
            <AppApolloProvider>
              <SearchBarContextProvider>
                <InternationalContextProvider>
                  <StartupInfoProvider>
                    <GlobalStyledChart />
                    <AppRouter />
                  </StartupInfoProvider>
                </InternationalContextProvider>
              </SearchBarContextProvider>
            </AppApolloProvider>
          </SnackbarProvider>
        </Auth0Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
};
