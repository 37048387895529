export const MOCKED_AVATARS = {
  avatarThree:
    'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_3.jpg',
  defaultAvatar:
    'https://api-dev-minimal-v4.vercel.app/assets/images/avatars/avatar_default.jpg',
};

export const ICONIFY_ICON_NAMES = {
  website: 'material-symbols:share',
  linkedin: 'eva:linkedin-fill',
  crunchbase: 'simple-icons:crunchbase',
  g2: 'simple-icons:g2',
  glassdoor: 'simple-icons:glassdoor',
  twitter: 'icon-park-solid:twitter',
  crm: 'logos:airtable',
  similarWeb: 'simple-icons:similarweb',
  refresh: 'ic:twotone-refresh',
  complementarity: 'material-symbols:link',
  teamGlobalScore: 'ic:twotone-search',
  coFounderScore: 'ooui:user-avatar',
  chevronRight: 'majesticons:chevron-right',
} as const;
