import { Avatar, BadgeOrigin, styled } from '@mui/material';

import { ColorSchema } from '@styles/theme/palette';

export const CustomAvatarStyles = {
  Avatar: styled(Avatar, {
    shouldForwardProp: (prop) => prop !== 'colr',
  })<{
    colr: Exclude<ColorSchema, 'custom'>;
  }>(({ theme, colr }) => ({
    color: theme.palette[colr]?.contrastText,
    backgroundColor: theme.palette[colr]?.main,
    fontWeight: theme.typography.fontWeightMedium,
  })),

  BadgeSX: { vertical: 'bottom', horizontal: 'right' } as BadgeOrigin,
};
