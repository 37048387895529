export const CardSX = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '430px',
  width: '100%',
  bgcolor: 'background.paper',
  p: 1,
  pb: 4,
};
