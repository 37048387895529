import { useState } from 'react';

export const useMainLayoutState = () => {
  const [open, setOpen] = useState(false);

  const onHandleOpenNav = () => setOpen(true);

  const onHandleCloseNav = () => setOpen(false);

  return {
    open,
    onHandleCloseNav,
    onHandleOpenNav,
  };
};
