import { useGetStartupLogoLinks } from '@hooks/useGetStartupLogoLinks';
import { useStartupInfo } from '@hooks/useStartupInfo';
import { useTogglePopover } from '@hooks/useTogglePopover';

import { getIcons } from './StartupHeader.constants';

export const useStartupHeaderState = (startupId?: string) => {
  const { handleClosePopover, handleOpenPopover, openPopover } = useTogglePopover();
  const { setIsRefreshModalWindowOpen } = useStartupInfo();

  const { loading, startup } = useGetStartupLogoLinks(startupId);

  const links = getIcons({
    crunchbaseURL: startup?.crunchbase_link,
    g2URL: startup?.g2_link,
    glassdoorURL: startup?.glassdoor,
    linkedInURL: startup?.linkedin,
    twitterURL: startup?.twitter,
    websiteURL: startup?.link,
  });
  const onRefreshIconClickhandler = () => setIsRefreshModalWindowOpen(true);
  return {
    links,
    loading,
    openPopover,
    startup,
    onRefreshIconClickhandler,
    handleOpenPopover,
    handleClosePopover,
  };
};
