import { useCallback, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { debounce } from 'lodash';
import { useSnackbar } from 'notistack';

import { useResponsive } from '@hooks/useResponsive';
import { useStartupId } from '@hooks/useStartupId';
import { useStartupInfo } from '@hooks/useStartupInfo';
import { useToggle } from '@hooks/useToggle';

import { addStartup, getStartups } from '../../ModalWindows/AddStartup/addStartup.api';
import { refreshStartup } from '../../ModalWindows/RefreshData/api';

import { ICrunchbaseStartup } from './DashboardHeader.types';

import { LOCAL_STORAGE_LAST_SEARCHED_STARTUP_ID_KEY } from '@constants/global';

export const useDashboardHeaderState = () => {
  const isDesktop = useResponsive('up', 'lg');
  const { logout, user } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsRefreshModalWindowOpen, setIsHiddenAlertPopup, isHiddenAlertPopup, isRefreshModalWindowOpen } =
    useStartupInfo();

  const { id } = useStartupId();

  const [errorStatus, setErrorStatus] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [startupName, setStartupName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [startups, setStartups] = useState<ICrunchbaseStartup[]>([]);

  const [isDataRefreshedModalWindowOpen, onToggleIsDataRefreshedModalWindowOpen] = useToggle();

  const onCloseRefreshModalWindow = () => setIsRefreshModalWindowOpen(false);
  const onRefreshButtonClickHandler = async () => {
    try {
      setIsLoading(true);
      await refreshStartup({
        startup_uuid: id || '',
        user_id: user?.sub || '',
      });
      setIsLoading(false);
      onCloseRefreshModalWindow();
      onToggleIsDataRefreshedModalWindowOpen();
      !isHiddenAlertPopup && setIsHiddenAlertPopup(true);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      onCloseRefreshModalWindow();
      enqueueSnackbar('Failed to refresh startup', {
        variant: 'error',
      });
    }
  };
  //logout handler
  const handleLogout = () => {
    localStorage.removeItem(LOCAL_STORAGE_LAST_SEARCHED_STARTUP_ID_KEY);
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  };

  //open result modal window
  const onCloseResultModalWindowhandler = () => setErrorStatus(null);

  //open add startup window
  const [isAddStartupModalWindowOpen, onOpenAddStartupModalWindowHandler] = useToggle();

  const onCloseModalWindow = () => {
    searchValue && setSearchValue('');
    startups.length && setStartups([]);
    startupName && setStartupName('');
    onOpenAddStartupModalWindowHandler();
  };

  const onAddStartupHandler = async (event: React.MouseEvent<HTMLButtonElement>) => {
    //add startup handler
    try {
      const startup_id = event.currentTarget.id;
      const response = await addStartup({
        startup_id,
        user_id: user?.sub || '',
      });

      const startupName = startups.find((startup) => startup.identifier.uuid === startup_id)?.identifier.value || '';

      setStartupName(startupName);
      setErrorStatus(response?.status);
      onCloseModalWindow();
    } catch (error: any) {
      onCloseModalWindow();
      setErrorStatus(error?.response?.status);
      enqueueSnackbar('Failed to add startup', {
        variant: 'error',
      });
      console.log(error);
    }
  };

  const onGetStartupsHandler = async (value: string) => {
    try {
      const { data } = await getStartups({ input: value });
      setStartups(data);
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar('Failed to fetch startups', {
        variant: 'error',
      });
      setIsLoading(false);
      console.log(error);
    }
  };

  const debouncedGetStartups = useCallback(debounce(onGetStartupsHandler, 500), []);

  const onFindStartupInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value) {
      setIsLoading(true);
      debouncedGetStartups(value);
    } else {
      setStartups([]);
    }
    setSearchValue(value);
  };

  return {
    onAddStartupHandler,
    onOpenAddStartupModalWindowHandler,
    onFindStartupInputChangeHandler,
    handleLogout,
    onCloseModalWindow,
    onRefreshButtonClickHandler,
    searchValue,
    isLoading,
    startups,
    isAddStartupModalWindowOpen,
    isDesktop,
    errorStatus,
    startupName,
    isRefreshModalWindowOpen,
    isDataRefreshedModalWindowOpen,
    onToggleIsDataRefreshedModalWindowOpen,
    onCloseRefreshModalWindow,
    onCloseResultModalWindowhandler,
  };
};
