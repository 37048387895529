import { FC, useEffect } from 'react';

import { Alert, Button, Grid, Stack, Typography } from '@mui/material';

import { useStartupInfo } from '@hooks/useStartupInfo';

export const AlertSweetSpot: FC<IQueryComponentProps> = ({ startupId }) => {
  const {
    startup,
    isHiddenAlertPopup,
    setIsHiddenAlertPopup,
    onDismissButtonClickHandler,
    setIsRefreshModalWindowOpen,
  } = useStartupInfo();

  useEffect(() => {
    if (startup && !startup.is_fitting_sourcing_criterias) {
      setIsHiddenAlertPopup(false);
    } else {
      setIsHiddenAlertPopup(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startup]);

  const onAddSweetSpotClickHandler = () => setIsRefreshModalWindowOpen(true);
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: isHiddenAlertPopup ? 'none' : 'block',
        transition: 'all 0.2s linear',
      }}
    >
      <Alert
        variant="filled"
        severity="error"
        sx={{
          '.MuiAlert-message': {
            display: 'flex',
            width: 1,
            alignItems: 'center',
            py: '1px',
            flexDirection: { xs: 'column', md: 'row' },
          },
        }}
      >
        <Typography
          variant="body2"
          sx={{
            flex: 1,
            textAlign: { xs: 'center', md: 'row' },
            fontSize: { xs: '12px', sm: '14px' },
          }}
        >
          Beware, a lot of data are missing because this startup is not in our
          sweet spot
        </Typography>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            sx={{
              color: (theme) => theme.palette.grey[700],
              background: 'white',
              ':hover': {
                background: (theme) => theme.palette.grey[100],
              },
              px: { xs: '3px', sm: '6px' },
              fontSize: { xs: '12px', md: '14px' },
            }}
            onClick={onAddSweetSpotClickHandler}
          >
            Add to sweet spot
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: 'inherit',
              borderColor: 'inherit',
              ':hover': {
                borderColor: 'inherit',
              },
              px: { xs: '2px' },
              fontSize: { xs: '12px' },
            }}
            onClick={onDismissButtonClickHandler}
          >
            Dismiss
          </Button>
        </Stack>
      </Alert>
    </Grid>
  );
};
