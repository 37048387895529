import { useCallback, useEffect, useRef, useState } from 'react';

import { Search_BarQuery } from '@generated/graphql';

interface IuseKeyboardSearchProps {
  items?: Omit<Search_BarQuery['search_startup_name'], 'link'>;
  onSearchItemClickHandler: (startupId: string) => null | undefined;
}
export const useKeyboardSearch = (props: IuseKeyboardSearchProps) => {
  const { items, onSearchItemClickHandler } = props;

  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );
  const selectedOptionRef = useRef<HTMLLIElement | null>(null);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (items?.length) {
        switch (event.key) {
          case 'ArrowUp':
            setSelectedOptionIndex((prevIndex) =>
              prevIndex !== null && prevIndex > 0
                ? prevIndex - 1
                : items?.length - 1
            );
            break;
          case 'ArrowDown':
            setSelectedOptionIndex((prevIndex) =>
              prevIndex !== null && prevIndex < items?.length - 1
                ? prevIndex + 1
                : 0
            );
            break;
          case 'Enter':
            if (selectedOptionIndex !== null) {
              const selectedItem = items[selectedOptionIndex];
              setSelectedOptionIndex(null);
              onSearchItemClickHandler(selectedItem.uuid);
            }
            break;
          default:
            break;
        }
      }
    },
    [items, onSearchItemClickHandler, selectedOptionIndex]
  );

  useEffect(() => {
    let animationFrameId: number | undefined;

    if (selectedOptionRef.current) {
      animationFrameId = window.requestAnimationFrame(() => {
        selectedOptionRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        });
      });
    }

    return () => {
      if (animationFrameId !== undefined) {
        window.cancelAnimationFrame(animationFrameId);
      }
    };
  }, [selectedOptionIndex]);

  return { handleKeyDown, selectedOptionIndex, selectedOptionRef };
};
