import { Theme, alpha } from '@mui/material';

import { ITooltipItemsProps } from './StartupLogoPopover.types';

import { ICONIFY_ICON_NAMES } from '@constants/icons';

export const INSIDE_SCOPE_MESSAGE = 'US startup led by European founders';

export const getPopoverLinkItems = (props: ITooltipItemsProps) => {
  const { crunchbase, g2, glassdoor, linkedIn, similarWeb, twitter } = props;
  return [
    {
      iconName: ICONIFY_ICON_NAMES.crunchbase,
      url: crunchbase.url,
      color: (theme: Theme) => theme.palette.secondary.main,
      title: 'Crunchbase',
      date: crunchbase.date,
    },
    {
      iconName: ICONIFY_ICON_NAMES.linkedin,
      url: linkedIn.url,
      color: (theme: Theme) => alpha(theme.palette.secondary.main, 0.9),
      title: 'LinkedIn',
      date: linkedIn.date,
    },
    {
      iconName: ICONIFY_ICON_NAMES.similarWeb,
      url: similarWeb.url,
      color: (theme: Theme) => alpha(theme.palette.warning.main, 0.9),
      title: 'Similar Web',
      date: similarWeb.date,
    },
    {
      iconName: ICONIFY_ICON_NAMES.glassdoor,
      url: glassdoor.url,
      color: (theme: Theme) => theme.palette.success.dark,
      title: 'Glassdoor',
      date: glassdoor.date,
    },
    {
      iconName: ICONIFY_ICON_NAMES.g2,
      url: g2.url,
      color: (theme: Theme) => theme.palette.error.main,
      title: 'G2 Crowd',
      date: g2.date,
    },
    {
      iconName: ICONIFY_ICON_NAMES.twitter,
      url: twitter.url,
      color: (theme: Theme) => theme.palette.secondary.main,
      title: 'Twitter',
      date: twitter.date,
    },
  ].filter((item) => item.date);
};
