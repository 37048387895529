import { AlertProps } from '@mui/material';
import { Theme } from '@mui/material/styles';

import { Iconify } from '../../components/Iconify';

const COLORS = ['info', 'success', 'warning', 'error'] as const;

export const Alert = (theme: Theme) => {
  const rootStyle = (ownerState: AlertProps) => {
    const standardVariant = ownerState.variant === 'standard';

    const filledVariant = ownerState.variant === 'filled';

    const outlinedVariant = ownerState.variant === 'outlined';

    const colorStyle = COLORS.map((color) => ({
      ...(ownerState.severity === color && {
        ...(standardVariant && {
          color: theme.palette[color].darker,
          backgroundColor: theme.palette[color].lighter,
          '& .MuiAlert-icon': {
            color: theme.palette[color].main,
          },
        }),

        ...(filledVariant && {
          color: theme.palette[color].contrastText,
          backgroundColor: theme.palette[color].main,
        }),

        ...(outlinedVariant && {
          color: theme.palette[color].dark,
          border: `solid 1px ${theme.palette[color].main}`,
          '& .MuiAlert-icon': {
            color: theme.palette[color].main,
          },
        }),
      }),
    }));

    return [...colorStyle];
  };

  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <Iconify icon="material-symbols:info-rounded" />,
        },
      },

      styleOverrides: {
        root: ({ ownerState }: { ownerState: AlertProps }) =>
          rootStyle(ownerState),
        icon: {
          alignItems: 'center',
          opacity: 1,
        },
      },
    },
    MuiAlertTitle: {
      styleOverrides: {
        root: {
          marginBottom: theme.spacing(0.5),
        },
      },
    },
  };
};
