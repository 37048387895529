import { FC } from 'react';

import { Skeleton, Stack } from '@mui/material';

export const AddStartupSkeleton: FC = () => {
  return (
    <>
      {[...Array(2)].map((_, index) => (
        <Stack
          key={index}
          direction="row"
          spacing={1}
          width={1}
          justifyContent="space-between"
          p={1}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Skeleton
              variant="rounded"
              sx={{ width: 40, height: 40, borderRadius: 1 }}
            />
            <Skeleton variant="rounded" sx={{ width: 100, height: 20 }} />
          </Stack>
          <Skeleton
            variant="rounded"
            sx={{ width: 50, height: 40, flexShrink: 0 }}
          />
        </Stack>
      ))}
    </>
  );
};
