import { FC } from 'react';

import { Paper, PaperProps, Typography } from '@mui/material';

interface IProps extends PaperProps {
  query?: string;
  isNoData?: boolean;
}

export const SearchNotFound: FC<IProps> = (props) => {
  const { query, sx, isNoData, ...other } = props;
  return query && isNoData ? (
    <Paper
      sx={{
        textAlign: 'center',
        boxShadow: 'none',
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h6" paragraph>
        Not found
      </Typography>

      <Typography variant="body2">
        No results found for &nbsp;
        <strong>&quot;{query}&quot;</strong>.
        <br /> Try checking for typos or using complete words.
      </Typography>
    </Paper>
  ) : (
    <Typography variant="body2" sx={{ textAlign: 'center', ...sx }}>
      Please enter keywords
    </Typography>
  );
};
