import { FC } from 'react';

import { Modal, Stack, TextField, Typography, alpha } from '@mui/material';

import { StyledCard } from '@styles/overrides/Card';

import { ICrunchbaseStartup } from '../../Header/DashboardHeader/DashboardHeader.types';
import { Scrollbar } from '../../ScrollBar';

import { AddStartupSkeleton } from './AddStartup.skeleton';
import { AddStartupStyles as Styled } from './AddStartup.styles';
import { StartupItem } from './StartupItem';

interface IAddStartupModalWindowProps {
  isOpen: boolean;
  isLoading: boolean;
  onCloseWindowHandler: () => void;
  startups: ICrunchbaseStartup[];
  inputValue: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onAddStartupHandler: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
}

export const AddStartupModalWindow: FC<IAddStartupModalWindowProps> = (
  props
) => {
  const {
    isOpen,
    onCloseWindowHandler,
    onAddStartupHandler,
    inputValue,
    isLoading,
    onChange,
    startups,
  } = props;

  return (
    <Modal open={isOpen} onClose={onCloseWindowHandler} sx={{ m: 1 }}>
      <StyledCard sx={Styled.CardSX}>
        <Stack sx={{ maxWidth: 350, width: 1 }}>
          <Stack spacing={2} alignItems="center">
            <Typography variant="h3">Add startup</Typography>
            <Typography
              variant="body1"
              sx={{
                width: 0.8,
                textAlign: 'center',
                color: (theme) => theme.palette.grey[600],
                pb: 3,
              }}
            >
              Search a startup from Crunchbase and add it to Ramp
            </Typography>
            <TextField
              fullWidth
              value={inputValue}
              onChange={onChange}
              label="Startup Name"
            />
          </Stack>
          <Stack
            sx={{
              borderRadius: 1,
              width: 1,
              ...((startups.length || isLoading) && {
                border: (theme) =>
                  `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`,
              }),
            }}
          >
            <Scrollbar
              sx={{
                maxHeight: 130,
                height: 1,
                '& .simplebar-track.simplebar-vertical': {
                  right: 0,
                  top: 10,
                },
              }}
            >
              {isLoading && <AddStartupSkeleton />}
              {!isLoading &&
                startups?.map((startup) => (
                  <StartupItem
                    key={startup.identifier.uuid}
                    startupId={startup.identifier.uuid}
                    startupLogoId={startup.identifier.image_id}
                    startupName={startup.identifier.value}
                    description={startup.short_description}
                    onAddStartupHandler={onAddStartupHandler}
                  />
                ))}
            </Scrollbar>
          </Stack>
        </Stack>
      </StyledCard>
    </Modal>
  );
};
