import { FC } from 'react';

import { Box, Stack } from '@mui/material';

import { Logo } from '../Logo';
import { Scrollbar } from '../ScrollBar';
import { VerticalNavSection } from '../VerticalNavSection';

import { NavAccount } from './NavAccount';

import { NavLinkRoutesConfig } from '@constants/config-navigation';

export const NavVerticalRenderContent: FC = () => {
  return (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Logo />
        <NavAccount />
      </Stack>

      <VerticalNavSection data={NavLinkRoutesConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );
};
