import { FC } from 'react';

import { Modal, Stack, Typography } from '@mui/material';

import { StyledCard } from '@styles/overrides/Card';

import { RESULT_MESSAGE_STRINGS } from './AddStartup.constants';
import { AddStartupStyles } from './AddStartup.styles';

interface IResultMessageProps {
  errorStatus: number;
  isOpen: boolean;
  startupName: string;
  onCloseModalWondow: () => void;
}
export const ResultMessageModalWindow: FC<IResultMessageProps> = (props) => {
  const { isOpen, onCloseModalWondow, errorStatus, startupName } = props;

  const strings = RESULT_MESSAGE_STRINGS(startupName);
  const resultString =
    errorStatus === 200
      ? strings.successfull
      : errorStatus >= 400
      ? strings.unsuccessfully
      : null;
  return (
    <Modal open={isOpen} onClose={onCloseModalWondow} sx={{ m: 1 }}>
      <StyledCard sx={{ ...AddStartupStyles.CardSX, px: '22px' }}>
        <Stack spacing={2} p={2} sx={{ textAlign: 'center' }}>
          <Typography variant="h3" sx={{ color: resultString?.color }}>
            {resultString?.title}
          </Typography>
          <Typography variant="body1">{resultString?.subtitle}</Typography>
        </Stack>
      </StyledCard>
    </Modal>
  );
};
