import { AppBar, Button, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { bgBlur } from '@utils/cssStyles';

import { IAppBarStylesProps } from './DashboardHeader.types';

import { HEADER_CONFIG, NAV_VERTICAL_CONFIG } from '@constants/config-global';

export const DashboardHeaderStyles = {
  AppBar: styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'isOffset' && prop !== 'isDesktop',
  })<IAppBarStylesProps>(({ theme, isDesktop, isOffset }) => ({
    boxShadow: 'none',
    height: HEADER_CONFIG.H_MOBILE,
    zIndex: theme.zIndex.appBar + 1,
    ...bgBlur({
      color: theme.palette.background.default,
    }),
    transition: theme.transitions.create(['height'], {
      duration: theme.transitions.duration.shorter,
    }),
    ...(isDesktop && {
      width: `calc(100% - ${NAV_VERTICAL_CONFIG.W_DASHBOARD + 1}px)`,
      height: HEADER_CONFIG.H_DASHBOARD_DESKTOP,
      ...(isOffset && {
        height: HEADER_CONFIG.H_DASHBOARD_DESKTOP_OFFSET,
      }),
    }),
  })),

  Button: styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.grey[700],
    fontSize: 13,
    padding: '6px 10px',
    marginRight: theme.spacing(5),
    textTransform: 'none',
    height: 34,
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(1),
    },
  })),

  ToolBar: styled(Toolbar)(({ theme }) => ({
    height: 1,
    [theme.breakpoints.up('md')]: {
      minHeight: HEADER_CONFIG.H_MOBILE,
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 5),
    },
  })),
};
