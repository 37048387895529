import { FC } from 'react';

import { Box, Divider, Stack, Typography } from '@mui/material';

import { IScopeInfoProps } from './StartupLogoPopover.types';

export const ScopeInfo: FC<IScopeInfoProps> = (props) => {
  const { scopeInfo } = props;
  const {
    isManualyAdded,
    isOutsideScope,
    color,
    items,
    scopeSubtitle,
    scopeTitle,
  } = scopeInfo;
  return (
    <>
      <Divider sx={{ borderStyle: 'solid', my: 1.5 }} />
      {isManualyAdded ? (
        <Typography
          variant="h6"
          color={(theme) => theme.palette.warning.main}
          mb={1}
        >
          Manually added
        </Typography>
      ) : (
        <>
          <Typography variant="h6" sx={{ color }}>
            {scopeTitle}
          </Typography>
          <Typography variant="body2">{scopeSubtitle}</Typography>
          <Stack mb={1}>
            {items.map((item) => (
              <Box key={item} component="li" sx={{ color }}>
                {item}
              </Box>
            ))}
          </Stack>
        </>
      )}

      {isOutsideScope && (
        <>
          <Divider sx={{ borderStyle: 'solid', mb: 1 }} />
          <Typography variant="h6" textAlign="center">
            Data Inside CRM
          </Typography>
        </>
      )}
    </>
  );
};
