import React, { FC } from 'react';

import { Box, Button, Stack, Typography, alpha } from '@mui/material';

import { BASE_CRANCHBASE_IMAGE_URL } from '@constants/global';

interface IStartupItemProps {
  startupName: string;
  startupId: string;
  startupLogoId: string;
  onAddStartupHandler: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  description: string;
}
export const StartupItem: FC<IStartupItemProps> = (props) => {
  const {
    startupName,
    description,
    startupId,
    startupLogoId,
    onAddStartupHandler,
  } = props;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      p={1.5}
      borderBottom={(theme) =>
        `solid 1px ${alpha(theme.palette.grey[500], 0.24)}`
      }
      sx={{
        ':last-child': {
          borderBottom: 'none',
        },
      }}
    >
      <Stack direction="row" alignItems="flex-start" spacing={1}>
        <Box
          component="img"
          sx={{
            borderRadius: 1,
            width: 40,
            height: 40,
            backgroundColor: (theme) => theme.palette.secondary.main,
          }}
          src={`${BASE_CRANCHBASE_IMAGE_URL}${startupLogoId}`}
          alt="logo"
        />
        <Stack width={1}>
          <Typography variant="subtitle2">{startupName}</Typography>
          <Typography variant="caption">{description}</Typography>
        </Stack>
      </Stack>

      <Button
        variant="contained"
        id={startupId}
        onClick={onAddStartupHandler}
        sx={{ maxHeight: 42 }}
      >
        Add
      </Button>
    </Stack>
  );
};
