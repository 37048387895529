import { FC } from 'react';

import { Box, alpha } from '@mui/material';

import { Iconify } from '../Iconify';

interface ICircleColorBackgroundIconProps {
  color: string;
  icon: string;
}
export const CircleColorBackgroundIcon: FC<ICircleColorBackgroundIconProps> = (
  props
) => {
  const { color, icon } = props;
  return (
    <Box
      component="span"
      sx={{
        borderRadius: '50px',
        width: 30,
        height: 30,
        background: alpha(color, 0.16),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Iconify icon={icon} color={color} />
    </Box>
  );
};
