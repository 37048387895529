import {
  BASE_ADD_REFRESH_STARTUP_URL,
  BASE_AUTOCOMPLETE_URL,
  apiServices,
} from '@services/apiService';

interface IAddStartupPayload {
  startup_id: string;
  user_id: string;
}

interface IGetStartupsParams {
  input: string;
}

export const getStartups = (params: IGetStartupsParams) =>
  apiServices.fetchData(BASE_AUTOCOMPLETE_URL, params);

export const addStartup = (payload: IAddStartupPayload) =>
  apiServices.postData(BASE_ADD_REFRESH_STARTUP_URL, payload);
