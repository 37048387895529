import { createContext } from 'react';

export interface IData {
  label: string;
  value: number;
}
export interface InternationalContextProps {
  isEmployeeCountryRepartitionLoading: boolean;
  isWebVisitsDistributionLoading: boolean;
  employeeData?: IData[];
  webVisitsData?: IData[];
  setIsEmployeeCountryRepartitionLoading: (loading: boolean) => void;
  setisWebVisitsDistributionLoading: (loading: boolean) => void;
  setEmployeeData: (data?: IData[]) => void;
  setWebVisitsData: (data?: IData[]) => void;
}
export const InternationalContext = createContext<InternationalContextProps>({
  isEmployeeCountryRepartitionLoading: false,
  isWebVisitsDistributionLoading: false,
  setIsEmployeeCountryRepartitionLoading: (loading: boolean) => {},
  setisWebVisitsDistributionLoading: (loading: boolean) => {},
  setEmployeeData: (data?: IData[]) => {},
  setWebVisitsData: (data?: IData[]) => {},
});
