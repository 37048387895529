export const VARIANTS = {
  varSmall: {
    hover: { scale: 1.1 },
    tap: { scale: 0.95 },
  },
  varMedium: {
    hover: { scale: 1.09 },
    tap: { scale: 0.97 },
  },
  varLarge: {
    hover: { scale: 1.08 },
    tap: { scale: 0.99 },
  },
};
