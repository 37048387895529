import { FC, SVGProps } from 'react';

import { ReactComponent as email } from '@assets/icons/cards/ic_email.svg';
import { ReactComponent as chartSparkline } from '@assets/icons/charts/ic_chart_sparkline.svg';
import { ReactComponent as founding } from '@assets/icons/charts/ic_founding.svg';
import { ReactComponent as growth } from '@assets/icons/charts/ic_growth.svg';
import { ReactComponent as impact } from '@assets/icons/charts/ic_impact.svg';
import { ReactComponent as presence } from '@assets/icons/charts/ic_presence.svg';
import { ReactComponent as priority } from '@assets/icons/charts/ic_priority.svg';
import { ReactComponent as usWebvisits } from '@assets/icons/charts/ic_us_webvisits.svg';
import { ReactComponent as icon404 } from '@assets/icons/ic_404.svg';
import { ReactComponent as startupLogo } from '@assets/icons/ic_add_startup_logo.svg';
import { ReactComponent as arrowDown } from '@assets/icons/ic_down.svg';
import { ReactComponent as emailSent } from '@assets/icons/ic_email_sent.svg';
import { ReactComponent as logout } from '@assets/icons/ic_logout.svg';
import { ReactComponent as password } from '@assets/icons/ic_password.svg';
import { ReactComponent as navHome } from '@assets/icons/navigation/ic_home.svg';
import { ReactComponent as navSettings } from '@assets/icons/navigation/ic_settings.svg';
import { ReactComponent as navStartups } from '@assets/icons/navigation/ic_startups.svg';
import { ReactComponent as logo } from '@assets/icons/ramp_logo.svg';

const ICONS = {
  icon404,
  startupLogo,
  chartSparkline,
  usWebvisits,
  founding,
  impact,
  growth,
  presence,
  priority,
  navHome,
  navSettings,
  navStartups,
  logo,
  password,
  emailSent,
  logout,
  arrowDown,
  email,
};

export type TIconType = keyof typeof ICONS;

interface IIconProps extends SVGProps<SVGSVGElement> {
  type: TIconType;
  selectProps?: unknown;
}

export const Icon: FC<IIconProps> = (props) => {
  const { type, fill, selectProps } = props;
  const NewIcon = ICONS[type];
  return <NewIcon {...(selectProps as Object)} style={{ fill: fill }} />;
};
