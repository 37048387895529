import React, { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import ReactDOM from 'react-dom/client';

import { App } from './App';

posthog.init('phc_8sOOJJSYprK5NZlzTLSERjXDc7F8GOkm4l4CzbJ3aZq', {
  api_host: 'https://eu.i.posthog.com',
  autocapture: true,
});

export const Root = () => {
  return (
    <React.StrictMode>
      <PostHogProvider client={posthog}>
        <App />
      </PostHogProvider>
    </React.StrictMode>
  );
};

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<Root />);
