import { FC, useEffect } from 'react';

import { Box, Drawer } from '@mui/material';
import { useLocation } from 'react-router-dom';

import { useResponsive } from '@hooks/useResponsive';

import { NavVerticalStyles as Styled } from './NavVertical.styles';
import { NavVerticalRenderContent } from './NavVerticalRenderContent';

import { NAV_VERTICAL_CONFIG as NAV } from '@constants/config-global';

interface INavVerticalProps {
  openNav: boolean;
  onCloseNav: VoidFunction;
}

export const NavVertical: FC<INavVerticalProps> = (props) => {
  const { openNav, onCloseNav } = props;

  const { pathname } = useLocation();
  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: Styled.DrawerSX,
          }}
        >
          <NavVerticalRenderContent />
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: Styled.NotDesktopDrawerSX,
          }}
        >
          <NavVerticalRenderContent />
        </Drawer>
      )}
    </Box>
  );
};
