import {
  Box,
  Popper as MUIPopper,
  PopperProps,
  Theme,
  alpha,
  styled,
} from '@mui/material';

import { bgBlur } from '@utils/cssStyles';

import { SEARCH_BAR_CONFIG } from '@constants/config-global';

export const SearchBarStyles = {
  Searchbar: styled('div')(({ theme }) => ({
    ...bgBlur({ color: theme.palette.background.default }),
    top: 0,
    left: 0,
    zIndex: 99,
    width: '100%',
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    height: SEARCH_BAR_CONFIG.H_MOBILE,
    padding: theme.spacing(0, 3),
    boxShadow: theme.customShadows.z8,
    [theme.breakpoints.up('md')]: {
      height: SEARCH_BAR_CONFIG.H_DESKTOP,
      padding: theme.spacing(0, 5),
    },
  })),

  Popper: styled((props: PopperProps) => <MUIPopper {...props} />)(
    ({ theme }) => ({
      left: `8px !important`,
      top: `${SEARCH_BAR_CONFIG.H_MOBILE + 8}px !important`,
      width: 'calc(100% - 16px) !important',
      transform: 'none !important',
      [theme.breakpoints.up('md')]: {
        top: `${SEARCH_BAR_CONFIG.H_DESKTOP + 8}px !important`,
      },
    })
  ),

  RenderOption: styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5, 2),
    margin: 0,
    display: 'block',
    border: `solid 1px transparent`,
    borderBottomColor: theme.palette.divider,
    '&:last-of-type': {
      borderBottomColor: 'transparent',
    },
    '&:hover': {
      borderColor: theme.palette.primary.main,
      borderStyle: 'dashed solid',
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.hoverOpacity
      ),
    },
  })),

  RenderOptionSX: {
    padding: (theme: Theme) => theme.spacing(0.5, 2),
    margin: 0,
    display: 'block',
    border: `solid 1px transparent`,
    borderBottomColor: (theme: Theme) => theme.palette.divider,
    '&:last-of-type': {
      borderBottomColor: 'transparent',
    },
    '&:hover': {
      borderColor: (theme: Theme) => theme.palette.primary.main,
      borderStyle: 'dashed solid',
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    },
    py: '12px !important',
  },
};

export const SkeletonStyles = {
  ImageSX: { width: 40, height: 40, borderRadius: '10px' },
  TextWrapperSX: {
    minWidth: 200,
    flex: 1,
    display: 'flex',
    height: 50,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
};
