import { useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { useSnackbar } from 'notistack';

//shows error popup
export const useNotistackError = (error: ApolloError | undefined) => {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (error) {
      console.log(error);

      enqueueSnackbar(error?.message, {
        variant: 'error',
      });
    }
  }, [enqueueSnackbar, error]);
};
