import { styled } from '@mui/material/styles';

export const LogoStyles = {
  Title: styled('h1')(({ theme }) => ({
    fontSize: '22px',
    color: theme.palette.primary.main,
    margin: 0,
    lineHeight: 1,
    paddingBottom: '0.3px',
  })),
  Subtitle: styled('span')(({ theme }) => ({
    fontSize: '12px',
    color: theme.palette.primary.main,
    fontWeight: 300,
    lineHeight: 1,
  })),
  TitleWrapperSX: {
    marginLeft: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
  MainWrapperSX: {
    width: '100%',
    height: '32px',
    display: 'flex',
  },
};
