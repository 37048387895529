import { FC } from 'react';

import { InputAdornment, InputBase } from '@mui/material';

import { Iconify } from '../../../../Iconify';

import { IRenderInputProps } from './SearchBar.types';

export const RenderInput: FC<IRenderInputProps> = (props) => {
  const { handleKeyDown, onInputChangeHandler } = props;

  return (
    <InputBase
      fullWidth
      autoFocus
      placeholder="Search..."
      onKeyDown={handleKeyDown}
      onChange={onInputChangeHandler}
      startAdornment={
        <InputAdornment position="start">
          <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
        </InputAdornment>
      }
      sx={{ height: 1, typography: 'h6' }}
    />
  );
};
