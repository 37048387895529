import { FC } from 'react';

import { LinearProgress } from '@mui/material';

import { LoadingScreenWrapper } from './LoadingScreen.styles';

export const LoadingScreen: FC = () => {
  return (
    <LoadingScreenWrapper>
      <LinearProgress
        color="inherit"
        sx={{ width: 1, maxWidth: { xs: 290, sm: 360 } }}
      />
    </LoadingScreenWrapper>
  );
};
